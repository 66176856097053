<template>
  <button v-if="globalStore.hasUserDeleteRule"
          @click="clickFunctionParameter ? clickFunction(clickFunctionParameter) : clickFunction()"
          class="btn btn-sm btn-outline-danger"
  >
    {{ globalStore.vocabularyStore.getWordByIdAndLanguage('delete', globalStore.languageId) }}
  </button>
</template>

<script>
import {useGlobalStore} from "@/stores/GlobalStore"

export default {
  name: 'ButtonDelete',
  components: {},
  props: {
    clickFunction: {
      required: true,
      type: Function
    },
    clickFunctionParameter: {
      required: false
    }
  },

  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore
    }
  }
}
</script>
