import { defineStore } from 'pinia'

export const useTournamentStageStore = defineStore('TournamentStageStore', {
  state: () => {
    return {
      tournamentStages: [],
      activeStageId: 0
    }
  },
  getters: {
    count: state => state.tournamentStages.length,
    getTournamentStageByStageId: state => {
      return stageId => {
        for (let i in state.tournamentStages) {
          if (state.tournamentStages[i].stageId === stageId) {
            return state.tournamentStages[i]
          }
        }
        return {}
      }
    },
    getPreviousStageIdByStageId: state => {
      return stageId => {
        let id = null
        for (let i in state.tournamentStages) {
          if (state.tournamentStages[i].stageId === stageId) {
            return id
          }
          id = state.tournamentStages[i].stageId
        }
        return null
      }
    },
    getNextStageIdByStageId: state => {
      return stageId => {
        let id = null
        for (let i in state.tournamentStages) {
          if (id) {
            return state.tournamentStages[i].stageId
          }
          if (state.tournamentStages[i].stageId === stageId) {
            id = state.tournamentStages[i].stageId
          }
        }
        return null
      }
    },
    getPreviousValidNonFinalTournamentStage: state => {
      return (stageId, tournamentParticipantCount, globalStore) => {
        for (let i = 0; i < 10; i++) {
          const previousStageId = state.getPreviousStageIdByStageId(stageId)
          if (previousStageId) {
            const previousTournamentStage = state.getTournamentStageByStageId(previousStageId)
            if (
              !globalStore.stageStore.isStageFinal(previousStageId)
              && previousTournamentStage.tournamentParticipantMinCount <= tournamentParticipantCount
            ) {
              return previousTournamentStage
            }
            stageId = previousStageId
          }
        }
        return null
      }
    },
    getActiveStageId: state => state.activeStageId
  },
  actions: {
    setData(data) {
      this.tournamentStages = data
    },
    addDataRow(data) {
      this.tournamentStages.push(data)
    },
    deleteDataRow(data) {
      this.tournamentStages = this.tournamentStages.filter(
        existingTournamentStage => existingTournamentStage.id !== data.id
      )
    },
    replaceDataRow(data) {
      for (let i in this.tournamentStages) {
        if (this.tournamentStages[i].stageId === data.stageId) {
          this.tournamentStages[i] = data
          return true
        }
      }
      return false
    },
    setActiveStageId(stageId) {
      this.activeStageId = stageId
    }
  }
})
