import { defineStore } from 'pinia'

export const useEventRefereeStore = defineStore('EventRefereeStore', {
  state: () => {
    return {
      eventReferees: []
    }
  },
  getters: {
    count: state => state.eventReferees.length,
    getEventRefereesByEventId: state => {
      return id => {
        return state.eventReferees.filter(row => row.eventId === id)
      }
    }
  },
  actions: {
    setData(data) {
      this.eventReferees = data
    },
    addData(data) {
      for (let i in data) {
        const referee = data[i]
        let exists = false
        for (let j in this.eventReferees) {
          if (this.eventReferees[j].id === referee.id) {
            exists = true
          }
        }
        if (!exists) {
          this.eventReferees[referee.id] = referee
        }
      }
    }
  }
})
