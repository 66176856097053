<template>
  <template v-if="!getItems().length">
    <template v-if="globalStore.isUserTypeAdministrator">
      <template v-if="addFormActive === true">
        <table class="table table-hover table-striped align-middle table-light">
          <tbody>
          <tr>
            <td>
              <ButtonBack :click-function="deInitAddForm"/>
            </td>
            <td>
              <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
                <FormKitSchema :schema="schema"/>
              </FormKit>
            </td>
          </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <ButtonAdd
          :click-function="initAddForm"
          :word="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage add params', globalStore.languageId)"
        />
      </template>
    </template>
  </template>
  <template v-else>
    <table class="table table-hover table-striped align-middle table-light">
      <tbody>
        <tr>
          <td>
            <template v-if="globalStore.isUserTypeAdministrator && updateFormActive === true">
              <ButtonBack :click-function="deInitUpdateForm"/>
              <FormKit type="form" v-model="itemData" @submit="processUpdateForm()">
                <FormKitSchema :schema="schema"/>
              </FormKit>
            </template>
            <template v-else>
              <ul>
                <template v-for="(roundParameter, round) in getItems()" :key="round">
                  <li>
                    {{ globalStore.getEventParameterNameById(roundParameter.eventParameterId) }}: {{ roundParameter.value }}
                  </li>
                </template>
              </ul>
            </template>
          </td>
          <td :rowspan="getItems().length">
            <template v-if="globalStore.isUserTypeAdministrator">
              <template v-if="(!eventStore.hasStageEvents(stageId) || eventStore.hasStageOnlyBlankEvents(stageId)) && updateFormActive === false">
                <ButtonUpdate :click-function="initUpdateForm"/>
                <span>&#160;</span>
                <ButtonDelete :click-function="deleteItem"/>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
</template>

<script>
import {ref, toRef} from 'vue'
import { useEventStore } from "@/stores/EventStore"
import { useTournamentStageStore } from "@/stores/TournamentStageStore"
import { useTeamEventRoundStore } from "@/stores/TeamEventRoundStore"
import { useTeamEventRoundParameterStore } from "@/stores/TeamEventRoundParameterStore"
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useEventParameterStore } from "@/stores/EventParameterStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import { FormKitSchema } from '@formkit/vue'
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";

export default {
  name: 'TeamEventRoundParameter',
  components: {ButtonDelete, ButtonUpdate, ButtonAdd, ButtonBack, FormKitSchema},
  props: {
    teamEventStage: {
      required: true,
      type: Number
    },
    stageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const teamEventStageId = toRef(props, 'teamEventStage'),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      tournamentStageStore = useTournamentStageStore(),
      teamEventRoundStore = useTeamEventRoundStore(),
      eventStore = useEventStore(),
      teamEventRoundParameterStore = useTeamEventRoundParameterStore(),
      eventStageParameterStore = useEventStageParameterStore(),
      eventParameterStore = useEventParameterStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore(),
      onlyTrue = {
        '1': ''
      },
      schema = ref([])

    function getItems() {
      let items = []
      for (let i in teamEventRoundParameterStore.teamEventRoundParameters[teamEventStageId.value]) {
        items.push(teamEventRoundParameterStore.teamEventRoundParameters[teamEventStageId.value][i])
      }
      return items
    }

    function setSchema() {
      schema.value = []
      schema.value.push({
        $el: 'h3',
        children: 'Parametry zápasu'
      });

      for (let i in eventParameterStore.eventParameters) {
        const item = eventParameterStore.eventParameters[i]
        if (
          item.id === 9 ||
          item.id === 11 ||
          item.id === 12 ||
          item.id === 1 ||
          item.id === 2 ||
          item.id === 3 ||
          item.id === 4 ||
          item.id === 5
        ) {
          const parameter = {
            $formkit: 'number',
            name: item.id.toString(),
            label: globalStore.getEventParameterNameById(item.id),
          }

          if (item.id === 1 || item.id === 2 || item.id === 11 || item.id === 12) {
            parameter.validation = 'required'
            parameter.min = 1
          } else if (item.id === 9) {
            parameter.min = 2
            parameter.max = 3
            parameter.validation = 'required|min:2|max:3'
          } else if (item.id === 4 || item.id === 5) {
            parameter.$formkit = 'checkbox'
            parameter.options = onlyTrue
          }
          schema.value.push(parameter)
        }
      }

      schema.value.push({
        $el: 'h3',
        children: 'Parametry livescore'
      });

      for (let i in eventParameterStore.eventParameters) {
        const item = eventParameterStore.eventParameters[i]
        if (
            item.id === 6 ||
            item.id === 7 ||
            item.id === 8 ||
            item.id === 13 ||
            item.id === 14
        ) {
          const parameter = {
            $formkit: 'number',
            name: item.id.toString(),
            label: globalStore.getEventParameterNameById(item.id),
            id: 'id' + item.id.toString(),
            min: 1
          }
          if (item.id === 13) {
            parameter.help = globalStore.vocabularyStore.getWordByIdAndLanguage('event timeout alert help', globalStore.languageId)
          } else if (item.id === 14) {
            parameter.help = globalStore.vocabularyStore.getWordByIdAndLanguage('event autotimeout help', globalStore.languageId)
          }
          schema.value.push(parameter)
        }
      }
    }

    function initAddForm() {
      itemData.value[''] = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      const items = getItems()
      if (!items.length) {
        return
      }
      itemData.value = {}
      for (let i in items) {
        const item = items[i]
        if (item.eventParameterId === 4 || item.eventParameterId === 5) {
          itemData.value[item.eventParameterId] = [item.value]
        } else {
          itemData.value[item.eventParameterId] = item.value
        }
      }
      setSchema()

      updateFormActive.value = true
    }

    function processAddForm() {
      itemData.value[''].teamEventStageId = teamEventStageId.value
      fetch(globalStore.apiUrl + '/admin/team-event-round-parameter', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            teamEventRoundParameterStore.addDataFromArray(response.data)
            deInitAddForm()
            notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId)})
          } else if (response.error) {
            notificationStore.add({type: 'error', message: response.error.description})
          } else {
            notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
          }
        })
        .catch((err) => console.error(err))
    }

    function processUpdateForm() {
      const item = itemData.value
      if (item) {
        itemData.value.teamEventStageId = teamEventStageId.value
        fetch(globalStore.apiUrl + '/admin/team-event-round-parameter', {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(itemData.value)
        })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            teamEventRoundParameterStore.replaceRoundParameters(response.data.stageEventParameter, teamEventStageId.value)
            if (response.data.eventParticipant !== undefined) {
              for (let i in response.data.eventParticipant) {
                const eventParticipant = response.data.eventParticipant[i],
                    event = eventStore.getEventById(eventParticipant.eventId)
                for (let j in event.eventParticipant) {
                  if (event.eventParticipant[j].targetPoints !== eventParticipant.targetPoints) {
                    event.eventParticipant[j].targetPoints = eventParticipant.targetPoints
                  }
                  if (event.eventParticipant[j].maxInnings !== eventParticipant.maxInnings) {
                    event.eventParticipant[j].maxInnings = eventParticipant.maxInnings
                  }
                }
              }
            }
            notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)})
            deInitUpdateForm()
          } else if (response.error) {
            notificationStore.add({type: 'error', message: response.error.description})
          } else {
            notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
          }
        })
        .catch(err =>notificationStore.add({type: 'error', message: err.message}))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const items = getItems(),
            parameters = []
        for (let i in items) {
          parameters.push(items[i].id)
        }
        parameters.teamEventStageId = teamEventStageId.value
        if (parameters.length) {
          fetch(globalStore.apiUrl + '/admin/team-event-round-parameter', {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(parameters)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                teamEventRoundParameterStore.deleteRoundParametersByEventStageId(teamEventStageId.value)
                notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId)})
              } else if (response.error) {
                notificationStore.add({type: 'error', message: response.error.description})
              } else {
                notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
              }
            })
            .catch(err => notificationStore.add({type: 'error', message: err.message}))
        }
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        delete(itemData.value = {})
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    return {
      globalStore, eventStageParameterStore, eventParameterStore, itemData, updateFormActive, addFormActive, schema,
      tournamentStageStore, teamEventRoundStore, eventStore, teamEventStageId,
      getItems, initAddForm, initUpdateForm, processAddForm, processUpdateForm, deleteItem, deInitUpdateForm, deInitAddForm
    }
  }
}
</script>
