<template>
  <CompetitionList />
</template>

<script>
import CompetitionList from "@/components/CompetitionList";
export default {
  components: { CompetitionList }
}
</script>
