<template>
  <div>
    <TournamentStageDefinition :stageId="stageId"/>
    <TeamEventRound v-if="tournamentStore.isTournamentForTeams" :stageId="stageId"/>
    <TournamentStageDefinitionEvent :stageId="stageId" v-else-if="isDefinitionForStage(stageId)"/>
  </div>
</template>

<script>
import {ref, toRef} from 'vue'
import { useTournamentStore } from "@/stores/TournamentStore"
import { useTournamentStageStore } from "@/stores/TournamentStageStore"
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import TournamentStageDefinition from "@/components/TournamentManagement/TournamentStageDefinition"
import TournamentStageDefinitionEvent from "@/components/TournamentManagement/TournamentStageDefinitionEvent"
import TeamEventRound from "@/components/TeamEventRound";

export default {
  name: 'TournamentStageSystem',
  components: {TeamEventRound, TournamentStageDefinition, TournamentStageDefinitionEvent},
  props: {
    stageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const stageId = toRef(props, 'stageId'),
        globalStore = useGlobalStore(),
        tournamentStore = useTournamentStore(),
        tournamentStageStore = useTournamentStageStore(),
        eventStageParameterStore = useEventStageParameterStore(),
        eventStageId = globalStore.getTournamentStageIdByStageId(stageId.value),
        itemData = ref({}),
        formActive = ref({})

    function getItem(id) {
      for (let i in eventStageParameterStore.eventStageParameters) {
        if (eventStageParameterStore.eventStageParameters[i].id === id) {
          return eventStageParameterStore.eventStageParameters[i]
        }
      }
      return {}
    }

    function initForm(id) {
      const item = getItem(id)
      if (!item) {
        return
      }

      itemData.value[id] = item
      formActive.value[id] = true
    }

    function processForm(id) {
      const item = getItem(id)
      if (item) {
        item.round = itemData.value[id].round

        fetch(globalStore.apiUrl + '/admin/event/' + item.id, {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            for (let i in eventStageParameterStore.eventStageParameters) {
              if (eventStageParameterStore.eventStageParameters[i].id === response.data.id) {
                eventStageParameterStore.eventStageParameters[i] = response.data
              }
            }
            deInitForm(id)
          })
          .catch((err) => console.error(err))
      }
    }

    function deInitForm(id) {
      formActive.value[id] = false
      if (itemData.value[id]) {
        delete(itemData.value[id])
      }
    }

    function isDefinitionForStage(stageId) {
      for (let i in tournamentStageStore.tournamentStages) {
        if (tournamentStageStore.tournamentStages[i].stageId === stageId) {
          return true
        }
      }
      return false
    }

    return {
      eventStageParameterStore, formActive, itemData, globalStore, eventStageId, tournamentStageStore, tournamentStore,
      initForm, processForm, deInitForm, isDefinitionForStage
    }
  }
}
</script>