import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { plugin, defaultConfig } from '@formkit/vue'
import '../node_modules/bootstrap'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '@formkit/themes/genesis'
import CKEditor from '@ckeditor/ckeditor5-vue'

createApp(App)
  .use(router)
  .use(CKEditor)
  .use(createPinia())
  .use(plugin, defaultConfig({ theme: 'genesis' }))
  .mount('#app')
