import { defineStore } from 'pinia'

export const useCommentStore = defineStore('CommentStore', {
  state: () => {
    return {
      data: []
    }
  },
  getters: {
    count: state => state.data.length,
    getById: state => {
      return id => {
        const data = state.data.filter(row => row.id === id)
        return data ? data[0] : null
      }
    },
    getByEventId: state => {
      return eventId => {
        return state.data.filter(row => row.eventId === eventId)
      }
    },
    getByTournamentId: state => {
      return tournamentId => {
        return state.data.filter(row => row.tournamentId === tournamentId)
      }
    },
    getByCompetitionId: state => {
      return competitionId => {
        return state.data.filter(row => row.competitionId === competitionId)
      }
    },
    getByOrganizationId: state => {
      return organizationId => {
        return state.data.filter(row => row.organizationId === organizationId)
      }
    },
    getByCommentTypeId: state => {
      return commentTypeId => {
        return state.data.filter(row => row.commentTypeId === commentTypeId)
      }
    },
    getByTournamentIdCommentTypeId: state => {
      return (tournamentId, commentTypeId) => {
        return state.data.filter(row => row.tournamentId === tournamentId && row.commentTypeId === commentTypeId)
      }
    },
    getByCompetitionIdCommentTypeId: state => {
      return (competitionId, commentTypeId) => {
        return state.data.filter(row => row.competitionId === competitionId && row.commentTypeId === commentTypeId)
      }
    },
    getAll: state => state.data,
  },
  actions: {
    setData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          if (this.data.filter(row => row.id === rows[i].id).length) {
            this.updateData(rows[i])
          } else {
            this.addData(rows[i])
          }
        }
      }
    },
    addData(item) {
      this.data.push(item)
    },
    updateData(item) {
      for (let i in this.data) {
        if (this.data[i].id === item.id) {
          this.data[i] = item
          break
        }
      }
    },
    unsetData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          if (this.data.filter(row => row.id === rows[i].id).length) {
            this.deleteData(rows[i])
          }
        }
      }
    },
    deleteData(item) {
      this.data = this.data.filter(row => row.id !== item.id)
    },
  }
})
