import { defineStore } from 'pinia'

export const useTeamEventStore = defineStore('TeamEventStore', {
  state: () => {
    return {
      freelancerId: 2876,
      freelancerTeamId: 2876,
      teamEvents: []
    }
  },
  getters: {
    count: state => state.teamEvents.length,
    getTeamEventsByStage: state => {
      return stageId => {
        return state.teamEvents.filter(
          event => event.stageId === stageId && !event.eventId
        )
      }
    },
    getTeamEventsByCompetition: state => {
      return competitionId => {
        return state.teamEvents.filter(
          row => +row.competitionId === +competitionId && !row.eventId
        )
      }
    },
    hasStageTeamEvents: state => {
      return stageId => {
        return state.teamEvents.filter(teamEvent => +teamEvent.stageId === +stageId && !teamEvent.eventId).length > 0
      }
    },
    hasStageOnlyBlankTeamEvents: state => {
      return stageId => {
        const stageTeamEvents = state.teamEvents.filter(teamEvent => teamEvent.stageId === stageId && !teamEvent.eventId)
        for (let i in stageTeamEvents) {
          for (let j in stageTeamEvents[i].eventParticipant) {
            const eventParticipant = stageTeamEvents[i].eventParticipant[j]
            if (eventParticipant.participantId !== state.freelancerTeamId && eventParticipant.score !== '' && eventParticipant.score !== null) {
              return false
            }
          }
        }
        return true
      }
    },
    hasStageOnlyWrittenTeamEvents: state => {
      return stageId => {
        const stageTeamEvents = state.teamEvents.filter(teamEvent => teamEvent.stageId === stageId && !teamEvent.eventId)
        if (!stageTeamEvents.length) {
          return false
        }
        for (let i in stageTeamEvents) {
          for (let j in stageTeamEvents[i].eventParticipant) {
            const eventParticipant = stageTeamEvents[i].eventParticipant[j]
            if (eventParticipant.score === '' || eventParticipant.score === null) {
              return false
            }
          }
        }
        return true
      }
    },
    hasTeamOnlyBlankTeamEvents: state => {
      return (stageId, teamId) => {
        const stageTeamEvents = state.teamEvents.filter(event => event.stageId === stageId && !event.eventId)
        if (!stageTeamEvents.length) {
          return true
        }
        for (let i in stageTeamEvents) {
          for (let j in stageTeamEvents[i].eventParticipant) {
            const eventParticipant = stageTeamEvents[i].eventParticipant[j]
            if (eventParticipant.teamId !== teamId && eventParticipant.score !== '' && eventParticipant.score === null) {
              return false
            }
          }
        }
        return true
      }
    },
    getTeamEventById: state => {
      return id => {
        return state.teamEvents.filter(event => event.id === id)[0]
      }
    }
  },
  actions: {
    setData(data) {
      this.teamEvents = data
    },
    addData(data) {
      for (let i in data) {
        let teamEvent = false
        for (let j in this.teamEvents) {
          if (this.teamEvents[j].id === data[i].id) {
            teamEvent = true
            break
          }
        }
        if (!teamEvent) {
          this.teamEvents.push(data[i])
        }
      }
    },
    actualizeRow(teamEvent) {
      for (let i in this.teamEvents) {
        if (this.teamEvents[i].id === teamEvent.id) {
          this.teamEvents[i] = teamEvent
        }
      }
    },
    actualizeTeamEventParticipants(eventParticipants) {
      for (let i in eventParticipants) {
        const teamEventId = eventParticipants[i].eventId,
          eventParticipantId = eventParticipants[i].id
        for (let j in this.teamEvents) {
          if (+this.teamEvents[j].id === +teamEventId) {
            for (let k in this.teamEvents[j].eventParticipant) {
              if (+this.teamEvents[j].eventParticipant[k].id === +eventParticipantId) {
                this.teamEvents[j].eventParticipant[k] = eventParticipants[i]
              }
            }
          }
        }
      }
    },
    deleteDataByStage(stageId) {
      this.teamEvents = this.teamEvents.filter(teamEvent => teamEvent.stageId !== stageId)
    },
    emptyData() {
      this.teamEvents = []
    },
    removeDataRow(id) {
      this.teamEvents = this.teamEvents.filter(row => +row.id !== +id)
    }
  }
})
