<template>
  <template v-if="addFormActive === true">
    <ButtonBack :click-function="deInitAddForm"/>
    <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
      <FormKitSchema :schema="schema" :data="itemData['']"/>
    </FormKit>
  </template>
  <template v-else-if="globalStore.hasUserAddRule">
    <ButtonAdd :click-function="initAddForm"/>
  </template>

  <table v-if="staticValueListStore.staticValueList[type]" class="table table-hover table-striped table-light">
    <tbody>
    <tr v-for="row in staticValueListStore.staticValueList[type]" :key="row.id">
      <td>#{{ row.id }}</td>
      <td>{{ globalStore.getWordById(row.name) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import {ref, toRef} from "vue";
import { useGlobalStore } from "@/stores/GlobalStore"
import { useStaticValueListStore } from "@/stores/StaticValueListStore";
import { useNotificationStore } from "@/stores/NotificationStore";
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";

export default {
  name: 'StaticValueListItemVocabularyName',
  components: {ButtonAdd, ButtonBack},
  props: {
    typeName: {
      required: true,
      type: String
    }
  },

  setup(props) {
    const type = toRef(props, 'typeName'),
        staticValueListStore = useStaticValueListStore(),
        globalStore = useGlobalStore(),
        notificationStore = useNotificationStore(),
        addFormActive = ref(false),
        schema = ref([]),
        itemData = ref({})

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'text',
        name: 'name',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('value', globalStore.languageId),
        validation: 'required'
      })
    }

    function initAddForm() {
      itemData.value[''] = {}
      setSchema()
      addFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    function processAddForm() {
      const item = itemData.value['']
      if (item) {
        item.languageId = globalStore.languageId
        item.type = type.value
        fetch(globalStore.apiUrl + '/admin/static-value-list', {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data && response.data[type.value]) {
              staticValueListStore.addRow(type.value, response.data[type.value])
              globalStore.vocabularyStore.addRow(response.data.vocabulary)
              notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId)})
              deInitAddForm()
            } else if (response.error) {
              notificationStore.add({type: 'error', message: response.error.description})
            } else {
              notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
            }
          })
          .catch(err =>notificationStore.add({type: 'error', message: err.message}))
      }
    }

    return {
      schema, itemData, addFormActive, globalStore, staticValueListStore, type,
      processAddForm, initAddForm, deInitAddForm
    }
  }
}
</script>
