<template>
  <tr>
    <template v-if="club">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="2">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td class="text-nowrap">{{ club.globalId + ' | ' + club.id }}</td>
        <td class="text-nowrap">
          <strong>{{ club.name }}</strong>
          <address>
            {{ address ? globalStore.formatAddressInline(address) : '' }}
          </address>
        </td>
        <td>
          <template v-if="globalStore.userAdminStore.isUserRegistry">
            <ButtonUpdate
              :click-function="initUpdateForm" />
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td colspan="3" v-if="addFormActive === false">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="2">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'ClubItem',
  components: { ButtonUpdate, ButtonBack, ButtonAdd },
  props: {
    clubObject: {
      required: false,
      type: Object
    },
    addressObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const club = toRef(props, 'clubObject'),
      address = toRef(props, 'addressObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      schema = ref([]),
      updateFormActive = ref(false),
      addFormActive = ref(false)

    function initAddForm() {
      itemData.value = {}

      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = club.value
      itemData.value.street = address.value ? address.value.street : null
      itemData.value.streetNumber = address.value ? address.value.streetNumber : null
      itemData.value.zip = address.value ? address.value.zip : null
      itemData.value.city = address.value ? address.value.city : null
      itemData.value.country = address.value ? address.value.country : null
      itemData.value.longitude = address.value ? address.value.longitude : null
      itemData.value.latitude = address.value ? address.value.latitude : null
      if (!itemData.value) {
        return
      }

      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'number',
        name: 'globalId',
        validation: 'required',
        label: globalStore.getWordById('registry id')
      })

      schema.value.push({
        $formkit: 'select',
        name: 'regionInOrganizationId',
        validation: 'required',
        options: globalStore.getSvlOrganizationRegion(globalStore.userAdminStore.activeOrganization),
        label: globalStore.getWordById('choose region'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'text',
        name: 'name',
        validation: 'required',
        label: globalStore.getWordById('name')
      })

      schema.value.push({
        $formkit: 'file',
        name: 'logo',
        label: globalStore.getWordById('logo') + (club.value && club.value.logo ? ' ' + globalStore.getWordById('replace') + ' ' + club.value.logo : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.png,.svg'
      })

      schema.value.push({
        $formkit: 'text',
        name: 'street',
        label: globalStore.getWordById('street')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'streetNumber',
        validation: 'required',
        label: globalStore.getWordById('street number')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'zip',
        validation: 'required',
        label: globalStore.getWordById('zip')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'city',
        label: globalStore.getWordById('city')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'country',
        label: globalStore.getWordById('country')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'longitude',
        label: globalStore.getWordById('longitude')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'latitude',
        label: globalStore.getWordById('latitude')
      })
    }

    function processAddForm() {
      itemData.value.organizationId = globalStore.userAdminStore.activeOrganization
      const formData = new FormData()
      for (let i in itemData.value) {
        if (i === 'logo') {
          formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
        } else {
          formData.append(i, itemData.value[i] || '')
        }
      }

      fetch(globalStore.apiUrl + '/admin/club', {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.clubStore.addDataRow(response.data.club)
            globalStore.addressStore.setData(response.data.address)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (club.value) {
        const formData = new FormData()
        for (let i in itemData.value) {
          if (i === 'logo') {
            formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
          } else {
            formData.append(i, itemData.value[i] || '')
          }
        }

        fetch(globalStore.apiUrl + '/admin/club-update', {
          method: 'POST',
          body: formData
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.clubStore.updateData(response.data.club)
              globalStore.addressStore.updateData(response.data.address)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, schema, addFormActive, club, address,
      initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
