import { defineStore } from 'pinia'

export const useLoungeClubAddressTableStore = defineStore('LoungeClubAddressTableStore', {
  state: () => {
    return {
      data: []
    }
  },
  getters: {
    count: state => state.data.length,
    getById: state => {
      return id => {
        const data = state.data.filter(row => row.id === id)
        return data ? data[0] : null
      }
    },
    getByTableSizeId: state => {
      return tableSizeId => {
        return state.data.filter(row => row.tableSizeId === tableSizeId)
      }
    },
    getByClubId: state => {
      return clubId => {
        return state.data.filter(row => row.clubId === clubId)
      }
    },
    getByClubIdTableSizeId: state => {
      return (clubId, tableSizeId) => {
        return state.data.filter(row => row.clubId === clubId && row.tableSizeId === tableSizeId)
      }
    },
    getByLoungeId: state => {
      return loungeId => {
        return state.data.filter(row => row.loungeId === loungeId)
      }
    },
    getByLoungeIdTableSizeId: state => {
      return (loungeId, tableSizeId) => {
        return state.data.filter(row => row.loungeId === loungeId && row.tableSizeId === tableSizeId)
      }
    },
    getByAddressId: state => {
      return addressId => {
        return state.data.filter(row => row.addressId === addressId)
      }
    },
    getByAddressIdTableSizeId: state => {
      return (addressId, tableSizeId) => {
        return state.data.filter(row => row.addressId === addressId && row.tableSizeId === tableSizeId)
      }
    },
    getTableIndex: state => {
      return id => {
        const table = state.getById(id),
          tables = state.data.filter(row => row.loungeId === table.loungeId && row.tableSizeId === table.tableSizeId)

        for (let i in tables) {
          if (+tables[i].id === +id) {
            return +i
          }
        }
        return false
      }
    },
    getLoungeIdByAddressId: state => {
      return addressId => {
        const tables = state.data.filter(row => row.addressId === addressId)
        if (tables.length) {
          return tables[0].loungeId
        }
        return null
      }
    },
    getLoungeTablesByTableId: state => {
      return id => {
        const table = state.getById(id)
        return state.data.filter(row => row.loungeId === table.loungeId && row.tableSizeId === table.tableSizeId)
      }
    }
  },
  actions: {
    setData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          this.addData(rows[i])
        }
      }
    },
    addData(item) {
      if (item && item.id && !this.data.filter(row => row.id === item.id).length) {
        this.data.push(item)
      }
    }
  }
})
