<template>
  <div class="table-responsive">
    <template v-if="globalStore.userAdminStore.propertiesLoaded">
      <table class="table table-striped">
        <tbody>
          <OrganizationRegionItem :organizationIdSet="organizationId" />
          <template v-if="globalStore.organizationRegionStore.getByOrganizationId(organizationId).length">
            <template v-for="row in globalStore.organizationRegionStore.getByOrganizationId(organizationId)" :key="row.id">
              <OrganizationRegionItem
                :organizationRegionObject="row" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef } from "vue"
import OrganizationRegionItem from "@/components/OrganizationManagement/OrganizationRegionItem"

export default {
  name: 'OrganizationRegionList',
  components: { OrganizationRegionItem },
  props: {
    organizationIdSet: {
      required: true,
      type: Number
    },
  },

  setup(props) {
    const organizationId = toRef(props, 'organizationIdSet'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadData()
    })

    function loadData() {
      const url = globalStore.apiUrl + '/admin/organization-regions/' + organizationId.value
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.organizationRegionStore.setData(data.data.organizationRegion)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, organizationId
    }
  }
}
</script>
