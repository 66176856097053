<template>
  <tr>
    <template v-if="organization">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="8">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          <span class="small mark">#{{ organization.id }}</span>
          <button v-if="globalStore.userAdminStore.isUserRegistry" @click="configVisible = !configVisible" class="btn btn-sm btn-outline-secondary d-block mt-2">Config</button>
        </td>
        <td>
          <template v-if="organization.countryId">
            {{ globalStore.getWordById(globalStore.countryStore.getById(organization.countryId).name) }}
          </template>
        </td>
        <td>{{ organization.name }}</td>
        <td>{{ organization.logo }}</td>
        <td>{{ organization.shortName }}</td>
        <td>{{ organization.subdomain }}</td>
        <td>{{ organization.official }}</td>
        <td>
          <template v-if="organization.clubId">
            {{ globalStore.clubStore.getById(organization.clubId).name }}
          </template>
        </td>
        <td>
          <template v-if="globalStore.userAdminStore.isUserRegistry">
            <ButtonUpdate
              :click-function="initUpdateForm" />
            <span>&#160;</span>
            <ButtonDelete
              :click-function="deleteItem" />
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="9">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="8">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
  <tr v-if="organization && configVisible">
    <td colspan="9">
      <OrganizationSettings :organizationIdSet="organization.id" />
    </td>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";
import OrganizationSettings from "@/components/OrganizationManagement/OrganizationSettings";

export default {
  name: 'OrganizationItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd, OrganizationSettings },
  props: {
    organizationObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const organization = toRef(props, 'organizationObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      schema = ref([]),
      configVisible = ref(false),
      updateFormActive = ref(false),
      addFormActive = ref(false)

    function initAddForm() {
      itemData.value = {}

      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = organization.value
      if (!itemData.value) {
        return
      }

      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'select',
        name: 'countryId',
        validation: 'required',
        options: globalStore.getSvlCountry,
        label: globalStore.getWordById('country'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'text',
        name: 'name',
        validation: 'required',
        label: globalStore.getWordById('name')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'shortName',
        validation: 'required',
        label: globalStore.getWordById('short name')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'subdomain',
        validation: 'required',
        label: globalStore.getWordById('subdomain')
      })

      schema.value.push({
        $formkit: 'radio',
        name: 'official',
        label: globalStore.getWordById('official organization'),
        options: globalStore.getSvlTrueFalse
      })

      schema.value.push({
        $formkit: 'select',
        name: 'clubId',
        options: globalStore.getSvlClub,
        label: globalStore.getWordById('choose club'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'file',
        name: 'logo',
        label: globalStore.getWordById('logo') + (organization.value && organization.value.logo ? ' ' + globalStore.getWordById('replace') + ' ' + organization.value.logo : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.png,.svg'
      })
    }

    function processAddForm() {
      const formData = new FormData()
      for (let i in itemData.value) {
        if (i === 'logo') {
          formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
        } else {
          formData.append(i, itemData.value[i] || '')
        }
      }

      fetch(globalStore.apiUrl + '/admin/organization', {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.organizationStore.setData(response.data.organization)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (organization.value) {
        const formData = new FormData()
        for (let i in itemData.value) {
          if (i === 'logo') {
            formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
          } else {
            formData.append(i, itemData.value[i] || '')
          }
        }

        fetch(globalStore.apiUrl + '/admin/organization-update', {
          method: 'POST',
          body: formData
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.organizationStore.updateData(response.data.organization)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = {
          id: organization.value.id
        }
        fetch(globalStore.apiUrl + '/admin/organization', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.organizationStore.unsetData(organization.value)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, schema, addFormActive, organization, configVisible,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
