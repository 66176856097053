<template>
  <tr>
    <template v-if="addFormActive === true">
      <td>
        <button @click="deInitAddForm()" class="btn btn-outline-success btn-sm">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('back', globalStore.languageId) }}
        </button>
      </td>
      <td colspan="7">
        <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
          <FormKitSchema :schema="schema" />
        </FormKit>
      </td>
    </template>
    <template v-else-if="globalStore.hasUserAddRule && !getLeaderRow().length">
      <td colspan="8">
        <button @click="initAddForm()" class="btn btn-outline-success btn-sm">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('add participant leader', globalStore.languageId) }}
        </button>
      </td>
    </template>
  </tr>
  <template v-for="row in getLeaderRow()" :key="row.id">
    <tr>
      <td colspan="4">
        {{ globalStore.vocabularyStore.getWordByIdAndLanguage('team leader', globalStore.languageId) }}:
        <template v-if="+row.season === +season">
          {{ globalStore.getParticipantNameById(row.participantId) }}
        </template>
      </td>
      <td colspan="3">
        <template v-if="updateFormActive[row.id]">
          <button @click="deInitUpdateForm(row.id)" class="btn btn-outline-success btn-sm">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('back', globalStore.languageId) }}
          </button>
          <FormKit type="form" v-model="itemData[row.id]" @submit="processUpdateForm(row.id)">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </template>
        <template v-else>
          <template v-if="+row.season === +season">
            <ButtonUpdate
              :click-function="initUpdateForm"
              :click-function-parameter="row.id" />
            <span>&#160;</span>
            <ButtonDelete
              :click-function="deleteItem"
              :click-function-parameter="row.id" />
          </template>
        </template>
      </td>
    </tr>
  </template>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { ref, toRef } from "vue"
import ButtonDelete from "@/components/Button/ButtonDelete"
import ButtonUpdate from "@/components/Button/ButtonUpdate"

export default {
  name: 'ParticipantLeader',
  components: { ButtonUpdate, ButtonDelete },
  props: {
    participantParent: {
      required: true,
      type: Number
    },
    seasonYear: {
      required: true,
      type: Number
    },
    club: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const participantParentId = toRef(props, 'participantParent'),
      season = toRef(props, 'seasonYear'),
      clubId = toRef(props, 'club'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref({}),
      schema = ref([]),
      itemId = ref('')

    function setSchema() {
      schema.value = []

      const options = {},
        clubTeamLeaderIds = globalStore.clubParticipantLeaderStore.getByClubId(clubId.value)
      for (let i in clubTeamLeaderIds) {
        options[clubTeamLeaderIds[i]] = globalStore.getParticipantNameById(clubTeamLeaderIds[i])
      }

      schema.value.push({
        $formkit: 'select',
        name: 'participantId',
        validation: 'required',
        options: options,
      })
    }

    function getItem() {
      const participantLeader = globalStore.participantLeaderStore.getByParticipantParentId(participantParentId.value)
      return participantLeader[0] || {}
    }

    function initAddForm() {
      itemData.value[''] = {}
      addFormActive.value = true
      setSchema()
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    function initUpdateForm(id) {
      const item = getItem(id)
      if (!item) {
        return
      }
      itemId.value = id

      itemData.value[id] = {
        id: item.id
      }

      setSchema()
      updateFormActive.value[id] = true
    }

    function deInitUpdateForm(id) {
      updateFormActive.value[id] = false
      if (itemData.value[id]) {
        delete itemData.value[id]
      }
      itemId.value = ''
    }

    function processAddForm() {
      itemData.value[''].participantParentId = participantParentId.value
      itemData.value[''].season = +season.value

      fetch(globalStore.apiUrl + '/admin/participant-leader', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.participantLeaderStore.addRow(response.data.participantLeader)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm(id) {
      const item = getItem(id)
      itemData.value[id].participantParentId = participantParentId.value
      itemData.value[id].season = season.value
      itemData.value[id].id = id
      if (item) {
        fetch(globalStore.apiUrl + '/admin/participant-leader', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value[id])
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.participantLeaderStore.updateRow(response.data.participantLeader)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm(id)
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(id)
        if (item) {
          item.season = season.value
          fetch(globalStore.apiUrl + '/admin/participant-leader', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                globalStore.participantLeaderStore.deleteRow(participantParentId.value)
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function getLeaderRow() {
      return globalStore.participantLeaderStore.getByParticipantParentId(participantParentId.value)
    }

    return {
      globalStore, addFormActive, updateFormActive, itemData, schema, participantParentId, season,
      initAddForm, deInitAddForm, initUpdateForm, deInitUpdateForm, processAddForm,
      processUpdateForm, deleteItem, getItem, getLeaderRow
    }
  }
}
</script>
