<template>
  <tr>
    <template v-if="competitionTeamEventParticipantSettingTypeStore.competitionTeamEventParticipantSettingType.id">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          {{ getTeamEventParticipantSettingTypeName(competitionTeamEventParticipantSettingTypeStore.competitionTeamEventParticipantSettingType.teamEventParticipantSettingTypeId) }}
        </td>
        <td>
          <ButtonUpdate
            :click-function="initUpdateForm" />
          <span>&#160;</span>
          <ButtonDelete
            :click-function="deleteItem" />
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="4">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="2">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useCompetitionTeamEventParticipantSettingTypeStore } from "@/stores/CompetitionTeamEventParticipantSettingTypeStore"
import { useTeamEventParticipantSettingTypeStore } from "@/stores/TeamEventParticipantSettingTypeStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'CompetitionTeamEventParticipantSettingTypeItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    cId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const competitionId = toRef(props, 'cId'),
      globalStore = useGlobalStore(),
      competitionTeamEventParticipantSettingTypeStore = useCompetitionTeamEventParticipantSettingTypeStore(),
      teamEventParticipantSettingTypeStore = useTeamEventParticipantSettingTypeStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([])

    function setSchema() {
      schema.value = []

      const options = {}
      for (let i in teamEventParticipantSettingTypeStore.teamEventParticipantSettingTypes) {
        const row = teamEventParticipantSettingTypeStore.teamEventParticipantSettingTypes[i]
        options[row.id] = globalStore.getWordById(row.name)
      }

      schema.value.push({
        $formkit: 'select',
        options: options,
        validation: 'required',
        name: 'teamEventParticipantSettingTypeId',
      })
    }

    function initAddForm() {
      itemData.value = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = competitionTeamEventParticipantSettingTypeStore.competitionTeamEventParticipantSettingType
      if (!itemData.value) {
        return
      }
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.competitionId = competitionId.value

      fetch(globalStore.apiUrl + '/admin/competition-participant-setting-type', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            competitionTeamEventParticipantSettingTypeStore.setData(response.data)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      fetch(globalStore.apiUrl + '/admin/competition-participant-setting-type', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            competitionTeamEventParticipantSettingTypeStore.updateRow(response.data)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitUpdateForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = competitionTeamEventParticipantSettingTypeStore.competitionTeamEventParticipantSettingType
        fetch(globalStore.apiUrl + '/admin/competition-participant-setting-type', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              competitionTeamEventParticipantSettingTypeStore.deleteRow(competitionTeamEventParticipantSettingTypeStore.competitionTeamEventParticipantSettingType.id)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function getTeamEventParticipantSettingTypeName(id) {
      for (let i in teamEventParticipantSettingTypeStore.teamEventParticipantSettingTypes) {
        const row = teamEventParticipantSettingTypeStore.teamEventParticipantSettingTypes[i]
        if (+row.id === +id) {
          return globalStore.getWordById(row.name)
        }
      }
      return ''
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive, schema, competitionTeamEventParticipantSettingTypeStore,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm, getTeamEventParticipantSettingTypeName
    }
  }
}
</script>
