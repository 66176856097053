<template>
  <h3>{{ globalStore.vocabularyStore.getWordByIdAndLanguage('registry office log', globalStore.languageId) }}</h3>
  <template v-if="data.length">
    <select class="form-select" v-model="filterApiName">
      <option value="">API</option>
      <template v-for="api in getApiNames()" :key="api">
        <option :value="api">{{ api }}</option>
      </template>
    </select>
    <select class="form-select" v-model="filterClubName">
      <option value="">Klub</option>
      <template v-for="club in getClubNames() " :key="club">
        <option :value="club">{{ club }}</option>
      </template>
    </select>
    <select class="form-select" v-model="filterParticipantName">
      <option value="">Participant</option>
      <template v-for="participant in getParticipantNames()" :key="participant">
        <option :value="participant">{{ participant }}</option>
      </template>
    </select>
    <table class="table table-sm table-striped">
      <thead>
        <tr>
          <th>API</th>
          <th>Datetime</th>
          <th>Request</th>
          <th>Response</th>
          <th>Request error</th>
          <th>Process exception</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="log in getLogs()" :key="log.id">
          <td>
            {{ log.api }}
          </td>
          <td class="text-nowrap">
            {{ globalStore.formatDateTime(log.inserted) }}
          </td>
          <td>
            <pre>{{ log.input }}</pre>
          </td>
          <td>
            <pre>{{ log.processed }}</pre>
          </td>
          <td>
            <pre>{{ log.inputError }}</pre>
          </td>
          <td>
            {{ log.exception }}
          </td>
        </tr>
      </tbody>
    </table>
  </template>
</template>

<script>

import { onMounted, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"

export default {
  name: 'RegistryOfficeLog',
  components: {},

  setup() {
    const globalStore = useGlobalStore(),
      data = ref([]),
      filterApiName = ref(''),
      filterClubName = ref(''),
      filterParticipantName = ref('')

    onMounted(() => {
      fetch(globalStore.apiUrl + '/utils/registry-office-log', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(response => {
          if (response.data && response.data.log && response.data.log.length) {
            data.value = response.data.log
          }
        })
        .catch(err => console.log(err))
    })

    function getLogs() {
      let result = data.value
      if (filterClubName.value) {
        result = result.filter(row => row.input && row.input[0].clubName && row.input[0].clubName === filterClubName.value)
      }
      if (filterParticipantName.value) {
        result = result.filter(row => row.input && row.input[0].participantName && row.input[0].participantName === filterParticipantName.value)
      }
      if (filterApiName.value) {
        result = result.filter(row => row.api && row.api === filterApiName.value)
      }
      return result
    }

    function getApiNames() {
      const result = {}
      for (const i in data.value) {
        if (data.value[i].api) {
          result[data.value[i].api] = data.value[i].api
        }
      }
      return result
    }

    function getClubNames() {
      const result = {}
      for (const i in data.value) {
        if (data.value[i].input) {
          for (const j in data.value[i].input) {
            if (data.value[i].input[j].clubName) {
              result[data.value[i].input[j].clubName] = data.value[i].input[j].clubName
            }
          }
        }
      }
      return result
    }

    function getParticipantNames() {
      const result = {}
      for (const i in data.value) {
        if (data.value[i].input) {
          for (const j in data.value[i].input) {
            if (data.value[i].input[j].participantName) {
              result[data.value[i].input[j].participantName] = data.value[i].input[j].participantName
            }
          }
        }
      }
      return result
    }

    return {
      globalStore, data, filterClubName, filterParticipantName, filterApiName,
      getLogs, getClubNames, getParticipantNames, getApiNames
    }
  }
}
</script>

<style scoped>
table {
  font-size: 70%;
}
</style>
