<template>
  <MenuBase :onChangeFn="loadClubs" />
  <div class="table-responsive">
    <template v-if="globalStore.userAdminStore.propertiesLoaded && globalStore.userAdminStore.activeOrganization">
      <table class="table table-striped">
        <tbody>
          <ClubItem />
          <template v-if="getClubs()">
            <template v-for="(row, i) in getClubs()" :key="i">
              <ClubItem :clubObject="row" :addressObject="globalStore.addressStore.getById(row.addressId)" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted } from "vue"
import MenuBase from "@/components/Menu/MenuBase"
import ClubItem from "@/components/ClubItem"

export default {
  name: 'ClubList',
  components: { MenuBase, ClubItem },

  setup() {
    const globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadClubs()
    })

    function loadClubs() {
      const url = globalStore.apiUrl + '/admin/organization-clubs/' + (globalStore.userAdminStore.activeOrganization || 'null')
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.organizationRegionStore.setData(data.data.organizationRegion)
          globalStore.clubStore.setData(data.data.club)
          globalStore.addressStore.setData(data.data.address)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    function getClubs() {
      return globalStore.clubStore.getByOrganizationId(globalStore.selectedOrganization)
    }

    return {
      globalStore,
      loadClubs, getClubs
    }
  }
}
</script>
