import {defineStore} from 'pinia'

export const useTeamEventRoundParameterStore = defineStore('TeamEventRoundParameterStore', {
  state: () => {
    return {
      teamEventRoundParameters: {}
    }
  },
  getters: {
    hasTeamEventRoundParameters: state => {
      return teamEventStageId => {
        return state.teamEventRoundParameters[teamEventStageId] && state.teamEventRoundParameters[teamEventStageId].length > 0
      }
    }
  },
  actions: {
    setData(data) {
      for (let i in data) {
        const teamEventStageId = data[i].teamEventStageId
        if (!this.teamEventRoundParameters[teamEventStageId]) {
          this.teamEventRoundParameters[teamEventStageId] = []
        }
        this.teamEventRoundParameters[teamEventStageId].push(data[i])
      }
    },
    addDataFromArray(data) {
      for (let i in data) {
        const teamEventStageId = data[i].teamEventStageId
        if (!this.teamEventRoundParameters[teamEventStageId]) {
          this.teamEventRoundParameters[teamEventStageId] = []
        }
        this.teamEventRoundParameters[teamEventStageId].push(data[i])
      }
    },
    replaceRoundParameters(data, teamEventStageId) {
      this.deleteRoundParametersByEventStageId(teamEventStageId)
      for (let i in data) {
        const teamEventStageId = data[i].teamEventStageId
        if (!this.teamEventRoundParameters[teamEventStageId]) {
          this.teamEventRoundParameters[teamEventStageId] = []
        }
        this.teamEventRoundParameters[teamEventStageId].push(data[i])
      }
    },
    deleteRoundParametersByEventStageId(teamEventStageId) {
      if (this.teamEventRoundParameters[teamEventStageId]) {
        delete this.teamEventRoundParameters[teamEventStageId]
      }
    }
  }
})