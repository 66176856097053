import { defineStore } from 'pinia'

export const useVocabularyPublicStore = defineStore('VocabularyPublicStore', {
  state: () => {
    return {
      vocabulary: []
    }
  },
  getters: {
    count: state => state.vocabulary.length,
    getWordByIdAndLanguage: state => {
      return (id, languageId) => {
        languageId = languageId || 1
        for (let i in state.vocabulary) {
          const word = state.vocabulary[i]
          if (word.vocabularyContentId === id && word.languageId === languageId) {
            return word.value
          }
        }
        for (let i in state.vocabulary) {
          const word = state.vocabulary[i]
          if (word.vocabularyContentId === id && word.value !== '') {
            return word.value
          }
        }
        return ''
      }
    },
    wordExistsByIdAndLanguage: state => {
      return (id, languageId) => {
        for (let i in state.vocabulary) {
          const word = state.vocabulary[i]
          if (word.vocabularyContentId === id && word.languageId === languageId) {
            return true
          }
        }
        return false
      }
    }
  },
  actions: {
    setData(data) {
      if (data && data.length) {
        for (let i in data) {
          const word = data[i]
          if (!this.wordExistsByIdAndLanguage(word.vocabularyContentId, word.languageId)) {
            this.vocabulary.push(word)
          }
        }
      }
    },
    addRow(row) {
      this.vocabulary[row.languageId + '-' + row.vocabularyContentId] = row
    },
    addRows(rows) {
      for (let i in rows) {
        this.addRow(rows[i])
      }
    }
  }
})
