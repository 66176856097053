import { defineStore } from 'pinia'

export const useCompetitionRegionStore = defineStore('CompetitionRegionStore', {
  state: () => {
    return {
      competitionRegions: [],
    }
  },
  getters: {
    count: state => state.competitionRegions.length,
    getByCompetitionId: state => {
      return id => {
        return state.competitionRegions.filter(row => +row.competitionId === +id)
      }
    }
  },
  actions: {
    setData(data) {
      this.competitionRegions = data
    },
    updateRowsByCompetitionId(data, competitionId) {
      this.competitionRegions = this.competitionRegions.filter(row => +row.competitionId !== +competitionId)
      for (let i in data) {
        this.addRow(data[i])
      }
    },
    deleteRowsByCompetitionId(competitionId) {
      this.competitionRegions = this.competitionRegions.filter(row => +row.competitionId !== +competitionId)
    },
    addRows(data) {
      for (let i in data) {
        const row = data[i]
        if (this.competitionRegions.filter(cr => +cr.id === row.id).length) {
          continue
        }
        this.competitionRegions.push(row)
      }
    },
    addRow(data) {
      this.competitionRegions.push(data)
    },
    deleteRow(id) {
      for (let i in this.competitionRegions) {
        if (+id === +this.competitionRegions[i].id) {
          delete this.competitionRegions[i]
        }
      }
    },
    updateRow(data) {
      for (let i in this.competitionRegions) {
        if (+data.id === +this.competitionRegions[i].id) {
          this.competitionRegions[i] = data
        }
      }
    },
  }
})
