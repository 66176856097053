<template>
  <tr>
    <template v-if="user">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="4">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td><span class="small mark">#{{ user.id }}</span></td>
        <td>
          {{ globalStore.getUserTypeNameById(user.userTypeId) }}
        </td>
        <td>
          {{ user.name }}
        </td>
        <td>
          {{ user.hasAddRule }} |
          {{ user.hasUpdateRule }} |
          {{ user.hasDeleteRule }}
        </td>
        <td>
          <template v-if="globalStore.userAdminStore.isUserRegion">
            <ButtonUpdate
              :click-function="initUpdateForm" />
            <span>&#160;</span>
            <ButtonDelete
              :click-function="deleteItem" />
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="5">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="4">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'UserItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    userObject: {
      required: false,
      type: Object
    },
    userParamsObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const user = toRef(props, 'userObject'),
      userParams = toRef(props, 'userParamsObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      schema = ref([]),
      updateFormActive = ref(false),
      addFormActive = ref(false)

    function initAddForm() {
      itemData.value = {}

      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      if (!user.value) {
        return
      }

      const item = Object.assign({}, user.value)
      item.hasAddRule = [item.hasAddRule]
      item.hasUpdateRule = [item.hasUpdateRule]
      item.hasDeleteRule = [item.hasDeleteRule]

      const userParameters = []

      for (let i in userParams.value) {
        const userParam = userParams.value[i],
          paramIndex = transformParamValueForInput(userParam.organizationId, userParam.sectionId, userParam.userParameterTypeId, userParam.value),
          param = {}
        param[paramIndex] = paramIndex
        userParameters.push(paramIndex)
      }

      item.userParams = userParameters
      itemData.value = item
      console.log(1)
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'select',
        name: 'userTypeId',
        options: globalStore.getSvlUserType,
        validation: 'required',
        label: globalStore.getWordById('user type'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'email',
        name: 'name',
        validation: 'required',
        label: globalStore.getWordById('name')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'password',
        validation: 'required',
        label: globalStore.getWordById('password')
      })

      schema.value.push({
        $formkit: 'checkbox',
        name: 'hasAddRule',
        options: globalStore.getSvlOnlyTrue,
        label: globalStore.getWordById('has add rule')
      })

      schema.value.push({
        $formkit: 'checkbox',
        name: 'hasUpdateRule',
        options: globalStore.getSvlOnlyTrue,
        label: globalStore.getWordById('has update rule')
      })

      schema.value.push({
        $formkit: 'checkbox',
        name: 'hasDeleteRule',
        options: globalStore.getSvlOnlyTrue,
        label: globalStore.getWordById('has delete rule')
      })

      const userParamOptions = {},
        prefix = '--',
        restrictions = globalStore.userAdminStore.getRestrictions['o']

      for (let i in restrictions) {
        const organization = restrictions[i],
          oId = 'o' + i

        userParamOptions[oId] = organization.name

        if (organization.s && Object.keys(organization.s).length) {
          for (let j in organization.s) {

            const section = organization.s[j],
              sId = 'o' + i + '-s' + section.ois

            userParamOptions[sId] = prefix + ' ' + globalStore.getWordById(section.name)

            if (section.r && Object.keys(section.r).length) {
              for (let k in section.r) {
                const region = section.r[k],
                  rId = 'o' + i + '-s' + section.ois + '-r' + globalStore.sectionHasRegionStore.getBySectionIdRegionInOrganizationId(j, k).id

                userParamOptions[rId] = prefix + prefix + ' ' + globalStore.getWordById(region.name)

                if (region.r && Object.keys(region.r).length) {
                  for (let l in region.r) {
                    const subRegion = region.r[l],
                      srId = 'o' + i + '-s' + section.ois + '-r' + globalStore.sectionHasRegionStore.getBySectionIdRegionInOrganizationId(j, l).id

                    userParamOptions[srId] = prefix + prefix + prefix + ' ' + globalStore.getWordById(subRegion.name)

                    if (subRegion.r && Object.keys(subRegion.r).length) {
                      for (let m in subRegion.r) {
                        const subSubRegion = subRegion.r[m],
                          ssrId = 'o' + i + '-s' + section.ois + '-r' + globalStore.sectionHasRegionStore.getBySectionIdRegionInOrganizationId(j, m).id

                        userParamOptions[ssrId] = prefix + prefix + prefix + prefix + ' ' + globalStore.getWordById(subSubRegion.name)

                        if (subSubRegion.r && Object.keys(subSubRegion.r).length) {
                          for (let n in subSubRegion.r) {
                            const subSubSubRegion = subSubRegion.r[n],
                              sssrId = 'o' + i + '-s' + section.ois + '-r' + globalStore.sectionHasRegionStore.getBySectionIdRegionInOrganizationId(j, n).id

                            userParamOptions[sssrId] = prefix + prefix + prefix + prefix + prefix + ' ' + globalStore.getWordById(subSubSubRegion.name)
                          }
                        }
                        if (subSubRegion.c && Object.keys(subSubRegion.c).length) {
                          for (let o in subSubRegion.c) {
                            const club = subSubRegion.c[o],
                              ssscId = 'o' + i + '-s' + section.ois + '-c' + o

                            userParamOptions[ssscId] = prefix + prefix + prefix + prefix + prefix + ' ' + club.name
                          }
                        }
                      }
                    }
                    if (subRegion.c && Object.keys(subRegion.c).length) {
                      for (let o in subRegion.c) {
                        const club = subRegion.c[o],
                          sscId = 'o' + i + '-s' + section.ois + '-c' + o

                        userParamOptions[sscId] = prefix + prefix + prefix + prefix + ' ' + club.name
                      }
                    }
                  }
                }
                if (region.c && Object.keys(region.c).length) {
                  for (let o in region.c) {
                    const club = region.c[o],
                      scId = 'o' + i + '-s' + section.ois + '-c' + o

                    userParamOptions[scId] = prefix + prefix + prefix + ' ' + club.name
                  }
                }
              }
            }
          }
        }
      }

      schema.value.push({
        $formkit: 'checkbox',
        name: 'userParams',
        options: userParamOptions,
        label: ''
      })
    }

    function transformParamValueForInput(organizationId, sectionId, userParameterTypeId, value) {
      let result = ''
      if (organizationId) {
        result = result + 'o' + organizationId
      } else if (userParameterTypeId === 1) {
        result = result + 'o' + value
      }
      if (sectionId) {
        result = result + '-s' + sectionId
      } else if (userParameterTypeId === 2) {
        result = result + '-s' + value
      }
      if (userParameterTypeId === 3) {
        result = result + '-r' + value
      } else if (userParameterTypeId === 4) {
        result = result + '-c' + value
      }

      return result
    }

    function collectUserParameters() {
      const userParameters = []
      for (let i in itemData.value.userParams) {
        const userParam = itemData.value.userParams[i],
          paramParts = userParam.split('-'),
          organizationId = paramParts.length > 0 ? +paramParts[0].substring(1) : null,
          sectionId = paramParts.length > 1 ? +paramParts[1].substring(1) : null,
          paramTypeId = paramParts.length === 1 ? 1 :
            paramParts.length === 2 ? 2 :
              paramParts.length === 3 && paramParts[2].indexOf('r') === 0 ? 3 :
                4,
          paramValue = paramParts.length === 1 ? +paramParts[0].substring(1) :
            paramParts.length === 2 ? +paramParts[1].substring(1) :
              +paramParts[2].substring(1)

        userParameters.push({
          'organizationId': paramTypeId === 1 ? null : organizationId,
          'sectionId': sectionId,
          'userParameterTypeId': paramTypeId,
          'value': paramValue,
        })
      }
      return userParameters
    }

    function processAddForm() {
      itemData.value.userParameters = collectUserParameters()

      fetch(globalStore.apiUrl + '/admin/user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.userStore.setData(response.data.user)
            globalStore.userParamsStore.setData(response.data.userParam)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (user.value) {
        itemData.value.userParameters = collectUserParameters()
        fetch(globalStore.apiUrl + '/admin/user', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.userStore.updateData(response.data.user)
              globalStore.userParamsStore.removeUserParamsByUserId(response.data.user.id)
              globalStore.userParamsStore.setData(response.data.userParam)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      if (user.value) {
        const text = globalStore.getWordById('really delete item')
        if (confirm(text) === true) {
          fetch(globalStore.apiUrl + '/admin/user', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user.value)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                globalStore.userStore.deleteData(user.value)
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    return {
      globalStore, updateFormActive, itemData, schema, addFormActive, user, userParams,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
