import {defineStore} from 'pinia'

export const useEventPredictionStore = defineStore('EventPredictionStore', {
  state: () => {
    return {
      eventPredictions: {}
    }
  },
  getters: {
    getEventParticipantPrediction: state => {
      return (stageId, stageSign, eventIndex, eventParticipantIndex) => {
        if (
          state.eventPredictions[stageId] &&
          state.eventPredictions[stageId][stageSign] &&
          state.eventPredictions[stageId][stageSign][eventIndex] &&
          state.eventPredictions[stageId][stageSign][eventIndex][eventParticipantIndex]
        ) {
          const eventParticipant = state.eventPredictions[stageId][stageSign][eventIndex][eventParticipantIndex]
          if (eventParticipant.predictionData) {
            return eventParticipant.predictionData
          }
        }
        return null
      }
    }
  },
  actions: {
    setData(data) {
      this.eventPredictions = data
    }
  }
})