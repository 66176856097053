import { defineStore } from 'pinia'

export const useTournamentParticipantStagePredictionStore = defineStore('TournamentParticipantStagePredictionStore', {
  state: () => {
    return {
      tournamentParticipantStagePredictions: {}
    }
  },
  getters: {
    getStageParticipantStageSignPrediction: state => {
      return (stageId, stageSign, setting) => {
        if (
          state.tournamentParticipantStagePredictions[stageId] &&
          state.tournamentParticipantStagePredictions[stageId][stageSign]
        ) {
          for (let i in state.tournamentParticipantStagePredictions[stageId][stageSign]) {
            const item = state.tournamentParticipantStagePredictions[stageId][stageSign][i]
            if (item.predictionSetting && item.setting && item.setting === setting) {
              return item
            }
          }
        }
        return null
      }
    }
  },
  actions: {
    setData(data) {
      this.tournamentParticipantStagePredictions = data
    }
  }
})
