import { defineStore } from 'pinia'

export const useLiveScoreStore = defineStore('LiveScoreStore', {
  state: () => {
    return {
      liveScores: {}
    }
  },
  getters: {
    count: state => Object.keys(state.liveScores).length,
    hasEventLiveScore: state => {
      return eventId => {
        return !!state.liveScores[eventId]
      }
    },
    hasEventEmptyLiveScore: state => {
      return eventId => {
        const liveScore = state.liveScores[eventId]
        if (liveScore && (liveScore.detail.runs !== undefined && liveScore.detail.runs[0] && liveScore.detail.runs[0][0] && liveScore.detail.runs[0][0].length)) {
          return false
        }
        return true
      }
    },
    isEventSaved: state => {
      return id => {
        if (!state.liveScores[id]) {
          return false
        }
        if (state.liveScores[id].detail) {
          return state.liveScores[id].detail.saved !== undefined
        }
      }
    }
  },
  actions: {
    setData(data) {
      for (let i in data) {
        this.liveScores[data[i].eventId] = data[i]
      }
    },
    addDataRows(data) {
      for (let i in data) {
        this.addDataRow(data[i])
      }
    },
    addDataRow(data) {
      if (!this.liveScores[data.eventId]) {
        this.liveScores[data.eventId] = data
      }
    },
    removeDataRow(id) {
      delete this.liveScores[id]
    },
    actualizeLiveRow(eventId, data) {
      if (this.liveScores[eventId].detail) {
        this.liveScores[eventId].detail = data
      }
    }
  }
})
