import { defineStore } from 'pinia'

export const useTournamentRefereeStore = defineStore('TournamentRefereeStore', {
  state: () => {
    return {
      tournamentReferees: []
    }
  },
  getters: {
    count: state => state.tournamentReferees.length,
    getTournamentRefereesByTournamentId: state => {
      return id => {
        return state.tournamentReferees.filter(row => row.tournamentId === id)
      }
    }
  },
  actions: {
    setData(data) {
      this.tournamentReferees = data
    },
    addData(data) {
      for (let i in data) {
        const referee = data[i]
        let exists = false
        for (let j in this.tournamentReferees) {
          if (this.tournamentReferees[j].id === referee.id) {
            exists = true
          }
        }
        if (!exists) {
          this.tournamentReferees[referee.id] = referee
        }
      }
    }
  }
})
