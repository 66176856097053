<template>
  <tr>
    <template v-if="sponsor">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          {{ globalStore.bannerStore.getById(sponsor.bannerId).name }}
        </td>
        <td>
          {{ globalStore.formatDateTime(sponsor.dateFrom) }}
        </td>
        <td>
          {{ globalStore.formatDateTime(sponsor.dateTo) }}
        </td>
        <td>
          <ButtonUpdate
            :click-function="initUpdateForm" />
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="4">
        <ButtonAdd :viewPermanently="true" :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'ClubSponsorItem',
  components: { ButtonUpdate, ButtonBack, ButtonAdd },
  props: {
    clubIdSet: {
      required: false,
      type: Number
    },
    sponsorObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const clubId = toRef(props, 'clubIdSet'),
      sponsor = toRef(props, 'sponsorObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([])

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'select',
        name: 'bannerId',
        validation: 'required',
        options: globalStore.getSvlBanner,
      })

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'dateFrom',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('from', globalStore.languageId)
      })

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'dateTo',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('to', globalStore.languageId)
      })
    }

    function initAddForm() {
      itemData.value = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      if (!sponsor.value) {
        return
      }
      const item = Object.assign({}, sponsor.value)
      itemData.value = item
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.clubId = clubId.value
      fetch(globalStore.apiUrl + '/admin/club-sponsor', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.sponsorStore.setData(response.data.sponsor)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (sponsor.value) {
        fetch(globalStore.apiUrl + '/admin/club-sponsor', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.sponsorStore.setData(response.data.sponsor)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = sponsor.value
        fetch(globalStore.apiUrl + '/admin/club-sponsor', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.sponsorStore.deleteData(item)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive, schema, clubId, sponsor,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
