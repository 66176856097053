import { defineStore } from 'pinia'
import { useVocabularyStore } from "@/stores/VocabularyStore"
import { useVocabularyPublicStore } from "@/stores/VocabularyPublicStore"
import { useStageStore } from "@/stores/StageStore"
import { useParticipantStore } from "@/stores/ParticipantStore"
import { useTeamStore } from "@/stores/TeamStore"
import { useTeamInSeasonStore } from "@/stores/TeamInSeasonStore"
import { useTeamParticipantStore } from "@/stores/TeamParticipantStore"
import { useTournamentParticipantStore } from "@/stores/TournamentParticipantStore"
import { useTournamentParticipantStageStore } from "@/stores/TournamentParticipantStageStore"
import { useEventParameterStore } from "@/stores/EventParameterStore"
import { useTournamentStageStore } from "@/stores/TournamentStageStore"
import { useTournamentStore } from "@/stores/TournamentStore"
import { useResultCriterionStore } from "@/stores/ResultCriterionStore"
import { useEventStore } from "@/stores/EventStore"
import { useDisciplineStore } from "@/stores/DisciplineStore"
import { useCookiesStore } from "@/stores/CookiesStore"
import { useUserAdminStore } from "@/stores/UserAdminStore"
import { useClubStore } from "@/stores/ClubStore"
import { useTableSizeStore } from "@/stores/TableSizeStore"
import { useTeamTypeStore } from "@/stores/TeamTypeStore"
import { useClubParticipantLeaderStore } from "@/stores/ClubParticipantLeaderStore"
import { useParticipantLeaderStore } from "@/stores/ParticipantLeaderStore"
import { useCompetitionStore } from "@/stores/CompetitionStore"
import { useAgeStore } from "@/stores/AgeStore"
import { useGenderStore } from "@/stores/GenderStore"
import { useLevelStore } from "@/stores/LevelStore"
import { useCompetitionDisciplineStore } from "@/stores/CompetitionDisciplineStore"
import { useTournamentDisciplineStore } from "@/stores/TournamentDisciplineStore"
import { useCompetitionRegionStore } from "@/stores/CompetitionRegionStore"
import { useTournamentRegionStore } from "@/stores/TournamentRegionStore"
import { useCompetitionParticipantStore } from "@/stores/CompetitionParticipantStore"
import { useTeamEventStore } from "@/stores/TeamEventStore"
import { useStatusStore } from "@/stores/StatusStore"
import { useLiveScoreStore } from "@/stores/LiveScoreStore"
import { useLiveStreamStore } from "@/stores/LiveStreamStore"
import { useTournamentVenueStore } from "@/stores/TournamentVenueStore";
import { useClubTodayEventIdStore } from "@/stores/ClubTodayEventIdStore";
import { useEventRefereeStore } from "@/stores/EventRefereeStore";
import { useLoungeClubAddressTableStore } from "@/stores/LoungeClubAddressTableStore";
import { useClubAddressStore } from "@/stores/ClubAddressStore";
import { useCommentStore } from "@/stores/CommentStore";
import { useCommentTypeStore } from "@/stores/CommentTypeStore";
import { useLeagueCommentStore } from "@/stores/LeagueCommentStore";
import { useTournamentCommentStore } from "@/stores/TournamentCommentStore";
import { useOrganizationStore } from "@/stores/OrganizationStore";
import { useCountryStore } from "@/stores/CountryStore";
import { useOrganizationHasSectionStore } from "@/stores/OrganizationHasSectionStore";
import { useSectionStore } from "@/stores/SectionStore";
import { useSeasonStore } from "@/stores/SeasonStore";
import { useOrganizationRegionStore } from "@/stores/OrganizationRegionStore";
import { useSectionHasRegionStore } from "@/stores/SectionHasRegionStore";
import { useUserStore } from "@/stores/UserStore";
import { useUserTypeStore } from "@/stores/UserTypeStore";
import { useUserParamsStore } from "@/stores/UserParamsStore";
import { useAddressStore } from "@/stores/AddressStore";
import { useSeasonParticipantStore } from "@/stores/SeasonParticipantStore";
import { useSeasonSettingTypeStore } from "@/stores/SeasonSettingTypeStore";
import { useSeasonSettingParameterStore } from "@/stores/SeasonSettingParameterStore";
import { useClubPresentationStore } from "@/stores/ClubPresentationStore";
import { useClubPresentationParameterStore } from "@/stores/ClubPresentationParameterStore";
import { useClubParameterStore } from "@/stores/ClubParameterStore";
import { useLoungeStore } from "@/stores/LoungeStore";
import { useBannerStore } from "@/stores/BannerStore";
import { useSponsorStore } from "@/stores/SponsorStore";
import { useLoungeEquipmentTableStore } from "@/stores/LoungeEquipmentTableStore";
import { useTableSignStore } from "@/stores/TableSignStore";
import { useClothSignStore } from "@/stores/ClothSignStore";
import { useLoungeEquipmentBallStore } from "@/stores/LoungeEquipmentBallStore";
import { useBallSignStore } from "@/stores/BallSignStore";
import { useOfficialParticipantStore } from "@/stores/OfficialParticipantStore";
import { useOfficialStore } from "@/stores/OfficialStore";
import { useOfficialCategoryStore } from "@/stores/OfficialCategoryStore";
import { useParticipantSeasonParameterStore } from "@/stores/ParticipantSeasonParameterStore"

export const useGlobalStore = defineStore('GlobalStore', {
  state: () => {
    return {
      cookiesStore: useCookiesStore(),
      apiUrl: decodeURIComponent(useCookiesStore().getCookieValueByName('API_URL')) || 'http://cookcool.local:8090/api',
      wsUrl: decodeURIComponent(useCookiesStore().getCookieValueByName('WS_URL')) || 'ws://localhost:9501',
      lsAdminUrl: decodeURIComponent(useCookiesStore().getCookieValueByName('LS_ADMIN_URL')) || 'http://cookcool.local:8090/api/admin/event/livescore/__ID__',
      lsTableAdminUrl: decodeURIComponent(useCookiesStore().getCookieValueByName('LS_BY_TABLE_ADMIN_URL')) || 'http://cookcool.local:8090/api/admin/event/livescore/by-table/__ID__',
      lstrUrl: window.location.href.includes('cuesport.eu') ? 'https://www.jvg.cz/youtube/yt.php?new=1&match=' : 'http://www.resultservice.cz:8088/yt.html?new=1&match=',
      pathImageOptimized: decodeURIComponent(useCookiesStore().getCookieValueByName('PATH_IMG_OPTIMIZED')) || '/images/photos/optimized/',
      filePath: decodeURIComponent(useCookiesStore().getCookieValueByName('PATH_IMG_OPTIMIZED')).split('/images')[0] + '/files/' || '/files/',
      languageId: 1,
      environment: (window.location.hostname.includes('cuesport.eu') ? 'PRODUCTION' : 'TEST'),
      vocabularyStore: useVocabularyStore(),
      vocabularyPublicStore: useVocabularyPublicStore(),
      stageStore: useStageStore(),
      participantStore: useParticipantStore(),
      teamStore: useTeamStore(),
      teamInSeasonStore: useTeamInSeasonStore(),
      teamParticipantStore: useTeamParticipantStore(),
      tournamentParticipantStore: useTournamentParticipantStore(),
      tournamentParticipantStageStore: useTournamentParticipantStageStore(),
      eventParameterStore: useEventParameterStore(),
      tournamentStageStore: useTournamentStageStore(),
      tournamentStore: useTournamentStore(),
      resultCriterionStore: useResultCriterionStore(),
      eventStore: useEventStore(),
      disciplineStore: useDisciplineStore(),
      userAdminStore: useUserAdminStore(),
      clubStore: useClubStore(),
      tableSizeStore: useTableSizeStore(),
      teamTypeStore: useTeamTypeStore(),
      clubParticipantLeaderStore: useClubParticipantLeaderStore(),
      participantLeaderStore: useParticipantLeaderStore(),
      competitionStore: useCompetitionStore(),
      ageStore: useAgeStore(),
      genderStore: useGenderStore(),
      levelStore: useLevelStore(),
      competitionDisciplineStore: useCompetitionDisciplineStore(),
      tournamentDisciplineStore: useTournamentDisciplineStore(),
      competitionRegionStore: useCompetitionRegionStore(),
      tournamentRegionStore: useTournamentRegionStore(),
      competitionParticipantStore: useCompetitionParticipantStore(),
      teamEventStore: useTeamEventStore(),
      statusStore: useStatusStore(),
      liveScoreStore: useLiveScoreStore(),
      liveStreamStore: useLiveStreamStore(),
      tournamentVenueStore: useTournamentVenueStore(),
      clubTodayEventIdStore: useClubTodayEventIdStore(),
      eventRefereeStore: useEventRefereeStore(),
      loungeClubAddressTableStore: useLoungeClubAddressTableStore(),
      clubAddressStore: useClubAddressStore(),
      commentStore: useCommentStore(),
      leagueCommentStore: useLeagueCommentStore(),
      tournamentCommentStore: useTournamentCommentStore(),
      commentTypeStore: useCommentTypeStore(),
      organizationStore: useOrganizationStore(),
      countryStore: useCountryStore(),
      organizationHasSectionStore: useOrganizationHasSectionStore(),
      sectionStore: useSectionStore(),
      seasonStore: useSeasonStore(),
      organizationRegionStore: useOrganizationRegionStore(),
      sectionHasRegionStore: useSectionHasRegionStore(),
      userStore: useUserStore(),
      userTypeStore: useUserTypeStore(),
      userParamsStore: useUserParamsStore(),
      addressStore: useAddressStore(),
      seasonParticipantStore: useSeasonParticipantStore(),
      seasonParticipantParameterStore: useSeasonSettingParameterStore(),
      seasonSettingTypeStore: useSeasonSettingTypeStore(),
      clubPresentationStore: useClubPresentationStore(),
      clubPresentationParameterStore: useClubPresentationParameterStore(),
      clubParameterStore: useClubParameterStore(),
      loungeStore: useLoungeStore(),
      bannerStore: useBannerStore(),
      sponsorStore: useSponsorStore(),
      loungeEquipmentTableStore: useLoungeEquipmentTableStore(),
      tableSignStore: useTableSignStore(),
      clothSignStore: useClothSignStore(),
      loungeEquipmentBallStore: useLoungeEquipmentBallStore(),
      ballSignStore: useBallSignStore(),
      officialParticipantStore: useOfficialParticipantStore(),
      officialStore: useOfficialStore(),
      officialCategoryStore: useOfficialCategoryStore(),
      participantSeasonParameterStore: useParticipantSeasonParameterStore(),
    }
  },
  getters: {
    getOrganizationNameById: state => {
      return id => {
        const organization = state.organizationStore.getById(+id)
        return organization ? organization.name : '?'
      }
    },
    getSectionNameById: state => {
      return id => {
        const section = state.sectionStore.getById(id),
          sectionNameId = section ? section.name : null
        return sectionNameId ? state.vocabularyStore.getWordByIdAndLanguage(sectionNameId, state.languageId) : '?'
      }
    },
    getStageNameById: state => {
      return id => {
        const stage = state.stageStore.getById(id),
          stageNameId = stage ? stage.name : null
        return stageNameId ? state.vocabularyStore.getWordByIdAndLanguage(stageNameId, state.languageId) : '?'
      }
    },
    getStageShortNameById: state => {
      return id => {
        const stageName = state.stageStore.stages[id] ? state.stageStore.stages[id].shortName : null
        return stageName ? stageName : state.getStageNameById(id)
      }
    },
    getWordById: state => {
      return id => {
        return state.vocabularyStore.getWordByIdAndLanguage(id, state.languageId)
      }
    },
    getPublicWordById: state => {
      return id => {
        return state.vocabularyPublicStore.getWordByIdAndLanguage(id, state.languageId)
      }
    },
    getUserRole: state => state.userAdminStore.adminUserRole,
    isUserSuperUser: state => state.userAdminStore.isUserSuperUser,
    isUserRegistry: state => state.userAdminStore.isUserRegistry,
    isUserSection: state => state.userAdminStore.isUserSection,
    isUserRegion: state => state.userAdminStore.isUserRegion,
    isUserClub: state => state.userAdminStore.isUserClub,
    isUserTypeAdministrator: state => state.userAdminStore.isUserTypeAdministrator,
    hasUserAddRule: state => state.userAdminStore.hasAddRule,
    hasUserUpdateRule: state => state.userAdminStore.hasUpdateRule,
    hasUserDeleteRule: state => state.userAdminStore.hasDeleteRule,
    getEventParameterNameById: state => {
      return id => {
        for (let i in state.eventParameterStore.eventParameters) {
          const row = state.eventParameterStore.eventParameters[i]
          if (row.id === id) {
            const eventParameterNameId = row.name
            return eventParameterNameId ? state.vocabularyStore.getWordByIdAndLanguage(eventParameterNameId, state.languageId) : '?'
          }
        }
        return '?'
      }
    },
    getResultCriterionNameById: state => {
      return id => {
        for (let i in state.resultCriterionStore.resultCriterion) {
          const row = state.resultCriterionStore.resultCriterion[i]
          if (row.id === id) {
            const resultCriterionNameId = row.name
            return resultCriterionNameId ? state.vocabularyStore.getWordByIdAndLanguage(resultCriterionNameId, state.languageId) : '?'
          }
        }
        return '?'
      }
    },
    getResultCriterionShortNameById: state => {
      return id => {
        for (let i in state.resultCriterionStore.resultCriterion) {
          const row = state.resultCriterionStore.resultCriterion[i]
          if (row.id === id) {
            const resultCriterionNameId = row.shortName
            return resultCriterionNameId ? state.vocabularyStore.getWordByIdAndLanguage(resultCriterionNameId, state.languageId) : '?'
          }
        }
        return '?'
      }
    },
    getParticipantName: state => {
      return row => {
        if (row.teamId) {
          return state.getTeamNameById(row.teamId)
        }
        return state.getParticipantNameById(row.participantId)
      }
    },
    getClubNameById: state => {
      return id => {
        const club = state.clubStore.getById(id)
        return club ? club.name : '?'
      }
    },
    getParticipantNameById: state => {
      return id => {
        if (state.eventStore.freelancerId === id) {
          return state.vocabularyStore.getWordByIdAndLanguage('bye', state.languageId)
        }
        return state.participantStore.participants[id] ? state.participantStore.participants[id].name : '?'
      }
    },
    getParticipantNameYearMonthBirthById: state => {
      return id => {
        if (state.eventStore.freelancerId === id) {
          return state.vocabularyStore.getWordByIdAndLanguage('bye', state.languageId)
        }
        return state.participantStore.participants[id] ? state.participantStore.participants[id].nameYearMonthBirth : '?'
      }
    },
    getTeamNameById: state => {
      return id => {
        if (state.eventStore.freelancerTeamId === id) {
          return state.vocabularyStore.getWordByIdAndLanguage('bye', state.languageId)
        }
        return state.teamStore.teams[id] ? state.teamStore.teams[id].name : '?'
      }
    },
    getDisciplineNameById: state => {
      return id => {
        for (let i in state.disciplineStore.disciplines) {
          if (+state.disciplineStore.disciplines[i].id === +id) {
            return state.vocabularyStore.getWordByIdAndLanguage(+state.disciplineStore.disciplines[i].name, state.languageId)
          }
        }
        return id
      }
    },
    getClubParameterNameById: state => {
      return id => {
        for (let i in state.clubParameterStore.data) {
          if (+state.clubParameterStore.data[i].id === +id) {
            return state.vocabularyStore.getWordByIdAndLanguage(+state.clubParameterStore.data[i].name, state.languageId)
          }
        }
        return id
      }
    },
    getTableSizeNameById: state => {
      return id => {
        for (let i in state.tableSizeStore.tableSizes) {
          if (+state.tableSizeStore.tableSizes[i].id === +id) {
            return state.vocabularyStore.getWordByIdAndLanguage(+state.tableSizeStore.tableSizes[i].name, state.languageId)
          }
        }
        return id
      }
    },
    getTeamTypeNameById: state => {
      return id => {
        for (let i in state.teamTypeStore.teamTypes) {
          if (+state.teamTypeStore.teamTypes[i].id === +id) {
            return state.vocabularyStore.getWordByIdAndLanguage(+state.teamTypeStore.teamTypes[i].name, state.languageId)
          }
        }
        return id
      }
    },
    getGenderNameById: state => {
      return id => {
        const gender = state.genderStore.getById(+id)
        if (gender) {
          return state.vocabularyStore.getWordByIdAndLanguage(+gender.name, state.languageId)
        }
        return id
      }
    },
    getCountryNameById: state => {
      return id => {
        const country = state.countryStore.getById(+id)
        if (country) {
          return state.vocabularyStore.getWordByIdAndLanguage(+country.name, state.languageId)
        }
        return id
      }
    },
    getLevelNameById: state => {
      return id => {
        const level = state.levelStore.getById(+id)
        if (level) {
          return state.vocabularyStore.getWordByIdAndLanguage(+level.name, state.languageId)
        }
        return id
      }
    },
    getAgeNameById: state => {
      return id => {
        const age = state.ageStore.getById(+id)
        if (age) {
          return state.vocabularyStore.getWordByIdAndLanguage(+age.name, state.languageId)
        }
        return id
      }
    },
    getCommentTypeNameById: state => {
      return id => {
        const commentType = state.commentTypeStore.getById(+id)
        if (commentType) {
          return state.vocabularyStore.getWordByIdAndLanguage(+commentType.name, state.languageId)
        }
        return id
      }
    },
    getUserTypeNameById: state => {
      return id => {
        const userType = state.userTypeStore.getById(+id)
        if (userType) {
          return state.vocabularyStore.getWordByIdAndLanguage(+userType.name, state.languageId)
        }
        return id
      }
    },
    getRegionNameById: state => {
      return (id, regions) => {
        let result = null
        regions = regions || state.userAdminStore.getRestrictions['o'][state.userAdminStore.activeOrganization]['s'][state.userAdminStore.activeSection]['r']
        if (regions) {
          for (let i in regions) {
            const region = regions[i]
            if (+i === +id) {
              result = state.getWordById(region.name)
            }
          }
          if (!result) {
            for (let i in regions) {
              const region = regions[i]
              if (!result && region.r) {
                result = state.getRegionNameById(id, region.r)
              }
            }
          }
        }
        return result
      }
    },
    getLeafRegions: state => {
      return regions => {
        if (regions.length === 1) {
          return regions
        }

        const data = []
        if (state.userAdminStore.activeOrganization && state.userAdminStore.activeSection) {
          const regionIds = {},
            regionParents = {}
          for (let i in regions) {
            regionIds[regions[i].regionInOrganizationId] = regions[i].regionInOrganizationId
          }
          for (let i in regions) {
            const objects = state.userAdminStore.getRegionsByRegion(state.userAdminStore.activeOrganization, state.userAdminStore.activeSection, regions[i].regionInOrganizationId)
            if (objects) {
              for (let j in objects) {
                const regionInSectionId = objects[j].regionInSectionId,
                  regionInOrganizationId = j
                if (regionInSectionId && regionIds[regionInSectionId] && regionIds[regionInOrganizationId]) {
                  regionParents[regionInSectionId] = j
                }
              }
            }
          }

          for (let i in regions) {
            const region = regions[i]
            if (!regionParents[region.regionInOrganizationId]) {
              data.push(region)
            }
          }
        }

        return data
      }
    },
    getSvlStatus: state => {
      const data = {}
      for (let i in state.statusStore.statuses) {
        const row = state.statusStore.statuses[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlOfficialCategory: state => {
      const data = {}
      for (let i in state.officialCategoryStore.data) {
        const row = state.officialCategoryStore.data[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlOfficialParticipantByClubId: state => {
      return clubId => {
        const data = {},
          source = state.officialParticipantStore.getByClubId(clubId)

        for (let i in source) {
          const row = source[i]
          data[row.id] = state.getParticipantNameById(row.participantId)
        }
        return data
      }
    },
    getSvlBanner: state => {
      const data = {}
      for (let i in state.bannerStore.data) {
        const row = state.bannerStore.data[i]
        data[row.id] = row.name
      }
      return data
    },
    getSvlLounge: state => {
      const data = {}
      for (let i in state.loungeClubAddressTableStore.data) {
        const row = state.loungeClubAddressTableStore.data[i]
        if (row.zip) {
          data[row.loungeId] = state.getClubNameById(row.clubId) + ' - ' + row.street + ' - ' + row.streetNumber
        }
      }
      return data
    },
    getSvlClub: state => {
      const data = {}
      for (let i in state.clubStore.clubs) {
        const row = state.clubStore.clubs[i]
        data[row.id] = row.name
      }
      return data
    },
    getSvlClubByOrganizationId: state => {
      return organizationId => {
        const data = {},
          source = state.clubStore.clubs.filter(row => +row.organizationId === +organizationId)
        for (let i in source) {
          const row = source[i]
          data[row.id] = row.name
        }
        return data
      }
    },
    getSvlParticipantByOrganizationId: state => {
      return organizationId => {
        const data = {},
          source = state.participantStore.getByOrganizationId(organizationId)
        for (let i in source) {
          const row = source[i]
          data[row.id] = row.name
        }
        return data
      }
    },
    getSvlUserType: state => {
      const data = {}
      for (let i in state.userTypeStore.data) {
        const row = state.userTypeStore.data[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlSection: state => {
      const data = {}
      for (let i in state.sectionStore.data) {
        const row = state.sectionStore.data[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlClubParameter: state => {
      const data = {}
      for (let i in state.clubParameterStore.data) {
        const row = state.clubParameterStore.data[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlCountry: state => {
      const data = {}
      for (let i in state.countryStore.data) {
        const row = state.countryStore.data[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlTableSize: state => {
      const data = {}
      for (let i in state.tableSizeStore.tableSizes) {
        const row = state.tableSizeStore.tableSizes[i]
        data[row.id] = state.getWordById(row.name) + ' (' + row.dimensions + ')'
      }
      return data
    },
    getSvlTableSign: state => {
      const data = {}
      for (let i in state.tableSignStore.data) {
        const row = state.tableSignStore.data[i]
        data[row.id] = row.name
      }
      return data
    },
    getSvlClothSign: state => {
      const data = {}
      for (let i in state.clothSignStore.data) {
        const row = state.clothSignStore.data[i]
        data[row.id] = row.name
      }
      return data
    },
    getSvlBallSign: state => {
      const data = {}
      for (let i in state.ballSignStore.data) {
        const row = state.ballSignStore.data[i]
        data[row.id] = row.name
      }
      return data
    },
    getSvlTeamType: state => {
      const data = {}
      for (let i in state.teamTypeStore.teamTypes) {
        const row = state.teamTypeStore.teamTypes[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlCommentType: state => {
      const data = {}
      for (let i in state.commentTypeStore.data) {
        const row = state.commentTypeStore.data[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlGender: state => {
      const data = {}
      for (let i in state.genderStore.genders) {
        const row = state.genderStore.genders[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlAge: state => {
      const data = {}
      for (let i in state.ageStore.ages) {
        const row = state.ageStore.ages[i]
        data[row.id] = state.getWordById(row.name) + ' (' + state.getWordById(row.shortName) + ')'
      }
      return data
    },
    getSvlLevel: state => {
      const data = {}
      for (let i in state.levelStore.levels) {
        const row = state.levelStore.levels[i]
        data[row.id] = state.getWordById(row.name)
      }
      return data
    },
    getSvlDiscipline: state => {
      return (sectionId, tableSizeId) => {
        const data = {}
        let source = state.disciplineStore.disciplines

        if (sectionId && tableSizeId) {
          source = state.disciplineStore.getDisciplinesBySectionAndTable(sectionId, tableSizeId)
        }

        for (let i in source) {
          const row = source[i]
          data[row.id] = state.getWordById(row.name)
        }
        return data
      }
    },
    getSvlSectionRegion: state => {
      return (organizationId, sectionId) => {
        const data = {},
          source = state.sectionHasRegionStore.getByOrganizationIdSectionId(organizationId, sectionId)
        for (let i in source) {
          const row = source[i]
          data[row.id] = state.getWordById(state.organizationRegionStore.getById(row.regionInOrganizationId).name)
        }
        return data
      }
    },
    getSvlOrganizationRegion: state => {
      return organizationId => {
        const data = {},
          source = state.organizationRegionStore.getByOrganizationId(organizationId)
        for (let i in source) {
          const row = source[i]
          data[row.id] = state.getWordById(row.name)
        }
        return data
      }
    },
    getSvlOnlyTrue: () => {
      return {
        '1': ''
      }
    },
    getSvlTrueFalse: () => {
      return {
        '1': 'Ano',
        '': 'Ne',
      }
    },
    orderSvl: () => {
      return (data, blankChoice) => {
        const result = [];
        if (blankChoice) {
          result.push({
            id: '',
            value: ''
          })
        }
        for (let i in data) {
          const row = data[i]
          result.push({
            'id': i,
            'label': row.name ? row.name : row
          })
        }
        const sorted = result.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
        return sorted
      }
    },
    getTournamentParticipantNameById: state => {
      return id => {
        for (let i in state.tournamentParticipantStore.tournamentParticipants) {
          const row = state.tournamentParticipantStore.tournamentParticipants[i]
          if (row.id === id) {
            if (row.participantId) {
              return state.getParticipantNameById(row.participantId)
            } else if (row.teamId) {
              return state.getTeamNameById(row.teamId)
            }
          }
        }
        return '?'
      }
    },
    getTournamentStageIdByStageId: state => {
      return stageId => {
        for (let i in state.tournamentStageStore.tournamentStages) {
          const row = state.tournamentStageStore.tournamentStages[i]
          if (row.stageId === stageId) {
            return row.id
          }
        }
        return null
      }
    },
    getEventStageSign: state => {
      return event => {
        if (!event.tournamentId) {
          return ''
        }
        if (event.stageSign) {
          return event.stageSign
        }
        for (let i in event.eventParticipant) {
          const participantId = event.eventParticipant[i].participantId
          if (participantId) {
            const tournamentParticipantId = state.tournamentParticipantStore.getTournamentParticipantsByParticipantId(participantId).id,
              stageParticipants = state.tournamentParticipantStageStore.getTournamentParticipantsByStageId(event.stageId)

            for (let stageSign in stageParticipants) {
              for (let i in stageParticipants[stageSign]) {
                if (stageParticipants[stageSign][i].tournamentParticipantId === tournamentParticipantId) {
                  return stageSign
                }
              }
            }
          }
        }
        return ''
      }
    },
    applyStageParticipantMinCountLimit: state => {
      return stageId => {
        const tournamentParticipantCount = state.tournamentParticipantStore.count,
          stageTournamentParticipantMinCountLimit = (+state.tournamentStageStore.getTournamentStageByStageId(stageId).tournamentParticipantMinCount || 0)
        return tournamentParticipantCount >= stageTournamentParticipantMinCountLimit
      }
    },
    getActiveClubName: state => {
      const activeClub = state.userAdminStore.activeClub
      if (activeClub) {
        const clubNames = state.userAdminStore.getUserClubNames
        if (clubNames && clubNames[activeClub]) {
          return clubNames[activeClub]
        }
      }
      return ''
    }
  },
  actions: {
    getCookieValueByName(name) {
      return this.cookiesStore.getCookieValueByName(name)
    },
    formatDate(string) {
      if (!string) {
        return ''
      }
      let parts = null
      if (string.indexOf(' ') > 0) {
        parts = string.split(' ')
      } else if (string.indexOf('T') > 0) {
        parts = string.split('T')
      } else {
        return string
      }
      const dateParts = parts[0].split('-')

      return parseInt(dateParts[2]) + '. ' + parseInt(dateParts[1]) + '. ' + dateParts[0] + ' 00:00:00'
    },
    formatDateTime(string) {
      if (!string) {
        return ''
      }
      let parts = null
      if (string.indexOf(' ') > 0) {
        parts = string.split(' ')
      } else if (string.indexOf('T') > 0) {
        parts = string.split('T')
      } else {
        return string
      }
      const dateParts = parts[0].split('-'),
        time = parts[1].substr(0, 5)

      return parseInt(dateParts[2]) + '. ' + parseInt(dateParts[1]) + '. ' + dateParts[0] + ', ' + time
    },
    formatZip(zip) {
      if (zip.length === 5 && +zip == zip) {
        return zip.substr(0, 3) + ' ' + zip.substr(2)
      }
      return zip
    },
    formatAddressInline(address) {
      return (address.street ? address.street + ' ' : '') + (address.streetNumber ? address.streetNumber : '') + (address.street || address.streetNumber ? ', ' : '') + this.formatZip(address.zip) + ' ' + address.city + ', ' + address.country
    },
    formatAverage(number, decimals = 3) {
      if (+number === 0) {
        return ''
      }
      const multiplier = Math.pow(10, decimals)
      return (Math.round(number * multiplier) / multiplier).toFixed(decimals)
    },
    openLinkInNewWindow(url) {
      return !window.open(url)
    },
    swapObject(objectKeyValue, sort = false) {
      const swapped = Object.fromEntries(Object.entries(objectKeyValue).map(
        ([key, value]) => [value, key]
      ))

      if (sort === true) {
        return Object.keys(swapped)
          .sort()
          .reduce((acc, key) => ({
            ...acc, [key]: swapped[key]
          }), {})
      }

      return swapped
    },
    getAdminUserRoleName(userId) {
      if (!userId) {
        userId = this.getUserRole
        if (!userId) {
          return this.getWordById('invalid user')
        }
      }
      switch (userId) {
        case 1:
          return this.getWordById('superuser user')
        case 2:
          return this.getWordById('registry user')
        case 3:
          return this.getWordById('section user')
        case 4:
          return this.getWordById('region user')
        case 5:
          return this.getWordById('club user')
        default:
          return this.getWordById('invalid user')
      }
    },
    sortBy(data, orderBy) {
      if (!data) {
        return []
      }
      // sortBy(array, [{points : 'asc'}, {innings: 'desc'}])
      orderBy = Array.isArray(orderBy) ? orderBy : [orderBy]
      return data.sort((a, b) => {
        for (let i = 0, size = orderBy.length; i < size; i++) {
          const key = Object.keys(orderBy[i])[0],
            o = orderBy[i][key],
            valueA = a[key],
            valueB = b[key]
          if (!(valueA || valueB)) {
            console.error('the objects from the data passed does not have the key "' + key + '" passed on sort!')
            return []
          }
          if (+valueA === +valueA) {
            return o.toLowerCase() === 'desc' ? valueB - valueA : valueA - valueB
          } else {
            if (valueA.localeCompare(valueB) > 0) {
              return o.toLowerCase() === 'desc' ? -1 : 1
            } else if (valueA.localeCompare(valueB) < 0) {
              return o.toLowerCase() === 'desc' ? 1 : -1
            }
          }
        }
      })
    },
    generateImgPreview(imageName) {
      if (!imageName) {
        return ''
      }
      return '<img src="' + this.pathImageOptimized + imageName + '" width="150"/>'
    },
    generateFilePreview(fileName) {
      if (!fileName) {
        return ''
      }
      return '<a href="' + this.filePath + fileName + '" _target="_blank">' + fileName + '</a>'
    },
    async checkUserAdminParams() {
      if (!Object.keys(this.userAdminStore.restrictions).length && this.userAdminStore.user) {
        await fetch(this.apiUrl + '/admin/login-parameter', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data && response.data.restriction) {
              this.userAdminStore.setRestrictions(response.data.restriction)
              if (response.data.restriction.vocabulary) {
                this.vocabularyStore.addRows(response.data.restriction.vocabulary)
              }
              this.userAdminStore.setDefaults()
              this.userAdminStore.setDefaultActiveOrganization()
              this.userAdminStore.setDefaultActiveSection()
              this.userAdminStore.setDefaultActiveSeason()
              this.userAdminStore.setDefaultActiveRegion()
              this.userAdminStore.setDefaultActiveClub()
            }
          }).then(() => {
            this.userAdminStore.propertiesLoaded = true
          })
          .catch(err => console.error(err))
      }
    }
  }
})
