<template>
  <button v-if="globalStore.hasUserAddRule" @click="clickFunction" class="btn btn-sm btn-primary">
    {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create youtube livestream', globalStore.languageId) }}
  </button>
</template>

<script>
import {useGlobalStore} from "@/stores/GlobalStore"

export default {
  name: 'ButtonCreateLivestream',
  components: {},
  props: {
    clickFunction: {
      required: true,
      type: Function
    }
  },

  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore
    }
  }
}
</script>
