<template>
  <div v-if="tournamentStore.tournament.id">
    <router-link v-if="tournamentStore.tournament.competitionId" :to="'/competition-management/' + tournamentStore.tournament.competitionId">
      {{ globalStore.vocabularyStore.getWordByIdAndLanguage('competition', globalStore.languageId) }}
    </router-link>
    <router-link v-else to="/tournaments">
      {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournaments', globalStore.languageId) }}
    </router-link>
    <h1>{{ tournamentStore.tournament.name }}</h1>
    <section>
      <!-- Pills -->
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="tournament-stages-tab" data-bs-toggle="pill" data-bs-target="#tournament-stages" type="button" role="tab" aria-controls="tournament-stages" aria-selected="true">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stages', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="tournament-participant-tab" data-bs-toggle="pill" data-bs-target="#tournament-participant" type="button" role="tab" aria-controls="tournament-participant">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participants', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="!tournamentStore.isTournamentForTeams" class="nav-item" role="presentation">
          <button class="nav-link" id="tournament-participant-setting-type-tab" data-bs-toggle="pill" data-bs-target="#tournament-participant-setting-type" type="button" role="tab" aria-controls="tournament-participant-setting-type">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant setting type', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="tournament-result-criterion-tab" data-bs-toggle="pill" data-bs-target="#tournament-result-criterion" type="button" role="tab" aria-controls="tournament-result-criterion">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament result criterion', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="tournamentStore.tournament.teamTypeId === 5" class="nav-item" role="presentation">
          <button class="nav-link" id="participant-tab" data-bs-toggle="pill" data-bs-target="#participant" type="button" role="tab" aria-controls="participant">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create double scotch team', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="!tournamentStore.tournament.teamTypeId || tournamentStore.tournament.teamTypeId === 5" class="nav-item" role="presentation">
          <button class="nav-link" id="schedule-tab" data-bs-toggle="pill" data-bs-target="#schedule" type="button" role="tab" aria-controls="schedule">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage schedule', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="globalStore.isUserTypeAdministrator && isTemplateApplyable() === true" class="nav-item" role="presentation">
          <button class="nav-link" id="template-tab" data-bs-toggle="pill" data-bs-target="#template" type="button" role="tab" aria-controls="template">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament template', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="referee-tab" data-bs-toggle="pill" data-bs-target="#referee" type="button" role="tab" aria-controls="referee">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament referee', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="globalStore.isUserTypeAdministrator && globalStore.environment === 'TEST'" class="nav-item" role="presentation">
          <TournamentReset />
        </li>
      </ul>
      <!-- Contents -->
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="tournament-stages" role="tabpanel" aria-labelledby="tournament-stages-tab">
          <TournamentStages />
        </div>
        <div class="tab-pane fade" id="tournament-participant" role="tabpanel" aria-labelledby="tournament-participant-tab">
          <TournamentParticipant />
        </div>
        <div v-if="!tournamentStore.isTournamentForTeams" class="tab-pane fade" id="tournament-participant-setting-type" role="tabpanel" aria-labelledby="tournament-participant-setting-type-tab">
          <TournamentParticipantSettingType />
        </div>
        <div class="tab-pane fade" id="tournament-result-criterion" role="tabpanel" aria-labelledby="tournament-result-criterion-tab">
          <TournamentResultCriterion />
        </div>
        <div class="tab-pane fade" id="participant" role="tabpanel" aria-labelledby="participant-tab">
          <ParticipantItem
            :participantType="tournamentStore.tournament.teamTypeId" />
        </div>
        <div class="tab-pane fade" id="template" role="tabpanel" aria-labelledby="template-tab">
          <TournamentTemplate />
        </div>
        <div class="tab-pane fade" id="referee" role="tabpanel" aria-labelledby="referee-tab">
          <TournamentReferee :tournamentObject="tournamentStore.tournament" />
        </div>
        <div v-if="!tournamentStore.tournament.teamTypeId || tournamentStore.tournament.teamTypeId === 5" class="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
          <template v-for="stage in tournamentStageStore.tournamentStages" :key="stage.id">
            <h3>{{ globalStore.getStageNameById(stage.stageId) }}</h3>
            <TournamentStageSchedule :set-stage-id="stage.stageId" />
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useEventParameterStore } from "@/stores/EventParameterStore"
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useTournamentStageStore } from "@/stores/TournamentStageStore"
import { useTournamentStore } from "@/stores/TournamentStore"
import { useTournamentParticipantStore } from "@/stores/TournamentParticipantStore"
import { useTournamentParticipantStageStore } from "@/stores/TournamentParticipantStageStore"
import { useTournamentResultCriterionStore } from "@/stores/TournamentResultCriterionStore"
import { useTournamentStageRankingStore } from "@/stores/TournamentStageRankingStore"
import { useParticipantStore } from "@/stores/ParticipantStore"
import { useTeamParticipantStore } from "@/stores/TeamParticipantStore"
import { useStageStore } from "@/stores/StageStore"
import { useResultCriterionStore } from "@/stores/ResultCriterionStore"
import { useVocabularyStore } from "@/stores/VocabularyStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import TournamentParticipant from '@/components/TournamentManagement/TournamentParticipant';
import TournamentParticipantSettingType from '@/components/TournamentManagement/TournamentParticipantSettingType';
import TournamentStages from '@/components/TournamentManagement/TournamentStages';
import TournamentResultCriterion from '@/components/TournamentManagement/TournamentResultCriterion';
import TournamentReset from '@/components/TournamentManagement/TournamentReset';
import { useTeamEventRoundStore } from "@/stores/TeamEventRoundStore";
import { useTeamEventRoundParameterStore } from "@/stores/TeamEventRoundParameterStore";
import { useDisciplineStore } from "@/stores/DisciplineStore";
import { useDisciplineCoefficientStore } from "@/stores/DisciplineCoefficientStore";
import ParticipantItem from "@/components/ParticipantItem.vue";
import TournamentStageSchedule from "@/components/TournamentManagement/TournamentStageSchedule.vue";
import TournamentReferee from "@/components/TournamentManagement/TournamentReferee.vue";
import TournamentTemplate from "@/components/TournamentManagement/TournamentTemplate.vue";
import { useTournamentParticipantSettingTypeStore } from "@/stores/TournamentParticipantSettingTypeStore";
import { useStaticValueListStore } from "@/stores/StaticValueListStore";
import tournamentResultCriterion from "@/components/TournamentManagement/TournamentResultCriterion.vue";
import { useTournamentParticipantStagePredictionStore } from "@/stores/TournamentParticipantStagePredictionStore";
import { useEventPredictionStore } from "@/stores/EventPredictionStore";
import { useNotificationStore } from '@/stores/NotificationStore'
import { useTournamentRefereeStore } from '@/stores/TournamentRefereeStore'

export default {
  name: 'TournamentManagement',
  computed: {
    tournamentResultCriterion() {
      return tournamentResultCriterion
    }
  },
  components: {
    TournamentStageSchedule,
    ParticipantItem, TournamentParticipant, TournamentParticipantSettingType, TournamentStages,
    TournamentResultCriterion, TournamentReset, TournamentTemplate, TournamentReferee
  },

  setup() {
    const route = useRoute(),
      id = +route.params.id,
      vocabularyStore = useVocabularyStore(),
      tournamentStore = useTournamentStore(),
      tournamentStageStore = useTournamentStageStore(),
      tournamentParticipantStore = useTournamentParticipantStore(),
      tournamentParticipantSettingTypeStore = useTournamentParticipantSettingTypeStore(),
      tournamentRefereeStore = useTournamentRefereeStore(),
      staticValueListStore = useStaticValueListStore(),
      tournamentParticipantStageStore = useTournamentParticipantStageStore(),
      tournamentParticipantStagePredictionStore = useTournamentParticipantStagePredictionStore(),
      tournamentResultCriterionStore = useTournamentResultCriterionStore(),
      tournamentStageRankingStore = useTournamentStageRankingStore(),
      eventParameterStore = useEventParameterStore(),
      eventStageParameterStore = useEventStageParameterStore(),
      eventPredictionStore = useEventPredictionStore(),
      participantStore = useParticipantStore(),
      teamEventRoundStore = useTeamEventRoundStore(),
      teamEventRoundParameterStore = useTeamEventRoundParameterStore(),
      teamParticipantStore = useTeamParticipantStore(),
      stageStore = useStageStore(),
      resultCriterionStore = useResultCriterionStore(),
      disciplineStore = useDisciplineStore(),
      disciplineCoefficientStore = useDisciplineCoefficientStore(),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      fetch(globalStore.apiUrl + '/admin/tournament-management/' + id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          tournamentStore.setDataOne(data.data.tournament)

          globalStore.userAdminStore.activeOrganization = data.data.tournament.organizationId
          globalStore.userAdminStore.activeSection = data.data.tournament.sectionId
          globalStore.userAdminStore.activeSeason = data.data.tournament.season
          globalStore.userAdminStore.setUserRole(data.data.tournament.organizationId, data.data.tournament.sectionId)
          globalStore.userAdminStore.setDefaults()

          participantStore.setData(data.data.participant)
          tournamentParticipantStore.setData(data.data.tournamentParticipant)
          tournamentParticipantStageStore.setData(data.data.tournamentParticipantStage)
          tournamentParticipantStagePredictionStore.setData(data.data.tournamentParticipantStagePrediction)
          eventPredictionStore.setData(data.data.eventPrediction)
          tournamentResultCriterionStore.setData(data.data.tournamentResultCriterion)
          tournamentStageStore.setData(data.data.eventStage)
          eventParameterStore.setData(data.data.eventParameter)
          eventStageParameterStore.setData(data.data.eventStageParameter)
          stageStore.setData(data.data.stage)
          resultCriterionStore.setData(data.data.resultCriterion)
          vocabularyStore.addRows(data.data.vocabulary)
          tournamentStageRankingStore.setData(data.data.tournamentStageRanking)
          disciplineStore.setData(data.data.discipline)
          disciplineCoefficientStore.setData(data.data.disciplineCoefficient)
          globalStore.loungeClubAddressTableStore.setData(data.data.loungeClubAddressTable)
          if (data.data.teamEventStage) {
            teamEventRoundStore.setData(data.data.teamEventStage)
          }
          if (data.data.tournamentReferee) {
            tournamentRefereeStore.setData(data.data.tournamentReferee)
          }
          if (data.data.teamEventStageParameter) {
            teamEventRoundParameterStore.setData(data.data.teamEventStageParameter)
          }
          if (data.data.tournamentParticipantSettingType) {
            tournamentParticipantSettingTypeStore.setData(data.data.tournamentParticipantSettingType)
          }
          if (data.data.participantSettingType) {
            staticValueListStore.setData('participantSettingType', data.data.participantSettingType)
          }
          if (data.data.participantHasParticipant) {
            teamParticipantStore.setData(data.data.participantHasParticipant)
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    })

    function isTemplateApplyable() {
      for (let i in teamEventRoundStore.teamEventRounds) {
        if (teamEventRoundStore.teamEventRounds[i].tournamentId) {
          return false
        }
      }
      for (let i in tournamentStageStore.tournamentStages) {
        if (tournamentStageStore.tournamentStages[i].tournamentId) {
          return false
        }
      }
      for (let i in tournamentResultCriterionStore.tournamentResultCriterion) {
        if (tournamentResultCriterionStore.tournamentResultCriterion[i].tournamentId) {
          return false
        }
      }
      for (let i in tournamentParticipantSettingTypeStore.tournamentParticipantSettingType) {
        if (tournamentParticipantSettingTypeStore.tournamentParticipantSettingType[i].tournamentId) {
          return false
        }
      }
      return true
    }

    return {
      tournamentStore, tournamentStageStore, globalStore, id,
      isTemplateApplyable
    }
  }
}
</script>
