<template v-if="tournament">
  <tr>
    <template v-if="tournament">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td><span class="small mark">#{{ tournament.id }}</span></td>
        <td>
          <router-link :to="'/tournament-management/' + tournament.id" target="_blank">{{ tournament.name }}</router-link>
          <p>
            <span>| </span>
            <small v-for="row in globalStore.tournamentDisciplineStore.getByTournamentId(tournament.id)" :key="row.id">
              {{ globalStore.getDisciplineNameById(row.disciplineId) }}
              <span> | </span>
            </small>
            <template v-if="globalStore.userAdminStore.isUserRegion">
              <br />
              <span>| </span>
              <small v-for="row in globalStore.getLeafRegions(globalStore.tournamentRegionStore.getByTournamentId(tournament.id))" :key="row.id">
                {{ globalStore.getRegionNameById(row.regionInOrganizationId) }}
                <span> | </span>
              </small>
            </template>
          </p>
        </td>
        <td>
          {{ globalStore.getTableSizeNameById(tournament.tableSizeId) }}<br />
          {{ globalStore.getTeamTypeNameById(tournament.teamTypeId) }}<br />
          {{ globalStore.getLevelNameById(tournament.levelId) }}
        </td>
        <td>
          <template v-if="globalStore.userAdminStore.isUserRegion">
            <ButtonUpdate
              :click-function="initUpdateForm" />
            <span>&#160;</span>
            <ButtonDelete
              :click-function="deleteItem" />
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="4">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="3">
          <template v-if="!cId">
            <h5>
              {{ globalStore.vocabularyStore.getWordByIdAndLanguage('clone tournament', globalStore.languageId) }}
            </h5>
            <FormKit type="form" v-model="autoloadData" @submit="processAddForm">
              <FormKit
                type="text"
                name="tournamentName"
                :help="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament autoload', globalStore.languageId)"
                @keyup="loadTournaments(autoloadData, 'tournamentName', 'followedTournamentId')"
                autocomplete="off" />
              <FormKit
                type="select"
                :options="autoloadedTournaments"
                validation="required"
                name="followedTournamentId"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament autoloaded', globalStore.languageId)" />
              <FormKit
                type="text"
                validation="required"
                name="name"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('name', globalStore.languageId)" />
              <FormKit
                type="datetime-local"
                validation="required"
                name="dateStart"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('date start', globalStore.languageId)" />
              <FormKit
                type="datetime-local"
                name="dateEnd"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('date end', globalStore.languageId)" />
              <FormKit
                type="datetime-local"
                name="singUpDateFrom"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('sing up start', globalStore.languageId)" />
              <FormKit
                type="datetime-local"
                name="singUpDateTo"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('sing up deadline', globalStore.languageId)" />
              <FormKit
                type="checkbox"
                name="regionId"
                :options="regionOptions"
                validation="required"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('region', globalStore.languageId)" />
              <FormKit
                type="text"
                name="clubName"
                :help="globalStore.vocabularyStore.getWordByIdAndLanguage('club autoload', globalStore.languageId)"
                @keyUp="() => {
      autoloadData.clubId = ''
      autoloadData.loungeId = []
    }" />
              <FormKit
                type="radio"
                name="clubId"
                :options="clubOptions"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('club provider', globalStore.languageId)"
                @change="() => {
      autoloadData.loungeId = []
    }" />
              <FormKit
                type="select"
                name="loungeId"
                multiple="multiple"
                :options="loungeOptions"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('lounge', globalStore.languageId)" />
            </FormKit>
            <hr />
            <h5>
              {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create tournament', globalStore.languageId) }}
            </h5>
          </template>
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref, computed, onMounted } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'TournamentItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    competitionId: {
      required: false,
      type: Number
    },
    tournamentId: {
      required: false,
      type: Number
    },
    tournament: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const id = toRef(props, 'tournamentId'),
      cId = toRef(props, 'competitionId'),
      tournament = toRef(props, 'tournament'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      competition = ref(cId.value ? getCompetition() : null),
      autoloadData = ref({}),
      itemData = ref({}),
      schema = ref([]),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      autoloadedTournaments = ref({}),
      disciplineOptions = computed(() => {
        if (cId.value) {
          const competitionDisciplines = globalStore.competitionDisciplineStore.getByCompetitionId(cId.value),
            result = {}
          for (let i in competitionDisciplines) {
            const row = competitionDisciplines[i]
            result[row.disciplineId] = globalStore.getDisciplineNameById(row.disciplineId)
          }
          if (Object.keys(result).length) {
            return result
          }
        }
        if (itemData.value.tableSizeId == '1') {
          return globalStore.getSvlDiscipline(globalStore.userAdminStore.activeSection, 1)
        } else if (itemData.value.tableSizeId == '2') {
          return globalStore.getSvlDiscipline(globalStore.userAdminStore.activeSection, 2)
        }
        return []
      }),
      loungeOptions = computed(() => {
        const data = {}
        for (let i in globalStore.loungeClubAddressTableStore.data) {
          const row = globalStore.loungeClubAddressTableStore.data[i]
          data[row.loungeId] = globalStore.getClubNameById(row.clubId) + ' - ' + row.street + ' ' + row.streetNumber + ', ' + row.city
        }
        let dataList = []
        for (let i in data) {
          dataList.push({
            label: data[i],
            value: i
          })
        }
        return globalStore.sortBy(dataList, [{ label: 'asc' }])
      }),
      clubOptions = computed(() => {
        const options = globalStore.getSvlClub
        if (!itemData.value.clubName && !autoloadData.value.clubName) {
          let dataList = []
          for (let i in options) {
            if (options[i].trim() !== '') {
              dataList.push({
                label: options[i],
                value: i
              })
            }
          }
          return globalStore.sortBy(dataList, [{ label: 'asc' }])
        }
        const data = {}
        for (let i in options) {
          const row = options[i]
          if (
            (autoloadData.value.clubName && row.toLowerCase().indexOf(autoloadData.value.clubName.toLowerCase()) > -1)
            || (itemData.value.clubName && row.toLowerCase().indexOf(itemData.value.clubName.toLowerCase()) > -1)
          ) {
            data[i] = row
          }
        }
        let dataList = []
        for (let i in data) {
          dataList.push({
            label: data[i],
            value: i
          })
        }
        return globalStore.sortBy(dataList, [{ label: 'asc' }])
      }),
      regionOptions = ref([])

    onMounted(() => {
      loadClubs()
    })

    function initAddForm() {
      itemData.value = {}
      autoloadData.value = {}

      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = tournament.value
      if (!itemData.value) {
        return
      }
      const regionId = [],
        disciplineId = [],
        tournamentLoungeId = [],
        regions = globalStore.tournamentRegionStore.getByTournamentId(id.value),
        disciplines = globalStore.tournamentDisciplineStore.getByTournamentId(id.value),
        tournamentVenues = globalStore.tournamentVenueStore.getByTournamentId(id.value)

      for (let i in regions) {
        regionId.push(regions[i].regionInOrganizationId)
      }
      for (let i in disciplines) {
        disciplineId.push(disciplines[i].disciplineId)
      }
      for (let i in tournamentVenues) {
        const loungeId = globalStore.loungeClubAddressTableStore.getLoungeIdByAddressId(tournamentVenues[i].addressId)
        tournamentLoungeId.push(+loungeId)
      }

      itemData.value.regionId = regionId
      itemData.value.disciplineId = disciplineId
      itemData.value.loungeId = tournamentLoungeId
      itemData.value.handicap = itemData.value.handicap ? [itemData.value.handicap] : null

      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'text',
        name: 'name',
        validation: 'required',
        label: globalStore.getWordById('name')
      })

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'dateStart',
        validation: 'required',
        label: globalStore.getWordById('date start')
      })

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'dateEnd',
        label: globalStore.getWordById('date end')
      })

      let regionSet = false
      if (cId.value) {
        const competitionRegions = globalStore.competitionRegionStore.getByCompetitionId(cId.value)
        if (competitionRegions.length) {
          const regionIds = []
          for (let i in competitionRegions) {
            regionIds.push(competitionRegions[i].regionInOrganizationId)
          }
          schema.value.push({
            $formkit: 'hidden',
            name: 'regionId',
            value: regionIds
          })
          regionSet = true
        }
      }

      if (!regionSet) {
        regionOptions.value = []
        for (let i in globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'][globalStore.userAdminStore.activeSection]['r']) {
          const region = globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'][globalStore.userAdminStore.activeSection]['r'][i]

          regionOptions.value.push({
            value: i,
            label: globalStore.getWordById(region.name)
          })

          if (region.r) {
            for (let j in region.r) {
              const subRegion = region.r[j]
              regionOptions.value.push({
                value: j,
                label: globalStore.getWordById(subRegion.name),
              })
              if (subRegion.r) {
                for (let k in subRegion.r) {
                  const subSubRegion = subRegion.r[k]
                  regionOptions.value.push({
                    value: k,
                    label: globalStore.getWordById(subSubRegion.name),
                  })
                  if (subSubRegion.r) {
                    for (let l in subSubRegion.r) {
                      const subSubSubRegion = subSubRegion.r[l]
                      regionOptions.value.push({
                        value: l,
                        label: globalStore.getWordById(subSubSubRegion.name),
                      })
                    }
                  }
                }
              }
            }
          }
        }
        schema.value.push({
          $formkit: 'checkbox',
          name: 'regionId',
          validation: 'required',
          label: globalStore.getWordById('region'),
          options: regionOptions.value
        })
      }

      schema.value.push({
        $formkit: 'text',
        name: 'clubName',
        help: globalStore.getWordById('club autoload'),
        onKeyUp: () => {
          itemData.value.clubId = ''
          itemData.value.loungeId = []
        }
      })

      schema.value.push({
        $formkit: 'radio',
        name: 'clubId',
        options: clubOptions,
        label: globalStore.getWordById('club provider'),
        onChange: () => {
          itemData.value.loungeId = []
        }
      })

      schema.value.push({
        $formkit: 'select',
        multiple: 'multiple',
        name: 'loungeId',
        options: loungeOptions,
        label: globalStore.getWordById('lounge')
      })

      if (competition.value && competition.value.levelId !== undefined) {
        schema.value.push({
          $formkit: 'hidden',
          name: 'levelId',
          value: competition.value.levelId
        })
      } else {
        schema.value.push({
          $formkit: 'select',
          name: 'levelId',
          validation: 'required',
          options: globalStore.getSvlLevel,
          label: globalStore.getWordById('level'),
          placeholder: '...'
        })
      }

      if (competition.value && competition.value.tableSizeId !== undefined) {
        schema.value.push({
          $formkit: 'hidden',
          name: 'tableSizeId',
          value: competition.value.tableSizeId
        })
      } else {
        schema.value.push({
          $formkit: 'select',
          name: 'tableSizeId',
          validation: 'required',
          options: globalStore.getSvlTableSize,
          label: globalStore.getWordById('table size'),
          placeholder: '...'
        })
      }

      if (disciplineOptions.value && Object.keys(disciplineOptions.value).length === 1) {
        schema.value.push({
          $formkit: 'hidden',
          name: 'disciplineId',
          value: Object.keys(disciplineOptions.value)[0]
        })
      } else {
        schema.value.push({
          $formkit: 'checkbox',
          name: 'disciplineId',
          validation: 'required',
          options: disciplineOptions,
          label: globalStore.getWordById('discipline')
        })
      }

      schema.value.push({
        $formkit: 'checkbox',
        name: 'handicap',
        options: globalStore.getSvlOnlyTrue,
        label: globalStore.getWordById('handicap')
      })

      if (competition.value && competition.value.teamTypeId !== undefined) {
        schema.value.push({
          $formkit: 'hidden',
          name: 'teamTypeId',
          value: competition.value.teamTypeId
        })
      } else {
        schema.value.push({
          $formkit: 'select',
          name: 'teamTypeId',
          options: {
            '': '', ...globalStore.getSvlTeamType
          },
          label: globalStore.getWordById('team type'),
          placeholder: '...'
        })
      }

      if (competition.value && competition.value.genderId !== undefined) {
        schema.value.push({
          $formkit: 'hidden',
          name: 'genderId',
          value: competition.value.genderId
        })
      } else {
        schema.value.push({
          $formkit: 'select',
          name: 'genderId',
          options: {
            '': '', ...globalStore.getSvlGender
          },
          label: globalStore.getWordById('gender'),
          placeholder: '...'
        })
      }

      if (competition.value && competition.value.ageId !== undefined) {
        schema.value.push({
          $formkit: 'hidden',
          name: 'ageId',
          value: competition.value.ageId
        })
      } else {
        schema.value.push({
          $formkit: 'select',
          name: 'ageId',
          options: {
            '': '', ...globalStore.getSvlAge
          },
          label: globalStore.getWordById('age'),
          placeholder: '...'
        })
      }

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'singUpDateFrom',
        label: globalStore.getWordById('sing up start')
      })

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'singUpDateTo',
        label: globalStore.getWordById('sing up deadline')
      })

      schema.value.push({
        $formkit: 'select',
        name: 'statusId',
        options: {
          '': '', ...globalStore.getSvlStatus
        },
        label: globalStore.getWordById('status'),
        placeholder: '...'
      })
    }

    function processAddForm() {
      itemData.value.organizationId = globalStore.userAdminStore.activeOrganization
      itemData.value.sectionId = globalStore.userAdminStore.activeSection
      itemData.value.season = globalStore.userAdminStore.activeSeason
      itemData.value.competitionId = cId.value || null

      if (autoloadData.value.followedTournamentId && autoloadData.value.name && !itemData.value.teamTypeId) {
        itemData.value.followedTournamentId = autoloadData.value.followedTournamentId
        itemData.value.name = autoloadData.value.name
        itemData.value.dateStart = autoloadData.value.dateStart
        itemData.value.dateEnd = autoloadData.value.dateEnd
        itemData.value.singUpDateFrom = autoloadData.value.singUpDateFrom
        itemData.value.singUpDateTo = autoloadData.value.singUpDateTo
        itemData.value.clubId = autoloadData.value.clubId
        itemData.value.loungeId = autoloadData.value.loungeId
        itemData.value.regionId = autoloadData.value.regionId
      }

      fetch(globalStore.apiUrl + '/admin/tournament', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.tournamentStore.addRow(response.data.tournament)
            globalStore.tournamentDisciplineStore.addRows(response.data.tournamentDiscipline)
            globalStore.tournamentRegionStore.addRows(response.data.tournamentRegion)
            globalStore.tournamentVenueStore.addDataRows(response.data.tournamentVenue)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      itemData.value.id = id.value
      if (tournament.value) {
        fetch(globalStore.apiUrl + '/admin/tournament', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.tournamentStore.updateRow(response.data.tournament)
              globalStore.tournamentDisciplineStore.updateRowsByTournamentId(response.data.tournamentDiscipline, id.value)
              globalStore.tournamentRegionStore.updateRowsByTournamentId(response.data.tournamentRegion, id.value)
              globalStore.tournamentVenueStore.unsetTournamentDataRows(response.data.tournament.id)
              globalStore.tournamentVenueStore.addDataRows(response.data.tournamentVenue)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = {
          id: id.value
        }
        fetch(globalStore.apiUrl + '/admin/tournament', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.tournamentStore.deleteRow(id.value)
              globalStore.tournamentDisciplineStore.deleteRowsByTournamentId(id.value)
              globalStore.tournamentRegionStore.deleteRowsByTournamentId(id.value)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function loadTournaments(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: 'tournament',
          o: globalStore.userAdminStore.activeOrganization,
          se: globalStore.userAdminStore.activeSection,
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const tournaments = {}
              for (let i = 0; i < response.data.length; i++) {
                tournaments[response.data[i].id] = response.data[i].season + ': ' + response.data[i].name
                if (setSelected === false) {
                  vModel[targetValueName] = response.data[i].id
                  setSelected = true
                }
              }
              autoloadedTournaments.value = tournaments
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function getCompetition() {
      if (!cId.value) {
        return
      }
      const competition = globalStore.competitionStore.getById(cId.value)

      if (competition && competition.id) {
        if (!globalStore.competitionRegionStore.getByCompetitionId(cId.value).length) {
          const url = globalStore.apiUrl + '/admin/competition-regions/' + cId.value
          fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => {
              if (res.status !== 200) {
                throw new Error(res.statusText)
              }
              return res.json()
            })
            .then(data => {
              globalStore.competitionRegionStore.addRows(data.data.competitionRegion)
            })
            .catch(err => notificationStore.add({ type: 'error', message: err }))
        }
        return competition
      }

      const url = globalStore.apiUrl + '/admin/competitions/' + (globalStore.userAdminStore.activeOrganization || 'null') + '/' + (globalStore.userAdminStore.activeSection || 'null') + '/' + (globalStore.userAdminStore.activeRegion || 'null') + '/' + (globalStore.userAdminStore.activeSeason || 'null') + '/' + (globalStore.userAdminStore.activeClub || 'null') + '/' + cId.value
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.competitionStore.addRow(data.data.competition[0])
          globalStore.tableSizeStore.setData(data.data.tableSize)
          globalStore.levelStore.setData(data.data.level)
          globalStore.genderStore.setData(data.data.gender)
          globalStore.ageStore.setData(data.data.age)
          globalStore.teamTypeStore.setData(data.data.teamType)
          globalStore.disciplineStore.setData(data.data.discipline)
          globalStore.disciplineStore.setSectionTableData(data.data.sectionTableDiscipline)
          globalStore.competitionDisciplineStore.setData(data.data.competitionDiscipline)
          globalStore.competitionRegionStore.addRows(data.data.competitionRegion)
          return globalStore.competitionStore.getById(cId.value)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    function loadClubs() {
      if (globalStore.clubStore.clubs.length) {
        return;
      }

      fetch(globalStore.apiUrl + '/admin/organization-clubs/' + (globalStore.userAdminStore.activeOrganization || 'null'), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.clubStore.addDataRows(data.data.club)
          globalStore.loungeClubAddressTableStore.setData(data.data.loungeClubAddressTable)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, updateFormActive, itemData, schema, addFormActive, autoloadData, autoloadedTournaments, cId,
      regionOptions, clubOptions, loungeOptions,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm, loadTournaments
    }
  }
}
</script>
