import { defineStore } from 'pinia'

export const useParticipantStore = defineStore('ParticipantStore', {
  state: () => {
    return {
      participants: {}
    }
  },
  getters: {
    count: state => Object.keys(state.participants).length,
    getById: state => {
      return id => {
        for (let j in state.participants) {
          if (+state.participants[j].id === +id) {
            return state.participants[j]
          }
        }
        return null
      }
    },
    getByOrganizationId: state => {
      return organizationId => {
        if (organizationId) {
          const data = {}
          for (let i in state.participants) {
            const participant = state.participants[i]
            if (+participant.organizationId === +organizationId) {
              data[i] = participant
            }
          }
          return data
        }
        return state.participants
      }
    },
    getMultiByOrganizationId: state => {
      return organizationId => {
        if (organizationId) {
          const data = {}
          for (let i in state.participants) {
            const participant = state.participants[i]
            if (+participant.organizationId === +organizationId && participant.participantTypeId !== null) {
              data[i] = participant
            }
          }
          return data
        }
        return state.participants
      }
    }
  },
  actions: {
    setData(data) {
      this.participants = data
    },
    addData(data) {
      for (let i in data) {
        const participant = data[i]
        let exists = false
        for (let j in this.participants) {
          if (this.participants[j].id === participant.id) {
            exists = true
          }
        }
        if (!exists) {
          this.participants[participant.id] = participant
        }
      }
    },
    updateData(item) {
      for (let i in this.data) {
        if (this.participants[i].id === item.id) {
          this.participants[i] = item
          break
        }
      }
    },
  }
})
