<template>
  <FormKit type="form" v-model="itemData['']" @submit="processUpdateForm()">
    <FormKitSchema :schema="schema" :data="itemData['']"/>
  </FormKit>
</template>

<script>
import { useTournamentParticipantStageStore } from "@/stores/TournamentParticipantStageStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import {useTournamentParticipantStore} from "@/stores/TournamentParticipantStore";
import {useEventStore} from "@/stores/EventStore";
import {ref, toRef} from "vue";
import {useTournamentStore} from "@/stores/TournamentStore";
import {useTeamEventStore} from "@/stores/TeamEventStore";
import {useNotificationStore} from "@/stores/NotificationStore";

export default {
  name: 'TournamentStageSubstitute',
  components: {},
  props: {
    stageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const stageIdent = toRef(props, 'stageId'),
        tournamentParticipantStageStore = useTournamentParticipantStageStore(),
        tournamentParticipantStore = useTournamentParticipantStore(),
        tournamentStore = useTournamentStore(),
        notificationStore = useNotificationStore(),
        eventStore = useEventStore(),
        teamEventStore = useTeamEventStore(),
        globalStore = useGlobalStore(),
        schema = ref([]),
        itemData = ref({}),
        tournamentId = ref('')

    setSchema()

    function setSchema() {
      schema.value = []
      const replaceableStageParticipants = {},
          replaceByParticipantOptions = {},
          currentStageReached = ref(false),
          isTournamentForTeams = tournamentStore.isTournamentForTeams,
          setParticipants = {}

      for (let sign in tournamentParticipantStageStore.tournamentParticipantStages) {
        const stageSignParticipants = tournamentParticipantStageStore.tournamentParticipantStages[sign]
        for (let i in stageSignParticipants) {
          for (let j in stageSignParticipants[i]) {
            const tournamentParticipantStage = stageSignParticipants[i][j],
                tournamentParticipantId = isTournamentForTeams ? tournamentParticipantStage.tournamentTeamId : tournamentParticipantStage.tournamentParticipantId,
                participantId = tournamentParticipantStore.getParticipantIdByTournamentParticipantId(tournamentParticipantId, isTournamentForTeams)
            setParticipants[tournamentParticipantId] = tournamentParticipantId

            if (currentStageReached.value === true && tournamentParticipantStage.stageId !== stageIdent.value && !replaceableStageParticipants[tournamentParticipantId]) {
              replaceByParticipantOptions[tournamentParticipantId] = globalStore.getTournamentParticipantNameById(tournamentParticipantId)
            } else if (tournamentParticipantStage.stageId === stageIdent.value) {
              currentStageReached.value = true
              if (
                  isTournamentForTeams && teamEventStore.hasTeamOnlyBlankTeamEvents(stageIdent.value, participantId) ||
                  !isTournamentForTeams && eventStore.hasParticipantOnlyBlankEvents(stageIdent.value, participantId)
              ) {
                replaceableStageParticipants[tournamentParticipantId] = globalStore.getTournamentParticipantNameById(tournamentParticipantId)
              }
            }
          }
        }
      }
      for (let i in tournamentParticipantStore.tournamentParticipants) {
        if (!tournamentId.value) {
          tournamentId.value = tournamentParticipantStore.tournamentParticipants[i].tournamentId
        }
        const tournamentParticipantId = tournamentParticipantStore.tournamentParticipants[i].id

        if (!setParticipants[tournamentParticipantId]) {
          replaceByParticipantOptions[tournamentParticipantId] = globalStore.getTournamentParticipantNameById(tournamentParticipantId)
        }
      }

      schema.value.push({
        $formkit: 'select',
        name: 'tournamentParticipantId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('replace who', globalStore.languageId),
        options: replaceableStageParticipants,
        validation: 'required'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'newTournamentParticipantId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('replace by', globalStore.languageId),
        options: replaceByParticipantOptions,
        validation: 'required'
      })
    }

    function processUpdateForm() {
      const item = itemData.value['']
      if (item) {
        item.stageId = stageIdent.value
        item.tournamentId = tournamentId.value
        fetch(globalStore.apiUrl + '/admin/tournament-participant-switch', {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              if (response.data.eventParticipant && response.data.eventParticipant.length) {
                eventStore.updateParticipants(response.data.eventParticipant)
              }
              if (response.data.teamEventParticipant !== undefined) {
                teamEventStore.actualizeTeamEventParticipants(response.data.teamEventParticipant)
              }
              tournamentParticipantStageStore.updateRows(response.data.tournamentParticipantStage)
              tournamentParticipantStore.updateRows(response.data.tournamentParticipant)
              notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)})
            } else if (response.error) {
              notificationStore.add({type: 'error', message: response.error.description})
            } else {
              notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
            }
          })
          .catch(err =>notificationStore.add({type: 'error', message: err.message}))
      }
    }

    return {
      tournamentParticipantStageStore, globalStore, schema, itemData,
      processUpdateForm
    }
  }
}
</script>
