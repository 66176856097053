import { defineStore } from 'pinia'

export const useLoungeEquipmentTableStore = defineStore('LoungeEquipmentTableStore', {
  state: () => {
    return {
      data: []
    }
  },
  getters: {
    count: state => state.data.length,
    getById: state => {
      return id => {
        const data = state.data.filter(row => row.id === id)
        return data ? data[0] : null
      }
    },
    getByLoungeId: state => {
      return loungeId => {
        return state.data.filter(row => +row.loungeId === +loungeId)
      }
    }
  },
  actions: {
    setData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          if (this.data.filter(row => row.id === rows[i].id).length) {
            this.updateData(rows[i])
          } else {
            this.addData(rows[i])
          }
        }
      }
    },
    addData(item) {
      this.data.push(item)
    },
    updateData(item) {
      for (let i in this.data) {
        if (this.data[i].id === item.id) {
          this.data[i] = item
          break
        }
      }
    },
    unsetData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          if (this.data.filter(row => row.id === rows[i].id).length) {
            this.deleteData(rows[i])
          }
        }
      }
    },
    deleteData(item) {
      this.data = this.data.filter(row => row.id !== item.id)
    }
  }
})
