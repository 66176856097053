import { defineStore } from 'pinia'

export const useCompetitionStore = defineStore('CompetitionStore', {
  state: () => {
    return {
      competitions: [],
      competition: {}
    }
  },
  getters: {
    count: state => state.competitions.length,
    getById: state => {
      return id => {
        if (!id) {
          return null
        }
        const competition = state.competitions.filter(row => +row.id === +id)
        if (competition && competition[0]) {
          return competition[0]
        }
        return null
      }
    }
  },
  actions: {
    setData(data) {
      this.competitions = data
    },
    setDataOne(data) {
      this.competition = data
    },
    deleteRow(id) {
      for (let i in this.competitions) {
        if (+id === +this.competitions[i].id) {
          delete this.competitions[i]
        }
      }
    },
    updateRow(data) {
      for (let i in this.competitions) {
        if (+data.id === +this.competitions[i].id) {
          this.competitions[i] = data
        }
      }
    },
    addRow(data) {
      for (let i in this.competitions) {
        if (+data.id === +this.competitions[i].id) {
          return
        }
      }
      this.competitions.push(data)
    }
  }
})
