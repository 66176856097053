<template>
  <li class="nav-item"><router-link class="nav-link" to="/">Home</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/competitions">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('competitions', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/tournaments">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournaments', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/teams-in-season">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('teams in season', globalStore.languageId) }}</router-link></li>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore";

export default {
  name: 'MenuClub',
  components: {},

  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore
    }
  }
}
</script>
