<template>
  <div>
    <br />
    <section>
      <!-- Pills -->
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="participant-setting-types-tab" data-bs-toggle="pill" data-bs-target="#participant-setting-types" type="button" role="tab" aria-controls="participant-setting-types" aria-selected="true">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('participant setting types', globalStore.languageId) }}
          </button>
        </li>
      </ul>
      <!-- Contents -->
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="participant-setting-types" role="tabpanel" aria-labelledby="participant-setting-types-tab">
          <StaticValueListItemVocabularyName typeName="participantSettingType" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import StaticValueListItemVocabularyName from "@/components/StaticValueList/StaticValueListItemVocabularyName.vue";
import { useStaticValueListStore } from "@/stores/StaticValueListStore";
import { useVocabularyStore } from "@/stores/VocabularyStore";
import { useGlobalStore } from "@/stores/GlobalStore";

export default {
  name: 'StaticValueList',
  components: {
    StaticValueListItemVocabularyName
  },

  setup() {
    const vocabularyStore = useVocabularyStore(),
      staticValueListStore = useStaticValueListStore(),
      globalStore = useGlobalStore()

    onMounted(() => {
      fetch(globalStore.apiUrl + '/admin/static-value-list/participantSettingType', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => res.json())
        .then(data => {
          vocabularyStore.addRows(data.data.vocabulary)
          if (data.data.participantSettingType) {
            staticValueListStore.addRows('participantSettingType', data.data.participantSettingType)
          }
        })
        .catch((err) => console.error(err))
    })

    return {
      globalStore
    }
  }
}
</script>
