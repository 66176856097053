import { defineStore } from 'pinia'

export const useLevelStore = defineStore('LevelStore', {
  state: () => {
    return {
      levels: []
    }
  },
  getters: {
    count: state => state.levels.length,
    getById: state => {
      return id => {
        const level = state.levels.filter(row => +row.id === +id)
        if (level && level[0]) {
          return level[0]
        }
        return null
      }
    }
  },
  actions: {
    setData(data) {
      this.levels = data
    }
  }
})
