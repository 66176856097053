import { defineStore } from 'pinia'

export const useTournamentVenueStore = defineStore('TournamentVenueStore', {
  state: () => {
    return {
      tournamentvenues: {}
    }
  },
  getters: {
    count: state => Object.keys(state.tournamentvenues).length,
    getByTournamentId: state => {
      return tournamentId => {
        const result = {}
        for (let i in state.tournamentvenues) {
          const row = state.tournamentvenues[i]
          if (+row.tournamentId === +tournamentId) {
            result[i] = row
          }
        }
        return result
      }
    }
  },
  actions: {
    setData(data) {
      for (let i in data) {
        this.tournamentvenues[data[i].id] = data[i]
      }
    },
    addDataRows(data) {
      for (let i in data) {
        this.addDataRow(data[i])
      }
    },
    addDataRow(data) {
      if (!this.tournamentvenues[data.id]) {
        this.tournamentvenues[data.id] = data
      }
    },
    removeDataRow(id) {
      delete this.tournamentvenues[id]
    },
    unsetTournamentDataRows(id) {
      for (let i in this.tournamentvenues) {
        if (+this.tournamentvenues[i].tournamentId === +id) {
          delete this.tournamentvenues[i]
        }
      }
    }
  }
})
