import { defineStore } from 'pinia'

export const useTeamParticipantStore = defineStore('TeamParticipantStore', {
  state: () => {
    return {
      teamParticipants: []
    }
  },
  getters: {
    count: state => state.teamEvents.length,
    getTeamParticipantsByTeamId: state => {
      return (participantParentId, season) => {
        if (!season) {
          return state.teamParticipants.filter(
            teamParticipant => +teamParticipant.participantParentId === +participantParentId
          )
        } else {
          return state.teamParticipants.filter(
            teamParticipant => +teamParticipant.participantParentId === +participantParentId && +teamParticipant.season === +season
          )
        }
      }
    },
    getParticipantByParticipantIdAndTeamId: state => {
      return (participantParentId, season, participantId) => {
        const participant = state.teamParticipants.filter(
          teamParticipant => +teamParticipant.participantParentId === +participantParentId
            && +teamParticipant.participantId === +participantId
            && +teamParticipant.season === +season
        )
        return participant && participant[0]
      }
    }
  },
  actions: {
    setData(data) {
      this.teamParticipants = data
    },
    deleteRow(id) {
      this.teamParticipants = this.teamParticipants.filter(
        row => row.id !== id
      )
    },
    updateRow(data) {
      for (let i in this.teamParticipants) {
        if (+this.teamParticipants[i].id === +data.id) {
          this.teamParticipants[i] = data
        }
      }
    },
    addRow(data) {
      this.teamParticipants.push(data)
    }
  }
})
