<template>
  <template v-if="formData.events[0]">
    <template v-if="!haveAllEventLiveScore()">
      <ButtonCreateLivescore type="button" data-bs-toggle="modal" :data-bs-target="'#teamEvent' + formData.identifier" />
      <div class="modal fade" :id="'teamEvent' + formData.identifier" tabindex="-1" :aria-labelledby="'teamEventLabel' + formData.identifier" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" :id="'teamEventLabel' + formData.identifier">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('create live score to event', globalStore.languageId) }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <table class="table table-borderless table-sm">
                <tbody>
                  <template v-for="(event, key) in itemData.events" :key="event.id">
                    <tr v-if="!liveScoreStore.liveScores[event.id]">
                      <td v-if="formData.events[0].length > 1">
                        <FormKit type="checkbox" v-model="formData.processEvent[key]" :value="event.id" />
                      </td>
                      <td>
                        <FormKit type="datetime-local" v-model="formData.events[key].dateStart" validation="required" :label="getNames(event.id)" />
                      </td>
                      <td>
                        <FormKit type="select" v-model="formData.events[key].loungeEquipmentTableId"
                          :label="globalStore.vocabularyStore.getWordByIdAndLanguage('table nr', globalStore.languageId)"
                          validation="required" :validation-messages="{ required: globalStore.vocabularyStore.getWordByIdAndLanguage('form required error', globalStore.languageId) }">
                          <option v-for="(value, label) in globalStore.swapObject(formData.tables, true)" :key="value" :value="value">{{ label }}</option>
                        </FormKit>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <ButtonSave :click-function="createLiveScore" data-bs-dismiss="modal" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="formData.events.length === 1
      && liveScoreStore.hasEventEmptyLiveScore(formData.events[0].id)
      && liveScoreStore.hasEventEmptyLiveScore(formData.events[0].id)
      ">
      <ButtonDeleteLivescore
        type="button"
        :click-function="deleteLiveScore"
        :click-function-parameter="formData.events[0].id" />
    </template>
  </template>
</template>

<script>

import { ref, toRef } from "vue"
import { useGlobalStore } from "@/stores/GlobalStore"
import { useLiveScoreStore } from "@/stores/LiveScoreStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useEventEquipmentTableStore } from "@/stores/EventEquipmentTableStore"
import { useTeamEventStore } from "@/stores/TeamEventStore";
import ButtonCreateLivescore from "@/components/Button/ButtonCreateLivescore.vue";
import ButtonSave from "@/components/Button/ButtonSave.vue";
import ButtonDeleteLivescore from "@/components/Button/ButtonDeleteLivescore.vue";

export default {
  name: 'EventLiveScoreModal',
  components: { ButtonDeleteLivescore, ButtonSave, ButtonCreateLivescore },
  props: {
    data: {
      required: true,
      type: Object
    },
    event: {
      required: true,
      type: Object
    }
  },

  setup(props) {
    const itemData = toRef(props, 'data'),
      eventObject = toRef(props, 'event'),
      formData = ref({}),
      disciplineNames = ref({}),
      teamNames = ref({}),
      homeParticipants = ref({}),
      awayParticipants = ref({}),
      globalStore = useGlobalStore(),
      liveScoreStore = useLiveScoreStore(),
      teamEventStore = useTeamEventStore(),
      notificationStore = useNotificationStore(),
      eventEquipmentTableStore = useEventEquipmentTableStore(),
      baseTableSizeId = eventObject.value.tableSizeId

    setViewData()

    function setViewData() {
      formData.value = itemData.value
      formData.value.processEvent = []
      let teamEventId = eventObject.value.id ? eventObject.value.eventId : formData.value.identifier
      if (eventObject.value.id && !teamEventId) {
        let events = globalStore.eventStore.getTeamEventEvents(eventObject.value.id)
        if (events && events[0] && events[0].eventId) {
          teamEventId = events[0].eventId
        }
      }
      const loungeId = teamEventId ? teamEventStore.getTeamEventById(teamEventId).loungeId : null,
        tables = {},
        availableTables = loungeId ? globalStore.loungeClubAddressTableStore.getByLoungeIdTableSizeId(loungeId, baseTableSizeId) : globalStore.loungeClubAddressTableStore.getByTableSizeId(baseTableSizeId)

      for (let i in availableTables) {
        tables[availableTables[i].id] = availableTables[i].number
      }
      formData.value.tables = tables
    }

    function getNames(eventId) {
      let names = '';
      for (let i in formData.value.events) {
        if (formData.value.events[i].id === eventId) {
          names += globalStore.getDisciplineNameById(formData.value.events[i].disciplineId) + ': ';
          const count = formData.value.events[i].eventParticipant.length
          for (let j = 0; j < count; j++) {
            names += globalStore.getParticipantNameById(formData.value.events[i].eventParticipant[j].participantId)
            if (j + 1 < count) {
              names += ' v '
            }
          }
        }
      }
      return names
    }

    function validate() {
      const countDistinctTables = {},
        countDistinctParticipants = {}
      for (let i in formData.value.events) {
        if (!formData.value.events[i].loungeEquipmentTableId) {
          notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('no chosen table', globalStore.languageId) })
          return false;
        } else {
          countDistinctTables[formData.value.events[i].loungeEquipmentTableId + '_' + formData.value.events[i].round] = countDistinctTables[formData.value.events[i].loungeEquipmentTableId + '_' + formData.value.events[i].round] || 0
          countDistinctTables[formData.value.events[i].loungeEquipmentTableId + '_' + formData.value.events[i].round]++
        }
        for (let j in formData.value.events[i].eventParticipant) {
          countDistinctParticipants[formData.value.events[i].eventParticipant[j].participantId + '_' + formData.value.events[i].round] = countDistinctParticipants[formData.value.events[i].eventParticipant[j].participantId + '_' + formData.value.events[i].round] || 0
          countDistinctParticipants[formData.value.events[i].eventParticipant[j].participantId + '_' + formData.value.events[i].round]++
        }
      }
      for (let i in countDistinctTables) {
        if (countDistinctTables[i] > 1) {
          notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('livescore table duplicated', globalStore.languageId) })
          return false;
        }
      }
      for (let i in countDistinctParticipants) {
        if (countDistinctParticipants[i] > 1) {
          notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('livescore participant duplicated', globalStore.languageId) })
          return false;
        }
      }
      return true
    }

    function createLiveScore() {
      if (!validate()) {
        return
      }

      fetch(globalStore.apiUrl + '/admin/event-live-score', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data && response.data.liveScore) {
            liveScoreStore.setData(response.data.liveScore)
            if (response.data.eventEquipmentTable) {
              const eventEquipmentTable = response.data.eventEquipmentTable[0]
              eventObject.value.loungeEquipmentTableId = eventEquipmentTable.id
              eventEquipmentTableStore.addDataRow(eventEquipmentTable)
              const loungeEquipmentTableId = eventEquipmentTable.loungeEquipmentTableId,
                tableData = globalStore.loungeClubAddressTableStore.getById(loungeEquipmentTableId)

              eventObject.value.table = {
                id: tableData.id,
                number: tableData.number,
                index: globalStore.loungeClubAddressTableStore.getTableIndex(loungeEquipmentTableId)
              }
            }
            notificationStore.add({
              type: 'success',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId)
            })
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({
              type: 'error',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
            })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function deleteLiveScore(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        fetch(globalStore.apiUrl + '/admin/event-live-score', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value.events[0])
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              liveScoreStore.removeDataRow(id)
              notificationStore.add({
                type: 'success',
                message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId)
              })
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({
                type: 'error',
                message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
              })
            }
          })
          .catch((err) => console.error(err))
      }
    }

    function haveAllEventLiveScore() {
      for (let i in formData.value.events) {
        if (!liveScoreStore.liveScores[formData.value.events[i].id]) {
          return false
        }
      }
      return true
    }

    return {
      itemData, formData, teamNames, homeParticipants, awayParticipants, disciplineNames, globalStore,
      liveScoreStore, baseTableSizeId,
      createLiveScore, getNames, deleteLiveScore, haveAllEventLiveScore
    }
  }
}
</script>
