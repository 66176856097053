<template>
  <MenuBase :onChangeFn="loadCompetitions" />
  <table v-if="globalStore.userAdminStore.propertiesLoaded && globalStore.userAdminStore.activeRegion" class="table table-striped">
    <tbody>
      <CompetitionItem v-if="globalStore.userAdminStore.isUserRegion" />
      <template v-if="globalStore.competitionStore.competitions.length">
        <template v-for="(row, i) in globalStore.competitionStore.competitions" :key="i">
          <CompetitionItem v-if="row && row.id" :competitionId="row.id" :competition="row" />
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted } from "vue"
import MenuBase from "@/components/Menu/MenuBase"
import CompetitionItem from "@/components/CompetitionItem"

export default {
  name: 'CompetitionList',
  components: { MenuBase, CompetitionItem },

  setup() {
    const globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadCompetitions()
    })

    function loadCompetitions() {
      if (!globalStore.userAdminStore.activeSeason) {
        return
      }
      const url = globalStore.apiUrl + '/admin/competitions/' + (globalStore.userAdminStore.activeOrganization || 'null') + '/' + (globalStore.userAdminStore.activeSection || 'null') + '/' + (globalStore.userAdminStore.activeRegion || 'null') + '/' + (globalStore.userAdminStore.activeSeason || 'null') + '/' + (globalStore.userAdminStore.activeClub || 'null') + '/null'
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.competitionStore.setData(data.data.competition)
          globalStore.tableSizeStore.setData(data.data.tableSize)
          globalStore.levelStore.setData(data.data.level)
          globalStore.genderStore.setData(data.data.gender)
          globalStore.ageStore.setData(data.data.age)
          globalStore.teamTypeStore.setData(data.data.teamType)
          globalStore.disciplineStore.setData(data.data.discipline)
          globalStore.disciplineStore.setSectionTableData(data.data.sectionTableDiscipline)
          globalStore.competitionDisciplineStore.setData(data.data.competitionDiscipline)
          globalStore.competitionRegionStore.addRows(data.data.competitionRegion)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, loadCompetitions
    }
  }
}
</script>
