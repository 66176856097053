<template>
  <template v-if="event">
    <template v-if="formActive === true">
      <tr class="event-form">
        <td>
          <ButtonBack :click-function="deInitForm" />
        </td>
        <td colspan="3">
          <FormKit type="datetime-local" v-model="itemData.dateStart" validation="required" />
        </td>
        <td>
          <FormKit type="number" v-model="itemData.round" validation="required" :label="globalStore.vocabularyStore.getWordByIdAndLanguage('event round', globalStore.languageId)" />
        </td>
        <td>
          <ButtonSave :click-function="processForm" />
        </td>
      </tr>
      <tr class="event-form">
        <td colspan="6">
          <table class="table table-sm">
            <tbody>
              <tr v-for="(eParticipant, index) in event.eventParticipant" :key="eParticipant.id">
                <td>
                  <template v-if="eParticipant.participantId !== '' && eParticipant.participantId !== null">
                    {{ globalStore.getParticipantNameById(eParticipant.participantId) }}
                  </template>
                  <template v-else-if="eParticipant.prediction && eParticipant.prediction.name">
                    {{ eParticipant.prediction.name }}
                    <template v-if="eParticipant.prediction.relatedEventParticipants">
                      <br />
                      <small>({{ eParticipant.prediction.relatedEventParticipants }})</small>
                    </template>
                  </template>
                  <template v-else>
                    {{ globalStore.getParticipantNameById(eParticipant.participantId) }}
                  </template>
                  <br />
                  <small>
                    {{ globalStore.getDisciplineNameById(eParticipant.disciplineId) }}<br />
                    ({{ eParticipant.targetPoints }}
                    <template v-if="eParticipant.maxInnings">&#160;/&#160;{{ eParticipant.maxInnings }}</template>)
                  </small>
                </td>
                <td v-for="(result, key) in eParticipant.eventParticipantResult" :key="result.id">
                  <FormKit type="number" v-model="result.points"
                    :max="eParticipant.targetPoints"
                    :validation="'max:' + eParticipant.targetPoints"
                    min="0" />
                  &#160;/&#160;
                  <FormKit type="number" v-model="result.innings"
                    :max="eParticipant.maxInnings ? eParticipant.maxInnings : 1000"
                    :validation="'max:' + (eParticipant.maxInnings ? eParticipant.maxInnings : 1000)"
                    min="0" />
                  <template v-if="isExtensionActive(key)">
                    <FormKit type="select" :placeholder="globalStore.getWordById('extension', globalStore.languageId)" :options="extensionOptions" v-model="result.extension" />
                  </template>
                </td>
                <td>
                  <FormKit type="number" v-model="itemData.eventParticipant[index].highRun1"
                    :validation="'max:' + eParticipant.targetPoints"
                    :max="eParticipant.targetPoints"
                    min="0" />
                  &#160;|&#160;
                  <FormKit type="number" v-model="itemData.eventParticipant[index].highRun2"
                    :validation="'max:' + eParticipant.targetPoints"
                    :max="eParticipant.targetPoints"
                    min="0" />
                </td>
                <td>
                  <FormKit type="select" :placeholder="globalStore.getWordById('disqualification', globalStore.languageId)" :options="disqualificationOptions" v-model="itemData.eventParticipant[index].disqualificationId" />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </template>
    <template v-else>
      <tr>
        <td>
          <template v-if="!eventHasFreelancer()">
            <strong>{{ event.round }}.</strong> |
            {{ globalStore.formatDateTime(event.dateStart) }}
            <template v-if="globalStore.getEventStageSign(event) !== ''"> | {{ globalStore.getEventStageSign(event) }}</template>
            <br />
            <img v-if="hasEventLiveScore()" :src="require('@/assets/images/details.svg')" @click="viewLiveScore = !viewLiveScore" alt="" class="icon-abacus" />
            <EventLiveScoreModal v-if="hasEventAllParticipants() && (!hasEventScore() || liveScoreStore.hasEventEmptyLiveScore(event.id))" :data="getDataForLiveScore()" :event="event" />
            <LiveStreamModal v-if="hasEventAllParticipants()" :eventObjectId="event.id" :eventObjectDateStart="event.dateStart" />
            <img v-if="hasEventLiveScore()" :src="require('@/assets/images/abacus.svg')" @click="openLiveScoreEntry()" alt="" class="icon-abacus" />
            <img :src="require('@/assets/images/referee.svg')" @click="viewReferee = !viewReferee" alt="" class="icon-abacus" />
            <EventReferee v-if="viewReferee" :eventObject="event" />
          </template>
          <template v-else>{{ globalStore.getEventStageSign(event) }}</template>
        </td>
        <template v-for="(eventParticipant, i) in event.eventParticipant" :key="eventParticipant.id">
          <td v-if="i % 2 === 1">
            <strong>{{ globalStore.eventStore.freelancerId === eventParticipant.participantId ? 0 : eventParticipant.score }}</strong>
          </td>
          <td>
            <template v-if="eventParticipant.participantId !== '' && eventParticipant.participantId !== null">
              {{ globalStore.getParticipantNameById(eventParticipant.participantId) }}
            </template>
            <template v-else-if="eventParticipant.prediction && eventParticipant.prediction.name">
              {{ eventParticipant.prediction.name }}
              <template v-if="eventParticipant.prediction.relatedEventParticipants">
                <br />
                <small>({{ eventParticipant.prediction.relatedEventParticipants }})</small>
              </template>
            </template>
            <template v-else>
              {{ globalStore.getParticipantNameById(eventParticipant.participantId) }}
            </template>
            <br />
            <small class="text-secondary">{{ globalStore.getDisciplineNameById(eventParticipant.disciplineId) }} ({{ eventParticipant.targetPoints }})</small>
          </td>
          <td v-if="i % 2 === 0">
            <strong>{{ globalStore.eventStore.freelancerId === eventParticipant.participantId ? 0 : eventParticipant.score }}</strong>
          </td>
        </template>
        <td>
          <ButtonUpdate
            v-if="isUpdatable && !eventHasFreelancer()"
            :click-function="initForm" />
        </td>
      </tr>
      <tr v-if="hasEventLiveScore() && viewLiveScore">
        <td colspan="6">
          <EventRecord
            :liveScoreObject="liveScoreStore.liveScores[event.id].detail" />
        </td>
      </tr>
    </template>
  </template>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useTeamEventStore } from "@/stores/TeamEventStore";
import { useLiveScoreStore } from "@/stores/LiveScoreStore";
import EventLiveScoreModal from "@/components/Modal/EventLiveScoreModal";
import LiveStreamModal from "@/components/Modal/LiveStreamModal";
import EventRecord from "@/components/EventRecord";
import EventReferee from "@/components/EventReferee";
import { useTournamentStageRankingStore } from "@/stores/TournamentStageRankingStore"
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonSave from "@/components/Button/ButtonSave.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";

export default {
  name: 'EventItem',
  components: { ButtonUpdate, ButtonSave, ButtonBack, EventLiveScoreModal, EventRecord, LiveStreamModal, EventReferee },
  props: {
    eventObject: {
      required: true,
      type: Object
    },
    isUpdatable: {
      required: true,
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const event = toRef(props, 'eventObject'),
      viewReferee = ref(false),
      viewLiveScore = ref(false),
      notificationStore = useNotificationStore(),
      tournamentStageRankingStore = useTournamentStageRankingStore(),
      teamEventStore = useTeamEventStore(),
      globalStore = useGlobalStore(),
      liveScoreStore = useLiveScoreStore(),
      itemData = ref({}),
      formActive = ref(false),
      extensionOptions = {
        '': globalStore.vocabularyStore.getWordByIdAndLanguage('extension', globalStore.languageId),
        '1': globalStore.vocabularyStore.getWordByIdAndLanguage('event extension win', globalStore.languageId)
      },
      disqualificationOptions = {
        '': '',
        '1': globalStore.vocabularyStore.getWordByIdAndLanguage('event disqualification error', globalStore.languageId),
        '2': globalStore.vocabularyStore.getWordByIdAndLanguage('event extension sorry', globalStore.languageId),
        '3': globalStore.vocabularyStore.getWordByIdAndLanguage('event extension time limit', globalStore.languageId)
      }

    function getDataForLiveScore() {
      if (event.value.table) {
        event.value.loungeEquipmentTableId = event.value.table.id
      }
      return {
        events: [event.value],
        identifier: event.value.id
      }
    }

    function initForm() {
      const item = event.value
      if (!item) {
        return
      }

      itemData.value = item
      const winningPartsCount = item.winningPartsCount,
        maxCountOfSets = winningPartsCount < 2 ? 1 : (winningPartsCount * 2) - 1
      for (let i in item.eventParticipant) {
        const eventParticipant = item.eventParticipant[i],
          eventParticipantResult = eventParticipant.eventParticipantResult || []
        for (let j = 0; j < maxCountOfSets; j++) {
          if (!eventParticipantResult[j]) {
            eventParticipantResult.push({
              eventParticipantId: eventParticipant.id, points: null, innings: null, extension: null
            })
          }
        }
      }

      formActive.value = true
    }

    function isFormValid() {
      const item = event.value,
        isEqualizingInning = !!item.equalizingInning,
        isExtension = !!item.extension,
        data = {},
        error = []

      for (let i in item.eventParticipant) {
        const highRun1 = item.eventParticipant[i].highRun1,
          highRun2 = item.eventParticipant[i].highRun2,
          targetPoints = item.eventParticipant[i].targetPoints,
          totalTargetPoints = item.eventParticipant[i].targetPoints * item.winningPartsCount

        if (+highRun1 < +highRun2) {
          error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant run 2 vs run 1', globalStore.languageId))
          // Participant has high run 2 bigger than high run 1
        }

        if (+highRun1 + +highRun2 > totalTargetPoints) {
          error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant run sum', globalStore.languageId))
          // Participant has high runs sum i bigger than total target points
        }
        let maxSetPoints = 0;

        for (let j in item.eventParticipant[i].eventParticipantResult) {
          data.points = data.points || {}
          data.points[j] = data.points[j] || []
          data.innings = data.innings || {}
          data.innings[j] = data.innings[j] || []
          data.extensions = data.extensions || {}
          data.extensions[j] = data.extensions[j] || []
          data.minHighRun = data.minHighRun || {}
          data.minHighRun[j] = data.minHighRun[j] || []
          data.points[j][i] = +item.eventParticipant[i].eventParticipantResult[j].points
          data.innings[j][i] = +item.eventParticipant[i].eventParticipantResult[j].innings
          data.extensions[j][i] = item.eventParticipant[i].eventParticipantResult[j].extension || null
          data.minHighRun[j][i] = +item.eventParticipant[i].eventParticipantResult[j].points / +item.eventParticipant[i].eventParticipantResult[j].innings

          if (data.points[j][i] > maxSetPoints) {
            maxSetPoints = data.points[j][i]
          }

          if (highRun1 < data.minHighRun[j][i]) {
            error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant high run is too low', globalStore.languageId))
            // Participant has high run smaller than should has
          }

          if (targetPoints < +data.points[j][i]) {
            error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant points', globalStore.languageId))
            // Participant has points bigger than target points
          }
        }
        data.disqualification = data.disqualification || []
        data.disqualification[i] = item.eventParticipant[i].disqualificationId

        if (highRun1 > maxSetPoints) {
          error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant run', globalStore.languageId))
          // Participant has high run bigger than target points
        }
      }

      let disqualificationSet = false
      for (let participant in data.disqualification) {
        if (data.disqualification[participant]) {
          if (disqualificationSet === true) {
            error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error event disqualification', globalStore.languageId))
            // Disqualification is set at more than one participant
          }
          disqualificationSet = true
        }
      }

      for (let set in data.points) {
        let pointsSet = []
        for (let participant in data.points[set]) {
          pointsSet[participant] = data.points[set][participant] !== '' && data.points[set][participant] !== null;
          let unique = [...new Set(pointsSet)]
          if (unique.length > 1) {
            error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant set points', globalStore.languageId))
            // One of participant has no set points set
          }
        }
      }

      for (let set in data.innings) {
        const unique = [...new Set(data.innings[set])]
        if (isEqualizingInning) {
          if (unique.length > 1) {
            error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant innings does not equal', globalStore.languageId))
            // Innings must equal if set equalizing inning
          }
        } else {
          if (unique.length > 1) {
            let min = Math.min(...unique),
              max = Math.max(...unique)
            if (max - min > 1) {
              error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant innings differs more than one', globalStore.languageId))
              // Innings can differ max by one
            }
          }
        }
      }

      if (isExtension) {
        for (let set in data.extensions) {
          if (isExtensionActive(set)) {
            let extensionSet = []
            for (let participant in data.extensions[set]) {
              extensionSet[participant] = data.extensions[set][participant] !== '' && data.extensions[set][participant] !== null;
            }
            let unique = [...new Set(extensionSet)]
            if (unique.length === 1) {
              error.push(globalStore.vocabularyStore.getWordByIdAndLanguage('error participant extension', globalStore.languageId))
              // Extension is able to have ony one participant
            }
          }
        }
      }

      if (error.length) {
        notificationStore.add({ type: 'error', message: error.join('\n') })
      }

      return error.length < 1;
    }

    function processForm() {
      const item = event.value
      if (item) {
        if (!isFormValid()) {
          return false
        }
        item.round = itemData.value.round

        fetch(globalStore.apiUrl + '/admin/event', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data && response.data.event) {
              globalStore.eventStore.actualizeRow(response.data.event)
              if (response.data.tournamentParticipantStage !== undefined) {
                globalStore.tournamentParticipantStageStore.mergeRows(response.data.tournamentParticipantStage)
              }
              if (response.data.tournamentParticipant !== undefined) {
                globalStore.tournamentParticipantStore.setData(response.data.tournamentParticipant)
              }
              if (response.data.teamEventParticipant !== undefined) {
                teamEventStore.actualizeTeamEventParticipants(response.data.teamEventParticipant)
              }
              if (response.data.events !== undefined) {
                for (let i in response.data.events) {
                  globalStore.eventStore.actualizeRow(response.data.events[i])
                  teamEventStore.actualizeRow(response.data.events[i])
                }
              }
              if (response.data.teamEvents !== undefined) {
                for (let i in response.data.teamEvents) {
                  teamEventStore.actualizeRow(response.data.teamEvents[i])
                }
              }
              if (response.data.tournamentStageRanking !== undefined) {
                tournamentStageRankingStore.setData(response.data.tournamentStageRanking)
              }
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
            deInitForm()
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deInitForm() {
      formActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function coefficientEquals(resultIndex) {
      let results = {}
      for (let j in event.value.eventParticipant) {
        const eventParticipant = event.value.eventParticipant[j]
        if (eventParticipant.eventParticipantResult[resultIndex]) {
          const eventParticipantResult = eventParticipant.eventParticipantResult[resultIndex]
          if (eventParticipantResult.points !== '') {
            if (eventParticipantResult.points > 0) {
              results[(eventParticipantResult.points / eventParticipant.targetPoints) * 100] = 1
            } else {
              results[0] = 1
            }
          } else {
            return false
          }
        }
      }
      return Object.keys(results).length === 1
    }

    function inningsEquals(resultIndex) {
      let results = {}
      for (let j in event.value.eventParticipant) {
        const eventParticipant = event.value.eventParticipant[j]
        if (eventParticipant.eventParticipantResult[resultIndex]) {
          const eventParticipantResult = eventParticipant.eventParticipantResult[resultIndex]
          if (eventParticipantResult.innings !== '') {
            if (eventParticipantResult.innings > 0) {
              results[eventParticipantResult.innings] = 1
            } else {
              results[0] = 1
            }
          } else {
            return false
          }
        }
      }
      return Object.keys(results).length === 1
    }

    function limitInningsReached(resultIndex) {
      for (let j in event.value.eventParticipant) {
        const eventParticipant = event.value.eventParticipant[j],
          maxInnings = eventParticipant.maxInnings
        if (maxInnings && eventParticipant.eventParticipantResult[resultIndex]) {
          const eventParticipantResult = eventParticipant.eventParticipantResult[resultIndex]
          if (+maxInnings !== +eventParticipantResult.innings) {
            return false
          }
        }
      }
      return true
    }

    function limitTargetPointsReached(resultIndex) {
      for (let i in event.value.eventParticipant) {
        const eventParticipant = event.value.eventParticipant[i]
        if (+eventParticipant.eventParticipantResult[resultIndex].points !== +eventParticipant.targetPoints) {
          return false
        }
      }

      return true
    }

    function isExtensionActive(setIndex) {
      for (let i in event.value.eventParticipant) {
        const eventParticipant = event.value.eventParticipant[i],
          result = eventParticipant.eventParticipantResult[setIndex]
        if (result) {
          const isExtensionActive = (result.points !== null && result.points !== '' && +result.points > 0) &&
            (!!event.value.extension === true || event.value.winningPartsCount > 1) &&
            coefficientEquals(setIndex) && inningsEquals(setIndex) &&
            (limitTargetPointsReached(setIndex) || limitInningsReached(setIndex) || !eventParticipant.maxInnings)

          if (!isExtensionActive) {
            nullateExtensions(setIndex)
          }
          return isExtensionActive
        }
      }
    }

    function nullateExtensions(setIndex) {
      for (let i in event.value.eventParticipant) {
        const eventParticipant = event.value.eventParticipant[i],
          result = eventParticipant.eventParticipantResult[setIndex]
        if (result && result.extension) {
          result.extension = null
        }
      }
    }

    function hasEventAllParticipants() {
      for (let i in event.value.eventParticipant) {
        if (!event.value.eventParticipant[i].participantId) {
          return false
        }
      }
      return true
    }

    function hasEventLiveScore() {
      return !!liveScoreStore.liveScores[event.value.id]
    }

    function eventHasFreelancer() {
      for (let i in event.value.eventParticipant) {
        if (event.value.eventParticipant[i].participantId === globalStore.eventStore.freelancerId) {
          return true
        }
      }
      return false
    }

    function hasEventScore() {
      for (let i in event.value.eventParticipant) {
        if (event.value.eventParticipant[i].score !== null && event.value.eventParticipant[i].score !== '') {
          return true
        }
      }
      return false
    }

    function openLiveScoreEntry() {
      window.open(globalStore.lsAdminUrl.replace('__ID__', liveScoreStore.liveScores[event.value.id].id), '_blank', 'noreferrer');
    }

    return {
      event, formActive, itemData, globalStore, extensionOptions, disqualificationOptions, liveScoreStore,
      viewLiveScore, viewReferee,
      initForm, processForm, deInitForm, getDataForLiveScore, hasEventAllParticipants,
      eventHasFreelancer, openLiveScoreEntry, hasEventLiveScore, hasEventScore, isExtensionActive
    }
  }
}
</script>

<style>
tr.event-form .formkit-outer[data-type=number] .formkit-inner {
  width: 4.5em;
}

tr.event-form .formkit-outer[data-type=number] {
  display: inline-block;
}
</style>
