import { defineStore } from 'pinia'

export const useParticipantSeasonParameterStore = defineStore('ParticipantSeasonParameterStore', {
  state: () => {
    return {
      participantSeasonParameters: []
    }
  },
  getters: {
    count: state => state.participantSeasonParameters.length,
    getBySeasonParticipantId: state => {
      return (id, settingTypeId) => {
        if (!settingTypeId) {
          return state.participantSeasonParameters.filter(row => +row.participantInSeasonId === +id)
        }
        return state.participantSeasonParameters.filter(row => +row.participantInSeasonId === +id && +row.seasonSettingTypeId === +settingTypeId)
      }
    }
  },
  actions: {
    setData(data) {
      this.participantSeasonParameters = data
    },
    addData(data) {
      for (let i in data) {
        const participantSeasonParameter = data[i],
          exists = this.participantSeasonParameters.filter(row => +row.id === +participantSeasonParameter.id).length

        if (!exists) {
          this.participantSeasonParameters.push(participantSeasonParameter)
        }
      }
    },
    deleteDataByParticipantInSeasonId(id) {
      this.participantSeasonParameters = this.participantSeasonParameters.filter(row => row.participantInSeasonId !== id)
    }
  }
})
