<template>
  <MenuBase :onChangeFn="loadParticipants" />
  <template v-if="isMultiParticipant()">
    <br />
    <FormKit
      type="select"
      :options="{ '': 'Discipline', ...disciplines }"
      v-model="showFilterDiscipline" />
    <FormKit
      type="select"
      :options="{ '': 'Team type', ...types }"
      v-model="showFilterType" />
    <FormKit
      type="select"
      :options="{ '': 'Table size', ...tables }"
      v-model="showFilterTable" />
  </template>

  <div class="table-responsive">
    <template v-if="globalStore.userAdminStore.propertiesLoaded && globalStore.userAdminStore.activeOrganization">
      <table class="table table-striped">
        <tbody>
          <ParticipantItem :multi="isMultiParticipant()" />
          <template v-if="getParticipants()">
            <template v-for="(row, i) in getParticipants()" :key="i">
              <template v-if="isMultiParticipant()">
                <ParticipantItem :multi="isMultiParticipant()" :participantObject="row" v-if="(!showFilterDiscipline || +row.disciplineId === +showFilterDiscipline)
    && (!showFilterTable || +row.tableSizeId === +showFilterTable)
    && (!showFilterType || +row.participantTypeId === +showFilterType)" />
              </template>
              <template v-else>
                <ParticipantItem :multi="isMultiParticipant()" :participantObject="row" />
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef, ref } from "vue"
import MenuBase from "@/components/Menu/MenuBase"
import ParticipantItem from "@/components/ParticipantItem"

export default {
  name: 'ParticipantList',
  components: { MenuBase, ParticipantItem },
  props: {
    multi: {
      required: false,
      type: Boolean
    }
  },

  setup(props) {
    const isMulti = toRef(props, 'multi'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      showFilterDiscipline = ref(''),
      showFilterTable = ref(''),
      showFilterType = ref(''),
      disciplines = ref({}),
      types = ref({}),
      tables = ref({})

    onMounted(() => {
      loadParticipants()
    })

    function loadParticipants() {
      const url = globalStore.apiUrl + '/admin/participants/' + (globalStore.userAdminStore.activeOrganization || 'null') + (isMulti.value ? '/team' : '')
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.participantStore.setData(data.data.participant)
          globalStore.genderStore.setData(data.data.gender)
          globalStore.countryStore.setData(data.data.country)
          globalStore.disciplineStore.setData(data.data.discipline)
          globalStore.tableSizeStore.setData(data.data.tableSize)
          globalStore.teamTypeStore.setData(data.data.teamType)
          if (isMulti.value) {
            disciplines.value = {}
            tables.value = {}
            types.value = {}
            for (let i in data.data.participant) {
              const row = data.data.participant[i]
              row.teamTypeName = row.participantTypeId ? globalStore.getTeamTypeNameById(row.participantTypeId) : ''
              row.tableSizeName = row.tableSizeId ? globalStore.getTableSizeNameById(row.tableSizeId) : ''
              row.disciplineName = row.disciplineId ? globalStore.getDisciplineNameById(row.disciplineId) : ''
              if (row.participantTypeId) {
                types.value[row.participantTypeId] = row.teamTypeName
              }
              if (row.tableSizeId) {
                tables.value[row.tableSizeId] = row.tableSizeName
              }
              if (row.disciplineId) {
                disciplines.value[row.disciplineId] = row.disciplineName
              }
            }
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    function getParticipants() {
      if (isMulti.value) {
        return globalStore.participantStore.getMultiByOrganizationId(globalStore.userAdminStore.activeOrganization)
      }
      return globalStore.participantStore.getByOrganizationId(globalStore.userAdminStore.activeOrganization)
    }

    function isMultiParticipant() {
      return isMulti.value
    }

    return {
      globalStore, showFilterDiscipline, showFilterTable, showFilterType,
      disciplines, tables, types,
      loadParticipants, getParticipants, isMultiParticipant
    }
  }
}
</script>
