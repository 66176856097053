<template>
  <CompetitionManagement />
</template>

<script>
import CompetitionManagement from "@/components/CompetitionManagement/CompetitionManagement";
export default {
  components: { CompetitionManagement }
}
</script>
