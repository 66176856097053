<template>
  <CommentList />
</template>

<script>
import CommentList from "@/components/CommentList";
export default {
  components: { CommentList }
}
</script>
