import { defineStore } from 'pinia'
import { useCookiesStore } from "@/stores/CookiesStore"
import { useLocalStorageStore } from "@/stores/LocalStorageStore"

export const useUserAdminStore = defineStore('UserAdminStore', {
  state: () => {
    return {
      user: {},
      restrictions: {},
      loggedAdminUserCookieName: 'aLogged',
      activeChoicesLocalStorageKeyName: 'aCh',
      cookiesStore: useCookiesStore(),
      localStorageStore: useLocalStorageStore(),
      activeChoices: null,
      userRole: null,
      activeOrganization: '',
      activeSection: '',
      activeSeason: '',
      activeRegion: '',
      activeClub: '',
      isActiveSeasonOpen: false,
      propertiesLoaded: false
    }
  },
  getters: {
    loggedUser: state => state.user,
    hasAddRule: state => state.user && state.user.har && state.isActiveSeasonOpen,
    hasUpdateRule: state => state.user && state.user.hur && state.isActiveSeasonOpen,
    hasDeleteRule: state => state.user && state.user.hdr && state.isActiveSeasonOpen,
    getRestrictions: state => state.restrictions,
    role: state => state.user && state.user.role ? state.user.role : null,
    roles: state => state.user && state.user.roles ? state.user.roles : null,
    adminUserRole: state => {
      if (state.user.role && state.user.role === 1) {
        return state.user.role
      }
      const roles = state.user?.roles
      if (roles && state.activeOrganization) {
        if (roles[state.activeOrganization] && roles[state.activeOrganization] <= 2) {
          return roles[state.activeOrganization]
        }
        if (state.activeSection && roles[state.activeOrganization] && roles[state.activeOrganization][state.activeSection]) {
          return roles[state.activeOrganization][state.activeSection]
        }
      }
      return null
    },
    isUserSuperUser: state => state.user.role && state.user.role === 1,
    isUserRegistry: state => {
      if (state.user.role && state.user.role === 1) {
        return true
      }
      if (state.activeOrganization) {
        const roles = state.user?.roles
        return roles && roles[state.activeOrganization] && roles[state.activeOrganization] <= 2
      }
      return false
    },
    isUserSection: state => {
      if (state.user.role && state.user.role === 1) {
        return true
      }
      if (state.activeOrganization && state.activeSection) {
        const roles = state.user?.roles
        return roles
          && roles[state.activeOrganization]
          && roles[state.activeOrganization][state.activeSection]
          && roles[state.activeOrganization][state.activeSection] <= 3
      }
      return false
    },
    isUserRegion: state => {
      if (state.user.role && state.user.role === 1) {
        return true
      }
      if (state.activeOrganization) {
        const roles = state.user?.roles
        if (roles && roles[state.activeOrganization] && roles[state.activeOrganization] <= 2) {
          return true
        }
      }
      if (state.activeOrganization && state.activeSection) {
        const roles = state.user?.roles
        return roles
          && roles[state.activeOrganization]
          && roles[state.activeOrganization][state.activeSection]
          && roles[state.activeOrganization][state.activeSection] <= 4
      }
      return false
    },
    isUserClub: state => {
      if (state.user.role && state.user.role === 1) {
        return true
      }
      if (state.activeOrganization) {
        const roles = state.user?.roles
        if (roles && roles[state.activeOrganization] && roles[state.activeOrganization] <= 2) {
          return true
        }
      }
      if (state.activeOrganization && state.activeSection) {
        const roles = state.user?.roles
        if (roles
          && roles[state.activeOrganization]
          && roles[state.activeOrganization][state.activeSection]
          && roles[state.activeOrganization][state.activeSection] <= 4
        ) {
          return true
        }
      }
      if (state.activeOrganization && state.activeSection) {
        const roles = state.user?.roles
        return roles
          && roles[state.activeOrganization]
          && roles[state.activeOrganization][state.activeSection]
          && roles[state.activeOrganization][state.activeSection] <= 5
      }
      return false
    },
    isUserTypeAdministrator: state => state.user && state.user.userTypeId === 1,
    getOrganizationRegions: state => organizationId => state.user && state.user.osr[organizationId] ? state.user.osr[organizationId] : null,
    getOrganizationSectionRegions: state => (organizationId, sectionId) => state.user && state.user.osr && state.user.osr[organizationId] && state.user.osr[organizationId][sectionId] ? state.user.osr[organizationId][sectionId] : null,
    getRegionsByRegion: state => (organizationId, sectionId, regionId, section) => {
      if (!regionId) {
        return null
      }
      if (!section && state.restrictions.o) {
        section = state.restrictions.o[organizationId].s[sectionId]
      }
      if (!section || !section.r) {
        return null
      }
      let result = null
      if (section.r) {
        for (let i in section.r) {
          const region = section.r[i]
          if (regionId == i) {
            if (region.r) {
              return region.r
            }
          }
        }
        for (let i in section.r) {
          const region = section.r[i]
          result = state.getRegionsByRegion(organizationId, sectionId, regionId, region)
          if (result) {
            return result
          }
        }
      }
      return result
    },
    getClubsByRegion: state => (organizationId, sectionId, regionId, section) => {
      if (!regionId) {
        return {}
      }
      section = section || state.restrictions.o[organizationId].s[sectionId]
      let result = {}
      if (section) {
        if (section.r) {
          for (let i in section.r) {
            const region = section.r[i]
            if (+regionId === +i) {
              if (region.c) {
                for (let j in region.c) {
                  result[j] = region.c[j]
                }
              }
              if (region.r) {
                for (let k in region.r) {
                  const childrenClubs = state.getClubsByRegion(organizationId, sectionId, k, region)
                  for (let l in childrenClubs) {
                    result[l] = childrenClubs[l]
                  }
                }
              }
              return result
            }
          }
          for (let i in section.r) {
            const subRregion = section.r[i],
              subResult = state.getClubsByRegion(organizationId, sectionId, regionId, subRregion)
            if (subResult) {
              for (let j in subResult) {
                result[j] = subResult[j]
              }
            }
          }
        }
      }
      return result
    },
    getUserOrganizationIds: state => state.restrictions.o ? Object.keys(state.restrictions.o) : [],
    getUserSectionIds: state => {
      if (state.restrictions.o) {
        const result = {}
        for (let i in state.restrictions.o) {
          const organization = state.restrictions.o[i]
          if (organization.s) {
            const sectionIds = Object.keys(organization.s)
            for (let j in sectionIds) {
              result[sectionIds[j]] = sectionIds[j]
            }
          }
        }
        return Object.keys(result)
      }
      return []
    },
    getUserSeasons: state => {
      if (state.restrictions.o) {
        const result = {}
        for (let i in state.restrictions.o) {
          if (+i === +state.activeOrganization) {
            const organization = state.restrictions.o[i]
            if (organization.s) {
              for (let j in organization.s) {
                if (+j === +state.activeSection) {
                  if (organization.s[j].y) {
                    const seasons = Object.keys(organization.s[j].y)
                    for (let k in seasons) {
                      result[seasons[k]] = seasons[k]
                    }
                  }
                }
              }
            }
          }
        }
        return Object.keys(result)
      }
      return []
    },
    getUserRegionIds: state => {
      if (state.restrictions.o) {
        const result = {}
        for (let i in state.restrictions.o) {
          if (+i === +state.activeOrganization) {
            const organization = state.restrictions.o[i]
            if (organization.s) {
              for (let j in organization.s) {
                if (+j === +state.activeSection) {
                  if (organization.s[j].r) {
                    const regionIds = Object.keys(organization.s[j].r)
                    for (let k in regionIds) {
                      result[regionIds[k]] = regionIds[k]
                    }
                  }
                }
              }
            }
          }
        }
        return Object.keys(result)
      }
      return []
    },
    getUserClubIds: state => {
      if (state.restrictions.o) {
        const result = {}
        for (let i in state.restrictions.o) {
          if (+i === +state.activeOrganization) {
            const organization = state.restrictions.o[i]
            if (organization.s) {
              for (let j in organization.s) {
                if (+j === +state.activeSection) {
                  if (organization.s[j].r) {
                    for (let k in organization.s[j].r) {
                      if (organization.s[j].r[k].c) {
                        const clubIds = Object.keys(organization.s[j].r[k].c)
                        for (let l in clubIds) {
                          result[clubIds[l]] = clubIds[l]
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        return Object.keys(result)
      }
      return []
    },
    getUserClubNames: state => {
      const clubs = state.getClubsByRegion(state.activeOrganization, state.activeSection, state.activeRegion)
      if (clubs) {
        const result = {}
        for (let i in clubs) {
          result[i] = clubs[i].name
        }
        return result
      }
      return {}
    },
    getActiveOrganization: state => state.activeOrganization,
    getActiveSection: state => state.activeSection
  },
  actions: {
    async setUser(data) {
      this.user = data
    },
    setRestrictions(data) {
      this.restrictions = data
    },
    unsetUser() {
      this.user = {}
      this.restrictions = {}
      this.activeOrganization = ''
      this.activeSection = ''
      this.activeSeason = ''
      this.activeRegion = ''
      this.activeClub = ''
    },
    setLoggedUser() {
      const cookieValue = this.cookiesStore.getCookieValueByName(this.loggedAdminUserCookieName)
      if (cookieValue) {
        this.user = JSON.parse(decodeURIComponent(cookieValue))
      } else {
        this.unsetUser()
      }
    },
    setUserRole(organizationId, sectionId) {
      const roles = this.user && this.user.roles ? this.user.roles : {}
      if (organizationId && sectionId) {
        if (roles[organizationId] && roles[organizationId][sectionId]) {
          this.userRole = roles[organizationId][sectionId]
        } else if (roles[organizationId]) {
          this.userRole = roles[organizationId]
        }
      } else if (organizationId) {
        if (roles[organizationId]) {
          this.userRole = roles[organizationId]
        }
      }
    },
    saveDefaults() {
      if (this.user && this.user.id) {
        if (this.activeOrganization) {
          let defaults = this.localStorageStore.getValueByKey(this.activeChoicesLocalStorageKeyName, true)
          if (!defaults) {
            defaults = {}
          }
          if (!defaults[this.user.id]) {
            defaults[this.user.id] = {}
          }
          defaults[this.user.id][this.activeOrganization] = {
            o: this.activeOrganization,
            s: this.activeSection,
            y: this.activeSeason,
            r: this.activeRegion,
            c: this.activeClub
          }
          defaults[this.user.id].o = this.activeOrganization
          this.localStorageStore.setValue(this.activeChoicesLocalStorageKeyName, defaults, true)
        }
      }
    },
    setDefaults() {
      const defaults = this.localStorageStore.getValueByKey(this.activeChoicesLocalStorageKeyName, true)
      if (!this.activeOrganization) {
        if (defaults && defaults[this.user.id]) {
          if (Object.keys(defaults[this.user.id]).length) {
            if (defaults[this.user.id].o) {
              this.activeOrganization = defaults[this.user.id].o
            } else {
              this.activeOrganization = Object.keys(defaults[this.user.id])[0]
            }
          }
        }
      }
      if (this.user && this.user.id && this.activeOrganization) {
        if (defaults && defaults[this.user.id] && defaults[this.user.id][this.activeOrganization]) {
          const defaultParams = defaults[this.user.id] && defaults[this.user.id][this.activeOrganization]
          this.activeOrganization = defaultParams.o ? defaultParams.o : ''
          this.activeSection = defaultParams.s ? defaultParams.s : ''
          this.activeSeason = defaultParams.y ? defaultParams.y : ''
          this.activeRegion = defaultParams.r ? defaultParams.r : ''
          this.activeClub = defaultParams.c ? defaultParams.c : ''
        }
      }
      if (this.restrictions.o) {
        for (let i in this.restrictions.o) {
          if (+i === +this.activeOrganization) {
            const organization = this.restrictions.o[i]
            if (organization.s) {
              for (let j in organization.s) {
                if (!this.activeSection || +j === +this.activeSection) {
                  this.activeSection = +j
                  if (organization.s[j].y) {
                    const regions = organization.s[j].r
                    for (let k in regions) {
                      if (!this.activeRegion || +k === +this.activeRegion) {
                        this.activeRegion = +k
                      }
                    }
                    const seasons = organization.s[j].y
                    for (let k in seasons) {
                      if (!this.activeSeason || +k === +this.activeSeason) {
                        this.activeSeason = +k
                        const season = seasons[k],
                          dateTo = new Date(season.dateTo),
                          now = new Date()
                        this.isActiveSeasonOpen = now.getTime() < dateTo.getTime()
                        break
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.saveDefaults()
    },
    setDefaultActiveOrganization() {
      if (!this.activeOrganization && this.getUserOrganizationIds.length === 1) {
        this.activeOrganization = this.getUserOrganizationIds[0]
      }
    },
    setDefaultActiveSection() {
      if (!this.activeSection && this.getUserSectionIds.length === 1) {
        this.activeSection = this.getUserSectionIds[0]
      }
    },
    setDefaultActiveSeason() {
      if (!this.activeSeason && this.getUserSeasons.length) {
        this.activeSeason = this.getUserSeasons[this.getUserSeasons.length - 1]
      }
    },
    setDefaultActiveRegion() {
      if (!this.activeRegion && this.getUserRegionIds.length === 1) {
        this.activeRegion = this.getUserRegionIds[0]
      }
    },
    setDefaultActiveClub() {
      if (!this.activeClub && this.getUserClubIds.length === 1) {
        this.activeClub = this.getUserClubIds[0]
      }
    },
    changedOrganization() {
      this.setDefaults()
      window.location.reload()
    },
    changedSection() {
      this.activeRegion = ''
      this.activeSeason = ''
      this.changedRegion()
      this.setDefaults()
    },
    changedSeason() {
      this.saveDefaults()
      this.setDefaults()
    },
    changedRegion() {
      this.activeClub = ''
      this.saveDefaults()
      this.changedClub()
    },
    changedClub() {
      this.saveDefaults()
    }
  }
})
