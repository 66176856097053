<template v-if="competition">
  <tr>
    <template v-if="competition">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td><span class="small mark">#{{ competition.id }}</span></td>
        <td>
          <router-link :to="'/competition-management/' + competition.id" target="_blank">{{ competition.name }}</router-link>
          <p>
            <span>| </span>
            <small v-for="row in globalStore.competitionDisciplineStore.getByCompetitionId(competition.id)" :key="row.id">
              {{ globalStore.getDisciplineNameById(row.disciplineId) }}
              <span> | </span>
            </small>
            <template v-if="globalStore.userAdminStore.isUserRegion">
              <br />
              <span>| </span>
              <small v-for="row in globalStore.getLeafRegions(globalStore.competitionRegionStore.getByCompetitionId(competition.id))" :key="row.id">
                {{ globalStore.getRegionNameById(row.regionInOrganizationId) }}
                <span> | </span>
              </small>
            </template>
          </p>
        </td>
        <td>
          {{ globalStore.getTableSizeNameById(competition.tableSizeId) }}<br />
          {{ globalStore.getTeamTypeNameById(competition.teamTypeId) }}<br />
          {{ globalStore.getLevelNameById(competition.levelId) }}
        </td>
        <td>
          <template v-if="globalStore.userAdminStore.isUserRegion">
            <ButtonUpdate
              :click-function="initUpdateForm" />
            <span>&#160;</span>
            <ButtonDelete
              :click-function="deleteItem" />
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="4">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="3">
          <h5>
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('clone competition', globalStore.languageId) }}
          </h5>
          <FormKit type="form" v-model="autoloadData" @submit="processAddForm">
            <FormKit
              type="text"
              name="competitionName"
              :help="globalStore.vocabularyStore.getWordByIdAndLanguage('competition autoload', globalStore.languageId)"
              @keyup="loadCompetitions(autoloadData, 'competitionName', 'cloningCompetitionId')"
              autocomplete="off" />
            <FormKit
              type="select"
              :options="autoloadedCompetitions"
              validation="required"
              name="cloningCompetitionId"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('competition autoloaded', globalStore.languageId)" />
            <FormKit
              type="text"
              validation="required"
              name="name"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('name', globalStore.languageId)" />
            <FormKit
              type="datetime-local"
              name="singUpDateFrom"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('sing up start', globalStore.languageId)" />
            <FormKit
              type="datetime-local"
              name="singUpDateTo"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('sing up deadline', globalStore.languageId)" />
          </FormKit>
          <hr />
          <h5>
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create competition', globalStore.languageId) }}
          </h5>
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref, computed } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'CompetitionItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    competitionId: {
      required: false,
      type: Number
    },
    competition: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const id = toRef(props, 'competitionId'),
      competition = toRef(props, 'competition'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      autoloadData = ref({}),
      itemData = ref({}),
      schema = ref([]),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      followableCompetitions = ref({}),
      autoloadedCompetitions = ref({}),
      disciplineOptions = computed(() => {
        if (itemData.value.tableSizeId == '1') {
          return globalStore.getSvlDiscipline(globalStore.userAdminStore.activeSection, 1)
        } else if (itemData.value.tableSizeId == '2') {
          return globalStore.getSvlDiscipline(globalStore.userAdminStore.activeSection, 2)
        }
        return []
      })

    function initAddForm() {
      itemData.value = {}
      autoloadData.value = {}

      const regionId = [],
        disciplineId = [],
        regions = globalStore.competitionRegionStore.getByCompetitionId(id.value),
        disciplines = globalStore.competitionDisciplineStore.getByCompetitionId(id.value)

      for (let i in regions) {
        regionId.push(regions[i].regionInOrganizationId)
      }
      for (let i in disciplines) {
        disciplineId.push(disciplines[i].disciplineId)
      }
      itemData.value.regionId = regionId
      itemData.value.disciplineId = disciplineId

      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = competition.value
      if (!itemData.value) {
        return
      }
      const regionId = [],
        disciplineId = [],
        regions = globalStore.competitionRegionStore.getByCompetitionId(id.value),
        disciplines = globalStore.competitionDisciplineStore.getByCompetitionId(id.value)

      for (let i in regions) {
        regionId.push(regions[i].regionInOrganizationId)
      }
      for (let i in disciplines) {
        disciplineId.push(disciplines[i].disciplineId)
      }
      itemData.value.regionId = regionId
      itemData.value.disciplineId = disciplineId

      const payload = {
        type: 'competition',
        o: globalStore.userAdminStore.activeOrganization,
        se: globalStore.userAdminStore.activeSection,
        teamTypeId: competition.value.teamTypeId,
        t: competition.value.tableSizeId,
      }
      const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            followableCompetitions.value = {}
            for (let i in response.data) {
              followableCompetitions.value[response.data[i].id] = response.data[i].name
            }
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))

      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'text',
        name: 'name',
        validation: 'required',
        label: globalStore.getWordById('name')
      })

      schema.value.push({
        $formkit: 'select',
        name: 'tableSizeId',
        validation: 'required',
        options: globalStore.getSvlTableSize,
        label: globalStore.getWordById('table size'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'followedCompetitionId',
        options: followableCompetitions,
        label: globalStore.getWordById('followed competition'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'levelId',
        validation: 'required',
        options: globalStore.getSvlLevel,
        label: globalStore.getWordById('level'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'teamTypeId',
        options: {
          '': '', ...globalStore.getSvlTeamType
        },
        label: globalStore.getWordById('team type'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'genderId',
        options: {
          '': '', ...globalStore.getSvlGender
        },
        label: globalStore.getWordById('gender'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'ageId',
        options: {
          '': '', ...globalStore.getSvlAge
        },
        label: globalStore.getWordById('age'),
        placeholder: '...'
      })

      const regionOptions = [];
      for (let i in globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'][globalStore.userAdminStore.activeSection]['r']) {
        const region = globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'][globalStore.userAdminStore.activeSection]['r'][i]

        regionOptions.push({
          value: i,
          label: globalStore.getWordById(region.name)
        })

        if (region.r) {
          for (let j in region.r) {
            const subRegion = region.r[j]
            regionOptions.push({
              value: j,
              label: globalStore.getWordById(subRegion.name),
            })
            if (subRegion.r) {
              for (let k in subRegion.r) {
                const subSubRegion = subRegion.r[k]
                regionOptions.push({
                  value: k,
                  label: globalStore.getWordById(subSubRegion.name),
                })
                if (subSubRegion.r) {
                  for (let l in subSubRegion.r) {
                    const subSubSubRegion = subSubRegion.r[l]
                    regionOptions.push({
                      value: l,
                      label: globalStore.getWordById(subSubSubRegion.name),
                    })
                  }
                }
              }
            }
          }
        }
      }
      schema.value.push({
        $formkit: 'checkbox',
        name: 'regionId',
        validation: 'required',
        label: globalStore.getWordById('region'),
        options: regionOptions
      })

      schema.value.push({
        $formkit: 'checkbox',
        name: 'disciplineId',
        validation: 'required',
        options: disciplineOptions,
        label: globalStore.getWordById('discipline')
      })

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'singUpDateFrom',
        label: globalStore.getWordById('sing up start')
      })

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'singUpDateTo',
        label: globalStore.getWordById('sing up deadline')
      })
    }

    function processAddForm() {
      itemData.value.organizationId = globalStore.userAdminStore.activeOrganization
      itemData.value.sectionId = globalStore.userAdminStore.activeSection
      itemData.value.season = globalStore.userAdminStore.activeSeason

      if (autoloadData.value.cloningCompetitionId && autoloadData.value.name && !itemData.value.teamTypeId) {
        itemData.value.cloningCompetitionId = autoloadData.value.cloningCompetitionId
        itemData.value.name = autoloadData.value.name
        itemData.value.singUpDateFrom = autoloadData.value.singUpDateFrom
        itemData.value.singUpDateTo = autoloadData.value.singUpDateTo
      }

      fetch(globalStore.apiUrl + '/admin/competition', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.competitionStore.addRow(response.data.competition)
            globalStore.competitionDisciplineStore.updateRowsByCompetitionId(response.data.competitionDiscipline, id.value)
            globalStore.competitionRegionStore.updateRowsByCompetitionId(response.data.competitionRegion, id.value)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      itemData.value.id = id.value
      if (competition.value) {
        fetch(globalStore.apiUrl + '/admin/competition', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.competitionStore.updateRow(response.data.competition)
              globalStore.competitionDisciplineStore.updateRowsByCompetitionId(response.data.competitionDiscipline, id.value)
              globalStore.competitionRegionStore.updateRowsByCompetitionId(response.data.competitionRegion, id.value)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = {
          id: id.value
        }
        fetch(globalStore.apiUrl + '/admin/competition', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.competitionStore.deleteRow(id.value)
              globalStore.competitionDisciplineStore.deleteRowsByCompetitionId(id.value)
              globalStore.competitionRegionStore.deleteRowsByCompetitionId(id.value)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function loadCompetitions(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: 'competition',
          o: globalStore.userAdminStore.activeOrganization,
          se: globalStore.userAdminStore.activeSection,
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const competitions = {}
              for (let i = 0; i < response.data.length; i++) {
                competitions[response.data[i].id] = response.data[i].name
                if (setSelected === false) {
                  vModel[targetValueName] = response.data[i].id
                  setSelected = true
                }
              }
              autoloadedCompetitions.value = competitions
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, schema, addFormActive, autoloadData, autoloadedCompetitions,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm, loadCompetitions
    }
  }
}
</script>
