<template>
  <tr>
    <template v-if="participant">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td :colspan="isMulti ? 7 : 15">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <template v-if="isMulti">
          <td class="text-nowrap">{{ participant.id }}</td>
          <td class="text-nowrap">{{ globalStore.getGenderNameById(participant.genderId) }}</td>
          <td class="text-nowrap">{{ globalStore.getCountryNameById(participant.countryId) }}</td>
          <td class="text-nowrap">{{ globalStore.getTeamTypeNameById(participant.participantTypeId) }}</td>
          <td class="text-nowrap">{{ participant.surname }}</td>
          <td class="text-nowrap">{{ globalStore.getTableSizeNameById(participant.tableSizeId) }}</td>
          <td class="text-nowrap">{{ globalStore.getDisciplineNameById(participant.disciplineId) }}</td>
        </template>
        <template v-else>
          <td class="text-nowrap">{{ participant.id }}</td>
          <td class="text-nowrap">{{ globalStore.getGenderNameById(participant.genderId) }}</td>
          <td class="text-nowrap">{{ globalStore.getCountryNameById(participant.countryId) }}</td>
          <td class="text-nowrap">{{ participant.titleBefore }}</td>
          <td class="text-nowrap">{{ participant.firstName }}</td>
          <td class="text-nowrap">{{ participant.middleName }}</td>
          <td class="text-nowrap">{{ participant.surname }}</td>
          <td class="text-nowrap">{{ participant.supplement }}</td>
          <td class="text-nowrap">{{ participant.titileAfter }}</td>
          <td class="text-nowrap">{{ globalStore.formatDate(participant.dateBirth) }}</td>
          <td class="text-nowrap">{{ globalStore.formatDate(participant.dateDeath) }}</td>
          <td class="text-nowrap">{{ participant.photo }}</td>
          <td class="text-nowrap">{{ participant.photoOfficial }}</td>
          <td class="text-nowrap">{{ participant.email }}</td>
          <td class="text-nowrap">{{ globalStore.formatDateTime(participant.agreement) }}</td>
        </template>
        <td>
          <template v-if="globalStore.userAdminStore.isUserRegistry">
            <ButtonUpdate
              :click-function="initUpdateForm" />
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td :colspan="isMulti ? 8 : 16" v-if="addFormActive === false">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td :colspan="isMulti ? 7 : 16">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'ParticipantItem',
  components: { ButtonUpdate, ButtonBack, ButtonAdd },
  props: {
    participantObject: {
      required: false,
      type: Object
    },
    multi: {
      required: false,
      type: Boolean
    }
  },

  setup(props) {
    const participant = toRef(props, 'participantObject'),
      isMulti = toRef(props, 'multi'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      schema = ref([]),
      updateFormActive = ref(false),
      addFormActive = ref(false)

    function initAddForm() {
      itemData.value = {}

      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = participant.value
      if (!itemData.value) {
        return
      }

      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'select',
        name: 'countryId',
        validation: 'required',
        options: globalStore.getSvlCountry,
        label: globalStore.getWordById('country'),
        placeholder: '...'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'genderId',
        validation: 'required',
        options: globalStore.getSvlGender,
        label: globalStore.getWordById('gender'),
        placeholder: '...'
      })

      if (isMulti.value) {
        schema.value.push({
          $formkit: 'select',
          name: 'participantTypeId',
          options: globalStore.getSvlTeamType,
          validation: 'required',
          label: globalStore.getWordById('team type'),
          placeholder: '...'
        })
      }

      if (!isMulti.value) {
        schema.value.push({
          $formkit: 'text',
          name: 'titleBefore',
          label: globalStore.getWordById('title before')
        })

        schema.value.push({
          $formkit: 'text',
          name: 'firstName',
          label: globalStore.getWordById('first name')
        })

        schema.value.push({
          $formkit: 'text',
          name: 'middleName',
          label: globalStore.getWordById('middle name')
        })

        schema.value.push({
          $formkit: 'text',
          name: 'surname',
          validation: 'required',
          label: globalStore.getWordById('surname')
        })
      } else {
        schema.value.push({
          $formkit: 'text',
          name: 'surname',
          validation: 'required',
          label: globalStore.getWordById('name')
        })
      }

      if (!isMulti.value) {
        schema.value.push({
          $formkit: 'text',
          name: 'supplement',
          label: globalStore.getWordById('supplement')
        })

        schema.value.push({
          $formkit: 'text',
          name: 'titleAfter',
          label: globalStore.getWordById('title after')
        })
      }

      if (isMulti.value) {
        schema.value.push({
          $formkit: 'select',
          name: 'tableSizeId',
          options: globalStore.getSvlTableSize,
          validation: 'required',
          label: globalStore.getWordById('table size'),
          placeholder: '...'
        })

        schema.value.push({
          $formkit: 'select',
          name: 'disciplineId',
          options: globalStore.getSvlDiscipline(),
          label: globalStore.getWordById('discipline'),
          placeholder: '...'
        })
      }

      schema.value.push({
        $formkit: 'file',
        name: 'photo',
        label: globalStore.getWordById('photo') + (participant.value && participant.value.photo ? ' ' + globalStore.getWordById('replace') + ' ' + participant.value.photo : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.png,.svg'
      })

      if (!isMulti.value) {
        schema.value.push({
          $formkit: 'email',
          name: 'email',
          label: globalStore.getWordById('email')
        })

        schema.value.push({
          $formkit: 'date',
          name: 'dateBirth',
          label: globalStore.getWordById('date birth')
        })

        schema.value.push({
          $formkit: 'date',
          name: 'dateDeath',
          label: globalStore.getWordById('date death')
        })

        schema.value.push({
          $formkit: 'datetime-local',
          name: 'agreement',
          label: globalStore.getWordById('agreement')
        })
      }
    }

    function processAddForm() {
      itemData.value.organizationId = globalStore.userAdminStore.activeOrganization
      const formData = new FormData()
      for (let i in itemData.value) {
        if (i === 'photo') {
          formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
        } else {
          formData.append(i, itemData.value[i] || '')
        }
      }

      fetch(globalStore.apiUrl + '/admin/participant', {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.participantStore.addData(response.data.participant)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (participant.value) {
        const formData = new FormData()
        for (let i in itemData.value) {
          if (i === 'photo') {
            formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
          } else {
            formData.append(i, itemData.value[i] || '')
          }
        }

        fetch(globalStore.apiUrl + '/admin/participant-update', {
          method: 'POST',
          body: formData
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.participantStore.updateData(response.data.participant)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, schema, addFormActive, participant, isMulti,
      initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
