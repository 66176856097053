<template>
  <tr>
    <template v-if="officialParticipant">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          {{ globalStore.getParticipantNameById(officialParticipant.participantId) }}
        </td>
        <td>
          {{ officialParticipant.email }}
        </td>
        <td>
          {{ officialParticipant.phone }}
        </td>
        <td>
          <ButtonUpdate
            :click-function="initUpdateForm" />
          <span>&#160;</span>
          <ButtonDelete
            :click-function="deleteItem" />
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="4">
        <ButtonAdd :viewPermanently="true" :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKit
              type="text"
              name="participantName"
              :help="globalStore.vocabularyStore.getWordByIdAndLanguage('participant autoload', globalStore.languageId)"
              @keyup="loadParticipants(itemData, 'participantName', 'participantId')"
              autocomplete="off" />
            <FormKit
              type="select"
              :options="autoloadedParticipants"
              validation="required"
              name="participantId" />
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";
import ButtonDelete from "@/components/Button/ButtonDelete";

export default {
  name: 'ClubOfficialParticipantItem',
  components: { ButtonUpdate, ButtonBack, ButtonAdd, ButtonDelete },
  props: {
    clubObject: {
      required: false,
      type: Object
    },
    officialParticipantObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const club = toRef(props, 'clubObject'),
      officialParticipant = toRef(props, 'officialParticipantObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([]),
      autoloadedParticipants = ref({})

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'email',
        name: 'email',
        validation: 'required',
        label: globalStore.getWordById('email')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'phone',
        validation: 'required',
        label: globalStore.getWordById('phone')
      })
    }

    function initAddForm() {
      itemData.value = {}
      itemData.value.participantName = ''
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      if (!officialParticipant.value) {
        return
      }
      const item = Object.assign({}, officialParticipant.value)
      itemData.value = item
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
      autoloadedParticipants.value = {}
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.clubId = club.value.id
      itemData.value.organizationId = club.value.organizationId
      fetch(globalStore.apiUrl + '/admin/club-official-participant', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.officialParticipantStore.setData(response.data.officialParticipant)
            globalStore.participantStore.addData(response.data.participant)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (officialParticipant.value) {
        fetch(globalStore.apiUrl + '/admin/club-official-participant', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.officialParticipantStore.setData(response.data.officialParticipant)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = officialParticipant.value
        fetch(globalStore.apiUrl + '/admin/club-official-participant', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.officialParticipantStore.deleteData(item)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function loadParticipants(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]

      if (key.length > 2) {
        const payload = {
          type: 'participant',
          o: club.value.organizationId,
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const participants = {}
              for (let i = 0; i < response.data.length; i++) {
                participants[response.data[i].id] = response.data[i].name
                if (setSelected === false) {
                  vModel[targetValueName] = response.data[i].id
                  setSelected = true
                }
              }
              autoloadedParticipants.value = participants
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive, schema, club, officialParticipant,
      autoloadedParticipants,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm, loadParticipants
    }
  }
}
</script>
