import { defineStore } from 'pinia'

export const useCookiesStore = defineStore('CookiesStore', {
  state: () => {
    return {}
  },
  getters: {},
  actions: {
    getCookieValueByName(name) {
      const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
      return match ? match[2] : ''
    },
    setCookieValue(name, value, exipreTimeInSeconds) {
      const origin = window.location.hostname,
        domainParts = origin.split('.')
      let domain = origin,
        expire = ''
      if (exipreTimeInSeconds) {
        const date = new Date()
        date.setTime(exipreTimeInSeconds * 1000);
        expire = 'expires=' + date.toUTCString() + ';'
      }
      if (domainParts.length > 2) {
        delete domainParts[0]
        domain = domainParts.join('.')
      }
      document.cookie = name + '=' + value + ';domain=' + domain + ';' + expire + 'path=/';
    }
  }
})
