<template>
  <div v-if="clubId">
    <section>
      <!-- Pills -->
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="club-presentation-tab" data-bs-toggle="pill" data-bs-target="#club-presentation" type="button" role="tab" aria-controls="club-presentation">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('club presentation', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="club-logo-tab" data-bs-toggle="pill" data-bs-target="#club-logo" type="button" role="tab" aria-controls="club-logo">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('club logo', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="club-media-tab" data-bs-toggle="pill" data-bs-target="#club-media" type="button" role="tab" aria-controls="club-media">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('club media', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="club-lounge-tab" data-bs-toggle="pill" data-bs-target="#club-lounge" type="button" role="tab" aria-controls="club-lounge">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('club lounges', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="club-sponsor-tab" data-bs-toggle="pill" data-bs-target="#club-sponsor" type="button" role="tab" aria-controls="club-sponsor">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('club sponsors', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="club-official-participant-tab" data-bs-toggle="pill" data-bs-target="#club-official-participant" type="button" role="tab" aria-controls="club-official-participant">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('club official participants', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="club-official-tab" data-bs-toggle="pill" data-bs-target="#club-official" type="button" role="tab" aria-controls="club-official">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('club officials', globalStore.languageId) }}
          </button>
        </li>
      </ul>
      <!-- Contents -->
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade" id="club-presentation" role="tabpanel" aria-labelledby="club-presentation-tab">
          <ClubPresentationItem :clubPresentationObject="globalStore.clubPresentationStore.getByClubId(clubId)" :clubIdSet="+clubId" />
        </div>
        <div class="tab-pane fade" id="club-logo" role="tabpanel" aria-labelledby="club-logo-tab">
          <ClubLogoItem :clubObject="globalStore.clubStore.getById(clubId)" />
        </div>
        <div class="tab-pane fade" id="club-media" role="tabpanel" aria-labelledby="club-media-tab">
          <ClubPresentationParameterList :clubObject="globalStore.clubStore.getById(clubId)" />
        </div>
        <div class="tab-pane fade" id="club-lounge" role="tabpanel" aria-labelledby="club-lounge-tab">
          <ClubLoungeList :clubObject="globalStore.clubStore.getById(clubId)" />
        </div>
        <div class="tab-pane fade" id="club-sponsor" role="tabpanel" aria-labelledby="club-sponsor-tab">
          <ClubSponsorList :clubObject="globalStore.clubStore.getById(clubId)" />
        </div>
        <div class="tab-pane fade" id="club-official-participant" role="tabpanel" aria-labelledby="club-official-participant-tab">
          <ClubOfficialParticipantList :clubObject="globalStore.clubStore.getById(clubId)" />
        </div>
        <div class="tab-pane fade" id="club-official" role="tabpanel" aria-labelledby="club-official-tab">
          <ClubOfficialList :clubObject="globalStore.clubStore.getById(clubId)" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { toRef, onMounted } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import ClubPresentationItem from '@/components/ClubPresentation/ClubPresentationItem'
import ClubLogoItem from '@/components/ClubPresentation/ClubLogoItem'
import ClubPresentationParameterList from '@/components/ClubPresentation/ClubPresentationParameterList'
import ClubLoungeList from '@/components/ClubPresentation/ClubLoungeList'
import ClubSponsorList from '@/components/ClubPresentation/ClubSponsorList'
import ClubOfficialParticipantList from '@/components/ClubPresentation/ClubOfficialParticipantList'
import ClubOfficialList from '@/components/ClubPresentation/ClubOfficialList'

export default {
  name: 'ClubPresentationParts',
  components: {
    ClubPresentationItem, ClubLogoItem, ClubPresentationParameterList, ClubLoungeList, ClubSponsorList,
    ClubOfficialParticipantList, ClubOfficialList
  },
  props: {
    clubIdSet: {
      required: true,
      type: Number
    },
  },

  setup(props) {
    const clubId = toRef(props, 'clubIdSet'),
      globalStore = useGlobalStore()

    onMounted(() => {
      loadClubPresentation()
    })

    function loadClubPresentation() {
      const url = globalStore.apiUrl + '/admin/club-presentation/' + clubId.value
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyPublicStore.addRows(data.data.vocabularyPublic)
          globalStore.clubPresentationStore.setData(data.data.clubPresentation)
        })
        .catch(err => globalStore.notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, clubId
    }
  }
}
</script>
