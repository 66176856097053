import { defineStore } from 'pinia'

export const useClubTodayEventIdStore = defineStore('ClubTodayEventIdStore', {
  state: () => {
    return {
      teamEventIds: [],
      tournamentIds: [],
    }
  },
  getters: {
    count: state => state.levels.length,
    getTeamEventIds: state => { () => state.teamEventIds },
    getTournamentIds: state => { () => state.tournamentIds },
  },
  actions: {
    setDataTeamEventIds(data) {
      this.teamEventIds = data
    },
    setDataTournamentIds(data) {
      this.tournamentIds = data
    }
  }
})
