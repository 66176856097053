<template>
  <div v-if="organizationId">
    <section>
      <!-- Pills -->
      <ul class=" nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link" :id="'organization-sections-tab-' + organizationId" data-bs-toggle="pill" :data-bs-target="'#organization-sections-' + organizationId" type="button" role="tab" aria-controls="organization-sections">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('organization sections', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" :id="'organization-regions-tab-' + organizationId" data-bs-toggle="pill" :data-bs-target="'#organization-regions-' + organizationId" type="button" role="tab" aria-controls="organization-regions">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('organization regions', globalStore.languageId) }}
          </button>
        </li>
      </ul>
      <!-- Contents -->
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade" :id="'organization-sections-' + organizationId" role="tabpanel" :aria-labelledby="'organization-sections-tab-' + organizationId">
          <OrganizationSectionList :organizationIdSet="organizationId" />
        </div>
        <div class="tab-pane fade" :id="'organization-regions-' + organizationId" role="tabpanel" :aria-labelledby="'organization-regions-tab-' + organizationId">
          <OrganizationRegionList :organizationIdSet="organizationId" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import OrganizationSectionList from '@/components/OrganizationManagement/OrganizationSectionList'
import OrganizationRegionList from '@/components/OrganizationManagement/OrganizationRegionList'

export default {
  name: 'OrganizationSettings',
  components: {
    OrganizationSectionList,
    OrganizationRegionList
  },
  props: {
    organizationIdSet: {
      required: true,
      type: Number
    },
  },

  setup(props) {
    const organizationId = toRef(props, 'organizationIdSet'),
      globalStore = useGlobalStore()

    return {
      globalStore, organizationId
    }
  }
}
</script>
