<template>
  <tr>
    <template v-if="lounge">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td>
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td :class="lounge.active ? 'text-dark' : 'text-secondary'">
          <strong>
            <address>
              {{ lounge ? globalStore.formatAddressInline(globalStore.addressStore.getById(lounge.addressId)) : '' }}
            </address>
          </strong>
        </td>
        <td>
          <ButtonUpdate
            :click-function="initUpdateForm" />
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="2">
        <ButtonAdd :viewPermanently="true" :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td>
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
  <template v-if="lounge">
    <tr>
      <td colspan="2">
        <ClubLoungeEquipmentTableList :loungeIdSet="lounge.id" />
      </td>
    </tr>
    <tr>
      <td colspan="2">
        <ClubLoungeEquipmentBallList :loungeIdSet="lounge.id" />
      </td>
    </tr>
  </template>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";
import ClubLoungeEquipmentTableList from "@/components/ClubPresentation/ClubLoungeEquipmentTableList";
import ClubLoungeEquipmentBallList from "@/components/ClubPresentation/ClubLoungeEquipmentBallList";

export default {
  name: 'ClubLoungeItem',
  components: { ButtonUpdate, ButtonBack, ButtonAdd, ClubLoungeEquipmentTableList, ClubLoungeEquipmentBallList },
  props: {
    clubIdSet: {
      required: false,
      type: Number
    },
    loungeObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const clubId = toRef(props, 'clubIdSet'),
      lounge = toRef(props, 'loungeObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([])

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'text',
        name: 'street',
        label: globalStore.getWordById('street')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'streetNumber',
        validation: 'required',
        label: globalStore.getWordById('street number')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'zip',
        validation: 'required',
        label: globalStore.getWordById('zip')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'city',
        label: globalStore.getWordById('city')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'country',
        label: globalStore.getWordById('country')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'longitude',
        label: globalStore.getWordById('longitude')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'latitude',
        label: globalStore.getWordById('latitude')
      })

      schema.value.push({
        $formkit: 'select',
        name: 'active',
        options: {
          '1': globalStore.getWordById('active-1'),
          '0': globalStore.getWordById('active-0'),
        },
        label: globalStore.getWordById('comment active'),
        required: true
      })
    }

    function initAddForm() {
      itemData.value = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      if (!lounge.value) {
        return
      }
      const item = Object.assign({}, lounge.value),
        address = globalStore.addressStore.getById(item.addressId)
      itemData.value = item
      itemData.value.street = address ? address.street : null
      itemData.value.streetNumber = address ? address.streetNumber : null
      itemData.value.zip = address ? address.zip : null
      itemData.value.city = address ? address.city : null
      itemData.value.country = address ? address.country : null
      itemData.value.longitude = address ? address.longitude : null
      itemData.value.latitude = address ? address.latitude : null
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.clubId = clubId.value
      fetch(globalStore.apiUrl + '/admin/club-lounge', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.loungeStore.setData(response.data.lounge)
            globalStore.addressStore.setData(response.data.address)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (lounge.value) {
        fetch(globalStore.apiUrl + '/admin/club-lounge', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.loungeStore.setData(response.data.lounge)
              globalStore.addressStore.setData(response.data.address)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = lounge.value
        fetch(globalStore.apiUrl + '/admin/club-lounge', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.loungeStore.deleteData(item)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive, schema, clubId, lounge,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
