import { defineStore } from 'pinia'

export const useTournamentDisciplineStore = defineStore('TournamentDisciplineStore', {
  state: () => {
    return {
      tournamentDisciplines: [],
    }
  },
  getters: {
    count: state => state.tournamentDisciplines.length,
    getByTournamentId: state => {
      return id => {
        return state.tournamentDisciplines.filter(row => +row.tournamentId === +id)
      }
    }
  },
  actions: {
    setData(data) {
      this.tournamentDisciplines = data
    },
    updateRowsByTournamentId(data, tournamentId) {
      this.tournamentDisciplines = this.tournamentDisciplines.filter(row => +row.tournamentId !== +tournamentId)
      for (let i in data) {
        this.addRow(data[i])
      }
    },
    deleteRowsByTournamentId(tournamentId) {
      this.tournamentDisciplines = this.tournamentDisciplines.filter(row => +row.tournamentId !== +tournamentId)
    },
    addRows(data) {
      for (let i in data) {
        this.addRow(data[i])
      }
    },
    addRow(data) {
      this.tournamentDisciplines.push(data)
    },
    deleteRow(id) {
      for (let i in this.tournamentDisciplines) {
        if (+id === +this.tournamentDisciplines[i].id) {
          delete this.tournamentDisciplines[i]
        }
      }
    },
    updateRow(data) {
      for (let i in this.tournamentDisciplines) {
        if (+data.id === +this.tournamentDisciplines[i].id) {
          this.tournamentDisciplines[i] = data
        }
      }
    },
  }
})
