<template>
  <table v-if="globalStore.tournamentParticipantStageStore.getTournamentParticipantsByStageId(stageId)" class="table table-hover align-middle table-light">
    <tbody>
      <template v-for="(rows, index) in getRows()" :key="index">
        <tr v-if="index">
          <th colspan="2" class="bg-primary">{{ index }}</th>
        </tr>
        <tr v-for="(row, index) in rows.sort((a, b) => a.setting - b.setting)" :key="index">
          <template v-if="!row.prediction">
            <td>{{ row.setting }}</td>
            <td>{{ globalStore.getTournamentParticipantNameById(row.tournamentParticipantId || row.tournamentTeamId) }}</td>
          </template>
          <template v-else-if="!isSettingSet(row.prediction.setting, rows)">
            <td>{{ row.prediction.setting }}</td>
            <td>{{ row.prediction.name }}</td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { toRef } from "vue"

export default {
  name: 'TournamentStageParticipantsGroupedBySignList',
  components: {},
  props: {
    stageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const sId = toRef(props, 'stageId'),
      globalStore = useGlobalStore()

    function getRows() {
      const tournamentParticipantsCount = globalStore.tournamentParticipantStore.count,
        previousTournamentStage = globalStore.tournamentStageStore.getPreviousValidNonFinalTournamentStage(sId.value, tournamentParticipantsCount, globalStore)

      if (previousTournamentStage && previousTournamentStage.tournamentParticipantMinCount <= globalStore.tournamentParticipantStore.count) {
        return globalStore.tournamentParticipantStageStore.getStageParticipantsWithPrediction(sId.value, globalStore)
      }
      return globalStore.tournamentParticipantStageStore.getTournamentParticipantsByStageId(sId.value)
    }

    function isSettingSet(setting, rows) {
      for (let i in rows) {
        if (rows[i].setting && rows[i].setting === setting) {
          return true
        }
      }
      return false
    }

    return {
      globalStore,
      getRows, isSettingSet
    }
  }
}
</script>
