<template>
  <div class="modal fade" :id="'stageDate' + currentStageId" tabindex="-1" :aria-labelledby="'stageDateLabel' + currentStageId" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="'stageDateLabel' + currentStageId">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('stage date time start', globalStore.languageId) }}</h5>
          <button @click="isAddFormActive = false; createAllStages = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <FormKit type="datetime-local" v-model="dateTimeStart" validation="required" />
        </div>
        <div class="modal-footer">
          <ButtonAdd
            data-bs-dismiss="modal" type="submit"
            :click-function="createStage"
            :click-function-parameter="currentStageId"
            :word="globalStore.vocabularyStore.getWordByIdAndLanguage('create stage', globalStore.languageId)" />
        </div>
      </div>
    </div>
  </div>

  <div v-if="globalStore.tournamentParticipantStageStore.getTournamentParticipantsByStageId(stageId)">
    <!-- Pills -->
    <ul class="nav nav-pills mb-3" :id="'pills-tabs-stage-' + stageId" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" :id="'tournament-stage-system-tab-' + stageId" data-bs-toggle="pill" :data-bs-target="'#tournament-stage-system-' + stageId" type="button" role="tab" :aria-controls="'tournament-stage-system-' + stageId" aria-selected="true">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage system', globalStore.languageId) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" :id="'tournament-stage-participants-tab-' + stageId" data-bs-toggle="pill" :data-bs-target="'#tournament-stage-participants-' + stageId" type="button" role="tab" :aria-controls="'tournament-stage-participants-' + stageId">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage participants', globalStore.languageId) }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button v-if="globalStore.eventStore.hasStageEvents(stageId)" class="nav-link" :id="'tournament-stage-events-tab-' + stageId" data-bs-toggle="pill" :data-bs-target="'#tournament-stage-events-' + stageId" type="button" role="tab" :aria-controls="'tournament-stage-events-' + stageId">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage events', globalStore.languageId) }}
        </button>
        <template v-else-if="isStageAddable(stageId)">
          <template v-if="areStageParamsComplete(stageId)">
            <span>&#160;</span>
            <button @click="initAddForm()" type="button" class="btn btn-success position-relative" data-bs-toggle="modal" :data-bs-target="'#stageDate' + currentStageId">
              {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create stage', globalStore.languageId) }}
            </button>
            <span>&#160;</span>
            <button @click="initAddForm(true)" type="button" class="btn btn-success position-relative" data-bs-toggle="modal" :data-bs-target="'#stageDate' + currentStageId">
              {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create all future stages', globalStore.languageId) }}
            </button>
            <span>&#160;</span>
          </template>
          <button v-else type="button" class="btn btn-secondary position-relative" disabled="disabled">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create stage', globalStore.languageId) }}
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {{ globalStore.vocabularyStore.getWordByIdAndLanguage('created stage condition error', globalStore.languageId) }}
            </span>
          </button>
        </template>
      </li>
      <li v-if="isStageFinished(stageId)" class="nav-item" role="presentation">
        <button class="nav-link" :id="'tournament-stage-ranking-tab-' + stageId" data-bs-toggle="pill" :data-bs-target="'#tournament-stage-ranking-' + stageId" type="button" role="tab" :aria-controls="'tournament-stage-ranking-' + stageId">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage ranking', globalStore.languageId) }}
        </button>
      </li>
      <li v-if="(!globalStore.tournamentStore.tournament.teamTypeId || globalStore.tournamentStore.tournament.teamTypeId === 5) && globalStore.applyStageParticipantMinCountLimit(stageId)" class="nav-item" role="presentation">
        <button class="nav-link" :id="'tournament-stage-schedule-tab-' + stageId" data-bs-toggle="pill" :data-bs-target="'#tournament-stage-schedule-' + stageId" type="button" role="tab" :aria-controls="'tournament-stage-schedule-' + stageId">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage schedule', globalStore.languageId) }}
        </button>
      </li>
      <li v-if="globalStore.isUserTypeAdministrator" class="nav-item" role="presentation">
        <button class="nav-link" :id="'tournament-stage-substitute-tab-' + stageId" data-bs-toggle="pill" :data-bs-target="'#tournament-stage-substitute-' + stageId" type="button" role="tab" :aria-controls="'tournament-stage-substitute-' + stageId">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage substitute', globalStore.languageId) }}
        </button>
      </li>
      <li v-if="isStageDeletable(stageId)" class="nav-item" role="presentation">
        <button class="btn" @click="deleteStage(stageId)">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('delete stage', globalStore.languageId) }}
        </button>
      </li>
      <li v-else-if="globalStore.isUserTypeAdministrator && globalStore.environment === 'TEST' && isStageResetable(stageId)" class="nav-item" role="presentation">
        <TournamentResetStage
          :stageId="stageId" />
      </li>
    </ul>
    <!-- Contents -->
    <div class="tab-content" id="pills-tabStageContent-'+ stageId">
      <div class="tab-pane fade active show" :id="'tournament-stage-system-' + stageId" role="tabpanel" :aria-labelledby="'tournament-stage-system-tab-' + stageId">
        <TournamentStageSystem
          :stageId="stageId" />
      </div>
      <div class="tab-pane fade" :id="'tournament-stage-participants-' + stageId" role="tabpanel" :aria-labelledby="'tournament-stage-participants-tab-' + stageId">
        <TournamentStageParticipantsGroupedBySignList
          :stageId="stageId" />
      </div>
      <div v-if="globalStore.isUserTypeAdministrator" class="tab-pane fade" :id="'tournament-stage-substitute-' + stageId" role="tabpanel" :aria-labelledby="'tournament-stage-substitute-tab-' + stageId">
        <TournamentStageSubstitute
          :stageId="stageId" />
      </div>
      <div v-if="(!globalStore.tournamentStore.tournament.teamTypeId || globalStore.tournamentStore.tournament.teamTypeId === 5) && globalStore.applyStageParticipantMinCountLimit(stageId)" class="tab-pane fade" :id="'tournament-stage-schedule-' + stageId" role="tabpanel" :aria-labelledby="'tournament-stage-schedule-tab-' + stageId">
        <TournamentStageSchedule
          :setStageId="stageId" />
      </div>
      <div class="tab-pane fade" :id="'tournament-stage-events-' + stageId" role="tabpanel" :aria-labelledby="'tournament-stage-events-tab-' + stageId">
        <template v-if="globalStore.tournamentStore.isTournamentForTeams">
          <TournamentStageTeamEvent v-if="teamEventStore.hasStageTeamEvents(stageId)"
            :setStageId="stageId" />
        </template>
        <template v-else>
          <TournamentStageEvent v-if="globalStore.eventStore.hasStageEvents(stageId)"
            :setStageId="stageId" />
        </template>
      </div>
      <div class="tab-pane fade" :id="'tournament-stage-ranking-' + stageId" role="tabpanel" :aria-labelledby="'tournament-stage-ranking-tab-' + stageId">
        <TournamentStageRanking
          :stageId="stageId" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRef } from 'vue'
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useTeamEventRoundStore } from "@/stores/TeamEventRoundStore"
import TournamentStageParticipantsGroupedBySignList from '@/components/TournamentManagement/TournamentStageParticipantsGroupedBySignList';
import TournamentStageTeamEvent from '@/components/TournamentManagement/TournamentStageTeamEvent';
import TournamentStageEvent from '@/components/TournamentManagement/TournamentStageEvent';
import TournamentStageSystem from '@/components/TournamentManagement/TournamentStageSystem';
import TournamentStageRanking from '@/components/TournamentManagement/TournamentStageRanking';
import TournamentResetStage from '@/components/TournamentManagement/TournamentResetStage';
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore";
import { useTeamEventStore } from "@/stores/TeamEventStore";
import TournamentStageSchedule from "@/components/TournamentManagement/TournamentStageSchedule.vue";
import TournamentStageSubstitute from "@/components/TournamentManagement/TournamentStageSubstitute.vue";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import { useTournamentStageRankingStore } from '@/stores/TournamentStageRankingStore';

export default {
  name: 'TournamentStage',
  components: {
    ButtonAdd,
    TournamentStageSchedule, TournamentStageParticipantsGroupedBySignList, TournamentStageTeamEvent,
    TournamentStageEvent, TournamentStageSystem, TournamentStageRanking, TournamentResetStage,
    TournamentStageSubstitute
  },
  props: {
    stageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const eventStageParameterStore = useEventStageParameterStore(),
      teamEventStore = useTeamEventStore(),
      teamEventRoundStore = useTeamEventRoundStore(),
      tournamentStageRankingStore = useTournamentStageRankingStore(),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      currentStageId = toRef(props, 'stageId'),
      isAddFormActive = ref(false),
      createAllStages = ref(false),
      dateTimeStart = ref(getDateTimeStart())

    function createStage(id) {
      fetch(globalStore.apiUrl + '/admin/tournament-management/stage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tournamentId: globalStore.tournamentStore.tournament.id, stageId: id, dateTimeStart: dateTimeStart.value, createAllStages: createAllStages.value })
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.eventStore.addData(response.data.events)
            teamEventStore.addData(response.data.teamEvents)
            globalStore.tournamentParticipantStageStore.mergeRows(response.data.tournamentParticipantStage)
            if (response.data.tournamentParticipant && response.data.tournamentParticipant.length) {
              globalStore.tournamentParticipantStore.setData(response.data.tournamentParticipant)
            }
            if (response.data.tournamentStageRanking !== undefined) {
              tournamentStageRankingStore.setData(response.data.tournamentStageRanking)
            }
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => console.error(err))
    }

    function deleteStage(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        fetch(globalStore.apiUrl + '/admin/tournament-management/stage', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ tournamentId: globalStore.tournamentStore.tournament.id, stageId: id })
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.eventStore.deleteDataByStage(id)
              teamEventStore.deleteDataByStage(id)
              globalStore.tournamentParticipantStageStore.deleteDataByStage(id)
              if (response.data) {
                if (response.data.tournamentParticipantStage !== undefined) {
                  globalStore.tournamentParticipantStageStore.mergeRows(response.data.tournamentParticipantStage)
                }
                if (response.data.tournamentParticipant !== undefined) {
                  globalStore.tournamentParticipantStore.setData(response.data.tournamentParticipant)
                }
              }
              notificationStore.add({
                type: 'success',
                message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId)
              })
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({
                type: 'error',
                message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
              })
            }
          })
          .catch((err) => console.error(err))
      }
    }

    function getDateTimeStart() {
      return globalStore.tournamentStore.tournament.dateStart
    }

    function isStageDeletable(stageId) {
      return (globalStore.eventStore.hasStageEvents(stageId) && globalStore.eventStore.hasStageOnlyBlankEvents(stageId))
    }

    function isStageResetable(stageId) {
      let nextStageId = getNextNonFinalStageIdByStageId(stageId),
        hasStageEvents = globalStore.tournamentStore.isTournamentForTeams ? teamEventStore.hasStageTeamEvents(stageId) : globalStore.eventStore.hasStageEvents(stageId)
      return (!globalStore.eventStore.hasStageEvents(nextStageId) && hasStageEvents)
    }

    function getPreviousNonFinalStageIdByStageId(stageId) {
      const previousStageId = globalStore.tournamentStageStore.getPreviousStageIdByStageId(stageId)

      if (globalStore.stageStore.isStageFinal(previousStageId)) {
        return getPreviousNonFinalStageIdByStageId(previousStageId)
      } else {
        return previousStageId
      }
    }

    function getNextNonFinalStageIdByStageId(stageId) {
      const nextStageId = globalStore.tournamentStageStore.getNextStageIdByStageId(stageId)

      if (globalStore.stageStore.isStageFinal(nextStageId)) {
        return getNextNonFinalStageIdByStageId(nextStageId)
      } else {
        return nextStageId
      }
    }

    function isStageAddable(stageId) {
      let previousStageId = getPreviousNonFinalStageIdByStageId(stageId),
        nextStageId = getNextNonFinalStageIdByStageId(stageId)

      if (globalStore.tournamentStore.isTournamentForTeams) {
        return isStageFinished(previousStageId) &&
          !teamEventStore.hasStageTeamEvents(stageId) &&
          !isStageCreated(nextStageId)
      }

      return (isStageFinished(previousStageId) || !globalStore.applyStageParticipantMinCountLimit(previousStageId)) &&
        !globalStore.eventStore.hasStageEvents(stageId) &&
        (!isStageCreated(nextStageId) || globalStore.stageStore.isStageFinal(stageId)) &&
        globalStore.applyStageParticipantMinCountLimit(stageId)
    }

    function isStageFinished(stageId) {
      return !stageId || globalStore.eventStore.hasStageOnlyWrittenEvents(stageId)
    }

    function isStageCreated(stageId) {
      return stageId && globalStore.eventStore.hasStageEvents(stageId)
    }

    function areStageParamsComplete(stageId) {
      if (globalStore.tournamentStore.isTournamentForTeams) {
        return teamEventRoundStore.hasStageParameters(stageId)
      }
      const eventStage = stageId ? globalStore.tournamentStageStore.getTournamentStageByStageId(stageId) : null
      return eventStage && eventStageParameterStore.hasStageParameters(eventStage.id)
    }

    function initAddForm(allStages) {
      isAddFormActive.value = true
      createAllStages.value = !!allStages
    }

    return {
      globalStore, teamEventStore, dateTimeStart, isAddFormActive,
      currentStageId, createAllStages,
      createStage, deleteStage, isStageDeletable, isStageAddable, isStageFinished, isStageResetable, areStageParamsComplete,
      initAddForm
    }
  }
}
</script>
