<template>
  <span class="video-icon">
    <img data-bs-toggle="modal" :data-bs-target="'#liveStream' + eventId" :src="require('@/assets/images/live.svg')" alt="" class="icon-abacus"/>
    <span v-if="!liveStreamStore.hasEventLiveStream(eventId)" class="position-absolute top-0 start-70 translate-middle badge rounded-pill bg-primary">+</span>
  </span>
  <div class="modal fade" :id="'liveStream' + eventId" tabindex="-1" :aria-labelledby="'liveStreamLabel' + eventId" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="'liveStreamLabel' + eventId">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('livestream', globalStore.languageId) }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <template v-if="!liveStreamStore.hasEventLiveStream(eventId)">
            <ButtonCreateLivestream v-if="isFutureEvent()" :clickFunction="openLiveStream" />
            <button v-else type="button" class="btn btn-primary btn-sm position-relative" disabled="disabled">
              {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create youtube livestream', globalStore.languageId) }}
              <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                {{ globalStore.vocabularyStore.getWordByIdAndLanguage('event must be in future', globalStore.languageId) }}
              </span>
            </button>
            <br />
            <p><br />{{ globalStore.vocabularyStore.getWordByIdAndLanguage('insert livestream', globalStore.languageId) }}</p>
            <FormKit type="form" v-model="itemData" @submit="processAddForm()">
              <FormKit type="url" name="url" v-model="itemData.url" validation="required"/>
            </FormKit>
          </template>
          <template v-else>
            <template v-if="!updateFormActive">
              <p class="alert alert-success">
                {{ globalStore.vocabularyStore.getWordByIdAndLanguage('stream successfully created', globalStore.languageId) }}
              </p>
              <button @click="globalStore.openLinkInNewWindow(liveStreamStore.liveStreams[eventId].url)" class="btn btn-outline-primary btn-sm">
                {{ liveStreamStore.liveStreams[eventId].url }}
              </button>
              <br /><br />
              <ButtonUpdate :click-function="initUpdateForm"/>
              <span>&#160;</span>
              <ButtonDelete :click-function="deleteItem"/>
              <br />
              <br />
              <h6>{{ globalStore.vocabularyStore.getWordByIdAndLanguage('stream admin', globalStore.languageId) }}</h6>
              <button @click="globalStore.openLinkInNewWindow(getStreamAdminUrl(liveStreamStore.liveStreams[eventId].url))" class="btn btn-outline-primary btn-sm">
                {{ getStreamAdminUrl(liveStreamStore.liveStreams[eventId].url) }}
              </button>
            </template>
            <template v-else>
              <FormKit type="form" @submit="processUpdateForm()">
                <FormKit type="url" name="url" v-model="itemData.url" validation="required"/>
              </FormKit>
            </template>
          </template>
        </div>
        <div class="modal-footer">
          <ButtonBack v-if="updateFormActive" :click-function="deInitUpdateForm"/>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('close', globalStore.languageId) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {toRef, ref} from "vue"
import {useGlobalStore} from "@/stores/GlobalStore"
import {useNotificationStore} from "@/stores/NotificationStore"
import {useLiveStreamStore} from "@/stores/LiveStreamStore";
import ButtonCreateLivestream from "@/components/Button/ButtonCreateLivestream.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";
import ButtonBack from "@/components/Button/ButtonBack.vue";

export default {
  name: 'LiveStreamModal',
  components: {ButtonBack, ButtonDelete, ButtonUpdate, ButtonCreateLivestream},
  props: {
    eventObjectId: {
      required: true,
      type: Number
    },
    eventObjectDateStart: {
      required: true,
      type: String
    }
  },

  setup(props) {
    const eventId = toRef(props, 'eventObjectId'),
        eventDateStart = toRef(props, 'eventObjectDateStart'),
        globalStore = useGlobalStore(),
        liveStreamStore = useLiveStreamStore(),
        notificationStore = useNotificationStore(),
        itemData = ref({}),
        updateFormActive = ref(false)

    function processAddForm() {
      itemData.value.eventId = eventId.value
      fetch(globalStore.apiUrl + '/admin/event-live-stream', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            liveStreamStore.addDataRow(response.data)
            notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId)})
          } else if (response.error) {
            notificationStore.add({type: 'error', message: response.error.description})
          } else {
            notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
          }
        })
        .catch((err) => console.error(err))
    }

    function initUpdateForm() {
      const item = liveStreamStore.liveStreams[eventId.value]
      if (!item) {
        return
      }
      itemData.value = {}
      itemData.value.id = item.id
      itemData.value.eventId = item.eventId
      itemData.value.url = item.url

      updateFormActive.value = true
    }

    function processUpdateForm() {
      const item = itemData.value
      if (item) {
        fetch(globalStore.apiUrl + '/admin/event-live-stream', {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              liveStreamStore.actualizeDataRow(eventId.value, response.data)
              notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)})
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({type: 'error', message: response.error.description})
            } else {
              notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
            }
          })
          .catch(err =>notificationStore.add({type: 'error', message: err.message}))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = liveStreamStore.liveStreams[eventId.value]
        if (!item) {
          return
        }
        fetch(globalStore.apiUrl + '/admin/event-live-stream', {
          method: 'DELETE',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              liveStreamStore.removeDataRow(eventId.value)
              notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId)})
            } else if (response.error) {
              notificationStore.add({type: 'error', message: response.error.description})
            } else {
              notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
            }
          })
          .catch(err => notificationStore.add({type: 'error', message: err.message}))
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      itemData.value = {}
    }

    function openLiveStream() {
      globalStore.openLinkInNewWindow(globalStore.lstrUrl + eventId.value + '&date_start_time=' + eventDateStart.value.replace(' ', 'T') + '&apiKeyId=1')
    }

    function isFutureEvent() {
      return (new Date()).getTime() < (new Date(eventDateStart.value)).getTime()
    }

    function getStreamAdminUrl(url) {
      const hash = url.split('=')[1]
      return 'https://studio.youtube.com/video/' + hash + '/livestreaming'
    }

    return {
      eventId, globalStore, notificationStore, liveStreamStore, itemData, updateFormActive,
      processAddForm, initUpdateForm, processUpdateForm, deleteItem, deInitUpdateForm, openLiveStream, isFutureEvent, getStreamAdminUrl
    }
  }
}
</script>

<style scoped>
span.video-icon {
  position: relative;
  display: inline-block;
}
span.video-icon span.badge {
  padding: .15em .45em;
  right: -.5em;
  margin-top: .35em;
}
</style>