<template>
  <div class="table-responsive">
    <template v-if="globalStore.userAdminStore.propertiesLoaded">
      <table class="table table-striped" v-if="organizationSection">
        <tbody>
          <SectionRegionItem
            :organizationIdSet="organizationSection.organizationId"
            :sectionIdSet="organizationSection.sectionId"
            :organizationSectionObject="organizationSection" />
          <template v-if="globalStore.sectionHasRegionStore.getByOrganizationIdSectionId(organizationSection.organizationId, organizationSection.sectionId).length">
            <template v-for="row in globalStore.sectionHasRegionStore.getByOrganizationIdSectionId(organizationSection.organizationId, organizationSection.sectionId)" :key="row.id">
              <SectionRegionItem :sectionRegionObject="row"
                :organizationSectionObject="organizationSection" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef } from "vue"
import SectionRegionItem from "@/components/OrganizationManagement/SectionManagement/SectionRegionItem"

export default {
  name: 'SectionRegionList',
  components: { SectionRegionItem },
  props: {
    organizationSectionIdSet: {
      required: true,
      type: Number
    },
  },

  setup(props) {
    const organizationSectionId = toRef(props, 'organizationSectionIdSet'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      organizationSection = globalStore.organizationHasSectionStore.getById(organizationSectionId.value)

    onMounted(() => {
      loadData()
    })

    function loadData() {
      const url = globalStore.apiUrl + '/admin/organization-section-regions/' + organizationSectionId.value
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.sectionHasRegionStore.setData(data.data.sectionHasRegion)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, organizationSection
    }
  }
}
</script>
