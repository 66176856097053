<template>
  <ParticipantMerge />
</template>

<script>
import ParticipantMerge from "@/components/ParticipantMerge";
export default {
  components: { ParticipantMerge }
}
</script>
