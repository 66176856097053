<template>
  <tr>
    <template v-if="addFormActive === true">
      <td>
        <button @click="deInitAddForm()" class="btn btn-outline-success btn-sm">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('back', globalStore.languageId) }}
        </button>
      </td>
      <td :colspan="globalStore.tournamentStore.tournament.competitionId ? 4 : 3">
        <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
          <FormKitSchema :schema="schema"/>
        </FormKit>
      </td>
    </template>
    <template v-else-if="globalStore.hasUserAddRule">
      <td :colspan="globalStore.tournamentStore.tournament.competitionId ? 5 : 4">
        <button @click="initAddForm()" class="btn btn-outline-success btn-sm">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('add new item', globalStore.languageId) }}
        </button>
      </td>
    </template>
  </tr>
  <template v-if="tournamentParticipantId && !stageId">
    <template v-for="(stage, stageId) in globalStore.tournamentParticipantStageStore.tournamentParticipantStages" :key="stage.id">
      <template v-for="(stageSignData, stageSign) in stage" :key="stageSign">
        <template v-for="(tournamentParticipantData, key, index) in stageSignData" :key="index">
          <template v-if="tournamentParticipantId === tournamentParticipantData.tournamentParticipantId || tournamentParticipantId === tournamentParticipantData.tournamentTeamId">
            <template v-for="(rows, stageSign, index) in globalStore.tournamentParticipantStageStore.getTournamentParticipantsByStageId(stageId)" :key="index">
              <template v-for="row in rows" :key="row.id">
                <tr>
                  <template v-if="updateFormActive[row.id]">
                    <td>
                      <button @click="deInitUpdateForm(row.id)" class="btn btn-outline-success btn-sm">
                        {{ globalStore.vocabularyStore.getWordByIdAndLanguage('back', globalStore.languageId) }}
                      </button>
                    </td>
                    <td :colspan="globalStore.tournamentStore.tournament.competitionId ? 4 : 3">
                      <FormKit type="form" v-model="itemData[row.id]" @submit="processUpdateForm(row.id)">
                        <FormKitSchema :schema="schema"/>
                      </FormKit>
                    </td>
                  </template>
                  <template v-else>
                    <template v-if="row.tournamentParticipantId === tournamentParticipantId || row.tournamentTeamId === tournamentParticipantId">
                      <td>{{ globalStore.getStageNameById(stageId) }} {{ stageSign }}<template v-if="row.setting"> ({{ row.setting }})</template></td>
                      <td :colspan="globalStore.tournamentStore.tournament.competitionId ? 4 : 3">
                        <template v-if="!globalStore.eventStore.hasStageEvents(stageId)">
                          <button @click="initUpdateForm(row.id)" class="btn btn-outline-primary btn-sm">
                            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('update', globalStore.languageId) }}
                          </button>
                          <span>&#160;</span>
                          <button @click="deleteItem(row.id)" class="btn btn-outline-danger btn-sm">
                            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('delete', globalStore.languageId) }}
                          </button>
                        </template>
                      </td>
                    </template>
                  </template>
                </tr>
              </template>
            </template>
          </template>
        </template>
      </template>
    </template>
  </template>
  <template v-else>
    <template v-for="(rows, stageSign, index) in globalStore.tournamentParticipantStageStore.getTournamentParticipantsByStageId(stageId)" :key="index">
      <template v-for="row in rows" :key="row.id">
        <tr>
          <template v-if="updateFormActive[row.id]">
            <td>
              <button @click="deInitUpdateForm(row.id)" class="btn btn-outline-success btn-sm">
                {{ globalStore.vocabularyStore.getWordByIdAndLanguage('back', globalStore.languageId) }}
              </button>
            </td>
            <td :colspan="globalStore.tournamentStore.tournament.competitionId ? 4 : 3">
              <FormKit type="form" v-model="itemData[row.id]" @submit="processUpdateForm()">
                <FormKitSchema :schema="schema"/>
              </FormKit>
            </td>
          </template>
          <template v-else>
            <template v-if="row.tournamentParticipantId === tournamentParticipantId || row.tournamentTeamId === tournamentParticipantId">
              <td>{{ globalStore.getStageNameById(stageId) }} {{ stageSign }}<template v-if="row.setting"> ({{ row.setting }})</template></td>
              <td :colspan="globalStore.tournamentStore.tournament.competitionId ? 4 : 3">
                <button @click="initUpdateForm(row.id)" class="btn btn-outline-primary btn-sm">
                  {{ globalStore.vocabularyStore.getWordByIdAndLanguage('update', globalStore.languageId) }}
                </button>
                <span>&#160;</span>
                <button @click="deleteItem(row.id)" class="btn btn-outline-danger btn-sm">
                  {{ globalStore.vocabularyStore.getWordByIdAndLanguage('delete', globalStore.languageId) }}
                </button>
              </td>
            </template>
          </template>
        </tr>
      </template>
    </template>
  </template>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import {ref, toRef, computed} from "vue";

export default {
  name: 'TournamentStageParticipant',
  components: {},
  props: {
    tournamentParticipantIdx: {
      required: true,
      type: Number
    },
    tournamentStageIdx: {
      required: false,
      type: Number
    }
  },

  setup(props) {
    const stageId = toRef(props, 'stageIdx'),
      tournamentParticipantId = toRef(props, 'tournamentParticipantIdx'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref({}),
      schema = ref([]),
      itemId = ref(''),
      groupOptions = ['', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      groupAvailableOptions = computed(() => {
        const stageId = +itemData.value[itemId.value].stageId,
          eventStage = globalStore.tournamentStageStore.getTournamentStageByStageId(stageId),
          options = []
        if (eventStage) {
          const groupParticipantCount = +eventStage.participantGroupPreferredCount,
            stageParticipantMaxCount = +eventStage.participantMaxCount,
            tournamentParticipantCount = globalStore.tournamentParticipantStore.count
          let stageParticipantsCount = tournamentParticipantCount > stageParticipantMaxCount ? stageParticipantMaxCount : tournamentParticipantCount

          if (stageParticipantsCount > 0 && groupParticipantCount > 0) {
            let groupCount = Math.floor(stageParticipantsCount / groupParticipantCount)
            if (groupCount > 1) {
              groupCount++
            }
            for (let i = 0; i < groupCount; i++) {
              options.push(groupOptions[i])
            }
          }
        }
        return options
      })

    function setSchema() {
      schema.value = []

      if (!tournamentParticipantId.value || addFormActive.value === true) {
        const stageOptions = {},
          participantHasStage = {}

        for (let stageId in globalStore.tournamentParticipantStageStore.tournamentParticipantStages) {
          const tournamentParticipantStageArray = globalStore.tournamentParticipantStageStore.tournamentParticipantStages[stageId]
          for (let stageSign in tournamentParticipantStageArray) {
            for (let i in tournamentParticipantStageArray[stageSign]) {
              if (tournamentParticipantStageArray[stageSign][i].tournamentParticipantId && tournamentParticipantStageArray[stageSign][i].tournamentParticipantId === tournamentParticipantId.value) {
                participantHasStage[stageId] = stageId
              } else if (tournamentParticipantStageArray[stageSign][i].tournamentTeamId && tournamentParticipantStageArray[stageSign][i].tournamentTeamId === tournamentParticipantId.value) {
                participantHasStage[stageId] = stageId
              }
            }
          }
        }

        for (let i in globalStore.tournamentStageStore.tournamentStages) {
          const tournamentStage = globalStore.tournamentStageStore.tournamentStages[i]
          if (globalStore.applyStageParticipantMinCountLimit(tournamentStage.stageId)) {
            if (!participantHasStage[tournamentStage.stageId]) {
              stageOptions[tournamentStage.stageId] = globalStore.getStageNameById(tournamentStage.stageId, 1)
            }
          }
        }

        schema.value.push({
          $formkit: 'select',
          name: 'stageId',
          label: globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage', globalStore.languageId),
          options: stageOptions,
          validation: 'required'
        })
      }

      schema.value.push({
        $formkit: 'select',
        name: 'stageSign',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage sign', globalStore.languageId),
        options: groupAvailableOptions,
      })

      schema.value.push({
        $formkit: 'number',
        name: 'setting',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage setting', globalStore.languageId)
      })
    }

    function getItem(id) {
      for (let stageId in globalStore.tournamentParticipantStageStore.tournamentParticipantStages) {
        const stageParticipants = globalStore.tournamentParticipantStageStore.tournamentParticipantStages[stageId]
        for (let stageSign in stageParticipants) {
          const stageSignParticipants = stageParticipants[stageSign]
          for (let i in stageSignParticipants) {
            if (stageSignParticipants[i].id === id) {
              return stageSignParticipants[i]
            }
          }
        }
      }
      return {}
    }

    function initAddForm() {
      itemData.value[''] = {}
      addFormActive.value = true
      setSchema()
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    function initUpdateForm(id) {
      const item = getItem(id)
      if (!item) {
        return
      }
      itemId.value = id

      itemData.value[id] = {
        id: item.id,
        tournamentParticipantId: item.tournamentParticipantId,
        stageId: item.stageId,
        stageSign: item.stageSign,
        setting: item.setting
      }
      setSchema()
      updateFormActive.value[id] = true
    }

    function deInitUpdateForm(id) {
      updateFormActive.value[id] = false
      if (itemData.value[id]) {
        delete itemData.value[id]
      }
      itemId.value = ''
    }

    function processAddForm() {
      itemData.value[''].tournamentParticipantId = tournamentParticipantId.value
      itemData.value[''].fixed = 1
      itemData.value[''].tournamentId = globalStore.tournamentStore.tournament.id
      itemData.value[''].stageSign = itemData.value[''].stageSign || ''
      fetch(globalStore.apiUrl + '/admin/tournament-participant-stage', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.tournamentParticipantStageStore.addRow(response.data)
            notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId)})
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({type: 'error', message: response.error.description})
          } else {
            notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
          }
        })
        .catch(err => notificationStore.add({type: 'error', message: err.message}))
    }

    function processUpdateForm(id) {
      const item = getItem(id)
      if (item) {
        itemData.value[id].id = item.id
        itemData.value[id].participantId = item.participantId
        itemData.value[id].fixed = 1
        itemData.value[id].tournamentId = globalStore.tournamentStore.tournament.id
        itemData.value[id].stageSign = itemData.value[id].stageSign || ''
        fetch(globalStore.apiUrl + '/admin/tournament-participant-stage', {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(itemData.value[id])
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.tournamentParticipantStageStore.updateRow(response.data)
              notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)})
              deInitUpdateForm(id)
            } else if (response.error) {
              notificationStore.add({type: 'error', message: response.error.description})
            } else {
              notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
            }
          })
          .catch((err) => notificationStore.add({type: 'error', message: err.message}))
      }
    }

    function deleteItem(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(id)
        item.tournamentId = globalStore.tournamentStore.tournament.id
        if (item) {
          fetch(globalStore.apiUrl + '/admin/tournament-participant-stage', {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                globalStore.tournamentParticipantStageStore.deleteRow(item)
                notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId)})
              } else {
                notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
              }
            })
            .catch(err => notificationStore.add({type: 'error', message: err.message}))
        }
      }
    }

    return {
      globalStore, addFormActive, updateFormActive, itemData, schema, stageId,
      tournamentParticipantId, groupAvailableOptions,
      initAddForm, deInitAddForm, initUpdateForm, deInitUpdateForm, processAddForm, processUpdateForm, deleteItem, getItem
    }
  }
}
</script>
