import { defineStore } from 'pinia'
import { useTournamentParticipantStagePredictionStore } from "@/stores/TournamentParticipantStagePredictionStore"

export const useTournamentParticipantStageStore = defineStore('TournamentParticipantStageStore', {
  state: () => {
    return {
      tournamentParticipantStages: {},
      tournamentParticipantStagePredictionStore: useTournamentParticipantStagePredictionStore(),
    }
  },
  getters: {
    count: state => Object.keys(state.tournamentParticipantStages).length,
    getTournamentParticipantsByStageId: state => {
      return id => state.tournamentParticipantStages[id] ?
        Object.keys(state.tournamentParticipantStages[id])
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = state.tournamentParticipantStages[id][key];
            return accumulator
          }, {}
          ) : {}
    },
    getTournamentParticipantsCountByStageId: state => {
      return id => {
        let count = 0
        for (let sign in state.tournamentParticipantStages[id]) {
          count = count + Object.keys(state.tournamentParticipantStages[id][sign]).length
        }
        return count
      }
    },
    getTournamentParticipantsByStageIdAndStageSignId: state => {
      return (id, sign) => state.tournamentParticipantStages[id]
        ? state.tournamentParticipantStages[id][sign] ? state.tournamentParticipantStages[id][sign] : {}
        : {}
    },
    getTournamentParticipantsStageSignsByStageId: state => {
      return id => {
        const data = {}
        for (let sign in state.tournamentParticipantStages[id]) {
          for (let tournamentParticipant in state.tournamentParticipantStages[id][sign]) {
            data[state.tournamentParticipantStages[id][sign][tournamentParticipant].tournamentParticipantId] = sign
          }
        }
        return data
      }
    },
    hasTournamentParticipantsAnyStage: state => {
      return id => {
        for (let i in state.tournamentParticipantStages) {
          for (let j in state.tournamentParticipantStages[i]) {
            for (let k in state.tournamentParticipantStages[i][j]) {
              if (state.tournamentParticipantStages[i][j][k].tournamentParticipantId && state.tournamentParticipantStages[i][j][k].tournamentParticipantId === id) {
                return true
              } else if (state.tournamentParticipantStages[i][j][k].tournamentTeamId && state.tournamentParticipantStages[i][j][k].tournamentTeamId === id) {
                return true
              }
            }
          }
        }
        return false
      }
    },
    getFirstApplyableStage: () => {
      return globalStore => {
        const participantCount = globalStore.tournamentParticipantStore.count
        for (let i in globalStore.tournamentStageStore.tournamentStages) {
          if (globalStore.tournamentStageStore.tournamentStages[i].tournamentParticipantMinCount <= participantCount) {
            return globalStore.tournamentStageStore.tournamentStages[i]
          }
        }
        return null
      }
    },
    getStageParticipantsWithPrediction: state => {
      return (stageId, globalStore) => {
        const stageSignParticipantGroups = state.getTournamentParticipantsByStageId(stageId) || {},
          stage = globalStore.tournamentStageStore.getTournamentStageByStageId(stageId),
          stageParticipantsCount = state.getTournamentParticipantsCountByStageId(stageId),
          missingParticipantCount = stage.participantMaxCount - stageParticipantsCount,
          tournamentParticipantsCount = globalStore.tournamentParticipantStore.count,
          previousTournamentStage = globalStore.tournamentStageStore.getPreviousValidNonFinalTournamentStage(stageId, tournamentParticipantsCount, globalStore)

        if (missingParticipantCount > 0 && previousTournamentStage) {
          const previousStageId = previousTournamentStage.stageId,
            participantGroupPreferredCount = stage.participantGroupPreferredCount

          if (Object.keys(stageSignParticipantGroups).length) {
            for (let stageSign in stageSignParticipantGroups) {
              for (let setting = 1; setting <= participantGroupPreferredCount; setting++) {
                let alreadySet = false
                for (let i in stageSignParticipantGroups[stageSign]) {
                  if (stageSignParticipantGroups[stageSign][i].setting == setting) {
                    alreadySet = true
                  }
                }
                if (!alreadySet) {
                  const prediction = state.tournamentParticipantStagePredictionStore.getStageParticipantStageSignPrediction(stageId, stageSign, setting)
                  if (prediction) {
                    stageSignParticipantGroups[stageSign].push({
                      prediction: {
                        name: globalStore.getStageShortNameById(previousStageId) + ' (' + prediction.predictionSetting + ')',
                        setting: setting
                      }
                    })
                  }
                }
              }
            }
          } else if (stage.stageSystemTypeId !== 1) {
            // Only prediction
            const stagePredictions = state.tournamentParticipantStagePredictionStore.tournamentParticipantStagePredictions[stageId]

            if (stagePredictions) {
              for (let stageSign in stagePredictions) {
                stageSignParticipantGroups[stageSign] = []
                for (let i in stagePredictions[stageSign]) {
                  const prediction = stagePredictions[stageSign][i]
                  stageSignParticipantGroups[stageSign].push({
                    prediction: {
                      name: globalStore.getStageShortNameById(previousStageId) + ' (' + prediction.predictionSetting + ')',
                      setting: prediction.predictionSetting
                    }
                  })
                }
              }
            }
          }
        }

        return stageSignParticipantGroups
      }
    }
  },
  actions: {
    setData(data) {
      for (let i in data) {
        const row = data[i],
          stageId = row.stageId,
          stageSign = row.stageSign ? row.stageSign : ''
        if (this.tournamentParticipantStages[stageId] === undefined) {
          this.tournamentParticipantStages[stageId] = {}
        }
        if (this.tournamentParticipantStages[stageId][stageSign] === undefined) {
          this.tournamentParticipantStages[stageId][stageSign] = []
        }
        this.tournamentParticipantStages[stageId][stageSign].push(row)
      }
    },
    updateRow(data) {
      let updated = false
      const stageSign = data.stageSign ? data.stageSign : '',
        stageId = data.stageId
      if (this.tournamentParticipantStages[stageId] === undefined) {
        this.tournamentParticipantStages[stageId] = {}
      }
      if (this.tournamentParticipantStages[stageId][stageSign] === undefined) {
        this.tournamentParticipantStages[stageId][stageSign] = []
      }
      for (let i in this.tournamentParticipantStages[stageId][stageSign]) {
        if (this.tournamentParticipantStages[stageId][stageSign][i].id === data.id) {
          this.tournamentParticipantStages[stageId][stageSign][i] = data
          updated = true
        }
      }
      if (updated === false) {
        for (let i in this.tournamentParticipantStages[stageId]['']) {
          if (this.tournamentParticipantStages[stageId][''][i].id === data.id) {
            this.tournamentParticipantStages[stageId][''][i] = data
            updated = true
          }
        }
      }
    },
    deleteDataByStage(id) {
      this.tournamentParticipantStages[id] = {}
    },
    deleteRow(data) {
      const stageSign = data.stageSign ? data.stageSign : '',
        stageId = data.stageId
      this.tournamentParticipantStages[stageId][stageSign] = this.tournamentParticipantStages[stageId][stageSign].filter(
        row => row.id !== data.id
      )
    },
    mergeRows(data) {
      let sign = null,
        sId = null
      for (let i in data) {
        const tournamentParticipantStage = data[i],
          stageId = tournamentParticipantStage.stageId,
          stageSign = tournamentParticipantStage.stageSign || '',
          tournamentParticipantId = tournamentParticipantStage.tournamentParticipantId ? tournamentParticipantStage.tournamentParticipantId : tournamentParticipantStage.tournamentTeamId,
          newRowId = data.id,
          newRowSetting = data.setting
        sign = stageSign
        sId = stageId
        if (!this.tournamentParticipantStages[stageId]) {
          this.tournamentParticipantStages[stageId] = {}
        }
        if (!this.tournamentParticipantStages[stageId][stageSign]) {
          this.tournamentParticipantStages[stageId][stageSign] = []
        }
        this.tournamentParticipantStages[stageId][stageSign] = this.tournamentParticipantStages[stageId][stageSign].filter(
          row => !(
            row.stageId === stageId &&
            (
              row.tournamentParticipantId && row.tournamentParticipantId === tournamentParticipantId ||
              row.tournamentTeamId && row.tournamentTeamId === tournamentParticipantId
            )
          )
        )
        this.tournamentParticipantStages[stageId][stageSign].push(tournamentParticipantStage)
        // Cleanup from predicted rows
        for (let i in this.tournamentParticipantStages[stageId][stageSign]) {
          if (
            (!this.tournamentParticipantStages[stageId][stageSign][i].id || this.tournamentParticipantStages[stageId][stageSign][i].id !== newRowId) &&
            this.tournamentParticipantStages[stageId][stageSign][i].setting === newRowSetting
          ) {
            this.tournamentParticipantStages[stageId][stageSign][i].fake = 1
          }
        }
      }
      if (sign === '') {
        for (let i in this.tournamentParticipantStages[sId]) {
          if (i !== sign) {
            delete this.tournamentParticipantStages[sId][i]
          }
        }
      }
    },
    addRows(data) {
      this.setData(data)
    },
    addRow(data) {
      this.setData([data])
    },
    updateRows(data) {
      for (let i in data) {
        this.updateRow(data[i])
      }
    }
  }
})
