<template>
  <nav :class="'navbar sticky-top navbar-expand-lg navbar-dark ' + (globalStore.environment.includes('PROD') ? 'bg-primary' : 'bg-info')">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img :src="require('@/assets/images/logo.svg')" alt="" /> Admin ({{ globalStore.getAdminUserRoleName() }})
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse text-right" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <template v-if="globalStore.userAdminStore.isUserSuperUser">
            <MenuSuper />
          </template>
          <template v-else-if="globalStore.userAdminStore.isUserSuperUser || globalStore.userAdminStore.isUserRegistry">
            <MenuRegistry />
          </template>
          <template v-else-if="globalStore.userAdminStore.isUserSuperUser || globalStore.userAdminStore.isUserRegistry || globalStore.userAdminStore.isUserRegion">
            <MenuSTK />
          </template>
          <template v-else-if="globalStore.userAdminStore.isUserSuperUser || globalStore.userAdminStore.isUserRegistry || globalStore.userAdminStore.isUserRegion || globalStore.userAdminStore.isUserClub">
            <MenuClub />
          </template>
          <li>
            <LogoutAdmin />
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <NotificationContainer />
  <router-view :key="$route.fullPath" v-if="userDataLoaded" />
  <LoginAdminModal v-if="!globalStore.userAdminStore.loggedUser || !globalStore.userAdminStore.loggedUser.id" />
</template>

<script>
import { onMounted, ref } from 'vue'
import NotificationContainer from "@/components/NotificationContainer"
import { useGlobalStore } from "@/stores/GlobalStore"
import { useLiveScoreStore } from "@/stores/LiveScoreStore"
import { useLiveStreamStore } from "@/stores/LiveStreamStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import LogoutAdmin from "@/components/Login/LogoutAdmin"
import LoginAdminModal from "@/components/Modal/LoginAdminModal"
import MenuSTK from "./components/Menu/MenuSTK"
import MenuSuper from "@/components/Menu/MenuSuper"
import MenuRegistry from "./components/Menu/MenuRegistry"
import MenuClub from "./components/Menu/MenuClub"

export default {
  components: {
    NotificationContainer,
    LogoutAdmin,
    LoginAdminModal,
    MenuSuper,
    MenuRegistry,
    MenuSTK,
    MenuClub
  },
  setup() {
    const globalStore = useGlobalStore(),
      liveScoreStore = useLiveScoreStore(),
      liveStreamStore = useLiveStreamStore(),
      notificationStore = useNotificationStore(),
      userDataLoaded = ref(false)
    let ws = new WebSocket(globalStore.wsUrl)

    onMounted(async () => {
      globalStore.userAdminStore.setLoggedUser()
      globalStore.vocabularyStore.setStaticData()
      await globalStore.checkUserAdminParams()
      userDataLoaded.value = true
    })

    function initWs() {
      ws.onmessage = function (message) {
        if (!message.data) {
          return
        }
        const messageObject = JSON.parse(message.data),
          eventId = messageObject && messageObject.id ? globalStore.eventStore.getEventIdByHash(messageObject.id) : null
        if (eventId) {
          const data = messageObject.data ? (typeof messageObject.data === "object" ? messageObject.data : JSON.parse(messageObject.data)) : null

          if (!data) {
            return
          }
          if (data.updateType && data.updateType === 'timeForStroke') {
            return
          }
          const liveScoreExists = liveScoreStore.hasEventLiveScore(eventId)
          let isSavedBefore = false

          if (liveScoreExists) {
            isSavedBefore = liveScoreStore.isEventSaved(eventId)
            liveScoreStore.actualizeLiveRow(eventId, data)
          } else {
            liveScoreStore.addDataRow({ eventId: eventId, detail: data })
          }
          const isSavedAfter = liveScoreStore.isEventSaved(eventId)

          globalStore.eventStore.actualizeEventGameData(eventId, data, isSavedBefore !== isSavedAfter)
          if (isSavedBefore !== isSavedAfter) {
            notificationStore.add({
              type: 'success',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)
            })
          }
        }
      }
      ws.onclose = function () {
      };
      ws.onerror = function () {
        ws.close();
      };
    }

    initWs()

    setInterval(() => {
      globalStore.userAdminStore.setLoggedUser()
      if (ws.readyState === WebSocket.CLOSED) {
        ws = new WebSocket(globalStore.wsUrl)
        initWs()
      }
    }, 2e3);

    window.addEventListener('message', message => {
      if (message.data && message.data.type && message.data.url) {
        if (message.data.type === 'liveStreamUrl') {
          insertLiveStreamUrl(message.data)
        }
      }
    }, false);

    function insertLiveStreamUrl(data) {
      const item = {
        eventId: +data.eventId,
        url: data.url
      }
      fetch(globalStore.apiUrl + '/admin/event-live-stream', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            liveStreamStore.addDataRow(response.data)
            notificationStore.add({
              type: 'success',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId)
            })
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({
              type: 'error',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
            })
          }
        })
        .catch((err) => console.error(err))
    }

    return {
      globalStore, ws, userDataLoaded
    }
  }
}
</script>

<style>
.navbar-brand img {
  width: 5em;
  height: auto;
  margin: 0 1em;
}

.tournament-result-criterion .formkit-outer[data-type=number] .formkit-wrapper {
  width: auto;
  clear: both;
}

.tournament-result-criterion .formkit-outer[data-type=number] .formkit-inner {
  width: 4em;
  float: left;
  margin-right: 1em;
}

.tournament-result-criterion .formkit-outer[data-type=number] .formkit-label {
  display: inline-block;
  padding-top: 1em;
}

img.icon-abacus {
  width: 2em;
  height: auto;
  cursor: pointer;
  margin: 0 .5em 0 0;
}

h1 {
  padding: .5em .25em;
}
</style>
