import { defineStore } from 'pinia'

export const useCompetitionParticipantStore = defineStore('CompetitionParticipantStore', {
  state: () => {
    return {
      competitionParticipants: []
    }
  },
  getters: {
    count: state => state.competitionParticipants.length,
    getById: state => {
      return id => {
        const rows = state.competitionParticipants.filter(row => +row.id === +id)
        if (rows && rows[0]) {
          return rows[0]
        }
        return false
      }
    },
    getByCompetitionId: state => {
      return competitionId => {
        return state.competitionParticipants.filter(row => +row.competitionId === +competitionId)
      }
    },
    getByCompetitionAndParticipantId: state => {
      return (competitionId, participantId) => {
        return state.competitionParticipants.filter(row => +row.competitionId === +competitionId && +row.participantId === +participantId)
      }
    }
  },
  actions: {
    setData(data) {
      this.competitionParticipants = data
    },
    emptyData() {
      this.competitionParticipants = []
    },
    deleteRow(id) {
      for (let i in this.competitionParticipants) {
        if (+id === +this.competitionParticipants[i].id) {
          delete this.competitionParticipants[i]
        }
      }
    },
    updateRow(data) {
      for (let i in this.competitionParticipants) {
        if (+data.id === +this.competitionParticipants[i].id) {
          this.competitionParticipants[i] = data
        }
      }
    },
    addRow(data) {
      this.competitionParticipants.push(data)
    }
  }
})
