import { defineStore } from 'pinia'

export const useEventEquipmentTableStore = defineStore('EventEquipmentTableStore', {
  state: () => {
    return {
      eventEquipmentTables: {}
    }
  },
  getters: {
    count: state => Object.keys(state.eventEquipmentTables).length,
    hasEventEquipmentTable: state => {
      return eventId => {
        return !!state.eventEquipmentTables[eventId]
      }
    }
  },
  actions: {
    setData(data) {
      for (let i in data) {
        this.eventEquipmentTables[data[i].eventId] = data[i]
      }
    },
    addDataRows(data) {
      for (let i in data) {
        this.addDataRow(data[i])
      }
    },
    addDataRow(data) {
      if (!this.eventEquipmentTables[data.eventId]) {
        this.eventEquipmentTables[data.eventId] = data
      }
    },
    removeDataRow(id) {
      delete this.eventEquipmentTables[id]
    },
    actualizeLiveRow(eventId, data) {
      if (this.eventEquipmentTables[eventId]) {
        this.eventEquipmentTables[eventId] = data
      }
    }
  }
})
