import {defineStore} from 'pinia'

export const useTournamentParticipantSettingTypeStore = defineStore('TournamentParticipantSettingTypeStore', {
  state: () => {
    return {
      tournamentParticipantSettingType: {}
    }
  },
  getters: {
    count: state => Object.keys(state.tournamentParticipantSettingType).length
  },
  actions: {
    setData(data) {
      this.addRows(data)
    },
    addRows(rows) {
      for (let i in rows) {
        this.addRow(rows[i])
      }
    },
    addRow(row) {
      this.tournamentParticipantSettingType = row
    },
    updateRow(row) {
      this.tournamentParticipantSettingType = row
    },
    deleteRow() {
      this.tournamentParticipantSettingType = {}
    }
  }
})