<template>
  <tr>
    <template v-if="comment">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="4">
          <div class="mb-3">
            <ckeditor :editor="editor" v-model="itemData.descriptionText" :config="editorConfig"></ckeditor>
          </div>
          <div class="mb-3">
            <button @click="itemData.viewLeagueList = !itemData.viewLeagueList" class="btn btn-secondary btn-small me-2">K soutěži</button>
            <button @click="itemData.viewTournamentList = !itemData.viewTournamentList" class="btn btn-secondary btn-small">K turnaji</button>
          </div>
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          <span :class="'small mark text-' + (comment.active ? 'success' : 'danger')">#{{ comment.id }}</span>
        </td>
        <td>
          {{ globalStore.getCommentTypeNameById(comment.commentTypeId) }}
          <small class="d-block text-secondary text-nowrap">{{ globalStore.formatDateTime(comment.date) }}</small>
          <template v-for="comment in globalStore.leagueCommentStore.getByCommentId(comment.id)" :key="comment.id">
            <small class="d-block text-nowrap">
              {{ leaguesSvl[comment.competitionId] }}
            </small>
          </template>
          <template v-for="comment in globalStore.tournamentCommentStore.getByCommentId(comment.id) " :key="comment.id">
            <small class="d-block text-nowrap">
              {{ tournamentsSvl[comment.tournamentId] }}
            </small>
          </template>
        </td>
        <td>
          {{ globalStore.getPublicWordById(+comment.name) }}
          <small class="d-block text-secondary text-nowrap">{{ comment.author }}</small>
          <template v-if="comment.attachment">
            <small v-if="comment.attachment.indexOf('.pdf') > 0" class="d-block text-secondary text-nowrap" v-html="globalStore.generateFilePreview(comment.attachment)" />
            <small v-else v-html="globalStore.generateImgPreview(comment.attachment)" />
          </template>
        </td>
        <td v-html="globalStore.getPublicWordById(+comment.description)"></td>
        <td>
          <ButtonUpdate
            :click-function="initUpdateForm" />
          <span>&#160;</span>
          <ButtonDelete
            :click-function="deleteItem" />
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="5">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="4">
          <div class="mb-3">
            <ckeditor :editor="editor" v-model="itemData.descriptionText" :config="editorConfig"></ckeditor>
          </div>
          <div class="mb-3">
            <button @click="itemData.viewLeagueList = !itemData.viewLeagueList" class="btn btn-secondary btn-small me-2">K soutěži</button>
            <button @click="itemData.viewTournamentList = !itemData.viewTournamentList" class="btn btn-secondary btn-small">K turnaji</button>
          </div>
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'CommentItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    commentObject: {
      required: false,
      type: Object
    },
    tournaments: {
      required: false,
      type: Object
    },
    leagues: {
      required: false,
      type: Object
    },
    tournamentIdSet: {
      required: false,
      type: Number
    },
    leagueIdSet: {
      required: false,
      type: Number
    },
    eventIdSet: {
      required: false,
      type: Number
    },
  },

  setup(props) {
    const comment = toRef(props, 'commentObject'),
      tournamentsSvl = toRef(props, 'tournaments'),
      leaguesSvl = toRef(props, 'leagues'),
      tournamentId = toRef(props, 'tournamentIdSet'),
      leagueId = toRef(props, 'leagueIdSet'),
      eventId = toRef(props, 'eventIdSet'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      schema = ref([]),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      autoloadedCompetitions = ref({}),
      editor = ClassicEditor,
      editorConfig = {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      }

    function initAddForm() {
      itemData.value = {
        viewTournamentList: false,
        viewLeagueList: false
      }
      itemData.value.nameText = ''
      itemData.value.descriptionText = ''

      itemData.value.tournamentIds = []
      if (tournamentId.value) {
        itemData.value.tournamentIds.push(tournamentId.value)
      }

      itemData.value.leagueIds = []
      if (leagueId.value) {
        itemData.value.leagueIds.push(leagueId.value)
      }

      itemData.value.eventId = null
      if (eventId.value) {
        itemData.value.eventId = eventId.value
      }

      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = comment.value
      if (!itemData.value) {
        return
      }
      itemData.value.nameText = globalStore.getPublicWordById(+comment.value.name)
      itemData.value.descriptionText = globalStore.getPublicWordById(+comment.value.description)
      itemData.value.viewTournamentList = false
      itemData.value.viewLeagueList = false
      itemData.value.tournamentIds = []
      if (comment.value.tournamentId) {
        itemData.value.tournamentIds.push(comment.value.tournamentId)
      }
      const tournamentComments = globalStore.tournamentCommentStore.getByCommentId(comment.value.id)
      for (let i in tournamentComments) {
        const tournamentCommentItem = tournamentComments[i]
        if (tournamentCommentItem.commentId === comment.value.id) {
          itemData.value.tournamentIds.push(tournamentCommentItem.tournamentId)
        }
      }

      itemData.value.leagueIds = []
      if (comment.value.competitionId) {
        itemData.value.leagueIds.push(comment.value.competitionId)
      }
      const leagueComments = globalStore.leagueCommentStore.getByCommentId(comment.value.id)
      for (let i in leagueComments) {
        const leagueCommentItem = leagueComments[i]
        if (leagueCommentItem.commentId === comment.value.id) {
          itemData.value.leagueIds.push(leagueCommentItem.competitionId)
        }
      }

      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'hidden',
        name: 'viewLeagueList',
        id: 'viewLeagueList'
      })

      schema.value.push({
        $formkit: 'hidden',
        name: 'viewTournamentList',
        id: 'viewTournamentList'
      })

      schema.value.push({
        $formkit: 'text',
        name: 'nameText',
        validation: 'required',
        label: globalStore.getWordById('comment name'),
        required: true
      })

      schema.value.push({
        $formkit: 'text',
        name: 'author',
        validation: 'required',
        label: globalStore.getWordById('comment author'),
        required: true
      })

      schema.value.push({
        $formkit: 'select',
        name: 'commentTypeId',
        id: 'commentTypeId',
        options: globalStore.getSvlCommentType,
        label: globalStore.getWordById('comment type'),
        placeholder: '...',
        required: true
      })

      schema.value.push({
        //if: '$get(commentTypeId).value === "2" || $get(commentTypeId).value === "3" || $get(commentTypeId).value === "6" || $get(commentTypeId).value === "7" || $get(commentTypeId).value === "9"',
        if: '$get(viewTournamentList).value === true',
        $formkit: 'checkbox',
        name: 'tournamentIds',
        options: tournamentsSvl.value,
        label: globalStore.getWordById('comment tournaments'),
      })

      schema.value.push({
        //if: '$get(commentTypeId).value === "2" || $get(commentTypeId).value === "3" || $get(commentTypeId).value === "6" || $get(commentTypeId).value === "7" || $get(commentTypeId).value === "9"',
        if: '$get(viewLeagueList).value === true',
        $formkit: 'checkbox',
        name: 'leagueIds',
        options: leaguesSvl.value,
        label: globalStore.getWordById('comment leagues'),
      })

      schema.value.push({
        $formkit: 'file',
        name: 'attachment',
        label: globalStore.getWordById('comment attachment') + (comment.value && comment.value.attachment ? ' ' + globalStore.getWordById('replace') + ' ' + comment.value.attachment : ''),
        multiple: false,
        accept: '.pdf'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'active',
        options: {
          '1': globalStore.getWordById('active-1'),
          '0': globalStore.getWordById('active-0'),
        },
        label: globalStore.getWordById('comment active'),
        required: true
      })
    }

    function processAddForm() {
      if (!itemData.value.descriptionText) {
        notificationStore.add({ type: 'error', message: 'Text nesmí být prázdný' })
        return false
      }
      itemData.value.organizationId = globalStore.userAdminStore.activeOrganization
      itemData.value.sectionId = globalStore.userAdminStore.activeSection
      itemData.value.season = globalStore.userAdminStore.activeSeason
      itemData.value.languageId = globalStore.languageId

      const formData = new FormData()
      for (let i in itemData.value) {
        if (i === 'attachment') {
          formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
        } else if (i === 'tournamentIds' || i == 'leagueIds') {
          formData.append(i, JSON.stringify(itemData.value[i]) || '')
        } else {
          formData.append(i, itemData.value[i] || '')
        }
      }

      fetch(globalStore.apiUrl + '/admin/comment', {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data && response.data.comment) {
            globalStore.commentStore.setData(response.data.comment)
            globalStore.vocabularyPublicStore.setData(response.data.vocabularyPublic)
            globalStore.tournamentCommentStore.setData(response.data.tournamentComment)
            globalStore.leagueCommentStore.setData(response.data.leagueComment)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (comment.value) {
        itemData.value.id = comment.value.id
        itemData.value.languageId = globalStore.languageId

        const formData = new FormData()
        for (let i in itemData.value) {
          if (i === 'attachment') {
            formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
          } else if (i === 'tournamentIds' || i == 'leagueIds') {
            formData.append(i, JSON.stringify(itemData.value[i]) || '')
          } else {
            formData.append(i, itemData.value[i] || '')
          }
        }

        fetch(globalStore.apiUrl + '/admin/comment-update', {
          method: 'POST',
          body: formData,
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.commentStore.setData(response.data.comment)
              globalStore.vocabularyPublicStore.setData(response.data.vocabularyPublic)
              globalStore.tournamentCommentStore.unsetData(
                globalStore.tournamentCommentStore.getByCommentId(comment.value.id)
              )
              globalStore.tournamentCommentStore.setData(response.data.tournamentComment)
              globalStore.leagueCommentStore.unsetData(
                globalStore.leagueCommentStore.getByCommentId(comment.value.id)
              )
              globalStore.leagueCommentStore.setData(response.data.leagueComment)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = {
          id: comment.value.id
        }
        fetch(globalStore.apiUrl + '/admin/comment', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.commentStore.deleteData(comment.value)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, schema, addFormActive,
      autoloadedCompetitions, editor, editorConfig, comment, tournamentsSvl, leaguesSvl,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
