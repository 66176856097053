import { defineStore } from 'pinia'

export const useClubParticipantLeaderStore = defineStore('ClubParticipantLeaderStore', {
  state: () => {
    return {
      clubParticipantLeaders: {}
    }
  },
  getters: {
    count: state => state.clubParticipantLeaders.length,
    getByClubId: state => {
      return clubId => state.clubParticipantLeaders[clubId] || {}
    }
  },
  actions: {
    setData(data, clubId) {
      if (clubId) {
        this.clubParticipantLeaders[clubId] = data
      }
    }
  }
})
