import { defineStore } from 'pinia'

export const useCompetitionDisciplineStore = defineStore('CompetitionDisciplineStore', {
  state: () => {
    return {
      competitionDisciplines: [],
    }
  },
  getters: {
    count: state => state.competitions.length,
    getByCompetitionId: state => {
      return id => {
        return state.competitionDisciplines.filter(row => +row.competitionId === +id)
      }
    }
  },
  actions: {
    setData(data) {
      this.competitionDisciplines = data
    },
    updateRowsByCompetitionId(data, competitionId) {
      this.competitionDisciplines = this.competitionDisciplines.filter(row => +row.competitionId !== +competitionId)
      for (let i in data) {
        this.addRow(data[i])
      }
    },
    deleteRowsByCompetitionId(competitionId) {
      this.competitionDisciplines = this.competitionDisciplines.filter(row => +row.competitionId !== +competitionId)
    },
    addRow(data) {
      this.competitionDisciplines.push(data)
    },
    deleteRow(id) {
      for (let i in this.competitionDisciplines) {
        if (+id === +this.competitionDisciplines[i].id) {
          delete this.competitionDisciplines[i]
        }
      }
    },
    updateRow(data) {
      for (let i in this.competitionDisciplines) {
        if (+data.id === +this.competitionDisciplines[i].id) {
          this.competitionDisciplines[i] = data
        }
      }
    },
  }
})
