import { defineStore } from 'pinia'

export const useParticipantLeaderStore = defineStore('ParticipantLeaderStore', {
  state: () => {
    return {
      participantLeaders: []
    }
  },
  getters: {
    count: state => state.participantLeaders.length,
    getByParticipantParentId: state => {
      return participantParentId => {
        return state.participantLeaders.filter(row => +participantParentId === +row.participantParentId)
      }
    }
  },
  actions: {
    setData(data) {
      this.participantLeaders = data
    },
    addRow(data) {
      this.participantLeaders.push(data)
    },
    updateRow(data) {
      for (let i in this.participantLeaders) {
        if (+this.participantLeaders[i].participantParentId === +data.participantParentId) {
          this.participantLeaders[i] = data
        }
      }
    },
    deleteRow(participantParentId) {
      for (let i in this.participantLeaders) {
        if (+this.participantLeaders[i].participantParentId === +participantParentId) {
          delete this.participantLeaders[i]
        }
      }
    }
  }
})
