<template>
  <table v-if="globalStore.userAdminStore.propertiesLoaded" class="table table-striped">
    <tbody>
      <CompetitionTeamEventParticipantSettingTypeItem :cId="id" />
    </tbody>
  </table>
</template>

<script>
import { toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import CompetitionTeamEventParticipantSettingTypeItem from "@/components/CompetitionManagement/CompetitionTeamEventParticipantSettingTypeItem"

export default {
  name: 'CompetitionTeamEventParticipantSettingTypeList',
  components: { CompetitionTeamEventParticipantSettingTypeItem },
  props: {
    competitionId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const id = toRef(props, 'competitionId'),
      globalStore = useGlobalStore()

    return {
      globalStore, id
    }
  }
}
</script>
