<template>
  <template v-if="loungeClubAddressTable">
    <br />
    <h3>Live score</h3>
    <template v-for="lounge in loungeClubAddressTable" :key="lounge.id">
      <h6>{{ lounge.street }}, {{ lounge.city }}</h6>
      <ul class="list-group list-group-horizontal-md">
        <template v-for="table in globalStore.sortBy(lounge.table, [{ tableSizeId: 'asc' }, { number: 'asc' }])" :key="table.id">
          <li v-if="table.loungeId === lounge.loungeId" class="list-group-item">
            <button type="button" class="btn btn-primary" @click="gotoLs(table.id)">
              {{ table.number }} - {{ table.tableSizeId === 1 ? 'MS' : 'ZS' }}
            </button>
          </li>
        </template>
      </ul>
    </template>
  </template>
  <template v-if="globalStore.teamEventStore.teamEvents.length && globalStore.clubTodayEventIdStore.teamEventIds.length
    || todayTournaments.length
    ">
    <br />
    <div id="todays-events" class="accordion">
      <div v-if="globalStore.teamEventStore.teamEvents.length && globalStore.clubTodayEventIdStore.teamEventIds.length" class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            Dnešní týmová utkání
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#todays-events">
          <div class="accordion-body">
            <table class="table table-striped">
              <tbody>
                <template v-for="(row, i) in globalStore.teamEventStore.teamEvents" :key="i">
                  <template v-if="globalStore.clubTodayEventIdStore.teamEventIds.indexOf(row.id) > -1">
                    <TeamEventItem :teamEventObject="row" :isUpdatable="true" />
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="todayTournaments.length" class="accordion-item">
        <h2 class="accordion-header">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            Dnešní turnaje
          </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#todays-events">
          <div class="accordion-body">
            <table class="table table-striped">
              <tbody>
                <template v-for="(row, i) in todayTournaments" :key="i">
                  <router-link :to="'/tournament-management/' + row.id">{{ row.name }}</router-link>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-if="loungeClubAddressTable">
    <br />
    <template v-for="lounge in loungeClubAddressTable" :key="lounge.id">
      <h6>{{ lounge.street }}, {{ lounge.city }} - Přehled zápasů</h6>
      <br />
      <div v-for="table in globalStore.sortBy(lounge.table.filter(row => row.loungeId === lounge.loungeId), [{ tableSizeId: 'asc' }, { number: 'asc' }])" :key="table.id" class="accordion" :id="'accordionPanelsStayOpenExample-' + table.id">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#panelsStayOpen-collapse-' + table.id" aria-expanded="false" :aria-controls="'panelsStayOpen-collapse-' + table.id">
              {{ table.number }} - {{ table.tableSizeId === 1 ? 'MS' : 'ZS' }}
            </button>
          </h2>
          <div :id="'panelsStayOpen-collapse-' + table.id" class="accordion-collapse collapse">
            <div class="accordion-body">
              <p>Live score video link: <a href="javascript:void(0)" @click="gotoSimpleLs(table.id)">{{ globalStore.apiUrl }}/admin/event/livescore/simple/by-table/rd/{{ table.id }}</a></p>
              <ul v-if="liveScoreEvents[table.id]">
                <li v-for="event in liveScoreEvents[table.id]" :key="event.id">
                  {{ event.dateStart }} -
                  {{ globalStore.getParticipantNameById(event.eventParticipant[0].participantId) }}
                  v
                  {{ globalStore.getParticipantNameById(event.eventParticipant[1].participantId) }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </template>
  </template>
  <div v-if="globalStore.userAdminStore.activeClub && globalStore.clubStore.getById(globalStore.userAdminStore.activeClub)" class="mt-3">
    <ClubPresentationParts :clubIdSet="+globalStore.userAdminStore.activeClub" />
  </div>
</template>

<script>

import { onMounted, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import TeamEventItem from "@/components/TeamEventItem"
import ClubPresentationParts from "@/components/ClubPresentation/ClubPresentationParts"

export default {
  name: 'HomePage',
  components: { TeamEventItem, ClubPresentationParts },

  setup() {
    const globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      loungeClubAddressTable = ref([]),
      liveScoreEvents = ref({}),
      todayTournaments = ref([])

    onMounted(() => {
      const userClubId = globalStore.userAdminStore.activeClub
      if (userClubId) {
        if (!globalStore.clubStore.getById(userClubId)) {
          fetch(globalStore.apiUrl + '/admin/clubs/' + userClubId, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
          })
            .then(res => {
              if (res.status !== 200) {
                throw new Error(res.statusText)
              }
              return res.json()
            })
            .then(response => {
              if (response.data && response.data.club) {
                globalStore.clubStore.setData(response.data.club)
              } else {
                alert(':(')
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err }))
        }

        fetch(globalStore.apiUrl + '/admin/club-day-event-summary/' + userClubId, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => {
            if (res.status !== 200) {
              throw new Error(res.statusText)
            }
            return res.json()
          })
          .then(response => {
            if (response.data.loungeClubAddressTable && response.data.loungeClubAddressTable.length) {
              const lounges = {}
              for (let i in response.data.loungeClubAddressTable) {
                const lounge = response.data.loungeClubAddressTable[i]
                lounge.table = []
                for (let j in response.data.loungeClubAddressTable) {
                  const table = response.data.loungeClubAddressTable[j]
                  lounge.table.push(table)
                }
                lounges[lounge.loungeId] = lounge
              }
              loungeClubAddressTable.value = lounges
            }

            if (response.data.liveScoreEvent) {
              for (let i in response.data.liveScoreEvent) {
                const event = response.data.liveScoreEvent[i]
                if (!liveScoreEvents.value[event.loungeEquipmentTableId]) {
                  liveScoreEvents.value[event.loungeEquipmentTableId] = []
                }
                liveScoreEvents.value[event.loungeEquipmentTableId].push(event)
              }
            }
            if (response.data.participant) {
              globalStore.participantStore.addData(response.data.participant)
            }
            if (response.data.discipline) {
              globalStore.disciplineStore.setData(response.data.discipline)
            }
            if (response.data.vocabulary) {
              globalStore.vocabularyStore.addRows(response.data.vocabulary)
            }
            if (response.data.participantHasParticipant) {
              globalStore.teamParticipantStore.setData(response.data.participantHasParticipant)
            }
            if (response.data.loungeClubAddressTable) {
              globalStore.loungeClubAddressTableStore.setData(response.data.loungeClubAddressTable)
            }
            if (response.data.tournament) {
              todayTournaments.value = response.data.tournament
            }
            if (response.data.teamEvent && response.data.teamEvent.length) {
              globalStore.userAdminStore.activeSeason = response.data.teamEvent[0].season
              globalStore.userAdminStore.isActiveSeasonOpen = true
              globalStore.teamEventStore.addData(response.data.teamEvent)
              const teamEventIds = []
              for (let i in response.data.teamEvent) {
                teamEventIds.push(response.data.teamEvent[i].id)
              }
              globalStore.clubTodayEventIdStore.setDataTeamEventIds(teamEventIds)
            }
            if (response.data.eventReferee) {
              globalStore.eventRefereeStore.addData(response.data.eventReferee)
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err }))
      }
    })

    function gotoLs(id) {
      fetch(globalStore.lsTableAdminUrl.replace('__ID__', id), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(response => {
          if (response.data && response.data.url) {
            return !window.open(response.data.url)
          } else {
            alert(':(')
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    function gotoSimpleLs(id) {
      fetch(globalStore.apiUrl + '/admin/event/livescore/simple/by-table/' + id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.text()
        })
        .then(response => {
          if (response) {
            return !window.open(response)
          } else {
            alert(':(')
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, loungeClubAddressTable, liveScoreEvents, todayTournaments,
      gotoLs, gotoSimpleLs
    }
  }
}
</script>

<style scoped>
li.nav-item {
  padding: 0 .25em;
}

li.nav-item:first-child {
  padding-left: 0;
}

li.nav-item:last-child {
  padding-right: 0;
}

li.nav-item a.nav-link.active {
  cursor: pointer;
}
</style>
