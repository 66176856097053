import { defineStore } from 'pinia'

export const useSeasonSettingParameterStore = defineStore('SeasonSettingParameterStore', {
  state: () => {
    return {
      data: []
    }
  },
  getters: {
    count: state => state.data.length,
    getById: state => {
      return id => {
        const data = state.data.filter(row => row.id === id)
        return data ? data[0] : null
      }
    },
    getByParticipantInSeasonId: state => {
      return participantInSeasonId => {
        return state.data.filter(row => +row.participantInSeasonId === +participantInSeasonId)
      }
    },
    getByParticipantInSeasonIdSeasonSettingTypeId: state => {
      return (participantInSeasonId, seasonSettingTypeId) => {
        const data = state.data.filter(row => +row.participantInSeasonId === +participantInSeasonId && +row.seasonSettingTypeId === +seasonSettingTypeId)
        return data ? data[0] : null
      }
    }
  },
  actions: {
    setData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          this.addData(rows[i])
        }
      }
    },
    addData(item) {
      if (!this.data.filter(row => row.id === item.id).length) {
        this.data.push(item)
      }
    },
    updateData(item) {
      for (let i in this.data) {
        if (this.data[i].id === item.id) {
          this.data[i] = item
          break
        }
      }
    },
    unsetData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          if (this.data.filter(row => +row.id === +rows[i].id).length) {
            this.deleteData(rows[i])
          }
        }
      }
    },
    deleteData(item) {
      for (let i in this.data) {
        if (+this.data[i].id === +item.id) {
          delete this.data[i]
          break
        }
      }
    },
    deleteDataByEntity(item) {
      for (let i in this.data) {
        if (+this.data[i].participantInSeasonId === +item.id) {
          delete this.data[i]
          break
        }
      }
    }
  }
})
