<template>
  <template v-if="globalStore.userAdminStore.getRestrictions['o']">
    <select v-model="globalStore.userAdminStore.activeOrganization"
      @change="globalStore.userAdminStore.changedOrganization(); onChangeFn()"
      class="form-select form-select-sm bg-primary text-white">
      <option value="" selected disabled>{{ globalStore.getWordById('choose organization') }}</option>
      <template v-for="(organization, i) in globalStore.userAdminStore.getRestrictions['o']" :key="i">
        <option :value="i">
          {{ globalStore.userAdminStore.getRestrictions['o'][i].name }}
        </option>
      </template>
    </select>
    <select v-if="globalStore.userAdminStore.activeOrganization > 0"
      v-model="globalStore.userAdminStore.activeSection"
      @change="globalStore.userAdminStore.changedSection(); onChangeFn()"
      class="form-select form-select-sm bg-primary text-white">
      <option value="" selected disabled>{{ globalStore.getWordById('choose section') }}</option>
      <template v-for="(section, j) in  globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'] " :key="j">
        <option :value="j">
          {{ globalStore.getWordById(globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'][j].name) }}
        </option>
      </template>
    </select>
    <select v-if="globalStore.userAdminStore.activeOrganization > 0 && globalStore.userAdminStore.activeSection > 0"
      v-model="globalStore.userAdminStore.activeSeason"
      @change="globalStore.userAdminStore.changedSeason(); onChangeFn()"
      class="form-select form-select-sm">
      <option value="" selected disabled>{{ globalStore.getWordById('choose season') }}</option>
      <template v-for="season in globalStore.userAdminStore.getUserSeasons" :key="season">
        <option :value="season">
          {{ season }}/{{ (+season + 1) }}
        </option>
      </template>
    </select>
    <select
      v-if="globalStore.userAdminStore.activeOrganization > 0
    && globalStore.userAdminStore.activeSection > 0
    && globalStore.userAdminStore.activeSeason > 0
    && globalStore.userAdminStore.getRestrictions['o']
    && globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]
    && globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s']
    && globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'][globalStore.userAdminStore.activeSection]
    && globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'][globalStore.userAdminStore.activeSection]['r']"
      v-model="globalStore.userAdminStore.activeRegion"
      @change="globalStore.userAdminStore.changedRegion(); onChangeFn()"
      class="form-select form-select-sm">
      <option value="" selected disabled>{{ globalStore.getWordById('choose region') }}</option>
      <template v-for="(region, l) in globalStore.userAdminStore.getRestrictions['o'][globalStore.userAdminStore.activeOrganization]['s'][globalStore.userAdminStore.activeSection]['r']" :key="l">
        <option :value="l">
          {{ globalStore.getWordById(region.name) }}
        </option>
        <template v-if="globalStore.userAdminStore.getRegionsByRegion(globalStore.userAdminStore.activeOrganization, globalStore.userAdminStore.activeSection, l)">
          <template v-for="(subRegion, m) in  globalStore.userAdminStore.getRegionsByRegion(globalStore.userAdminStore.activeOrganization, globalStore.userAdminStore.activeSection, l)" :key="m">
            <option :value="m">
              &#160;&#160;{{ globalStore.getWordById(subRegion.name) }}
            </option>
            <template v-if="globalStore.userAdminStore.getRegionsByRegion(globalStore.userAdminStore.activeOrganization, globalStore.userAdminStore.activeSection, m)">
              <template v-for="( subSubRegion, n ) in  globalStore.userAdminStore.getRegionsByRegion(globalStore.userAdminStore.activeOrganization, globalStore.userAdminStore.activeSection, m) " :key="n">
                <option :value="n">
                  &#160;&#160;&#160;&#160;{{ globalStore.getWordById(subSubRegion.name) }}
                </option>
                <template v-if="globalStore.userAdminStore.getRegionsByRegion(globalStore.userAdminStore.activeOrganization, globalStore.userAdminStore.activeSection, n)">
                  <template v-for="( subSubSubRegion, o ) in  globalStore.userAdminStore.getRegionsByRegion(globalStore.userAdminStore.activeOrganization, globalStore.userAdminStore.activeSection, n) " :key="o">
                    <option :value="o">
                      &#160;&#160;&#160;&#160;&#160;&#160;{{ globalStore.getWordById(subSubSubRegion.name) }}
                    </option>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>
      </template>
    </select>
    <select v-if="globalStore.userAdminStore.getClubsByRegion(globalStore.userAdminStore.activeOrganization, globalStore.userAdminStore.activeSection, globalStore.userAdminStore.activeRegion)
    && globalStore.userAdminStore.activeOrganization > 0
    && globalStore.userAdminStore.activeSection > 0
    && globalStore.userAdminStore.activeSeason > 0 && globalStore.userAdminStore.activeRegion > 0
    "
      v-model="globalStore.userAdminStore.activeClub"
      @change="globalStore.userAdminStore.changedClub(); onChangeFn()"
      class="form-select form-select-sm">
      <option value="" selected disabled>{{ globalStore.getWordById('choose club') }}</option>
      <template v-for="( club, p ) in  globalStore.orderSvl(globalStore.userAdminStore.getClubsByRegion(globalStore.userAdminStore.activeOrganization, globalStore.userAdminStore.activeSection, globalStore.userAdminStore.activeRegion), true) " :key="p">
        <option :value="club.id">
          {{ club.label }}
        </option>
      </template>
    </select>
  </template>
</template>

<script>
import { onMounted } from "vue";
import { useGlobalStore } from "@/stores/GlobalStore";

export default {
  name: 'MenuBase',
  components: {},
  props: {
    onChangeFn: {
      required: true,
      type: Function
    }
  },

  setup() {
    const globalStore = useGlobalStore()

    onMounted(() => {
      globalStore.userAdminStore.setDefaults()
    })

    return {
      globalStore
    }
  }
}
</script>
