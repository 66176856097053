<template>
  <ParticipantList />
</template>

<script>
import ParticipantList from "@/components/ParticipantList";
export default {
  components: { ParticipantList }
}
</script>
