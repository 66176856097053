import { defineStore } from 'pinia'
import { useTeamEventRoundParameterStore } from "@/stores/TeamEventRoundParameterStore"

export const useTeamEventRoundStore = defineStore('TeamEventRoundStore', {
  state: () => {
    return {
      teamEventRounds: [],
      teamEventRoundParameterStore: useTeamEventRoundParameterStore(),
    }
  },
  getters: {
    count: state => state.teamEventRounds.length,
    getTeamEventRoundsByStage: state => {
      return stageId => {
        return state.teamEventRounds.filter(
          event => event.stageId === stageId
        )
      }
    },
    getTeamEventRoundsById: state => {
      return id => {
        return state.teamEventRounds.filter(
          event => event.id === id
        )
      }
    },
    hasStageParameters: state => {
      return stageId => {
        for (let i in state.teamEventRounds) {
          if (state.teamEventRounds[i].stageId === stageId) {
            if (state.teamEventRoundParameterStore.hasTeamEventRoundParameters(state.teamEventRounds[i].id) === true) {
              return true
            }
          }
        }
        return false
      }
    }
  },
  actions: {
    setData(data) {
      this.teamEventRounds = data
    },
    addData(data) {
      for (let i in data) {
        let teamEvent = false
        for (let j in this.teamEventRounds) {
          if (+this.teamEventRounds[j].id === +data[i].id) {
            teamEvent = true
            break
          }
        }
        if (!teamEvent) {
          this.teamEventRounds.push(data[i])
        }
      }
    },
    actualizeRow(teamEventRound) {
      for (let i in this.teamEventRounds) {
        if (+this.teamEventRounds[i].id === +teamEventRound.id) {
          this.teamEventRounds[i] = teamEventRound
        }
      }
    },
    deleteDataById(id) {
      this.teamEventRounds = this.teamEventRounds.filter(teamEventRound => teamEventRound.id !== id)
    }
  }
})
