import { defineStore } from 'pinia'

export const useCompetitionStageStore = defineStore('CompetitionStageStore', {
  state: () => {
    return {
      competitionStages: [],
      activeStageId: 0
    }
  },
  getters: {
    count: state => state.competitionStages.length,
    getCompetitionStageByStageId: state => {
      return stageId => {
        for (let i in state.competitionStages) {
          if (state.competitionStages[i].stageId === stageId) {
            return state.competitionStages[i]
          }
        }
        return {}
      }
    },
    getPreviousStageIdByStageId: state => {
      return stageId => {
        let id = null
        for (let i in state.competitionStages) {
          if (state.competitionStages[i].stageId === stageId) {
            return id
          }
          id = state.competitionStages[i].stageId
        }
        return null
      }
    },
    getNextStageIdByStageId: state => {
      return stageId => {
        let id = null
        for (let i in state.competitionStages) {
          if (id) {
            return state.competitionStages[i].stageId
          }
          if (state.competitionStages[i].stageId === stageId) {
            id = state.competitionStages[i].stageId
          }
        }
        return null
      }
    },
    getPreviousValidNonFinalCompetitionStage: state => {
      return (stageId, competitionParticipantCount, globalStore) => {
        for (let i = 0; i < 10; i++) {
          const previousStageId = state.getPreviousStageIdByStageId(stageId)
          if (previousStageId) {
            const previousCompetitionStage = state.getCompetitionStageByStageId(previousStageId)
            if (
              !globalStore.stageStore.isStageFinal(previousStageId)
              && previousCompetitionStage.competitionParticipantMinCount <= competitionParticipantCount
            ) {
              return previousCompetitionStage
            }
            stageId = previousStageId
          }
        }
        return null
      }
    },
    getActiveStageId: state => state.activeStageId
  },
  actions: {
    setData(data) {
      this.competitionStages = data
    },
    addDataRow(data) {
      this.competitionStages.push(data)
    },
    deleteDataRow(data) {
      this.competitionStages = this.competitionStages.filter(
        existingCompetitionStage => existingCompetitionStage.id !== data.id
      )
    },
    replaceDataRow(data) {
      for (let i in this.competitionStages) {
        if (this.competitionStages[i].stageId === data.stageId) {
          this.competitionStages[i] = data
          return true
        }
      }
      return false
    },
    setActiveStageId(stageId) {
      this.activeStageId = stageId
    }
  }
})
