import { defineStore } from 'pinia'

export const useCompetitionRankingPointStore = defineStore('CompetitionRankingPointStore', {
  state: () => {
    return {
      competitionRankingPoints: {}
    }
  },
  getters: {
    count: state => state.competitionRankingPoints.length
  },
  actions: {
    setData(data) {
      this.competitionRankingPoints = data
    },
    addDataRows(data) {
      for (let i in data) {
        this.addDataRow(data[i])
      }
    },
    addDataRow(data) {
      if (!this.competitionRankingPoints[data.id]) {
        this.competitionRankingPoints[data.id] = data
      }
    },
    removeDataRow(id) {
      delete this.competitionRankingPoints[id]
    },
    actualizeDataRow(data) {
      if (this.competitionRankingPoints[data.id]) {
        this.competitionRankingPoints[data.id] = data
      } else {
        this.addDataRow(data)
      }
    }
  }
})
