<template>
  <div class="competition-tournament-result-criterion">
    <template v-if="globalStore.isUserTypeAdministrator">
      <template v-if="getFirstItem() === null">
        <FormKit v-if="addFormActive === true" type="form" v-model="itemData['']" @submit="processAddForm()">
          <FormKitSchema :schema="schema" />
        </FormKit>
        <template v-else>
          <ButtonAdd :click-function="initAddForm"
            :word="globalStore.vocabularyStore.getWordByIdAndLanguage('add definition', globalStore.languageId)" />
        </template>
      </template>
    </template>
    <template v-if="getFirstItem() !== null">
      <table class="table table-hover table-striped align-middle table-light">
        <tbody>
          <template v-if="globalStore.isUserTypeAdministrator && updateFormActive[updateItemKey] === true">
            <tr>
              <td>
                <ButtonBack :click-function="deInitUpdateForm" :click-function-parameter="updateItemKey" />
              </td>
              <td>
                <FormKit type="form" v-model="itemData[updateItemKey]" @submit="processUpdateForm()">
                  <FormKitSchema :schema="schema" />
                </FormKit>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td>
                <h6>
                  <template v-if="competitionTournamentResultCriterionStore.competitionTournamentResultCriterion[0].competitionId">{{
                    globalStore.vocabularyStore.getWordByIdAndLanguage('defined by competition', globalStore.languageId)
                  }}</template>
                  <template v-else-if="competitionTournamentResultCriterionStore.competitionTournamentResultCriterion[0].sectionId">{{
                    globalStore.vocabularyStore.getWordByIdAndLanguage('defined by section', globalStore.languageId)
                  }}</template>
                </h6>
                <ol>
                  <template v-for="(value) in getSortedListByPriority()" :key="value.id">
                    <li>
                      {{ globalStore.getResultCriterionNameById(value.resultCriterionId) }}
                    </li>
                  </template>
                </ol>
              </td>
              <td>
                <template v-if="globalStore.isUserTypeAdministrator">
                  <template v-if="updateFormActive[updateItemKey] === true">
                    <button @click="processUpdateForm()" class="btn btn-outline-primary btn-sm">Send</button>
                  </template>
                  <template v-else>
                    <ButtonUpdate :click-function="initUpdateForm" :click-function-parameter="updateItemKey" />
                    <span>&#160;</span>
                    <ButtonDelete v-if="getFirstItem().competitionId" :click-function="deleteItem"
                      :click-function-parameter="updateItemKey" />
                  </template>
                </template>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useCompetitionTournamentResultCriterionStore } from "@/stores/CompetitionTournamentResultCriterionStore"
import { useResultCriterionStore } from "@/stores/ResultCriterionStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";

export default {
  name: 'CompetitionTournamentResultCriterionList',
  components: {
    ButtonDelete,
    ButtonUpdate,
    ButtonBack,
    ButtonAdd
  },

  setup() {
    const itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref(false),
      competitionTournamentResultCriterionStore = useCompetitionTournamentResultCriterionStore(),
      resultCriterionStore = useResultCriterionStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore(),
      updateItemKey = 'up',
      schema = ref([])

    function setSchema() {
      schema.value = []

      const resultCriterions = {
        '0': ''
      }

      for (let i = 0; i < resultCriterionStore.resultCriterion.length; i++) {
        const resultCriterionItem = resultCriterionStore.resultCriterion[i];
        schema.value.push({
          $formkit: 'number',
          name: resultCriterionItem.id.toString(),
          label: globalStore.getResultCriterionNameById(resultCriterionItem.id),
          options: resultCriterions
        })
      }
    }

    function getFirstItem() {
      return competitionTournamentResultCriterionStore.competitionTournamentResultCriterion[0] || null
    }

    function initAddForm() {
      itemData.value[''] = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm(id) {
      setSchema()
      const items = competitionTournamentResultCriterionStore.competitionTournamentResultCriterion
      if (!items) {
        return
      }
      setSchema()
      itemData.value[id] = {}
      for (let j in resultCriterionStore.resultCriterion) {
        const resultCriterionItem = resultCriterionStore.resultCriterion[j];
        itemData.value[id][resultCriterionItem.id] = getTournamentResultCriterionValueByResultCriterionId(resultCriterionItem.id)
      }
      updateFormActive.value[id] = true
    }

    function getTournamentResultCriterionValueByResultCriterionId(resultCriterionId) {
      for (let i in competitionTournamentResultCriterionStore.competitionTournamentResultCriterion) {
        const tournamentResultCriterion = competitionTournamentResultCriterionStore.competitionTournamentResultCriterion[i]
        if (tournamentResultCriterion.resultCriterionId === resultCriterionId) {
          return tournamentResultCriterion.priority
        }
      }
      return null
    }

    function processAddForm() {
      console.log(globalStore.competitionStore.competition.id)
      itemData.value[''].competitionId = globalStore.competitionStore.competition.id
      fetch(globalStore.apiUrl + '/admin/competition-tournament-result-criterion', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            competitionTournamentResultCriterionStore.setData(response.data)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => console.error(err))
    }

    function processUpdateForm() {
      const item = getFirstItem()
      if (item) {
        if (!item.competitionId) {
          console.log(itemData.value)
          itemData.value[''] = itemData.value[updateItemKey]
          delete itemData.value[updateItemKey]
          processAddForm()
          deInitUpdateForm(updateItemKey)
        } else {
          itemData.value[updateItemKey].competitionId = globalStore.competitionStore.competition.id
          fetch(globalStore.apiUrl + '/admin/competition-tournament-result-criterion', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemData.value[updateItemKey])
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200 && response.data) {
                competitionTournamentResultCriterionStore.setData(response.data)
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
                deInitUpdateForm(updateItemKey)
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getFirstItem()
        if (item.competitionId) {
          fetch(globalStore.apiUrl + '/admin/competition-tournament-result-criterion', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                if (response.data && response.data[0]) {
                  competitionTournamentResultCriterionStore.setData(response.data)
                  notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
                } else {
                  competitionTournamentResultCriterionStore.setData([])
                  notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
                }
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deInitUpdateForm(id) {
      updateFormActive.value[id] = false
      if (itemData.value[id]) {
        delete (itemData.value[id])
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    function getSortedListByPriority() {
      return competitionTournamentResultCriterionStore.competitionTournamentResultCriterion.sort(function (a, b) {
        return a['priority'] - b['priority'];
      })
    }

    return {
      itemData, updateFormActive, addFormActive, competitionTournamentResultCriterionStore, schema, globalStore, updateItemKey,
      getFirstItem, initAddForm, initUpdateForm, processAddForm, processUpdateForm, deleteItem, deInitUpdateForm, deInitAddForm,
      getSortedListByPriority
    }
  }
}
</script>
