import { defineStore } from 'pinia'

export const useTeamEventParticipantSettingTypeStore = defineStore('TeamEventParticipantSettingTypeStore', {
  state: () => {
    return {
      teamEventParticipantSettingTypes: []
    }
  },
  getters: {
    count: state => Object.keys(state.teamEventParticipantSettingTypes).length
  },
  actions: {
    setData(data) {
      this.teamEventParticipantSettingTypes = data
    }
  }
})
