import {defineStore} from 'pinia'

export const useEventParameterStore = defineStore('EventParameterStore', {
  state: () => {
    return {
      eventParameters: []
    }
  },
  getters: {
    count: state => state.eventParameters.length
  },
  actions: {
    setData(data) {
      this.eventParameters = data
    }
  }
})