<template>
  <MenuBase :onChangeFn="loadUsers" />
  <table v-if="globalStore.userAdminStore.propertiesLoaded" class="table table-striped">
    <tbody>
      <UserItem v-if="globalStore.userAdminStore.isUserSuperUser" />
      <template v-if="globalStore.userStore.data.length">
        <template v-for="(row, i) in globalStore.userStore.data" :key="i">
          <template v-if="row">
            <UserItem :userObject="row" :userParamsObject="globalStore.userParamsStore.getByUserId(row.id)" />
          </template>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted } from "vue"
import MenuBase from "@/components/Menu/MenuBase"
import UserItem from "@/components/UserItem"

export default {
  name: 'UserList',
  components: { MenuBase, UserItem },

  setup() {
    const globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadUsers()
    })

    function loadUsers() {
      fetch(globalStore.apiUrl + '/admin/users/' + (globalStore.userAdminStore.activeOrganization || 'null') + '/' + (globalStore.userAdminStore.activeSection || 'null') + '/' + (globalStore.userAdminStore.activeRegion || 'null') + '/' + (globalStore.userAdminStore.activeClub || 'null'), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.userParamsStore.setData(data.data.userParam)
          globalStore.userTypeStore.setData(data.data.userType)
          globalStore.sectionHasRegionStore.setData(data.data.sectionHasRegion)
          globalStore.userStore.setData(data.data.user)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore,
      loadUsers
    }
  }
}
</script>
