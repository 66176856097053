<template>
  <table v-if="globalStore.userAdminStore.propertiesLoaded" class="table table-striped">
    <tbody>
      <CompetitionParticipantItem v-if="globalStore.userAdminStore.isUserRegion" :cId="id" />
      <template v-if="globalStore.competitionParticipantStore.competitionParticipants.length">
        <template v-for="(row, i) in globalStore.competitionParticipantStore.competitionParticipants" :key="i">
          <CompetitionParticipantItem v-if="row && row.id" :cId="competitionId" :item="row" />
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import { toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import CompetitionParticipantItem from "@/components/CompetitionManagement/CompetitionParticipantItem"

export default {
  name: 'CompetitionParticipantList',
  components: { CompetitionParticipantItem },
  props: {
    competitionId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const id = toRef(props, 'competitionId'),
      globalStore = useGlobalStore()

    return {
      globalStore, id
    }
  }
}
</script>
