<template>
  <button v-if="globalStore.hasUserDeleteRule" @click="clickFunction(clickFunctionParameter)" class="btn btn-sm btn-danger">
    {{ globalStore.vocabularyStore.getWordByIdAndLanguage('remove live score', globalStore.languageId) }}
  </button>
</template>

<script>
import {useGlobalStore} from "@/stores/GlobalStore"

export default {
  name: 'ButtonDeleteLivescore',
  components: {},
  props: {
    clickFunction: {
      required: true,
      type: Function
    },
    clickFunctionParameter: {
      required: true
    }
  },

  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore
    }
  }
}
</script>
