<template>
  <ClubList />
</template>

<script>
import ClubList from "@/components/ClubList";
export default {
  components: { ClubList }
}
</script>
