<template>
  <table class="table table-sm">
    <tbody>
      <tr>
        <template v-if="clubPresentation">
          <template v-if="updateFormActive">
            <td>
              <ButtonBack :click-function="deInitUpdateForm" />
            </td>
            <td colspan="3">
              <div class="mb-3">
                <ckeditor :editor="editor" v-model="itemData.descriptionText" :config="editorConfig"></ckeditor>
              </div>
              <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
                <FormKitSchema :schema="schema" />
              </FormKit>
            </td>
          </template>
          <template v-else>
            <td>
              <div v-html="globalStore.getPublicWordById(clubPresentation.description)" />
            </td>
            <td>
              <span class="d-block" v-html="globalStore.generateImgPreview(clubPresentation.photo1)" />
              <span class="d-block" v-html="globalStore.generateImgPreview(clubPresentation.photo2)" />
              <span class="d-block" v-html="globalStore.generateImgPreview(clubPresentation.photo3)" />
              <span class="d-block" v-html="globalStore.generateImgPreview(clubPresentation.photo4)" />
              <span class="d-block" v-html="globalStore.generateImgPreview(clubPresentation.photo5)" />
              <span class="d-block" v-html="globalStore.generateImgPreview(clubPresentation.photo6)" />
            </td>
            <td>
              <ButtonUpdate
                :click-function="initUpdateForm" />
              <span>&#160;</span>
              <ButtonDelete
                :click-function="deleteItem" />
            </td>
          </template>
        </template>
        <template v-else>
          <td v-if="addFormActive === false" colspan="3">
            <ButtonAdd :viewPermanently="true" :click-function="initAddForm" />
          </td>
          <template v-else>
            <td>
              <ButtonBack :click-function="deInitAddForm" />
            </td>
            <td colspan="3">
              <div class="mb-3">
                <ckeditor :editor="editor" v-model="itemData.descriptionText" :config="editorConfig"></ckeditor>
              </div>
              <FormKit type="form" v-model="itemData" @submit="processAddForm">
                <FormKitSchema :schema="schema" />
              </FormKit>
            </td>
          </template>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate"
import ButtonDelete from "@/components/Button/ButtonDelete"
import ButtonAdd from "@/components/Button/ButtonAdd"
import ButtonBack from "@/components/Button/ButtonBack"
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export default {
  name: 'ClubPresentationItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    clubPresentationObject: {
      required: false,
      type: Object
    },
    clubIdSet: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const clubId = toRef(props, 'clubIdSet'),
      clubPresentation = toRef(props, 'clubPresentationObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([]),
      editor = ClassicEditor,
      editorConfig = {
        toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList'],
      }

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'file',
        name: 'photo1',
        label: globalStore.getWordById('image attachment') + (clubPresentation.value && clubPresentation.value.photo1 ? ' ' + globalStore.getWordById('replace') + ' ' + clubPresentation.value.photo1 : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.gif,.png,.svg'
      })

      schema.value.push({
        $formkit: 'file',
        name: 'photo2',
        label: globalStore.getWordById('image attachment') + (clubPresentation.value && clubPresentation.value.photo2 ? ' ' + globalStore.getWordById('replace') + ' ' + clubPresentation.value.photo2 : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.gif,.png,.svg'
      })

      schema.value.push({
        $formkit: 'file',
        name: 'photo3',
        label: globalStore.getWordById('image attachment') + (clubPresentation.value && clubPresentation.value.photo3 ? ' ' + globalStore.getWordById('replace') + ' ' + clubPresentation.value.photo3 : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.gif,.png,.svg'
      })

      schema.value.push({
        $formkit: 'file',
        name: 'photo4',
        label: globalStore.getWordById('image attachment') + (clubPresentation.value && clubPresentation.value.photo4 ? ' ' + globalStore.getWordById('replace') + ' ' + clubPresentation.value.photo4 : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.gif,.png,.svg'
      })

      schema.value.push({
        $formkit: 'file',
        name: 'photo5',
        label: globalStore.getWordById('image attachment') + (clubPresentation.value && clubPresentation.value.photo5 ? ' ' + globalStore.getWordById('replace') + ' ' + clubPresentation.value.photo5 : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.gif,.png,.svg'
      })

      schema.value.push({
        $formkit: 'file',
        name: 'photo6',
        label: globalStore.getWordById('image attachment') + (clubPresentation.value && clubPresentation.value.photo6 ? ' ' + globalStore.getWordById('replace') + ' ' + clubPresentation.value.photo6 : ''),
        multiple: false,
        accept: '.jpg,.jpeg,.gif,.png,.svg'
      })
    }

    function initAddForm() {
      itemData.value = {}
      itemData.value.descriptionText = ''
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      if (!clubPresentation.value) {
        return
      }
      const item = Object.assign({}, clubPresentation.value)
      itemData.value = item
      itemData.value.descriptionText = globalStore.getPublicWordById(+clubPresentation.value.description)
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.clubId = clubId.value
      itemData.value.languageId = globalStore.languageId

      const formData = new FormData()
      for (let i in itemData.value) {
        if (i.includes('photo')) {
          formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
        } else {
          formData.append(i, itemData.value[i] || '')
        }
      }

      fetch(globalStore.apiUrl + '/admin/club-presentation', {
        method: 'POST',
        body: formData
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.clubPresentationStore.setData(response.data.clubPresentation)
            globalStore.vocabularyPublicStore.addRows(response.data.vocabularyPublic)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (clubPresentation.value) {
        itemData.value.languageId = globalStore.languageId

        const formData = new FormData()
        for (let i in itemData.value) {
          if (i.includes('photo')) {
            formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
          } else {
            formData.append(i, itemData.value[i] || '')
          }
        }

        fetch(globalStore.apiUrl + '/admin/club-presentation-update', {
          method: 'POST',
          body: formData
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.clubPresentationStore.setData(response.data.clubPresentation)
              globalStore.vocabularyPublicStore.addRows(response.data.vocabularyPublic)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = clubPresentation.value
        fetch(globalStore.apiUrl + '/admin/club-presentation', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.clubPresentationStore.deleteData(item)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive, schema, clubId, clubPresentation,
      editor, editorConfig,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
