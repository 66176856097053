<template>
  <template v-if="!hasAnyEventParticipant()">
    <!-- insert -->
    <template v-if="itemData.events">
      <button v-if="!actionActive" @click="setViewData" type="button" class="btn btn-primary btn-sm">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('set players to event', globalStore.languageId) }}</button>
      <button v-else type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" :data-bs-target="'#teamEvent' + formData.id">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('set players to event action', globalStore.languageId) }}</button>
      <div v-if="formData.id && !actionUpdateActive" class="modal fade" :id="'teamEvent' + formData.id" tabindex="-1" :aria-labelledby="'teamEventLabel' + formData.id" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" :id="'teamEventLabel' + formData.id">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('set players to event', globalStore.languageId) }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <table class="table table-borderless table-sm">
                <thead>
                  <tr>
                    <th>
                      {{ teamNames.home }}
                    </th>
                    <th>
                      {{ teamNames.away }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(eventsCount, key, index) in itemData.events" :key="index">
                    <tr>
                      <th colspan="2">{{ disciplineNames[key.trim()] }}</th>
                    </tr>
                    <tr>
                      <td>
                        <template v-for="number in eventsCount" :key="number">
                          <FormKit type="select" v-model="formData[key.trim()].home[number - 1]"
                            validation="required" :validation-messages="{ required: globalStore.vocabularyStore.getWordByIdAndLanguage('form required error', globalStore.languageId) }">
                            <optgroup :label="globalStore.getWordById('team players', globalStore.languageId)">
                              <option v-for="(participant, id) in homeParticipants" :key="id" :value="id">{{ participant }}</option>
                            </optgroup>
                            <optgroup :label="globalStore.getWordById('team player substitutes', globalStore.languageId)">
                              <option v-for="(id, participant) in globalStore.swapObject(homeParticipantSubstitutes, true)" :key="id" :value="id">{{ participant }}</option>
                            </optgroup>
                          </FormKit>
                        </template>
                      </td>
                      <td>
                        <template v-for="number in eventsCount" :key="number">
                          <FormKit type="select" v-model="formData[key.trim()].away[number - 1]"
                            validation="required" :validation-messages="{ required: globalStore.vocabularyStore.getWordByIdAndLanguage('form required error', globalStore.languageId) }">
                            <optgroup :label="globalStore.getWordById('team players', globalStore.languageId)">
                              <option v-for="(participant, id) in awayParticipants" :key="id" :value="id">{{ participant }}</option>
                            </optgroup>
                            <optgroup :label="globalStore.getWordById('team player substitutes', globalStore.languageId)">
                              <option v-for="(id, participant) in globalStore.swapObject(awayParticipantSubstitutes, true)" :key="id" :value="id">{{ participant }}</option>
                            </optgroup>
                          </FormKit>
                        </template>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <ButtonSave :click-function="setParticipants" data-bs-dismiss="modal" type="button" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </template>
  <template v-else-if="!hasAnyEventScore() && !hasAnyEventLiveScore()">
    <!-- update -->
    <template v-if="actionActive">
      <div class="modal fade" :id="'teamEvent' + formData.id + '-update'" tabindex="-1" :aria-labelledby="'teamEventLabel' + formData.id + '-update'" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" :id="'teamEventLabel' + formData.id + '-update'">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('set players to event', globalStore.languageId) }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <table class="table table-borderless table-sm">
                <thead>
                  <tr>
                    <th>
                      {{ teamNames.home }}
                    </th>
                    <th>
                      {{ teamNames.away }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(event, key, index) in getEventParticipantsToUpdate()" :key="index">
                    <tr v-if="event.hasTitle">
                      <th colspan="2">{{ globalStore.getWordById('round') }}: {{ event.round }}, {{ globalStore.getWordById('discipline') }}: {{ globalStore.getDisciplineNameById(event.disciplineId) }}</th>
                    </tr>
                    <tr>
                      <td>
                        <FormKit type="select" v-model="formDataUpdate[event.eventParticipants[0].id]"
                          validation="required" :validation-messages="{ required: globalStore.vocabularyStore.getWordByIdAndLanguage('form required error', globalStore.languageId) }">
                          <optgroup :label="globalStore.getWordById('team players', globalStore.languageId)">
                            <option v-for="(participant, id) in homeParticipants" :key="id" :value="id">{{ participant }}</option>
                          </optgroup>
                          <optgroup :label="globalStore.getWordById('team player substitutes', globalStore.languageId)">
                            <option v-for="(id, participant) in globalStore.swapObject(homeParticipantSubstitutes, true)" :key="id" :value="id">{{ participant }}</option>
                          </optgroup>
                        </FormKit>
                      </td>
                      <td>
                        <FormKit type="select" v-model="formDataUpdate[event.eventParticipants[1].id]"
                          validation="required" :validation-messages="{ required: globalStore.vocabularyStore.getWordByIdAndLanguage('form required error', globalStore.languageId) }">
                          <optgroup :label="globalStore.getWordById('team players', globalStore.languageId)">
                            <option v-for="(participant, id) in awayParticipants" :key="id" :value="id">{{ participant }}</option>
                          </optgroup>
                          <optgroup :label="globalStore.getWordById('team player substitutes', globalStore.languageId)">
                            <option v-for="(id, participant) in globalStore.swapObject(awayParticipantSubstitutes, true)" :key="id" :value="id">{{ participant }}</option>
                          </optgroup>
                        </FormKit>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <ButtonSave :click-function="updateParticipants" data-bs-dismiss="modal" type="button" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <button @click="removeParticipants()" type="button" class="btn btn-danger btn-sm">{{ globalStore.getWordById('delete players to event') }}</button>
    <span>&#160;</span>
    <button v-if="!actionActive" @click="setViewData" type="button" class="btn btn-primary btn-sm">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('set players to event', globalStore.languageId) }}</button>
    <button v-else @click="actionUpdateActive = true" type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" :data-bs-target="'#teamEvent' + formData.id + '-update'">{{ globalStore.getWordById('change players to event') }}</button>
  </template>
  <span>&#160;</span>
</template>

<script>

import { ref, toRef } from "vue";
import { useGlobalStore } from "@/stores/GlobalStore";
import { useNotificationStore } from "@/stores/NotificationStore";
import { useEventStore } from "@/stores/EventStore";
import { useLiveScoreStore } from "@/stores/LiveScoreStore";
import { useTeamSubstituteStore } from "@/stores/TeamSubstituteStore";
import { useParticipantStore } from "@/stores/ParticipantStore";
import ButtonSave from "@/components/Button/ButtonSave.vue";

export default {
  name: 'TeamEventParticipantSettingModal',
  components: { ButtonSave },
  props: {
    data: {
      required: true,
      type: Object
    }
  },

  setup(props) {
    const itemData = toRef(props, 'data'),
      actionActive = ref(false),
      actionUpdateActive = ref(false),
      formData = ref({}),
      formDataUpdate = ref({}),
      disciplineNames = ref({}),
      teamNames = ref({}),
      homeParticipants = ref({}),
      homeParticipantSubstitutes = ref({}),
      awayParticipants = ref({}),
      awayParticipantSubstitutes = ref({}),
      eventsToUpdate = ref([]),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      eventStore = useEventStore(),
      teamSubstituteStore = useTeamSubstituteStore(),
      participantStore = useParticipantStore(),
      liveScoreStore = useLiveScoreStore()

    async function setViewData() {
      for (let disciplineId in itemData.value.events) {
        disciplineId = disciplineId.trim()
        const countEvents = itemData.value.events[disciplineId]
        formData.value[disciplineId] = {
          home: [],
          away: [],
        }
        for (let j = 0; j < countEvents; j++) {
          formData.value[disciplineId].home.push('')
          formData.value[disciplineId].away.push('')
        }
      }
      for (let teamIndex in itemData.value.teams) {
        for (let teamId in itemData.value.teams[teamIndex]) {
          if (teamId !== null) {
            const teamName = itemData.value.teams[teamIndex][teamId]

            await getSubstitutes(teamId, itemData.value.competitionId, itemData.value.tournamentId, itemData.value.dateStart)
              .then(substitutes => {
                if (+teamIndex === 0) {
                  teamNames.value.home = teamName
                  homeParticipantSubstitutes.value = substitutes
                  homeParticipants.value = itemData.value.teamParticipants[teamId]
                } else {
                  teamNames.value.away = teamName
                  awayParticipantSubstitutes.value = substitutes
                  awayParticipants.value = itemData.value.teamParticipants[teamId]
                }
              }).catch(e => console.error(e.message))
          }
        }
      }

      disciplineNames.value = itemData.value.disciplines
      formData.value.id = itemData.value.teamEventId
      actionActive.value = true
    }

    async function getSubstitutes(teamId, competitionId, tournamentId, dateStart) {
      if (teamSubstituteStore.teamSubstitutes[teamId] === undefined) {
        return await fetch(globalStore.apiUrl + '/admin/team-substitute/' + teamId + '/' + competitionId + '/' + tournamentId + '/' + dateStart, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => res.json())
          .then(data => {
            teamSubstituteStore.setData(data.data)
            participantStore.addData(data.data[teamId])
            return teamSubstituteStore.teamSubstitutes[teamId]
          })
      } else {
        return teamSubstituteStore.teamSubstitutes[teamId]
      }
    }

    function isFormValid() {
      let success = true
      for (let discipline in formData.value) {
        for (let team in formData.value[discipline]) {
          if (!formData.value[discipline][team].length) {
            notificationStore.add({
              type: 'error',
              message: globalStore.getWordById('team event setting participant empty error', globalStore.languageId)
            })
            success = false
          }
          const unique = [...new Set(formData.value[discipline][team])];
          if (unique.length !== formData.value[discipline][team].length) {
            notificationStore.add({
              type: 'error',
              message: globalStore.getWordById('team event setting participant doubles error', globalStore.languageId)
            })
            success = false
          }
        }
      }
      return success
    }

    function setParticipants() {
      if (!isFormValid()) {
        return
      }
      fetch(globalStore.apiUrl + '/admin/team-event-participant', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data && response.data.eventParticipant) {
            eventStore.updateParticipants(response.data.eventParticipant)
            notificationStore.add({
              type: 'success',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)
            })
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({
              type: 'error',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
            })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function removeParticipants() {
      fetch(globalStore.apiUrl + '/admin/team-event-participant', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: itemData.value.teamEventId })
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data && response.data.eventParticipant) {
            eventStore.updateParticipants(response.data.eventParticipant)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            actionUpdateActive.value = false
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function hasAnyEventParticipant() {
      const events = eventStore.getTeamEventEvents(itemData.value.teamEventId)
      for (let i in events) {
        for (let j in events[i].eventParticipant) {
          if (events[i].eventParticipant[j].participantId) {
            return true
          }
        }
      }
      return false
    }

    function hasAnyEventScore() {
      const events = eventStore.getTeamEventEvents(itemData.value.teamEventId)
      for (let i in events) {
        for (let j in events[i].eventParticipant) {
          if (events[i].eventParticipant[j].score !== null) {
            return true
          }
        }
      }
      return false
    }

    function hasAnyEventLiveScore() {
      const events = eventStore.getTeamEventEvents(itemData.value.teamEventId)
      for (let i in events) {
        if (liveScoreStore.liveScores[events[i].id]) {
          return true
        }
      }
      return false
    }

    function getEventParticipantsToUpdate() {
      if (!eventsToUpdate.value.length) {
        formDataUpdate.value = {}
        const events = eventStore.getTeamEventEvents(itemData.value.teamEventId)
        let round = null,
          disciplineId = null
        for (let i in events) {
          const event = events[i]
          if (!liveScoreStore.liveScores[event.id]) {
            const eventParticipants = event.eventParticipant
            let isParticipantsUpdatable = true
            for (let j in eventParticipants) {
              if (eventParticipants[j].score !== null) {
                isParticipantsUpdatable = false
              }
            }
            if (isParticipantsUpdatable) {
              const eventToUpdate = {
                round: event.round,
                disciplineId: event.disciplineId,
                eventParticipants: eventParticipants
              }
              if (disciplineId !== event.disciplineId || round !== event.round) {
                eventToUpdate.hasTitle = true
                disciplineId = event.disciplineId
                round = event.round
              }
              eventsToUpdate.value.push(eventToUpdate)
              formDataUpdate.value[eventParticipants[0].id] = eventParticipants[0].participantId
              formDataUpdate.value[eventParticipants[1].id] = eventParticipants[1].participantId
            }
          }
        }
      }
      return eventsToUpdate.value
    }

    function updateParticipants() {
      const events = eventStore.getTeamEventEvents(itemData.value.teamEventId),
        eventParticipants = {}
      for (let i in events) {
        const event = events[i]
        for (let j in event.eventParticipant) {
          eventParticipants[event.eventParticipant[j].id] = +event.eventParticipant[j].participantId
        }
      }

      for (let eventParticipantId in formDataUpdate.value) {
        const participantId = +formDataUpdate.value[eventParticipantId]
        if (eventParticipants[eventParticipantId] !== participantId) {
          const dataToUpdate = {
            id: eventParticipantId,
            participantId: participantId
          }
          fetch(globalStore.apiUrl + '/admin/team-event-event-participant', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dataToUpdate)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200 && response.data && response.data.eventParticipant) {
                eventStore.updateParticipants(response.data.eventParticipant)
                notificationStore.add({
                  type: 'success',
                  message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)
                })
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({
                  type: 'error',
                  message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
                })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    return {
      itemData, formData, teamNames, homeParticipants, awayParticipants, disciplineNames, globalStore,
      homeParticipantSubstitutes, awayParticipantSubstitutes, actionActive, actionUpdateActive, formDataUpdate,
      setParticipants, removeParticipants, hasAnyEventParticipant, hasAnyEventScore, hasAnyEventLiveScore,
      setViewData, getEventParticipantsToUpdate, updateParticipants
    }
  }
}
</script>
