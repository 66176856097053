<template>
  <tr>
    <template v-if="teamEventRound">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          {{ teamEventRound.eventCount }} x
        </td>
        <td>
          {{ globalStore.getDisciplineNameById(teamEventRound.disciplineId) }}
          <ul>
            <li v-for="row in teamEventRoundParameterStore.teamEventRoundParameters[teamEventRound.id]" :key="row.id">
              {{ globalStore.getEventParameterNameById(row.eventParameterId) }}:
              {{ row.value }}
            </li>
          </ul>
        </td>
        <td>
          <template v-if="!globalStore.teamEventStore.getTeamEventsByCompetition(competitionId).length">
            <ButtonUpdate
              :click-function="initUpdateForm" />
            <span>&#160;</span>
            <ButtonDelete
              :click-function="deleteItem" />
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="4">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="2">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useTeamEventRoundStore } from "@/stores/TeamEventRoundStore"
import { useTeamEventRoundParameterStore } from "@/stores/TeamEventRoundParameterStore"
import { useEventParameterStore } from "@/stores/EventParameterStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'CompetitionEventRoundItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    cId: {
      required: true,
      type: Number
    },
    item: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const teamEventRound = toRef(props, 'item'),
      competitionId = toRef(props, 'cId'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([]),
      teamEventRoundStore = useTeamEventRoundStore(),
      eventParameterStore = useEventParameterStore(),
      teamEventRoundParameterStore = useTeamEventRoundParameterStore()

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'number',
        name: 'eventCount',
        validation: 'required',
        label: globalStore.getWordById('event count')
      })

      const disciplineOptions = {}
      for (let i in globalStore.competitionDisciplineStore.competitionDisciplines) {
        const row = globalStore.competitionDisciplineStore.competitionDisciplines[i]
        disciplineOptions[row.disciplineId] = globalStore.getDisciplineNameById(row.disciplineId)
      }
      schema.value.push({
        $formkit: 'select',
        name: 'disciplineId',
        options: disciplineOptions,
        validation: 'required',
        label: globalStore.getWordById('discipline')
      })

      schema.value.push({
        $el: 'h3',
        children: 'Parametry zápasu'
      });

      for (let i in eventParameterStore.eventParameters) {
        const item = eventParameterStore.eventParameters[i]
        if (
          item.id === 9 ||
          item.id === 12 ||
          item.id === 1 ||
          item.id === 2 ||
          item.id === 3 ||
          item.id === 4 ||
          item.id === 5
        ) {
          const parameter = {
            $formkit: 'number',
            name: item.id.toString(),
            label: globalStore.getEventParameterNameById(item.id),
          }

          if (item.id === 1 || item.id === 2 || item.id === 10 || item.id === 11 || item.id === 12) {
            parameter.validation = 'required'
            parameter.min = 1
          } else if (item.id === 9) {
            parameter.min = 2
            parameter.max = 3
            parameter.validation = 'required|min:2|max:3'
          } else if (item.id === 4 || item.id === 5) {
            parameter.$formkit = 'checkbox'
            parameter.options = globalStore.getSvlOnlyTrue
          }
          schema.value.push(parameter)
        }
      }

      schema.value.push({
        $el: 'h6',
        children: 'Parametry livescore'
      });

      for (let i in eventParameterStore.eventParameters) {
        const item = eventParameterStore.eventParameters[i]
        if (
          item.id === 6 ||
          item.id === 7 ||
          item.id === 8 ||
          item.id === 13 ||
          item.id === 14
        ) {
          const parameter = {
            $formkit: 'number',
            name: item.id.toString(),
            label: globalStore.getEventParameterNameById(item.id),
            id: 'id' + item.id.toString(),
            min: 1
          }
          if (item.id === 13) {
            parameter.help = globalStore.vocabularyStore.getWordByIdAndLanguage('event timeout alert help', globalStore.languageId)
          } else if (item.id === 14) {
            parameter.help = globalStore.vocabularyStore.getWordByIdAndLanguage('event autotimeout help', globalStore.languageId)
          }
          /*if (item.id === 6 || item.id === 7 || item.id === 8 || item.id === 13) {
            parameter.if = '$get(id14).value === undefined'
          } else if (item.id === 14) {
            parameter.if = '$get(id6).value === undefined'
          }*/
          schema.value.push(parameter)
        }
      }
    }

    function initAddForm() {
      itemData.value = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      if (!teamEventRound.value) {
        return
      }
      itemData.value = {
        disciplineId: teamEventRound.value.disciplineId,
        eventCount: teamEventRound.value.eventCount,
      }
      if (teamEventRoundParameterStore.hasTeamEventRoundParameters(+teamEventRound.value.id)) {
        for (let i in teamEventRoundParameterStore.teamEventRoundParameters[teamEventRound.value.id]) {
          const parameter = teamEventRoundParameterStore.teamEventRoundParameters[teamEventRound.value.id][i]
          itemData.value[parameter.eventParameterId] = parameter.value
        }
      }
      if (itemData.value[4]) {
        itemData.value[4] = [itemData.value[4]]
      }
      if (itemData.value[5]) {
        itemData.value[5] = [itemData.value[5]]
      }
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.competitionId = competitionId.value
      itemData.value.stageId = 15
      fetch(globalStore.apiUrl + '/admin/competition-event-stage-round', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            teamEventRoundStore.addData([response.data.teamEventStage])
            teamEventRoundParameterStore.addDataFromArray(response.data.teamEventStageParameter)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (teamEventRound.value) {
        itemData.value.competitionId = competitionId.value
        itemData.value.id = teamEventRound.value.id
        itemData.value.stageId = teamEventRound.value.stageId
        fetch(globalStore.apiUrl + '/admin/competition-event-stage-round', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              teamEventRoundStore.actualizeRow(response.data.teamEventStage)
              teamEventRoundParameterStore.replaceRoundParameters(response.data.teamEventStageParameter, teamEventRound.value.id)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = teamEventRound.value
        fetch(globalStore.apiUrl + '/admin/competition-event-stage-round', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              teamEventRoundStore.deleteDataById(teamEventRound.value.id)
              teamEventRoundParameterStore.deleteRoundParametersByEventStageId(teamEventRound.value.id)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive,
      schema, teamEventRound, teamEventRoundParameterStore, competitionId,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
