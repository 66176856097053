<template>
  <table v-if="globalStore.userAdminStore.propertiesLoaded" class="table table-striped">
    <tbody>
      <CompetitionEventRoundItem :cId="id" />
      <template v-if="teamEventRoundStore.teamEventRounds.length">
        <template v-for="(row, i) in teamEventRoundStore.teamEventRounds" :key="i">
          <CompetitionEventRoundItem v-if="row && row.id" :cId="competitionId" :item="row" />
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import { toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useTeamEventRoundStore } from "@/stores/TeamEventRoundStore"
import CompetitionEventRoundItem from "@/components/CompetitionManagement/CompetitionEventRoundItem"

export default {
  name: 'CompetitionEventRoundList',
  components: { CompetitionEventRoundItem },
  props: {
    competitionId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const id = toRef(props, 'competitionId'),
      globalStore = useGlobalStore(),
      teamEventRoundStore = useTeamEventRoundStore()

    return {
      globalStore, id, teamEventRoundStore
    }
  }
}
</script>
