<template>
  <table class="table table-sm">
    <tbody>
      <tr>
        <template v-if="club">
          <template v-if="updateFormActive">
            <td>
              <ButtonBack :click-function="deInitUpdateForm" />
            </td>
            <td colspan="3">
              <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
                <FormKitSchema :schema="schema" />
              </FormKit>
            </td>
          </template>
          <template v-else>
            <td v-html="globalStore.generateImgPreview(club.logo)"></td>
            <td>
              <ButtonUpdate
                :click-function="initUpdateForm" />
              <span>&#160;</span>
              <ButtonDelete v-if="club.logo"
                :click-function="deleteItem" />
            </td>
          </template>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate"
import ButtonDelete from "@/components/Button/ButtonDelete"
import ButtonBack from "@/components/Button/ButtonBack"

export default {
  name: 'ClubLogoItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack },
  props: {
    clubObject: {
      required: true,
      type: Object
    }
  },

  setup(props) {
    const club = toRef(props, 'clubObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref(false),
      schema = ref([])

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'file',
        name: 'logo',
        label: globalStore.getWordById('image attachment') + (club.value && club.value.logo ? ' ' + globalStore.getWordById('replace') + ' ' + club.value.logo : ''),
        multiple: false,
        validation: 'required',
        accept: '.jpg,.jpeg,.gif,.png,.svg'
      })
    }

    function initUpdateForm() {
      if (!club.value) {
        return
      }
      const item = Object.assign({}, club.value)
      itemData.value = item
      setSchema()
      updateFormActive.value = true
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processUpdateForm() {
      if (club.value) {
        const formData = new FormData()
        for (let i in itemData.value) {
          if (i.includes('logo')) {
            formData.append(i, document.querySelector('input[name="' + i + '"]').files[0] || '')
          } else {
            formData.append(i, itemData.value[i] || '')
          }
        }

        fetch(globalStore.apiUrl + '/admin/club-logo', {
          method: 'POST',
          body: formData
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.clubStore.setData(response.data.club)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = club.value
        fetch(globalStore.apiUrl + '/admin/club-logo', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.clubStore.setData(response.data.club)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, schema, club,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm
    }
  }
}
</script>
