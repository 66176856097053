import { defineStore } from 'pinia'
import { useGlobalStore } from "@/stores/GlobalStore";

export const useTeamInSeasonStore = defineStore('TeamInSeasonStore', {
  state: () => {
    return {
      teamsInSeason: [],
      globalStore: useGlobalStore()
    }
  },
  getters: {
    count: state => state.teamsInSeason.length,
    getById: state => {
      return id => {
        for (let i in state.teamsInSeason) {
          if (+state.teamsInSeason[i].id === +id) {
            return state.teamsInSeason[i]
          }
        }
      }
    },
    getByTeamId: state => {
      return id => {
        for (let i in state.teamsInSeason) {
          if (+state.teamsInSeason[i].participantId === +id) {
            return state.teamsInSeason[i]
          }
        }
      }
    },
    getByClubIdSeason: state => {
      return (motherClubId, season) => {
        return state.teamsInSeason.filter(row => {
          const parameter = state.globalStore.participantSeasonParameterStore.getBySeasonParticipantId(row.id, 1)
          if (parameter.length) {
            return +parameter[0].clubId === +motherClubId && +row.season === season
          }
          return false
        })
      }
    }
  },
  actions: {
    setData(data) {
      this.teamsInSeason = data
    },
    addData(data) {
      for (let i in data) {
        const teamInSeason = data[i],
          exists = this.teamsInSeason.filter(row => +row.id === +teamInSeason.id).length

        if (!exists) {
          this.teamsInSeason.push(teamInSeason)
        }
      }
    },
    deleteDataById(id) {
      this.teamsInSeason = this.teamsInSeason.filter(row => +row.id !== +id)
    },
    emptyData() {
      this.teamsInSeason = []
    }
  }
})
