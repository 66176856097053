<template>
  <RegistryOfficeLog />
</template>

<script>
import RegistryOfficeLog from "@/components/RegistryOfficeLog";
export default {
  components: { RegistryOfficeLog }
}
</script>
