<template>
  <template v-if="globalStore.isUserTypeAdministrator">
    <template v-if="addFormActive === true">
      <ButtonBack :click-function="deInitAddForm" />
      <br />
      <br />
      <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
        <FormKitSchema :schema="schema" :data="itemData['']" />
      </FormKit>
    </template>
    <template v-else>
      <ButtonAdd :click-function="initAddForm"
        :word="globalStore.vocabularyStore.getWordByIdAndLanguage('add stage definition', globalStore.languageId)" />
      <br />
      <br />
    </template>
  </template>
  <template v-if="competitionStageStore.competitionStages.length">
    <table class="table table-hover table-striped align-middle table-light">
      <tbody>
        <tr v-for="row in competitionStageStore.competitionStages" :key="row.id">
          <td>
            <h6>{{ globalStore.getStageNameById(row.stageId) }}</h6>
            <template v-if="updateFormActive[row.id]">
              <table class="table table-hover table-striped align-middle table-light">
                <tbody>
                  <template
                    v-if="globalStore.isUserTypeAdministrator && updateFormActive[row.id] === true && itemData[row.id].id === row.id">
                    <tr>
                      <td>
                        <ButtonBack :click-function="deInitUpdateForm" :click-function-parameter="row.id" />
                      </td>
                      <td>
                        <FormKit type="form" v-model="itemData[row.id]" @submit="processUpdateForm(row.stageId)">
                          <FormKitSchema :schema="schema" :data="itemData[row.id]" />
                        </FormKit>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </template>
            <ul v-else>
              <template v-if="row.stageParts">
                <li>
                  <template v-for="stagePart in row.stageParts" :key="stagePart.id">
                    <ul>
                      <template v-for="(value, key) in stagePart" :key="key">
                        <template v-if="key === 'disciplineId' || key === 'eventCount'">
                          <li>
                            {{ globalStore.vocabularyStore.getWordByIdAndLanguage(key, 1) }}:
                            {{ value }}
                          </li>
                        </template>
                      </template>
                    </ul>
                  </template>
                </li>
              </template>
              <template v-else>
                <template v-for="(value, key, index) in row" :key="key">
                  <template v-if="key !== 'priority'">
                    <li v-if="index > 3">
                      {{ globalStore.vocabularyStore.getWordByIdAndLanguage(key, 1) }}:
                      <template v-if="key === 'stageSystemTypeId'">{{ stageSystemTypes[value] }}</template>
                      <template v-else-if="key === 'stageSettingTypeId'">{{ stageSettingTypes[value] }}</template>
                      <template v-else-if="key === 'eventOrderTypeId'">{{ eventOrderTypes[value] || eventOrderTypes4[value] || 'Default' }}</template>
                      <template v-else>{{ value }}</template>
                    </li>
                  </template>
                </template>
              </template>
            </ul>
            <CompetitionStageDefinitionEvent :stageId="row.stageId" />
          </td>
          <td>
            <template v-if="globalStore.isUserTypeAdministrator">
              <template v-if="updateFormActive[row.id]">
                <button @click="processUpdateForm(row.stageId)" class="btn btn-outline-primary btn-sm">Send</button>
              </template>
              <template v-else>
                <ButtonUpdate :click-function="initUpdateForm" :click-function-parameter="row.stageId" />
                <span>&#160;</span>
                <ButtonDelete :click-function="deleteItem" :click-function-parameter="row.stageId" />
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
</template>

<script>
import { ref } from 'vue'
import { useEventStore } from "@/stores/EventStore"
import { useCompetitionStageStore } from "@/stores/CompetitionStageStore"
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useStageStore } from "@/stores/StageStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";
import CompetitionStageDefinitionEvent from "@/components/CompetitionManagement/CompetitionStageDefinitionEvent";

export default {
  name: 'CompetitionStageDefinition',
  components: { ButtonDelete, ButtonUpdate, ButtonAdd, ButtonBack, CompetitionStageDefinitionEvent },

  setup() {
    const itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref(false),
      competitionStageStore = useCompetitionStageStore(),
      eventStageParameterStore = useEventStageParameterStore(),
      stageStore = useStageStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore(),
      eventStore = useEventStore(),
      stageSystemTypes = {
        '1': 'Každý s každým',
        '2': 'KO',
        '4': 'KO *'
      },
      stageSettingTypes = {
        '1': 'Náhodně',
        '2': 'Dle výsledku předchozí fáze'
      },
      eventOrderTypes = {
        '0': '[2-3][1-3][1-2]',
        '1': '[2-3][1-L][W-1]',
        '2': '[2-3][1-W][L-1]',
        '5': '[1-3][2-3][1-2]'
      },
      eventOrderTypes4 = {
        '0': '[2-3][1-4][2-4][1-3][3-4][1-2]',
        '3': '[2-3][1-4][L-L][W-W][?-?][1-?]',
        '4': '[2-3][1-4][W-L][L-W][?-?][1-?]',
      },
      schema = ref([])

    function setSchema() {
      schema.value = []
      if (addFormActive.value === true) {
        const stageOptions = {},
          competitionHasStage = {}

        for (let i in competitionStageStore.competitionStages) {
          competitionHasStage[competitionStageStore.competitionStages[i].stageId] = competitionStageStore.competitionStages[i].stageId
        }

        for (let i in stageStore.stages) {
          const stage = stageStore.stages[i]
          if (!competitionHasStage[stage.id]) {
            stageOptions[stage.id] = globalStore.vocabularyStore.getWordByIdAndLanguage(stage.name, 1)
          }
        }

        schema.value.push({
          $formkit: 'select',
          name: 'stageId',
          label: globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage', globalStore.languageId),
          options: stageOptions,
          validation: 'required'
        })
      }

      schema.value.push({
        $formkit: 'select',
        name: 'stageSystemTypeId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('stageSystemTypeId', globalStore.languageId),
        options: stageSystemTypes,
        validation: 'required'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'stageSettingTypeId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('stageSettingTypeId', globalStore.languageId),
        options: stageSettingTypes,
        validation: 'required'
      })

      schema.value.push({
        $formkit: 'number',
        name: 'participantMaxCount',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('participantMaxCount', globalStore.languageId),
        validation: 'required',
        min: 1
      })

      schema.value.push({
        $formkit: 'number',
        name: 'participantGroupPreferredCount',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('participantGroupPreferredCount', globalStore.languageId),
        validation: 'required',
        min: 1
      })

      schema.value.push({
        if: "$participantGroupPreferredCount == '3'",
        $formkit: 'select',
        name: 'eventOrderTypeId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('eventOrderTypeId', globalStore.languageId),
        options: eventOrderTypes
      })
      schema.value.push({
        if: "$participantGroupPreferredCount == '4'",
        $formkit: 'select',
        name: 'eventOrderTypeId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('eventOrderTypeId', globalStore.languageId),
        options: eventOrderTypes4
      })

      schema.value.push({
        $formkit: 'text',
        name: 'tournamentParticipantMinCount',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('tournamentParticipantMinCount', globalStore.languageId)
      })

      schema.value.push({
        $formkit: 'number',
        name: 'playgroundCount',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('playgroundCount', globalStore.languageId),
        validation: 'required',
        min: 1
      })

      schema.value.push({
        $formkit: 'number',
        name: 'eventDuration',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('eventDuration', globalStore.languageId),
        validation: 'required',
        min: 1
      })
    }

    function getItem(stageId) {
      for (let i in competitionStageStore.competitionStages) {
        if (+competitionStageStore.competitionStages[i].stageId === +stageId) {
          console.log(competitionStageStore.competitionStages[i])
          return competitionStageStore.competitionStages[i]
        }
      }
      return {}
    }

    function initAddForm() {
      itemData.value[''] = {}
      addFormActive.value = true
      setSchema()
    }

    function initUpdateForm(stageId) {
      const item = getItem(stageId)
      if (!item) {
        return
      }
      setSchema()
      itemData.value[item.id] = {
        id: item.id,
        stageId: item.stageId,
        stageSystemTypeId: item.stageSystemTypeId,
        stageSettingTypeId: item.stageSettingTypeId,
        eventOrderTypeId: item.eventOrderTypeId,
        participantMaxCount: item.participantMaxCount,
        participantGroupPreferredCount: item.participantGroupPreferredCount,
        competitionParticipantMinCount: item.competitionParticipantMinCount,
        tournamentParticipantMinCount: item.tournamentParticipantMinCount,
        playgroundCount: item.playgroundCount,
        eventDuration: item.eventDuration
      }
      updateFormActive.value[item.id] = true
    }

    function processAddForm() {
      itemData.value[''].competitionId = globalStore.competitionStore.competition.id
      fetch(globalStore.apiUrl + '/admin/tournament-event-stage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            const eventStageId = getItem(itemData.value[''].stageId).id,
              set = competitionStageStore.replaceDataRow(response.data)
            if (!set) {
              competitionStageStore.addDataRow(response.data)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            } else {
              eventStageParameterStore.deleteStageParametersByEventStageId(eventStageId)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            }
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => console.error(err))
    }

    function processUpdateForm(stageId) {
      const item = getItem(stageId)
      if (item) {
        itemData.value[item.id].competitionId = globalStore.competitionStore.competition.id
        itemData.value[item.id].stageId = stageId
        fetch(globalStore.apiUrl + '/admin/tournament-event-stage', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value[item.id])
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              competitionStageStore.replaceDataRow(response.data)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm(item.id)
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem(stageId) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(stageId)
        item.competitionId = globalStore.competitionStore.competition.id
        item.stageId = stageId
        if (item) {
          fetch(globalStore.apiUrl + '/admin/tournament-event-stage', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                if (response.data && response.data.item) {
                  competitionStageStore.replaceDataRow(response.data.item)
                  notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
                  if (response.data.parameters) {
                    eventStageParameterStore.replaceStageParameters(response.data.parameters, getItem(stageId).id)
                  }
                } else {
                  competitionStageStore.deleteDataRow(item)
                  notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
                }
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deInitUpdateForm(id) {
      updateFormActive.value[id] = false
      if (itemData.value[id]) {
        delete (itemData.value[id])
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    return {
      itemData, updateFormActive, addFormActive, stageSystemTypes, stageSettingTypes, competitionStageStore, schema,
      globalStore, eventStore, eventOrderTypes, eventOrderTypes4,
      getItem, initAddForm, initUpdateForm, processAddForm, processUpdateForm, deleteItem, deInitUpdateForm, deInitAddForm
    }
  }
}
</script>
