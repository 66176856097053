import { defineStore } from 'pinia'

export const useCompetitionTeamEventParticipantSettingTypeStore = defineStore('CompetitionTeamEventParticipantSettingTypeStore', {
  state: () => {
    return {
      competitionTeamEventParticipantSettingType: {}
    }
  },
  getters: {
    count: state => Object.keys(state.competitionTeamEventParticipantSettingType).length
  },
  actions: {
    setData(data) {
      this.competitionTeamEventParticipantSettingType = data
    },
    addRow(row) {
      this.competitionTeamEventParticipantSettingType = row
    },
    updateRow(row) {
      this.competitionTeamEventParticipantSettingType = row
    },
    deleteRow() {
      this.competitionTeamEventParticipantSettingType = {}
    }
  }
})
