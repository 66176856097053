<template>
  <div class="table-responsive" v-if="club">
    <template v-if="globalStore.userAdminStore.propertiesLoaded">
      <table class="table table-striped">
        <tbody>
          <ClubOfficialItem :clubObject="club" />
          <template v-if="globalStore.officialStore.getByClubId(club.id).length">
            <template v-for="row in globalStore.officialStore.getByClubId(club.id)" :key="row.id">
              <ClubOfficialItem
                :clubObject="club"
                :officialObject="row" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef } from "vue"
import ClubOfficialItem from "@/components/ClubPresentation/ClubOfficialItem"

export default {
  name: 'ClubOfficialList',
  components: { ClubOfficialItem },
  props: {
    clubObject: {
      required: true,
      type: Object
    },
  },

  setup(props) {
    const club = toRef(props, 'clubObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadData()
    })

    function loadData() {
      if (club.value) {
        const url = globalStore.apiUrl + '/admin/club-officials/' + club.value.id
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => {
            if (res.status !== 200) {
              throw new Error(res.statusText)
            }
            return res.json()
          })
          .then(data => {
            globalStore.vocabularyStore.addRows(data.data.vocabulary)
            globalStore.officialStore.setData(data.data.official)
            globalStore.officialParticipantStore.setData(data.data.officialParticipant)
            globalStore.officialCategoryStore.setData(data.data.officialCategory)
            globalStore.participantStore.addData(data.data.participant)
          })
          .catch(err => notificationStore.add({ type: 'error', message: err }))
      }
    }

    return {
      globalStore, club
    }
  }
}
</script>
