<template>
  <tr>
    <template v-if="organizationSection">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          {{ globalStore.getWordById(globalStore.sectionStore.getById(organizationSection.sectionId).name) }}
          <button v-if="globalStore.userAdminStore.isUserRegistry" @click="configVisible = !configVisible" class="btn btn-sm btn-outline-secondary d-block mt-2">Config</button>
        </td>
        <td>
          {{ organizationSection.subdomain }}
        </td>
        <td>
          <ButtonUpdate
            :click-function="initUpdateForm" />
          <span>&#160;</span>
          <ButtonDelete
            :click-function="deleteItem" />
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="3">
        <ButtonAdd :viewPermanently="true" :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
  <tr v-if="organizationSection && configVisible">
    <td colspan="9">
      <SectionSettings :organizationSectionIdSet="organizationSection.id" />
    </td>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";
import SectionSettings from "@/components/OrganizationManagement/SectionManagement/SectionSettings";

export default {
  name: 'OrganizationSectionItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd, SectionSettings },
  props: {
    organizationIdSet: {
      required: false,
      type: Number
    },
    organizationSectionObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const organizationId = toRef(props, 'organizationIdSet'),
      organizationSection = toRef(props, 'organizationSectionObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      configVisible = ref(false),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([])

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'select',
        name: 'sectionId',
        validation: 'required',
        options: globalStore.getSvlSection,
        label: globalStore.getWordById('choose section')
      })

      schema.value.push({
        $formkit: 'text',
        name: 'value',
        label: globalStore.getWordById('subdomain')
      })
    }

    function initAddForm() {
      itemData.value = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      if (!organizationSection.value) {
        return
      }
      const item = Object.assign({}, organizationSection.value)
      itemData.value = item
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.organizationId = organizationId.value
      fetch(globalStore.apiUrl + '/admin/organization-section', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.organizationHasSectionStore.setData(response.data.organizationHasSection)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (organizationSection.value) {
        fetch(globalStore.apiUrl + '/admin/organization-section', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.organizationHasSectionStore.setData(response.data.organizationHasSection)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = organizationSection.value
        fetch(globalStore.apiUrl + '/admin/organization-section', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.organizationHasSectionStore.deleteData(item)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive, schema, organizationId, organizationSection,
      configVisible,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
