import {defineStore} from 'pinia'

export const useStaticValueListStore = defineStore('staticValueListStore', {
  state: () => {
    return {
      staticValueList: {}
    }
  },
  getters: {
    count: state => Object.keys(state.staticValueList).length,
    getItemByTypeAndId: state => {
      return (type, id) => {
        if (!state.staticValueList[type]) {
          return null;
        }
        for (let i in state.staticValueList[type]) {
          if (+state.staticValueList[type][i].id === +id) {
            return state.staticValueList[type][i]
          }
        }
      }
    }
  },
  actions: {
    setData(type, data) {
      this.initType(type);
      this.staticValueList[type] = data
    },
    addRows(type, rows) {
      this.initType(type);
      for (let i in rows) {
        this.addRow(type, rows[i])
      }
    },
    addRow(type, row) {
      this.initType(type);
      this.staticValueList[type][row.id] = row
    },
    updateRow(type, row) {
      this.initType(type);
      this.staticValueList[type][row.id] = row
    },
    deleteRow(type, id) {
      this.initType(type);
      this.staticValueList[type] = this.staticValueList[type].filter(row => row.id !== id)
    },
    initType(type) {
      this.staticValueList[type] = this.staticValueList[type] || {};
    }
  }
})