import { createRouter, createWebHistory } from 'vue-router'

import TournamentManagement from '@/views/TournamentManagement'
import CompetitionManagement from '@/views/CompetitionManagement'
import CompetitionList from '@/views/CompetitionList'
import CommentList from '@/views/CommentList'
import TournamentList from '@/views/TournamentList'
import UserList from '@/views/UserList'
import StaticValueList from '@/views/StaticValueList'
import ParticipantAdmin from '@/views/ParticipantAdmin'
import ParticipantList from '@/views/ParticipantList'
import RegistryOfficeLogPage from '@/views/RegistryOfficeLogPage'
import OrganizationsAdmin from '@/views/OrganizationsAdmin'
import TeamList from '@/views/TeamList'
import ClubList from '@/views/ClubList'
import TeamsInSeason from '@/views/TeamsInSeason'
import ParticipantsInSeason from '@/views/ParticipantsInSeason'
import ParticipantsMerge from '@/views/ParticipantsMerge'
import LoginAdmin from '@/views/LoginAdmin'
import { useCookiesStore } from '@/stores/CookiesStore'
import { useUserAdminStore } from '@/stores/UserAdminStore'
import HomePage from "@/components/HomePage";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login-admin',
    name: 'LoginAdmin',
    component: LoginAdmin,
    beforeEnter: requireNotLoggedAdmin
  },
  {
    path: '/tournament-management/:id',
    name: 'TournamentManagement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: TournamentManagement,
    beforeEnter: requireLoggedAdmin
  },
  {
    path: '/competition-management/:id',
    name: 'CompetitionManagement',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CompetitionManagement,
    beforeEnter: requireLoggedAdmin
  },
  {
    path: '/comments',
    name: 'Comments',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CommentList,
    beforeEnter: requireLoggedAdmin
  },
  {
    path: '/static-value-list',
    name: 'StaticValueList',
    component: StaticValueList,
    beforeEnter: requireLoggedAdminSuperUser
  },
  {
    path: '/organizations',
    name: 'OrganizationstAdmin',
    component: OrganizationsAdmin,
    beforeEnter: requireLoggedAdminRegistryUser
  },
  {
    path: '/participants',
    name: 'ParticipantList',
    component: ParticipantList,
    beforeEnter: requireLoggedAdminRegistryUser
  },
  {
    path: '/participant',
    name: 'ParticipantAdmin',
    component: ParticipantAdmin,
    beforeEnter: requireLoggedAdmin
  },
  {
    path: '/participants-merge',
    name: 'ParticipantsMerge',
    component: ParticipantsMerge,
    beforeEnter: requireLoggedAdminSuperUser
  },
  {
    path: '/registry-office-log',
    name: 'RegistryOfficeLog',
    component: RegistryOfficeLogPage,
    beforeEnter: requireLoggedAdminSuperUser
  },
  {
    path: '/teams',
    name: 'Teams',
    component: TeamList,
    beforeEnter: requireLoggedAdminRegistryUser
  },
  {
    path: '/teams-in-season',
    name: 'TeamsInSeason',
    component: TeamsInSeason,
    //beforeEnter: requireLoggedAdminRegionUser
  },
  {
    path: '/participants-in-season',
    name: 'ParticipantsInSeason',
    component: ParticipantsInSeason,
    beforeEnter: requireLoggedAdminRegistryUser
  },
  {
    path: '/competitions',
    name: 'CompetitionList',
    component: CompetitionList,
    beforeEnter: requireLoggedAdmin
  },
  {
    path: '/tournaments',
    name: 'TournamentList',
    component: TournamentList,
    beforeEnter: requireLoggedAdmin
  },
  {
    path: '/users',
    name: 'userList',
    component: UserList,
    beforeEnter: requireLoggedAdminSuperUser
  },
  {
    path: '/clubs',
    name: 'clubList',
    component: ClubList,
    beforeEnter: requireLoggedAdminRegistryUser
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: 'router-link-exact-active active'
})

function requireLoggedAdmin(to, from, next) {
  if (useCookiesStore().getCookieValueByName('aLogged')) {
    next()
  } else {
    next('login-admin');
  }
}

function requireLoggedAdminSuperUser(to, from, next) {
  if (useUserAdminStore().isUserSuperUser) {
    next()
  } else {
    next('login-admin');
  }
}

function requireLoggedAdminRegistryUser(to, from, next) {
  if (useUserAdminStore().isUserRegistry) {
    next()
  } else {
    next('login-admin');
  }
}

/* function requireLoggedAdminRegionUser(to, from, next) {
  if (useUserAdminStore().isUserRegion) {
    next()
  } else {
    next('login-admin');
  }
}
 */
function requireNotLoggedAdmin(to, from, next) {
  if (!useCookiesStore().getCookieValueByName('aLogged')) {
    next()
  } else {
    next(from)
  }
}

export default router
