<template>
  <MenuBase v-if="!competitionId" :onChangeFn="loadTournaments" />
  <table v-if="globalStore.userAdminStore.propertiesLoaded" class="table table-striped">
    <tbody>
      <TournamentItem v-if="globalStore.userAdminStore.isUserRegion" :competitionId="competitionId" />
      <template v-if="globalStore.tournamentStore.tournaments.length">
        <template v-for="(row, i) in globalStore.tournamentStore.tournaments" :key="i">
          <TournamentItem v-if="row && row.id" :competitionId="row.competitionId" :tournamentId="row.id" :tournament="row" />
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef } from "vue"
import MenuBase from "@/components/Menu/MenuBase"
import TournamentItem from "@/components/TournamentItem"

export default {
  name: 'TournamentList',
  components: { MenuBase, TournamentItem },
  props: {
    competitionId: {
      required: false,
      type: Number
    }
  },

  setup(props) {
    const cId = toRef(props, 'competitionId'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadTournaments()
      loadClubs()
    })

    function loadTournaments() {
      if (!globalStore.userAdminStore.activeSeason) {
        return
      }
      fetch(globalStore.apiUrl + '/admin/tournaments/' + (globalStore.userAdminStore.activeOrganization || 'null') + '/' + (globalStore.userAdminStore.activeSection || 'null') + '/' + (globalStore.userAdminStore.activeRegion || 'null') + '/' + (globalStore.userAdminStore.activeSeason || 'null') + '/' + (globalStore.userAdminStore.activeClub || 'null') + '/' + (cId.value || 'null'), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.tournamentStore.setData(data.data.tournament)
          globalStore.tableSizeStore.setData(data.data.tableSize)
          globalStore.levelStore.setData(data.data.level)
          globalStore.genderStore.setData(data.data.gender)
          globalStore.ageStore.setData(data.data.age)
          globalStore.teamTypeStore.setData(data.data.teamType)
          globalStore.disciplineStore.setData(data.data.discipline)
          globalStore.disciplineStore.setSectionTableData(data.data.sectionTableDiscipline)
          globalStore.tournamentDisciplineStore.setData(data.data.tournamentDiscipline)
          globalStore.tournamentRegionStore.setData(data.data.tournamentRegion)
          globalStore.loungeClubAddressTableStore.addData(data.data.loungeClubAddressTable)
          globalStore.tournamentVenueStore.addDataRows(data.data.tournamentVenue)
          globalStore.statusStore.addDataRows(data.data.status)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    function loadClubs() {
      if (globalStore.clubStore.clubs.length) {
        return;
      }

      fetch(globalStore.apiUrl + '/admin/organization-clubs/' + (globalStore.userAdminStore.activeOrganization || 'null'), {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.clubStore.addDataRows(data.data.club)
          globalStore.loungeClubAddressTableStore.addData(data.data.loungeClubAddressTable)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, loadTournaments
    }
  }
}
</script>
