<template>
  <table class="table table-hover table-striped align-middle table-light">
    <tbody>
      <template v-for="(participant, index) in getRows()" :key="participant.id">
        <tr>
          <td>{{ index + 1 }}.</td>
          <td>{{ participant.tournamentParticipantId ? globalStore.getTournamentParticipantNameById(participant.tournamentParticipantId) : globalStore.getTournamentParticipantNameById(participant.tournamentTeamId) }}</td>
        </tr>
      </template>
    </tbody>
  </table>
  <table v-for="(data, stageSign) in getGroupedRows()" :key="stageSign" class="table table-hover table-striped align-middle table-light">
    <thead>
      <th colspan="2">{{ stageSign !== 'null' && stageSign !== 'undefined' ? stageSign : ''}}</th>
      <template v-for="criterion in tournamentResultCriterionStore.tournamentResultCriterion" :key="criterion.id">
        <th v-if="criterion.resultCriterionId < 14 || criterion.resultCriterionId > 16">
          {{ globalStore.getResultCriterionShortNameById(criterion.resultCriterionId) }}
        </th>
      </template>
    </thead>
    <tbody>
    <template v-for="(participant, index) in data" :key="participant.id">
      <tr>
        <td>{{ index + 1 }}.</td>
        <td>{{ participant.tournamentParticipantId ? globalStore.getTournamentParticipantNameById(participant.tournamentParticipantId) : globalStore.getTournamentParticipantNameById(participant.tournamentTeamId) }}</td>
        <template v-for="criterion in tournamentResultCriterionStore.tournamentResultCriterion" :key="criterion.id">
          <template v-for="(result, key) in tournamentStageRankingStore.tournamentStageRanking[stageId]" :key="key">
            <template v-if="participant.tournamentParticipantId && result.tournamentParticipantId === participant.tournamentParticipantId ||
                            participant.tournamentTeamId && result.tournamentParticipantId === participant.tournamentTeamId">
              <td v-if="criterion.resultCriterionId < 14 || criterion.resultCriterionId > 16">
                {{ result[criterion.resultCriterionId] }}
              </td>
            </template>
          </template>
        </template>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
import { useTournamentParticipantStageStore } from "@/stores/TournamentParticipantStageStore"
import { useTournamentResultCriterionStore } from "@/stores/TournamentResultCriterionStore"
import { useTournamentStageRankingStore } from "@/stores/TournamentStageRankingStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import {toRef} from "vue";

export default {
  name: 'TournamentStageRanking',
  components: {},
  props: {
    stageId: {
      required: false,
      type: Number
    }
  },

  setup(props) {
    const globalStore = useGlobalStore(),
      tournamentParticipantStageStore = useTournamentParticipantStageStore(),
      tournamentResultCriterionStore = useTournamentResultCriterionStore(),
      tournamentStageRankingStore = useTournamentStageRankingStore(),
      stage = toRef(props, 'stageId')

    function getRows() {
      return tournamentStageRankingStore.tournamentStageRanking[stage.value]
    }

    function getGroupedRows() {
      const rows = {};
      for (let i in tournamentStageRankingStore.tournamentStageRanking[stage.value]) {
        const participant = tournamentStageRankingStore.tournamentStageRanking[stage.value][i]
        if (!rows[participant.stageSign]) {
          rows[participant.stageSign] = []
        }
        rows[participant.stageSign].push(participant)
      }

      return Object.keys(rows)
          .sort()
          .reduce((accumulator, key) => {
            accumulator[key] = rows[key];
            return accumulator;
          }, {});
    }

    return {
      tournamentParticipantStageStore, tournamentResultCriterionStore, globalStore, tournamentStageRankingStore,
      getRows, getGroupedRows
    }
  }
}
</script>
