import { defineStore } from 'pinia'

export const useGenderStore = defineStore('GenderStore', {
  state: () => {
    return {
      genders: []
    }
  },
  getters: {
    count: state => state.genders.length,
    getById: state => {
      return id => {
        const gender = state.genders.filter(row => +row.id === +id)
        if (gender && gender[0]) {
          return gender[0]
        }
        return null
      }
    }
  },
  actions: {
    setData(data) {
      this.genders = data
    }
  }
})
