<template>
  <div class="modal fade show" id="loginAdmin" tabindex="-1" aria-labelledby="loginAdminLabel" aria-modal="true" style="display: block" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="loginAdminLabel">Login do administrace Výsledkového servisu</h5>
        </div>
        <div class="modal-body">
          <LoginAdmin/>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>

<script>

import LoginAdmin from "@/components/Login/LoginAdmin";

export default {
  name: 'LoginAdminModal',
  components: {LoginAdmin}
}
</script>
