<template>
  <tr>
    <template v-if="competitionParticipant">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <p>
              {{ globalStore.getParticipantNameById(competitionParticipant.participantId) }}
            </p>
            <FormKit
              type="number"
              name="setting"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('setting', globalStore.languageId)" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          {{ competitionParticipant.setting }}
        </td>
        <td>
          {{ globalStore.getParticipantNameById(competitionParticipant.participantId) }}
        </td>
        <td>
          <ButtonUpdate
            :click-function="initUpdateForm" />
          <span>&#160;</span>
          <ButtonDelete
            :click-function="deleteItem" />
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="4">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="2">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKit
              type="number"
              name="setting"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('setting', globalStore.languageId)" />
            <FormKit
              type="text"
              name="participantName"
              :help="globalStore.vocabularyStore.getWordByIdAndLanguage('team autoload', globalStore.languageId)"
              @keyup="loadParticipants(itemData, 'participantName', 'participantId')"
              autocomplete="off" />
            <FormKit
              type="select"
              :options="autoloadedParticipants"
              validation="required"
              name="participantId"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('team autoloaded', globalStore.languageId)" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'CompetitionParticipantItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    cId: {
      required: true,
      type: Number
    },
    item: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const competitionParticipant = toRef(props, 'item'),
      competitionId = toRef(props, 'cId'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      autoloadData = ref({}),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([]),
      autoloadedParticipants = ref({}),
      competition = globalStore.competitionStore.competition,
      competitionDisciplines = globalStore.competitionDisciplineStore.getByCompetitionId(competition.id)

    function setSchema() {
      schema.value = []

      schema.value.push({
        $formkit: 'number',
        name: 'setting',
        label: globalStore.getWordById('setting')
      })

      schema.value.push({
        $formkit: 'hidden',
        name: 'participantId',
        validation: 'required'
      })
    }

    function initAddForm() {
      itemData.value = {}
      autoloadData.value = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = competitionParticipant.value
      if (!itemData.value) {
        return
      }

      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.competitionId = competitionId.value

      if (itemData.value.participantId) {
        fetch(globalStore.apiUrl + '/admin/competition-participant', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.competitionParticipantStore.addRow(response.data.competitionParticipant)
              globalStore.participantStore.addData([response.data.participant])
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitAddForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function processUpdateForm() {
      if (competitionParticipant.value) {
        itemData.value.id = competitionParticipant.value.id
        itemData.value.competitionId = competitionParticipant.value.competitionId
        fetch(globalStore.apiUrl + '/admin/competition-participant', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.competitionParticipantStore.updateRow(response.data.competitionParticipant)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = competitionParticipant.value
        fetch(globalStore.apiUrl + '/admin/competition-participant', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.competitionParticipantStore.deleteRow(competitionParticipant.value.id)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function loadParticipants(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: 'team',
          o: globalStore.userAdminStore.activeOrganization,
          s: globalStore.userAdminStore.activeSeason,
          se: globalStore.userAdminStore.activeSection,
          teamTypeId: competition.teamTypeId,
          t: competition.tableSizeId,
          d: competitionDisciplines.length === 1 && +competitionDisciplines[0].disciplineId === 6 ? 6 : null,
          of: +globalStore.userAdminStore.activeOrganization === 4 || +globalStore.userAdminStore.activeOrganization === 20 ? 1 : null,
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const participants = {}
              for (let i = 0; i < response.data.length; i++) {
                participants[response.data[i].id] = response.data[i].name
                if (setSelected === false) {
                  vModel[targetValueName] = response.data[i].id
                  setSelected = true
                }
              }
              autoloadedParticipants.value = participants
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive, autoloadedParticipants,
      competitionParticipant, schema,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm, loadParticipants
    }
  }
}
</script>
