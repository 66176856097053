<template>

  <tr>
    <template v-if="participantInSeason">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td :colspan="globalStore.seasonSettingTypeStore.data.length + 1">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td class="text-nowrap sticky-left">{{ participant.name }}</td>
        <td v-for="param in globalStore.seasonSettingTypeStore.data" :key="param.id" class="text-nowrap">
          <template v-if="globalStore.seasonParticipantParameterStore.getByParticipantInSeasonIdSeasonSettingTypeId(participantInSeason.id, param.id)">
            {{ globalStore.getClubNameById(globalStore.seasonParticipantParameterStore.getByParticipantInSeasonIdSeasonSettingTypeId(participantInSeason.id, param.id).clubId) }}
          </template>
        </td>
        <td>
          <template v-if="globalStore.userAdminStore.isUserRegistry">
            <ButtonUpdate
              :click-function="initUpdateForm" />
            <span>&#160;</span>
            <ButtonDelete
              :click-function="deleteItem" />
          </template>
        </td>
      </template>
    </template>
    <template v-else>
      <td :colspan="globalStore.seasonSettingTypeStore.data.length + 2" v-if="addFormActive === false">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td :colspan="globalStore.seasonSettingTypeStore.data.length + 1">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKit
              type="text"
              name="participantName"
              :help="globalStore.vocabularyStore.getWordByIdAndLanguage('participant autoload', globalStore.languageId)"
              @keyup="loadParticipants(itemData, 'participantName', 'participantId')"
              autocomplete="off" />
            <FormKit
              type="select"
              :options="autoloadedParticipants"
              validation="required"
              name="participantId" />
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";
import ButtonDelete from "@/components/Button/ButtonDelete";

export default {
  name: 'ParticipantInSeasonItem',
  components: { ButtonUpdate, ButtonBack, ButtonAdd, ButtonDelete },
  props: {
    participantInSeasonObject: {
      required: false,
      type: Object
    },
    participantObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const participant = toRef(props, 'participantObject'),
      participantInSeason = toRef(props, 'participantInSeasonObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      schema = ref([]),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      autoloadedParticipants = ref({})

    function initAddForm() {
      itemData.value = {}
      itemData.value.participantName = ''
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      itemData.value = participantInSeason.value
      if (!itemData.value) {
        return
      }
      const parameters = globalStore.seasonParticipantParameterStore.getByParticipantInSeasonId(+participantInSeason.value.id),
        seasonSettinParameters = globalStore.seasonSettingTypeStore.data
      for (let i in seasonSettinParameters) {
        const seasonSettinParameter = seasonSettinParameters[i]
        let paramValue = ''
        for (let j in parameters) {
          const parameter = parameters[j]
          if (+seasonSettinParameter.id === +parameter.seasonSettingTypeId) {
            paramValue = parameter.clubId
            break
          }
        }
        itemData.value['p' + seasonSettinParameter.id] = paramValue
      }
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
      autoloadedParticipants.value = {}
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function setSchema() {
      schema.value = []

      const settins = globalStore.seasonSettingTypeStore.data
      for (let i in settins) {
        const setting = settins[i]
        schema.value.push({
          $formkit: 'select',
          name: 'p' + setting.id,
          options: { '': '', ...globalStore.getSvlClubByOrganizationId(globalStore.userAdminStore.activeOrganization) },
          label: globalStore.getWordById(setting.name),
          placeholder: '...'
        })
      }
    }

    function processAddForm() {
      itemData.value.organizationId = globalStore.userAdminStore.activeOrganization
      itemData.value.sectionId = globalStore.userAdminStore.activeSection
      itemData.value.season = globalStore.userAdminStore.activeSeason
      fetch(globalStore.apiUrl + '/admin/participant-in-season', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.participantStore.addData([response.data.participant])
            globalStore.seasonParticipantStore.addData(response.data.seasonParticipant)
            globalStore.seasonParticipantParameterStore.setData(response.data.seasonSettingParameter)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (participant.value) {
        fetch(globalStore.apiUrl + '/admin/participant-in-season', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.seasonParticipantParameterStore.deleteDataByEntity(participantInSeason.value)
              globalStore.seasonParticipantParameterStore.setData(response.data.seasonSettingParameter)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = {
          id: participantInSeason.value.id
        }
        fetch(globalStore.apiUrl + '/admin/participant-in-season', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.seasonParticipantStore.deleteData(participantInSeason.value)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function loadParticipants(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]

      if (key.length > 2) {
        const payload = {
          type: 'participant',
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const participants = {}
              for (let i = 0; i < response.data.length; i++) {
                participants[response.data[i].id] = response.data[i].name
                if (setSelected === false) {
                  vModel[targetValueName] = response.data[i].id
                  setSelected = true
                }
              }
              autoloadedParticipants.value = participants
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, schema, addFormActive, participant, participantInSeason, autoloadedParticipants,
      initUpdateForm, deInitUpdateForm, processUpdateForm, loadParticipants,
      initAddForm, deInitAddForm, processAddForm, deleteItem
    }
  }
}
</script>
