<template>
  <tr>
    <template v-if="addFormActive === true">
      <td>
        <button @click="deInitAddForm()" class="btn btn-outline-success btn-sm">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('back', globalStore.languageId) }}
        </button>
      </td>
      <td colspan="7">
        <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
          <FormKit v-if="globalStore.organizationStore.getById(globalStore.userAdminStore.activeOrganization) !== 4"
            type="text"
            name="participantName"
            :help="globalStore.vocabularyStore.getWordByIdAndLanguage('participant autoload', globalStore.languageId)"
            @keyup="loadParticipants(itemData[''], 'participantName', 'participantId')"
            autocomplete="off" />
          <FormKitSchema :schema="schema" />
        </FormKit>
      </td>
    </template>
    <template v-else-if="globalStore.hasUserAddRule">
      <td colspan="8">
        <button @click="initAddForm()" class="btn btn-outline-success btn-sm">
          {{ globalStore.vocabularyStore.getWordByIdAndLanguage('add participant to team', globalStore.languageId) }}
        </button>
      </td>
    </template>
  </tr>
  <template v-for="row in globalStore.teamParticipantStore.getTeamParticipantsByTeamId(participantParentId, season)" :key="row.id">
    <tr>
      <template v-if="updateFormActive[row.id]">
        <td>
          <p>{{ globalStore.getParticipantNameById(row.participantId) }}</p>
          <button @click="deInitUpdateForm(row.id)" class="btn btn-outline-success btn-sm">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('back', globalStore.languageId) }}
          </button>
        </td>
        <td colspan="6">
          <FormKit type="form" v-model="itemData[row.id]" @submit="processUpdateForm(row.id)">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <template v-if="+row.season === +season">
          <td colspan="3">
            {{ globalStore.getParticipantNameById(row.participantId) }}
          </td>
          <td colspan="3">
            {{ globalStore.formatDateTime(row.dateFrom) }}
            <br />
            {{ globalStore.formatDateTime(row.dateTo) }}
          </td>
          <td>
            <ButtonUpdate
              :click-function="initUpdateForm"
              :click-function-parameter="row.id" />
            <span>&#160;</span>
            <ButtonDelete
              :click-function="deleteItem"
              :click-function-parameter="row.id" />
          </td>
        </template>
      </template>
    </tr>
  </template>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { ref, toRef, onMounted } from "vue";
import ButtonDelete from "@/components/Button/ButtonDelete"
import ButtonUpdate from "@/components/Button/ButtonUpdate"

export default {
  name: 'ParticipantHasParticipant',
  components: { ButtonUpdate, ButtonDelete },
  props: {
    participantParent: {
      required: true,
      type: Number
    },
    seasonYear: {
      required: true,
      type: Number
    },
    club: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const participantParentId = toRef(props, 'participantParent'),
      season = toRef(props, 'seasonYear'),
      clubId = toRef(props, 'club'),
      autoloadedParticipants = ref({}),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref({}),
      schema = ref([]),
      itemId = ref('')

    onMounted(() => {
      loadParticipantsByClub()
    })

    function setSchema() {
      schema.value = []

      if (addFormActive.value === true) {
        schema.value.push({
          $formkit: 'select',
          name: 'participantId',
          validation: 'required',
          options: autoloadedParticipants,
          label: globalStore.vocabularyStore.getWordByIdAndLanguage('participant autoloaded', globalStore.languageId)
        })
      }

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'dateFrom',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('from', globalStore.languageId)
      })

      schema.value.push({
        $formkit: 'datetime-local',
        name: 'dateTo',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('to', globalStore.languageId)
      })
    }

    function getItem(id) {
      for (let i in globalStore.teamParticipantStore.teamParticipants) {
        if (+globalStore.teamParticipantStore.teamParticipants[i].id === +id) {
          return globalStore.teamParticipantStore.teamParticipants[i]
        }
      }
      return {}
    }

    function initAddForm() {
      itemData.value[''] = {}
      itemData.value[''].dateFrom = (+season.value) + '-09-01 00:00'
      itemData.value[''].dateTo = (season.value + 1) + '-08-31 23:59'
      addFormActive.value = true
      setSchema()
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
      if (globalStore.organizationStore.getById(globalStore.userAdminStore.activeOrganization) !== 4) {
        //autoloadedParticipants.value = {}
      }
    }

    function initUpdateForm(id) {
      const item = getItem(id)
      if (!item) {
        return
      }
      itemId.value = id

      itemData.value[id] = {
        id: item.id,
        dateFrom: item.dateFrom,
        dateTo: item.dateTo
      }
      setSchema()
      updateFormActive.value[id] = true
    }

    function deInitUpdateForm(id) {
      updateFormActive.value[id] = false
      if (itemData.value[id]) {
        delete itemData.value[id]
      }
      itemId.value = ''
    }

    function processAddForm() {
      itemData.value[''].participantParentId = participantParentId.value
      itemData.value[''].season = season.value

      fetch(globalStore.apiUrl + '/admin/participant-has-participant', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.teamParticipantStore.addRow(response.data.participantHasParticipant)
            globalStore.participantStore.addData([response.data.participant])
            for (let i in autoloadedParticipants.value) {
              if (+i === +itemData.value[''].participantId) {
                delete autoloadedParticipants.value[i]
              }
            }
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm(id) {
      const item = getItem(id)
      if (item) {
        fetch(globalStore.apiUrl + '/admin/participant-has-participant', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value[id])
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.teamParticipantStore.updateRow(response.data.participantHasParticipant)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm(id)
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(id)
        if (item) {
          item.season = season.value
          fetch(globalStore.apiUrl + '/admin/participant-has-participant', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                globalStore.teamParticipantStore.deleteRow(id)
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function loadParticipants(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: 'participant',
          of: globalStore.userAdminStore.activeOrganization == 4 ? 1 : 0,
          o: globalStore.userAdminStore.activeOrganization,
          se: globalStore.userAdminStore.activeSection,
          s: season.value,
          c: globalStore.userAdminStore.activeClub || clubId,
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const participants = {}
              for (let i = 0; i < response.data.length; i++) {
                //if (tournamentParticipantStore.getTournamentParticipantsByParticipantId(response.data[i].id) === false) {
                participants[response.data[i].id] = response.data[i].name
                if (setSelected === false) {
                  vModel[targetValueName] = response.data[i].id
                  setSelected = true
                }
                //}
              }
              autoloadedParticipants.value = participants
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function loadParticipantsByClub() {
      if (!globalStore.userAdminStore.activeClub) {
        return
      }
      const payload = {
        type: 'participant',
        of: globalStore.userAdminStore.activeOrganization == 4 || globalStore.userAdminStore.activeOrganization == 20 ? 1 : 0,
        o: globalStore.userAdminStore.activeOrganization,
        se: globalStore.userAdminStore.activeSection,
        s: season.value,
        c: globalStore.userAdminStore.activeClub || clubId,
        key: ''
      }
      const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            const participants = {}
            for (let i = 0; i < response.data.length; i++) {
              if (!globalStore.teamParticipantStore.getParticipantByParticipantIdAndTeamId(participantParentId.value, season.value, response.data[i].id)) {
                participants[response.data[i].id] = response.data[i].name
              }
            }
            autoloadedParticipants.value = participants
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    return {
      globalStore, addFormActive, updateFormActive, itemData, schema, participantParentId, season,
      autoloadedParticipants,
      initAddForm, deInitAddForm, initUpdateForm, deInitUpdateForm, processAddForm,
      processUpdateForm, deleteItem, getItem, loadParticipants
    }
  }
}
</script>
