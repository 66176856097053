<template>
  <ParticipantList :multi="true" />
</template>

<script>
import ParticipantList from "@/components/ParticipantList";
export default {
  components: { ParticipantList }
}
</script>
