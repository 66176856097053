import { defineStore } from 'pinia'

export const useClubStore = defineStore('ClubStore', {
  state: () => {
    return {
      clubs: []
    }
  },
  getters: {
    count: state => state.clubs.length,
    getById: state => {
      return id => {
        for (let i in state.clubs) {
          if (+state.clubs[i].id === +id) {
            return state.clubs[i]
          }
        }
      }
    },
    getByOrganizationId: state => {
      return organizationId => {
        if (organizationId) {
          return state.clubs.fiter(row => row.organizationId === organizationId)
        }
        return state.clubs
      }
    }
  },
  actions: {
    setData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          if (this.clubs.filter(row => row.id === rows[i].id).length) {
            this.updateData(rows[i])
          } else {
            this.addData(rows[i])
          }
        }
      }
    },
    addData(item) {
      this.clubs.push(item)
    },
    updateData(item) {
      for (let i in this.clubs) {
        if (this.clubs[i].id === item.id) {
          this.clubs[i] = item
          break
        }
      }
    },
    addDataRows(data) {
      for (let i in data) {
        this.addDataRow(data[i])
      }
    },
    addDataRow(data) {
      if (!this.clubs.filter(row => +row.id === +data.id).length) {
        this.clubs.push(data)
      }
    }
  }
})
