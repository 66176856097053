<template>
  <button
    v-if="globalStore.hasUserAddRule || viewPermanently"
    @click="clickFunctionParameter ? clickFunction(clickFunctionParameter) : clickFunction()"
    class="btn btn-sm btn-outline-success">
    {{ word ? word : globalStore.vocabularyStore.getWordByIdAndLanguage('add new item', globalStore.languageId) }}
  </button>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"

export default {
  name: 'ButtonAdd',
  components: {},
  props: {
    clickFunction: {
      required: true,
      type: Function
    },
    clickFunctionParameter: {
      required: false
    },
    word: {
      required: false,
      type: String
    },
    viewPermanently: {
      required: false,
      type: Boolean
    }
  },

  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore
    }
  }
}
</script>
