import { defineStore } from 'pinia'

export const useDisciplineStore = defineStore('DisciplineStore', {
  state: () => {
    return {
      disciplines: {},
      sectionTableDisciplines: []
    }
  },
  getters: {
    count: state => Object.keys(state.disciplines).length,
    getDisciplinesBySectionAndTable: state => {
      return (sectionId, tableSizeId) => {
        const sectionTableDisciplines = state.sectionTableDisciplines.filter(row => +row.sectionId === +sectionId && +row.tableSizeId === +tableSizeId),
          data = []
        for (let i in sectionTableDisciplines) {
          const diciplineId = sectionTableDisciplines[i].disciplineId
          for (let j in state.disciplines) {
            const dicipline = state.disciplines[j]
            if (+diciplineId === +dicipline.id) {
              data.push(dicipline)
              break
            }
          }
        }
        return data
      }
    }
  },
  actions: {
    setData(data) {
      this.disciplines = data
    },
    setSectionTableData(data) {
      this.sectionTableDisciplines = data
    }
  }
})
