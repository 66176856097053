import { defineStore } from 'pinia'

export const useTournamentRegionStore = defineStore('TournamentRegionStore', {
  state: () => {
    return {
      tournamentRegions: [],
    }
  },
  getters: {
    count: state => state.tournamentRegions.length,
    getByTournamentId: state => {
      return id => {
        return state.tournamentRegions.filter(row => +row.tournamentId === +id)
      }
    }
  },
  actions: {
    setData(data) {
      this.tournamentRegions = data
    },
    updateRowsByTournamentId(data, tournamentId) {
      this.tournamentRegions = this.tournamentRegions.filter(row => +row.tournamentId !== +tournamentId)
      for (let i in data) {
        this.addRow(data[i])
      }
    },
    deleteRowsByTournamentId(tournamentId) {
      this.tournamentRegions = this.tournamentRegions.filter(row => +row.tournamentId !== +tournamentId)
    },
    addRows(data) {
      for (let i in data) {
        this.addRow(data[i])
      }
    },
    addRow(data) {
      this.tournamentRegions.push(data)
    },
    deleteRow(id) {
      for (let i in this.tournamentRegions) {
        if (+id === +this.tournamentRegions[i].id) {
          delete this.tournamentRegions[i]
        }
      }
    },
    updateRow(data) {
      for (let i in this.tournamentRegions) {
        if (+data.id === +this.tournamentRegions[i].id) {
          this.tournamentRegions[i] = data
        }
      }
    },
  }
})
