<template>
  <br />
  <ButtonAdd
    v-if="addFormActive === false"
    :click-function="initAddForm"
    :word="globalStore.vocabularyStore.getWordByIdAndLanguage('add referee', globalStore.languageId)"
  />
  <table v-if="addFormActive === true" class="table table-hover table-striped align-middle table-light">
    <tbody>
    <tr>
      <td>
        <ButtonBack :click-function="deInitAddForm"/>
      </td>
      <td>
        <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
          <FormKit
              type="text"
              name="participantName"
              :help="globalStore.vocabularyStore.getWordByIdAndLanguage('participant autoload', globalStore.languageId)"
              @keyup="loadParticipants(itemData[''], 'participantName', 'participantId')"
              autocomplete="off"
          />
          <FormKit
              type="select"
              :options="autoloadedParticipants"
              validation="required"
              name="participantId"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('referee autoloaded', globalStore.languageId)"
          />
        </FormKit>
      </td>
    </tr>
    </tbody>
  </table>
  <div v-if="eventRefereeStore.count">
    <table class="table table-hover table-striped align-middle table-light">
      <tbody>
      <template v-for="row in eventRefereeStore.getEventRefereesByEventId(event.id)" :key="row.id">
        <tr>
          <td>{{ globalStore.getParticipantName(row) }}</td>
          <td>
            <ButtonDelete
              :click-function="deleteItem"
              :click-function-parameter="row.id"
            />
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {ref, toRef} from 'vue'
import { useEventRefereeStore } from "@/stores/EventRefereeStore"
import { useParticipantStore } from "@/stores/ParticipantStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";

export default {
  name: 'EventReferee',
  components: {ButtonAdd, ButtonDelete, ButtonBack},
  props: {
    eventObject: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const event = toRef(props, 'eventObject'),
      itemData = ref({}),
      addFormActive = ref(false),
      autoloadedParticipants = ref({}),
      eventRefereeStore = useEventRefereeStore(),
      participantStore = useParticipantStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore()

    function getItem(id) {
      for (let i in eventRefereeStore.eventReferees) {
        if (eventRefereeStore.eventReferees[i].id === id) {
          return eventRefereeStore.eventReferees[i]
        }
      }
      return {}
    }

    function initAddForm() {
      itemData.value[''] = {}
      addFormActive.value = true
    }

    function processAddForm() {
      itemData.value[''].eventId = event.value.id
      fetch(globalStore.apiUrl + '/admin/event-referee', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(itemData.value[''])
      })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              participantStore.participants[response.data.participant.id] = response.data.participant
              eventRefereeStore.eventReferees.push(response.data.item)
              notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId)})
              deInitAddForm()
            } else if (response.error) {
              notificationStore.add({type: 'error', message: response.error.description})
            } else {
              notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
            }
          })
          .catch(err => notificationStore.add({type: 'error', message: err.message}))
    }

    function deleteItem(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(id)
        if (item) {
          fetch(globalStore.apiUrl + '/admin/event-referee', {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                for (let i in eventRefereeStore.eventReferees) {
                  if (eventRefereeStore.eventReferees[i].id === id) {
                    eventRefereeStore.eventReferees.splice(i, 1)
                    break
                  }
                }
                notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId)})
              }
            })
            .catch(err => notificationStore.add({type: 'error', message: err.message}))
        }
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
      autoloadedParticipants.value = {}
    }

    function loadParticipants(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: 'participant',
          of: (event.value.organizationId === 4) ? 1 : 0,
          o: event.value.organizationId,
          //se: event.value.sectionId,
          //s: event.value.season,
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const participants = {}
              for (let i = 0; i < response.data.length; i++) {
                participants[response.data[i].id] = response.data[i].name
                if (setSelected === false) {
                  vModel[targetValueName] = response.data[i].id
                  setSelected = true
                }
              }
              autoloadedParticipants.value = participants
            }
          })
          .catch(err => notificationStore.add({type: 'error', message: err.message}))
      }
    }

    return {
      addFormActive, itemData, participantStore, globalStore, autoloadedParticipants, eventRefereeStore, event,
      initAddForm, deInitAddForm, processAddForm, deleteItem, loadParticipants
    }
  }
}
</script>