import { defineStore } from 'pinia'

export const useStatusStore = defineStore('StatusStore', {
  state: () => {
    return {
      statuses: {},
    }
  },
  getters: {
    count: state => Object.keys(state.statuses).length,
  },
  actions: {
    setData(data) {
      this.statuses = data
    },
    addDataRows(data) {
      for (let i in data) {
        this.addDataRow(data[i])
      }
    },
    addDataRow(data) {
      if (!this.statuses[data.id]) {
        this.statuses[data.id] = data
      }
    }
  }
})
