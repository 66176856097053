<template>
  <MenuBase :onChangeFn="loadTeamsInSeason" />
  <br /><br />
  <template v-if="globalStore.userAdminStore.isUserRegion">
    <ButtonAdd
      v-if="addFormActive === false && globalStore.userAdminStore.activeClub"
      :click-function="initAddForm"
      :word="globalStore.vocabularyStore.getWordByIdAndLanguage('add team to season', globalStore.languageId)" />
    <br /><br />
    <table v-if="addFormActive === true" class="table table-hover table-striped align-middle table-light">
      <tbody>
        <tr>
          <td>
            <ButtonBack :click-function="deInitAddForm" />
          </td>
          <td>
            <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
              <p>
                {{ globalStore.vocabularyStore.getWordByIdAndLanguage('setting team to season action', globalStore.languageId) }}:
                <strong>{{ globalStore.getActiveClubName }}</strong>
              </p>
              <FormKit
                type="text"
                name="participantName"
                :help="globalStore.vocabularyStore.getWordByIdAndLanguage('team autoload', globalStore.languageId)"
                @keyup="loadTeams(itemData[''], 'participantName', 'participantId')"
                autocomplete="off" />
              <FormKit
                type="select"
                :options="autoloadedTeams"
                validation="required"
                name="participantId"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('team autoloaded', globalStore.languageId)" />
              <FormKit
                type="hidden"
                validation="required"
                name="clubId"
                v-model="globalStore.userAdminStore.activeClub" />
              <FormKit
                type="hidden"
                v-model="globalStore.userAdminStore.activeOrganization"
                validation="required"
                name="organizationId" />
              <FormKit
                type="hidden"
                validation="required"
                v-model="globalStore.userAdminStore.activeSection"
                name="sectionId" />
              <FormKit
                type="hidden"
                validation="required"
                :value="globalStore.userAdminStore.activeSeason"
                name="season" />
              <FormKit
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('team name for season', globalStore.languageId)"
                type="text"
                validation="required"
                name="name" />
            </FormKit>
          </td>
        </tr>
      </tbody>
    </table>
  </template>

  <div v-if="globalStore.teamInSeasonStore.count && globalStore.userAdminStore.activeClub">
    <table class="table table-hover table-striped align-middle table-light">
      <tbody>
        <template v-for="row in globalStore.teamInSeasonStore.getByClubIdSeason(+globalStore.userAdminStore.activeClub, +globalStore.userAdminStore.activeSeason)" :key="row.id">
          <tr class="table-dark">
            <td>{{ getTeamTypeNameByParticipantId(row.participantId) }}</td>
            <td>{{ getTableSizeNameByParticipantId(row.participantId) }}</td>
            <td>{{ getDisciplineNameByParticipantId(row.participantId) }}</td>
            <td>{{ globalStore.getParticipantNameById(row.participantId) }}</td>
            <td>{{ row.name }}</td>
            <td>{{ globalStore.getActiveClubName }}</td>
            <td>
              <ButtonDelete
                :click-function="deleteItem"
                :click-function-parameter="row.id" />
            </td>
          </tr>
          <ParticipantLeader
            :participantParent="+row.participantId"
            :seasonYear="+globalStore.userAdminStore.activeSeason"
            :club="+globalStore.userAdminStore.activeClub" />
          <ParticipantHasParticipant
            :participantParent="+row.participantId"
            :seasonYear="+globalStore.userAdminStore.activeSeason"
            :club="+globalStore.userAdminStore.activeClub" />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import ButtonBack from "@/components/Button/ButtonBack"
import ButtonDelete from "@/components/Button/ButtonDelete"
import ButtonAdd from "@/components/Button/ButtonAdd"
import ParticipantHasParticipant from "@/components/ParticipantHasParticipant"
import MenuBase from "@/components/Menu/MenuBase"
import ParticipantLeader from "@/components/ParticipantLeader"

export default {
  name: 'TeamsInSeason',
  components: { ButtonAdd, ButtonDelete, ButtonBack, ParticipantHasParticipant, MenuBase, ParticipantLeader },
  setup() {
    const itemData = ref({}),
      globalStore = useGlobalStore(),
      rows = ref([]),
      clubs = ref([{ value: '', label: '' }]),
      autoloadedTeams = ref({}),
      addFormActive = ref(false),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadTeamsInSeason()
    })

    function loadTeamsInSeason() {
      globalStore.teamInSeasonStore.emptyData()
      if (!globalStore.userAdminStore.activeClub) {
        return
      }
      fetch(globalStore.apiUrl + '/admin/teams-in-season/' + (globalStore.userAdminStore.getActiveOrganization || 'null') + '/' + (globalStore.userAdminStore.getActiveSection || 'null') + '/' + (globalStore.userAdminStore.activeSeason || 'null') + '/' + (globalStore.userAdminStore.activeClub || 'null') + '/null', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          if (data.data.teamInSeason) {
            globalStore.teamInSeasonStore.setData(data.data.teamInSeason)
            globalStore.teamParticipantStore.setData(data.data.participantHasParticipant)
            globalStore.clubStore.setData(data.data.club)
            globalStore.participantStore.addData(data.data.participant)
            globalStore.participantSeasonParameterStore.setData(data.data.parameter)
            globalStore.teamTypeStore.setData(data.data.teamType)
            globalStore.tableSizeStore.setData(data.data.tableSize)
            globalStore.disciplineStore.setData(data.data.discipline)
            globalStore.vocabularyStore.addRows(data.data.vocabulary)
            globalStore.clubParticipantLeaderStore.setData(data.data.clubParticipantLeader, data.data.club[0] ? data.data.club[0].id : null)
            globalStore.participantLeaderStore.setData(data.data.participantLeader)
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    function getItem(id) {
      for (let i in globalStore.teamInSeasonStore.teamsInSeason) {
        const teamInSeason = globalStore.teamInSeasonStore.teamsInSeason[i]
        if (teamInSeason.id === id) {
          return teamInSeason
        }
      }
      return {}
    }

    function initAddForm() {
      itemData.value[''] = {}
      addFormActive.value = true
    }

    function processAddForm() {
      itemData.value[''].season = globalStore.userAdminStore.activeSeason
      fetch(globalStore.apiUrl + '/admin/team-in-season', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.participantSeasonParameterStore.addData([response.data.parameter])
            globalStore.participantStore.addData([response.data.participant])
            globalStore.teamInSeasonStore.addData([response.data.teamInSeason])
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function deleteItem(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(id)
        if (item) {
          fetch(globalStore.apiUrl + '/admin/team-in-season', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                globalStore.participantSeasonParameterStore.deleteDataByParticipantInSeasonId(id)
                globalStore.teamInSeasonStore.deleteDataById(id)
                rows.value = rows.value.filter(row => +row.id !== +id)
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
      autoloadedTeams.value = {}
    }

    function getMotherClubId(participantInSeasonId) {
      const parameter = globalStore.participantSeasonParameterStore.getBySeasonParticipantId(participantInSeasonId, 1)
      if (parameter && parameter[0]) {
        return parameter[0].clubId
      }
      return null
    }

    function getTeamTypeNameByParticipantId(participantId) {
      const id = globalStore.participantStore.getById(participantId).participantTypeId
      if (id) {
        return globalStore.getTeamTypeNameById(id)
      }
      return null
    }

    function getTableSizeNameByParticipantId(participantId) {
      const id = globalStore.participantStore.getById(participantId).tableSizeId
      if (id) {
        return globalStore.getTableSizeNameById(id)
      }
      return null
    }

    function getDisciplineNameByParticipantId(participantId) {
      const id = globalStore.participantStore.getById(participantId).disciplineId
      if (id) {
        return globalStore.getDisciplineNameById(id)
      }
      return null
    }

    function loadTeams(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key && key.length > 2) {
        const payload = {
          type: 'team',
          ncd: 1, // not compare discipline
          //of: null,
          //o: 4,
          //se: 1,
          //s: globalStore.userAdminStore.activeSeason,
          //c: '',
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const teams = {}
              for (let i = 0; i < response.data.length; i++) {
                if (!globalStore.teamInSeasonStore.getByTeamId(response.data[i].id)) {
                  teams[response.data[i].id] = response.data[i].name
                  if (setSelected === false) {
                    vModel[targetValueName] = response.data[i].id
                    setSelected = true
                  }
                }
              }
              autoloadedTeams.value = teams
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      addFormActive, itemData, globalStore, autoloadedTeams, clubs,
      initAddForm, deInitAddForm, processAddForm, deleteItem, getMotherClubId,
      getTeamTypeNameByParticipantId, getTableSizeNameByParticipantId, getDisciplineNameByParticipantId,
      loadTeams, loadTeamsInSeason
    }
  }
}
</script>
