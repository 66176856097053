<template>
  <div class="events" v-if="globalStore.eventStore.hasStageEvents(stageId)">
    <FormKit v-if="Object.keys(getStageSigns()).length > 1" type="select"
      :options="{ '': globalStore.vocabularyStore.getWordByIdAndLanguage('filter groups', globalStore.languageId), ...getStageSigns() }"
      v-model="groupSign" />

    <template v-if="isEventMultiDiscipline()">
      <table class="table table-hover align-middle table-light">
        <tbody>
          <template v-for="eventUnionHash in getMultiDisciplineNameHashes(stageId)" :key="eventUnionHash">
            <tr class="table-primary"
              v-if="(groupSign === '' || groupSign === getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[0].stageSign)">
              <td></td>
              <th>
                {{ getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[0].name }}
                {{ getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[0].innings > 0 ? '(' +
                  (getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[0].points /
                    getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[0].innings).toFixed(3) + ')' : '' }}
              </th>
              <th>
                ({{ getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[0].score }})
                {{ getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[0].totalScore }}
              </th>
              <th>
                {{ getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[1].totalScore }}
                ({{ getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[1].score }})
              </th>
              <th>
                {{ getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[1].name }}
                {{ getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[1].innings > 0 ? '(' +
                  (getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[1].points /
                    getEventSummaryByStageIdAndHash(stageId, eventUnionHash)[1].innings).toFixed(3) + ')' : '' }}
              </th>
              <td></td>
            </tr>
            <template v-for="row in getSortedEvents()" :key="row.id">
              <EventItem
                v-if="(groupSign === '' || groupSign === getStageSign(row.id)) && getNamesHash(row) === eventUnionHash"
                :eventObject="row" :isUpdatable="!isStageLocked(row.stageId)" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
    <template v-else>
      <table class="table table-hover align-middle table-light">
        <tbody>
          <template v-for="row in getSortedEvents()" :key="row.id">
            <EventItem v-if="groupSign === '' || groupSign === getStageSign(row.id)" :eventObject="row"
              :isUpdatable="!isStageLocked(row.stageId)" />
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { ref, toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useDisciplineCoefficientStore } from "@/stores/DisciplineCoefficientStore"
import EventItem from "@/components/EventItem";

export default {
  name: 'TournamentStageEvent',
  components: { EventItem },
  props: {
    setStageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const stageId = toRef(props, 'setStageId'),
      globalStore = useGlobalStore(),
      disciplineCoefficientStore = useDisciplineCoefficientStore(),
      itemData = ref({}),
      formActive = ref({}),
      groupSign = ref('')

    function isStageLocked(stageId) {
      if (globalStore.stageStore.isStageFinal(stageId)) {
        return false
      }
      const nextStageId = globalStore.tournamentStageStore.getNextStageIdByStageId(stageId)
      return globalStore.eventStore.hasStageEvents(nextStageId) && !globalStore.eventStore.hasStageOnlyBlankEvents(nextStageId)
    }

    function isStageKO(sId) {
      const stage = globalStore.tournamentStageStore.getTournamentStageByStageId(sId)
      return +stage.stageSystemTypeId !== 1
    }

    function getStageSigns() {
      const events = globalStore.eventStore.getEventsByStage(stageId.value),
        data = {}
      for (let i in events) {
        data[events[i].stageSign] = globalStore.vocabularyStore.getWordByIdAndLanguage('filter group', globalStore.languageId) + ' ' + events[i].stageSign
      }

      return Object.keys(data).sort().reduce((obj, key) => {
        obj[key] = data[key]
        return obj
      }, {})
    }

    function getStageSign(id) {
      const event = globalStore.eventStore.getEventById(id)

      if (event.stageSign) {
        return event.stageSign
      }

      return ''
    }

    function isEventMultiDiscipline() {
      return globalStore.disciplineStore.disciplines.length > 1 && !globalStore.tournamentStore.tournament.teamTypeId
    }

    function getMultiDisciplineNameHashes(stageId) {
      const nameHashes = [],
        events = globalStore.eventStore.getEventsByStage(stageId)
      for (let i in events) {
        const namesHash = getNamesHash(events[i])
        if (nameHashes.indexOf(namesHash) < 0) {
          nameHashes.push(namesHash)
        }
      }
      return nameHashes
    }

    function getNamesHash(event) {
      let participantIds = []
      for (let j in event.eventParticipant) {
        participantIds.push(event.eventParticipant[j].participantId)
      }
      return participantIds.sort().join('')
    }

    function getEventSummaryByStageIdAndHash(stageId, hash) {
      const events = globalStore.eventStore.getEventsByStage(stageId),
        summary = {}
      for (let i in events) {
        if (getNamesHash(events[i]) === hash) {
          for (let j in events[i].eventParticipant) {
            const eventParticipant = events[i].eventParticipant[j]
            if (!summary[hash]) {
              summary[hash] = []
            }
            if (!summary[hash][j]) {
              summary[hash][j] = {
                name: globalStore.getParticipantNameById(eventParticipant.participantId),
                score: +eventParticipant.score > 0 ? eventParticipant.score : 0,
                points: disciplineCoefficientStore.getCoefficientByDisciplineId(eventParticipant.disciplineId) * +eventParticipant.points,
                innings: +eventParticipant.innings,
                totalScore: 0,
                stageSign: getStageSign(events[i].id)
              }
            } else {
              summary[hash][j].score += (+eventParticipant.score > 0 ? eventParticipant.score : 0)
              summary[hash][j].points += (disciplineCoefficientStore.getCoefficientByDisciplineId(eventParticipant.disciplineId) * +eventParticipant.points)
              summary[hash][j].innings += +eventParticipant.innings
            }
          }
        }
      }
      if (summary[hash][0].score > summary[hash][1].score) {
        summary[hash][0].totalScore = 2
        summary[hash][1].totalScore = 0
      } else if (summary[hash][0].score < summary[hash][1].score) {
        summary[hash][0].totalScore = 0
        summary[hash][1].totalScore = 2
      } else if (summary[hash][0].points > 0 || summary[hash][1].points > 0) {
        summary[hash][0].totalScore = 1
        summary[hash][1].totalScore = 1
      }

      return summary[hash]
    }

    function getSortedEvents() {
      return globalStore.eventStore.getEventsByStageWithParticipantPredictionByStageId(stageId.value, globalStore).sort((a, b) => {
        if (!isStageKO(stageId.value)) {
          if (a.round === b.round && a.table && b.table) {
            return a.table.index - b.table.index
          }
          return a.round - b.round
        }
        return a.id - b.id
      })
    }

    return {
      stageId, formActive, itemData, globalStore, groupSign,
      isStageLocked, getStageSign, getStageSigns, isEventMultiDiscipline, getMultiDisciplineNameHashes, getNamesHash, getEventSummaryByStageIdAndHash,
      getSortedEvents
    }
  }
}
</script>
