import { defineStore } from 'pinia'

export const useVocabularyStore = defineStore('VocabularyStore', {
  state: () => {
    return {
      vocabulary: {},
      static: [
        { vocabularyContentId: 'setting', languageId: 1, value: 'Nasazení' },
        { vocabularyContentId: 'view stage setting', languageId: 1, value: 'Zobrazit nasazení do fází?' },
        { vocabularyContentId: 'bye', languageId: 1, value: 'Volný los' },
        { vocabularyContentId: 'event count', languageId: 1, value: 'Počet zápasů' },
        { vocabularyContentId: 'form required error', languageId: 1, value: 'Povinná položka' },
        { vocabularyContentId: 'set players to event', languageId: 1, value: 'Nasazení hráčů' },
        { vocabularyContentId: 'change players to event', languageId: 1, value: 'Upravit nasazení hráčů' },
        { vocabularyContentId: 'set players to event action', languageId: 1, value: 'Nasadit hráče' },
        { vocabularyContentId: 'delete players to event', languageId: 1, value: 'Odnasazení hráčů' },
        { vocabularyContentId: 'create live score to event', languageId: 1, value: 'Vytvořit live score' },
        { vocabularyContentId: 'remove live score', languageId: 1, value: 'Odstranit live score' },
        { vocabularyContentId: 'table nr', languageId: 1, value: 'Stůl' },
        { vocabularyContentId: 'discipline', languageId: 1, value: 'Disciplína' },
        { vocabularyContentId: 'create double scotch team', languageId: 1, value: 'Vytořit DS tým' },
        { vocabularyContentId: 'stage date time start', languageId: 1, value: 'Začátek fáze' },
        { vocabularyContentId: 'no chosen table', languageId: 1, value: 'Není vybraný stůl' },
        { vocabularyContentId: 'error participant set points', languageId: 1, value: 'Body musí být zadané u všech hráčů' },
        { vocabularyContentId: 'error participant points', languageId: 1, value: 'Body nemůžou přesáhnout limit' },
        { vocabularyContentId: 'error event disqualification', languageId: 1, value: 'Skreč může být zadaná pouze u jednoho hráče' },
        { vocabularyContentId: 'error participant run', languageId: 1, value: 'Série nemůže být vyšší než nahraný počet bodů v setu' },
        { vocabularyContentId: 'error participant innings does not equal', languageId: 1, value: 'Náběhy se musí rovnat' },
        { vocabularyContentId: 'error participant innings differs more than one', languageId: 1, value: 'Náběhy se mohou lišit jen o jeden' },
        { vocabularyContentId: 'error participant extension', languageId: 1, value: 'Jeden hráč musí mít zadané vítězství v prodloužení' },
        { vocabularyContentId: 'error participant run 2 vs run 1', languageId: 1, value: 'Druhá nejvyšší série nemůže být vyšší než první' },
        { vocabularyContentId: 'error participant run sum', languageId: 1, value: 'Součet nejvyšších sérií nemůže být vyšší celkový počet možných bodů' },
        { vocabularyContentId: 'error participant high run is too low', languageId: 1, value: 'Nejvyšší sérié musí být vyšší' },
        { vocabularyContentId: 'filter groups', languageId: 1, value: 'Všechny skupiny' },
        { vocabularyContentId: 'filter group', languageId: 1, value: 'Skupina' },
        { vocabularyContentId: 'filter rounds', languageId: 1, value: 'Všechna kola' },
        { vocabularyContentId: 'filter team participants', languageId: 1, value: 'Všechny týmy' },
        { vocabularyContentId: 'filter finished', languageId: 1, value: 'Odehrané' },
        { vocabularyContentId: 'filter all', languageId: 1, value: 'Vše' },
        { vocabularyContentId: 'filter not finished', languageId: 1, value: 'Neodehrané' },
        { vocabularyContentId: 'inning', languageId: 1, value: 'Náběh' },
        { vocabularyContentId: 'round', languageId: 1, value: 'Kolo' },
        { vocabularyContentId: 'extension', languageId: 1, value: 'Prodloužení' },
        { vocabularyContentId: 'disqualification', languageId: 1, value: 'Skreč' },
        { vocabularyContentId: 'count of', languageId: 1, value: 'Počet' },

        { vocabularyContentId: 'really delete item', languageId: 1, value: 'Opravdu smazat položku?' },
        { vocabularyContentId: 'add new item', languageId: 1, value: 'Přidat nový záznam' },
        { vocabularyContentId: 'back', languageId: 1, value: 'Zpět' },
        { vocabularyContentId: 'save', languageId: 1, value: 'Uložit' },
        { vocabularyContentId: 'update', languageId: 1, value: 'Upravit' },
        { vocabularyContentId: 'delete', languageId: 1, value: 'Odstranit' },
        { vocabularyContentId: 'close', languageId: 1, value: 'Zavřít' },
        { vocabularyContentId: 'create stage', languageId: 1, value: 'Vytvořit fázi' },
        { vocabularyContentId: 'create all future stages', languageId: 1, value: 'Vytvořit tuto a všechny budoucí fáze' },
        { vocabularyContentId: 'created stage condition error', languageId: 1, value: 'Chybí parametry fáze' },
        { vocabularyContentId: 'delete stage', languageId: 1, value: 'Smazat fázi' },
        { vocabularyContentId: 'add team to season', languageId: 1, value: 'Nasadit tým do sezóny' },
        { vocabularyContentId: 'add participant to team', languageId: 1, value: 'Nasadit hráče do týmu' },
        { vocabularyContentId: 'add participant leader', languageId: 1, value: 'Nastavit vedoucího týmu' },

        { vocabularyContentId: 'successfully added', languageId: 1, value: 'Úspěšně uloženo' },
        { vocabularyContentId: 'successfully updated', languageId: 1, value: 'Úspěšně upraveno' },
        { vocabularyContentId: 'successfully deleted', languageId: 1, value: 'Úspěšně smazáno' },
        { vocabularyContentId: 'error occurred', languageId: 1, value: 'Nastala chyba' },

        { vocabularyContentId: 'competition', languageId: 1, value: 'Soutěž' },
        { vocabularyContentId: 'defined by competition', languageId: 1, value: 'Definováno soutěží' },
        { vocabularyContentId: 'defined by tournament', languageId: 1, value: 'Definováno turnajem' },
        { vocabularyContentId: 'defined by section', languageId: 1, value: 'Definováno sekcí' },
        { vocabularyContentId: 'add definition', languageId: 1, value: 'Přidat definici' },

        { vocabularyContentId: 'club autoload', languageId: 1, value: 'Napište název klubu nebo jeho část a vyberte ze seznamu' },
        { vocabularyContentId: 'team autoload', languageId: 1, value: 'Napište název týmu nebo jeho část a vyberte ze seznamu' },
        { vocabularyContentId: 'team autoloaded', languageId: 1, value: 'Seznam týmů k dispozici' },
        { vocabularyContentId: 'competition autoload', languageId: 1, value: 'Napište název soutěže nebo jeho část a vyberte ze seznamu' },
        { vocabularyContentId: 'competition autoloaded', languageId: 1, value: 'Seznam soutěží k dispozici' },
        { vocabularyContentId: 'participant autoload', languageId: 1, value: 'Napište příjmení nebo jeho část a vyberte ze seznamu' },
        { vocabularyContentId: 'participant autoloaded', languageId: 1, value: 'Seznam hráčů k dispozici' },
        { vocabularyContentId: 'tournament autoload', languageId: 1, value: 'Napište název nebo jeho část a vyberte ze seznamu' },
        { vocabularyContentId: 'tournament autoloaded', languageId: 1, value: 'Seznam turnajů k dispozici' },
        { vocabularyContentId: 'referee autoloaded', languageId: 1, value: 'Seznam rozhodčích k dispozici' },
        { vocabularyContentId: 'add referee', languageId: 1, value: 'Přidat rozhodčího' },
        { vocabularyContentId: 'clone competition', languageId: 1, value: 'Vytvořit klon soutěže' },
        { vocabularyContentId: 'create competition', languageId: 1, value: 'Vytvořit novou soutěž' },
        { vocabularyContentId: 'clone tournament', languageId: 1, value: 'Vytvořit klon turnaje' },
        { vocabularyContentId: 'create tournament', languageId: 1, value: 'Vytvořit nový turnaj' },

        { vocabularyContentId: 'tournaments', languageId: 1, value: 'Turnaje' },
        { vocabularyContentId: 'tournament stages', languageId: 1, value: 'Fáze turnaje' },
        { vocabularyContentId: 'tournament participants', languageId: 1, value: 'Účastníci turnaje' },
        { vocabularyContentId: 'tournament result criterion', languageId: 1, value: 'Kritéria pořadí' },
        { vocabularyContentId: 'tournament participant setting type', languageId: 1, value: 'Typ nasazení' },
        { vocabularyContentId: 'tournament stage system', languageId: 1, value: 'Herní systém ve fázi' },
        { vocabularyContentId: 'tournament stage events', languageId: 1, value: 'Zápasy ve fázi' },
        { vocabularyContentId: 'tournament stage ranking', languageId: 1, value: 'Pořadí ve fázi' },
        { vocabularyContentId: 'tournament stage participants', languageId: 1, value: 'Nasazení do fáze' },
        { vocabularyContentId: 'tournament stage substitute', languageId: 1, value: 'Výměna hráče' },
        { vocabularyContentId: 'tournament stage schedule', languageId: 1, value: 'Harmonogram' },
        { vocabularyContentId: 'tournament template', languageId: 1, value: 'Šablona' },
        { vocabularyContentId: 'tournament stage', languageId: 1, value: 'Fáze' },
        { vocabularyContentId: 'tournament stage sign', languageId: 1, value: 'Skupina' },
        { vocabularyContentId: 'tournament stage setting', languageId: 1, value: 'Nasazení' },
        { vocabularyContentId: 'tournament referee', languageId: 1, value: 'Hlavní rozhodčí' },
        { vocabularyContentId: 'add stage definition', languageId: 1, value: 'Přidat fázi' },
        { vocabularyContentId: 'update schedule', languageId: 1, value: 'Upravit harmonogram' },
        { vocabularyContentId: 'save schedule', languageId: 1, value: 'Uložit úpravy' },

        { vocabularyContentId: 'reset tournament', languageId: 1, value: 'Tvrdý reset' },
        { vocabularyContentId: 'reset stage', languageId: 1, value: 'Tvrdý reset fáze' },
        { vocabularyContentId: 'tournament reset question', languageId: 1, value: 'Ostranit zápasy a nemanuálně nasazené ze všech fází?' },
        { vocabularyContentId: 'tournament reset stage question', languageId: 1, value: 'Ostranit zápasy a nemanuálně nasazené z fáze?' },
        { vocabularyContentId: 'tournament successfully reset', languageId: 1, value: 'Úspěšně zresetováno' },
        { vocabularyContentId: 'click to reload', languageId: 1, value: 'Klikněte pro reload' },
        { vocabularyContentId: 'choose', languageId: 1, value: 'Vyberte' },

        { vocabularyContentId: 'tournament participant points', languageId: 1, value: 'Body do soutěže' },
        { vocabularyContentId: 'tournament participant ranking', languageId: 1, value: 'Pořadí' },
        { vocabularyContentId: 'tournament participant setting', languageId: 1, value: 'Nasazení' },
        { vocabularyContentId: 'tournament participant inserted', languageId: 1, value: 'Čas přihlášení' },
        { vocabularyContentId: 'sort by', languageId: 1, value: 'Řadit dle' },
        { vocabularyContentId: 'ranking', languageId: 1, value: 'Pořadí' },

        { vocabularyContentId: 'sing up start', languageId: 1, value: 'Přihlášky od' },
        { vocabularyContentId: 'sing up deadline', languageId: 1, value: 'Přihlášky do' },
        { vocabularyContentId: 'name', languageId: 1, value: 'Název' },
        { vocabularyContentId: 'table size', languageId: 1, value: 'Velikost stolu' },
        { vocabularyContentId: 'team type', languageId: 1, value: 'Družstvo' },
        { vocabularyContentId: 'gender', languageId: 1, value: 'Pohlaví' },
        { vocabularyContentId: 'age', languageId: 1, value: 'Věková kategorie' },
        { vocabularyContentId: 'level', languageId: 1, value: 'Úroveň' },
        { vocabularyContentId: 'region', languageId: 1, value: 'Region' },
        { vocabularyContentId: 'comment type', languageId: 1, value: 'Typ komentáře' },
        { vocabularyContentId: 'comment attachment', languageId: 1, value: 'Příloha (.pdf)' },
        { vocabularyContentId: 'comment name', languageId: 1, value: 'Titulek' },
        { vocabularyContentId: 'comment active', languageId: 1, value: 'Aktivní' },
        { vocabularyContentId: 'comment author', languageId: 1, value: 'Autor' },
        { vocabularyContentId: 'comment tournaments', languageId: 1, value: 'K turnajům' },
        { vocabularyContentId: 'comment leagues', languageId: 1, value: 'K soutěžím' },

        { vocabularyContentId: 'image attachment', languageId: 1, value: 'Příloha (.jpg, .jpeg, .gif, .png, .svg)' },

        { vocabularyContentId: 'redefine for tournament', languageId: 1, value: 'Předefinovat pro tento turnaj' },
        { vocabularyContentId: 'redefine for competition', languageId: 1, value: 'Předefinovat pro tuto soutěž' },
        { vocabularyContentId: 'team players', languageId: 1, value: 'Hráči týmu' },
        { vocabularyContentId: 'team player substitutes', languageId: 1, value: 'Náhradníci' },
        { vocabularyContentId: 'team event setting participant doubles error', languageId: 1, value: 'Hráč nemůže hrát 2x v jednom kole' },
        { vocabularyContentId: 'team event setting participant empty error', languageId: 1, value: 'Musí být zadaný hráč' },
        { vocabularyContentId: 'livescore table duplicated', languageId: 1, value: 'Stůl musí být v kole unikátní' },
        { vocabularyContentId: 'livescore participant duplicated', languageId: 1, value: 'Hráč musí být v kole unikátní' },

        { vocabularyContentId: 'schedule', languageId: 1, value: 'Kalendář' },
        { vocabularyContentId: 'table number', languageId: 1, value: 'Číslo stolu' },
        { vocabularyContentId: 'actual', languageId: 1, value: 'Aktuální' },
        { vocabularyContentId: 'tables', languageId: 1, value: 'Stoly' },
        { vocabularyContentId: 'balls', languageId: 1, value: 'Koule' },

        { vocabularyContentId: 'tournament stage add round', languageId: 1, value: 'Přidat definici disciplíny' },
        { vocabularyContentId: 'tournament stage add params', languageId: 1, value: 'Přidat definici zápasů' },

        { vocabularyContentId: 'event round', languageId: 1, value: 'Kolo' },
        { vocabularyContentId: 'event timeout alert help', languageId: 1, value: 'Kolik sekund před čerpáním timeoutu zazní zvuková výstraha' },
        { vocabularyContentId: 'event autotimeout help', languageId: 1, value: 'Délka autotimeoutu v sekundách' },
        { vocabularyContentId: 'event extension loose', languageId: 1, value: 'Prodloužení prohra' },
        { vocabularyContentId: 'event extension win', languageId: 1, value: 'Prodloužení výhra' },

        { vocabularyContentId: 'event disqualification error', languageId: 1, value: 'Porušení soutěžního řádu' },
        { vocabularyContentId: 'event extension sorry', languageId: 1, value: 'Omluvené odstoupení od zápasu' },
        { vocabularyContentId: 'event extension time limit', languageId: 1, value: 'Překročení časového limitu' },
        { vocabularyContentId: 'winner', languageId: 1, value: 'Vítěz' },
        { vocabularyContentId: 'loser', languageId: 1, value: 'Poražený' },
        { vocabularyContentId: 'team leader', languageId: 1, value: 'Vedoucí týmu' },
        { vocabularyContentId: 'status', languageId: 1, value: 'Stav' },
        { vocabularyContentId: 'handicap', languageId: 1, value: 'Handicap' },

        { vocabularyContentId: 'stageSystemTypeId', languageId: 1, value: 'Systém' },
        { vocabularyContentId: 'stageSettingTypeId', languageId: 1, value: 'Nasazení' },
        { vocabularyContentId: 'eventOrderTypeId', languageId: 1, value: 'Posloupnost zápasů' },
        { vocabularyContentId: 'participantMaxCount', languageId: 1, value: 'Max. počet hráčů' },
        { vocabularyContentId: 'participantGroupPreferredCount', languageId: 1, value: 'Preferovaný počet hráčů ve skupině' },
        { vocabularyContentId: 'tournamentParticipantMinCount', languageId: 1, value: 'Při minimálním počtu hráčů v turnaji' },
        { vocabularyContentId: 'playgroundCount', languageId: 1, value: 'Počet stolů' },
        { vocabularyContentId: 'eventDuration', languageId: 1, value: 'Doba fáze (počet minut)' },

        { vocabularyContentId: 'schedule help', languageId: 1, value: 'Pomocí drag and drop můžete přeskládat časový harmonogram zápasů.' },
        { vocabularyContentId: 'schedule switch error stage does not equal', languageId: 1, value: 'Zápasy musí být ve stejné fázi.' },
        { vocabularyContentId: 'create youtube livestream', languageId: 1, value: 'Vytvořit Youtube live stream' },
        { vocabularyContentId: 'insert livestream', languageId: 1, value: 'nebo vložit live stream url' },
        { vocabularyContentId: 'livestream', languageId: 1, value: 'Live stream' },
        { vocabularyContentId: 'event must be in future', languageId: 1, value: 'Stream lze vytvořit pouze u budoucího zápasu' },
        { vocabularyContentId: 'stream successfully created', languageId: 1, value: 'Stream je úspěšně vytvořen, je dostupný zde: ' },
        { vocabularyContentId: 'stream admin', languageId: 1, value: 'Odkaz k zahájení živého přenosu: ' },

        { vocabularyContentId: 'replace who', languageId: 1, value: 'Koho nahradit' },
        { vocabularyContentId: 'replace by', languageId: 1, value: 'Nahradit za' },
        { vocabularyContentId: 'replace', languageId: 1, value: 'Nahradit' },

        { vocabularyContentId: 'value', languageId: 1, value: 'Hodnota' },
        { vocabularyContentId: 'add value', languageId: 1, value: 'Přidat hodnotu' },
        { vocabularyContentId: 'participant setting types', languageId: 1, value: 'Typy nasazení participantů' },

        { vocabularyContentId: 'superuser user', languageId: 1, value: 'Superuser' },
        { vocabularyContentId: 'registry user', languageId: 1, value: 'Matrikář' },
        { vocabularyContentId: 'section user', languageId: 1, value: 'Sporťák' },
        { vocabularyContentId: 'region user', languageId: 1, value: 'STKář' },
        { vocabularyContentId: 'club user', languageId: 1, value: 'Klubista' },
        { vocabularyContentId: 'invalid user', languageId: 1, value: 'Vetřelec' },

        { vocabularyContentId: 'from', languageId: 1, value: 'Od' },
        { vocabularyContentId: 'to', languageId: 1, value: 'Do' },
        { vocabularyContentId: 'priority', languageId: 1, value: 'Priorita' },

        { vocabularyContentId: 'competition participants', languageId: 1, value: 'Účastníci soutěže' },
        { vocabularyContentId: 'club provider', languageId: 1, value: 'Pořádající klub' },
        { vocabularyContentId: 'lounge', languageId: 1, value: 'Místo konání' },
        { vocabularyContentId: 'date start', languageId: 1, value: 'Datum a čas konání' },
        { vocabularyContentId: 'date end', languageId: 1, value: 'Datum a čas ukončení' },
        { vocabularyContentId: 'home', languageId: 1, value: 'Domácí' },
        { vocabularyContentId: 'away', languageId: 1, value: 'Host' },
        { vocabularyContentId: 'competition result criterion', languageId: 1, value: 'Kritéria pořadí' },
        { vocabularyContentId: 'competition ranking point', languageId: 1, value: 'Body za umístění v turnaji' },
        { vocabularyContentId: 'competition tournament result criterion', languageId: 1, value: 'Kritéria pořadí v turnajích' },
        { vocabularyContentId: 'competition round definition', languageId: 1, value: 'Formát utkání' },
        { vocabularyContentId: 'competition tournament definition', languageId: 1, value: 'Formát turnajů' },
        { vocabularyContentId: 'competition team event participant setting type', languageId: 1, value: 'Nasazení hráčů zápasů utkání' },
        { vocabularyContentId: 'followed competition', languageId: 1, value: 'Navazující na soutěž' },
        { vocabularyContentId: 'events', languageId: 1, value: 'Zápasy' },

        { vocabularyContentId: 'team name for season', languageId: 1, value: 'Název týmu pro sezónu' },
        { vocabularyContentId: 'setting team to season action', languageId: 1, value: 'Nasazení týmu do sezóny pro klub' },

        { vocabularyContentId: 'choose organization', languageId: 1, value: 'Vyberte organizaci' },
        { vocabularyContentId: 'choose section', languageId: 1, value: 'Vyberte sekci' },
        { vocabularyContentId: 'choose region', languageId: 1, value: 'Vyberte region' },
        { vocabularyContentId: 'choose season', languageId: 1, value: 'Vyberte sezónu' },
        { vocabularyContentId: 'choose club', languageId: 1, value: 'Vyberte klub' },
        { vocabularyContentId: 'choose region', languageId: 1, value: 'Vyberte region' },
        { vocabularyContentId: 'club presentation', languageId: 1, value: 'Prezentace klubu' },
        { vocabularyContentId: 'club logo', languageId: 1, value: 'Logo klubu' },
        { vocabularyContentId: 'club media', languageId: 1, value: 'Média klubu' },
        { vocabularyContentId: 'club lounges', languageId: 1, value: 'Herní místa' },
        { vocabularyContentId: 'club sponsors', languageId: 1, value: 'Sponzoři' },
        { vocabularyContentId: 'clothed at', languageId: 1, value: 'Datum potažení' },
        { vocabularyContentId: 'manufactured at', languageId: 1, value: 'Datum výroby' },
        { vocabularyContentId: 'count', languageId: 1, value: 'Počet' },

        { vocabularyContentId: 'country', languageId: 1, value: 'Země' },
        { vocabularyContentId: 'short name', languageId: 1, value: 'Zkratka' },
        { vocabularyContentId: 'subdomain', languageId: 1, value: 'Subdoména' },
        { vocabularyContentId: 'official organization', languageId: 1, value: 'Oficiální organizace' },
        { vocabularyContentId: 'logo', languageId: 1, value: 'Logo' },

        { vocabularyContentId: 'comments', languageId: 1, value: 'Komentáře' },
        { vocabularyContentId: 'organizations', languageId: 1, value: 'Organizace' },

        { vocabularyContentId: 'active-0', languageId: 1, value: 'Ne' },
        { vocabularyContentId: 'active-1', languageId: 1, value: 'Ano' },

        { vocabularyContentId: 'organization sections', languageId: 1, value: 'Sekce' },
        { vocabularyContentId: 'organization regions', languageId: 1, value: 'Regiony' },
        { vocabularyContentId: 'organization section regions', languageId: 1, value: 'Regiony' },
        { vocabularyContentId: 'start year', languageId: 1, value: 'Počáteční rok' },
        { vocabularyContentId: 'organization section seasons', languageId: 1, value: 'Sezóny' },
        { vocabularyContentId: 'parent region', languageId: 1, value: 'Nadřazený region' },
        { vocabularyContentId: 'users', languageId: 1, value: 'Uživatelé' },
        { vocabularyContentId: 'password', languageId: 1, value: 'Heslo' },
        { vocabularyContentId: 'user type', languageId: 1, value: 'Typ uživatele' },
        { vocabularyContentId: 'has add rule', languageId: 1, value: 'Právo vkládat nové záznamy' },
        { vocabularyContentId: 'has update rule', languageId: 1, value: 'Právo upravovat záznamy' },
        { vocabularyContentId: 'has delete rule', languageId: 1, value: 'Právo mazat záznamy' },

        { vocabularyContentId: 'participants', languageId: 1, value: 'Hráči' },
        { vocabularyContentId: 'title before', languageId: 1, value: 'Titul před jménem' },
        { vocabularyContentId: 'first name', languageId: 1, value: 'Křestní jméno' },
        { vocabularyContentId: 'middle name', languageId: 1, value: 'Prostřední jméno' },
        { vocabularyContentId: 'surname', languageId: 1, value: 'Příjmení' },
        { vocabularyContentId: 'supplement', languageId: 1, value: 'Přídomek' },
        { vocabularyContentId: 'title after', languageId: 1, value: 'Titul za jménem' },
        { vocabularyContentId: 'photo', languageId: 1, value: 'Fotografie' },
        { vocabularyContentId: 'email', languageId: 1, value: 'E-mail' },
        { vocabularyContentId: 'date birth', languageId: 1, value: 'Datum narození' },
        { vocabularyContentId: 'date death', languageId: 1, value: 'Datum úmrtí' },
        { vocabularyContentId: 'agreement', languageId: 1, value: 'Souhlas GDPR udělen' },
        { vocabularyContentId: 'clubs', languageId: 1, value: 'Kluby' },
        { vocabularyContentId: 'registry id', languageId: 1, value: 'Matrika id' },
        { vocabularyContentId: 'street', languageId: 1, value: 'Ulice' },
        { vocabularyContentId: 'street number', languageId: 1, value: 'Číslo popisné' },
        { vocabularyContentId: 'orientation number', languageId: 1, value: 'Číslo popisné / orientační' },
        { vocabularyContentId: 'zip', languageId: 1, value: 'PSČ' },
        { vocabularyContentId: 'city', languageId: 1, value: 'Město' },
        { vocabularyContentId: 'longitude', languageId: 1, value: 'Longitude' },
        { vocabularyContentId: 'latitude', languageId: 1, value: 'Latitude' },
        { vocabularyContentId: 'participants in season', languageId: 1, value: 'Hráči v sezóně' },
        { vocabularyContentId: 'player', languageId: 1, value: 'Hráč' },
        { vocabularyContentId: 'club official participants', languageId: 1, value: 'Činovníci' },
        { vocabularyContentId: 'club officials', languageId: 1, value: 'Funkcionáři' },
        { vocabularyContentId: 'phone', languageId: 1, value: 'Telefon' },

        { vocabularyContentId: 'teams', languageId: 1, value: 'Družstva' },
        { vocabularyContentId: 'teams in season', languageId: 1, value: 'Družstva v sezóně' },
        { vocabularyContentId: 'competitions', languageId: 1, value: 'Soutěže' },
        { vocabularyContentId: 'registry office log', languageId: 1, value: 'Log matrika' },
        { vocabularyContentId: 'login admin title', languageId: 1, value: 'Login do administrace Výsledkového servisu' },
        { vocabularyContentId: 'logout', languageId: 1, value: 'Odhlásit' },
      ]
    }
  },
  getters: {
    count: state => state.vocabulary.length,
    getWordByIdAndLanguage: state => {
      return (id, languageId) => {
        languageId = languageId || 1
        for (let i in state.vocabulary) {
          const word = state.vocabulary[i]
          if (word.vocabularyContentId == id && word.languageId === languageId) {
            return word.value
          }
        }
        for (let i in state.vocabulary) {
          const word = state.vocabulary[i]
          if (word.vocabularyContentId == id && word.value !== '') {
            return word.value
          }
        }
        return '???'
      }
    }
  },
  actions: {
    setStaticData() {
      for (let i in this.static) {
        this.vocabulary[this.static[i].languageId + '-' + this.static[i].vocabularyContentId] = this.static[i]
      }
    },
    addRow(row) {
      this.vocabulary[row.languageId + '-' + row.vocabularyContentId] = row
    },
    addRows(rows) {
      for (let i in rows) {
        this.addRow(rows[i])
      }
    }
  }
})
