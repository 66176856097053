<template>
  <table v-if="globalStore.userAdminStore.propertiesLoaded" class="table table-striped">
    <tbody>
      <CompetitionRankingPointItem :cId="id" />
      <template v-if="competitionRankingPointStore.competitionRankingPoints.length">
        <template v-for="(row, i) in competitionRankingPointStore.competitionRankingPoints" :key="i">
          <CompetitionRankingPointItem v-if="row && row.id" :cId="id" :item="row" />
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import { toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useCompetitionRankingPointStore } from "@/stores/CompetitionRankingPointStore"
import CompetitionRankingPointItem from "@/components/CompetitionManagement/CompetitionRankingPointItem"

export default {
  name: 'CompetitionRankingPointList',
  components: { CompetitionRankingPointItem },
  props: {
    competitionId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const id = toRef(props, 'competitionId'),
      globalStore = useGlobalStore(),
      competitionRankingPointStore = useCompetitionRankingPointStore()

    return {
      globalStore, id, competitionRankingPointStore
    }
  }
}
</script>
