import { defineStore } from 'pinia'

export const useAgeStore = defineStore('AgeStore', {
  state: () => {
    return {
      ages: []
    }
  },
  getters: {
    count: state => state.ages.length,
    getById: state => {
      return id => {
        const age = state.ages.filter(row => +row.id === +id)
        if (age && age[0]) {
          return age[0]
        }
        return null
      }
    }
  },
  actions: {
    setData(data) {
      this.ages = data
    }
  }
})
