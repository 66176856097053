<template>
  <button v-if="globalStore.hasUserAddRule" class="btn btn-sm btn-primary">
    {{ globalStore.vocabularyStore.getWordByIdAndLanguage('create live score to event', globalStore.languageId) }}
  </button>
</template>

<script>
import {useGlobalStore} from "@/stores/GlobalStore"

export default {
  name: 'ButtonCreateLivescore',
  components: {},

  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore
    }
  }
}
</script>
