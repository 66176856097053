import { defineStore } from 'pinia'

export const useEventStageParameterStore = defineStore('EventStageParameterStore', {
  state: () => {
    return {
      eventStageParameters: {}
    }
  },
  getters: {
    hasStageParameters: state => {
      return eventStageId => {
        return state.eventStageParameters[eventStageId] && state.eventStageParameters[eventStageId][1] && state.eventStageParameters[eventStageId][1].filter(eventStageParameter => +eventStageParameter.eventStageId === +eventStageId).length > 0
      }
    }
  },
  actions: {
    setData(data) {
      for (let i in data) {
        const eventStageId = data[i].eventStageId,
          round = data[i].round
        if (!this.eventStageParameters[eventStageId]) {
          this.eventStageParameters[eventStageId] = {}
        }
        if (!this.eventStageParameters[eventStageId][round]) {
          this.eventStageParameters[eventStageId][round] = []
        }
        let isSet = false
        for (let j in this.eventStageParameters[eventStageId][round]) {
          if (this.eventStageParameters[eventStageId][round][j].id === data[i].id) {
            isSet = true
            break
          }
        }
        if (!isSet) {
          this.eventStageParameters[eventStageId][round].push(data[i])
        }
      }
    },
    addDataFromArray(data) {
      for (let i in data) {
        const eventStageId = data[i].eventStageId,
          round = data[i].round
        if (!this.eventStageParameters[eventStageId]) {
          this.eventStageParameters[eventStageId] = {}
        }
        if (!this.eventStageParameters[eventStageId][round]) {
          this.eventStageParameters[eventStageId][round] = []
        }
        let isSet = false
        for (let j in this.eventStageParameters[eventStageId][round]) {
          if (this.eventStageParameters[eventStageId][round][j].id === data[i].id) {
            isSet = true
            break
          }
        }
        if (!isSet) {
          this.eventStageParameters[eventStageId][round].push(data[i])
        }
      }
    },
    replaceStageParameters(data, eventStageId) {
      const round = data[0].round
      this.deleteStageParametersByEventStageId(eventStageId, round)
      for (let i in data) {
        const eventStageId = data[i].eventStageId,
          round = data[i].round
        if (!this.eventStageParameters[eventStageId]) {
          this.eventStageParameters[eventStageId] = {}
        }
        if (!this.eventStageParameters[eventStageId][round]) {
          this.eventStageParameters[eventStageId][round] = []
        }
        this.eventStageParameters[eventStageId][round].push(data[i])
      }
    },
    deleteStageParametersByEventStageId(eventStageId, round) {
      if (this.eventStageParameters[eventStageId] && this.eventStageParameters[eventStageId][round]) {
        delete this.eventStageParameters[eventStageId][round]
      }
    }
  }
})
