import { defineStore } from 'pinia'

export const useLocalStorageStore = defineStore('LocalStorageStore', {
  state: () => {
    return {}
  },
  getters: {},
  actions: {
    getValueByKey(key, isObject) {
      const value = localStorage.getItem(key)
      if (value) {
        if (isObject) {
          return JSON.parse(value)
        }
        return value
      }
      return null
    },
    setValue(key, value, isObject) {
      if (value) {
        if (isObject) {
          return localStorage.setItem(key, JSON.stringify(value))
        }
        return localStorage.setItem(key, value)
      }
      return null
    }
  }
})
