<template>
  <div class="table-responsive" v-if="club">
    <template v-if="globalStore.userAdminStore.propertiesLoaded">
      <table class="table table-striped">
        <tbody>
          <ClubLoungeItem :clubIdSet="club.id" />
          <template v-if="globalStore.loungeStore.getByClubId(club.id).length">
            <template v-for="row in globalStore.loungeStore.getByClubId(club.id)" :key="row.id">
              <ClubLoungeItem
                :loungeObject="row" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef } from "vue"
import ClubLoungeItem from "@/components/ClubPresentation/ClubLoungeItem"

export default {
  name: 'ClubLoungeList',
  components: { ClubLoungeItem },
  props: {
    clubObject: {
      required: true,
      type: Object
    },
  },

  setup(props) {
    const club = toRef(props, 'clubObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadData()
    })

    function loadData() {
      const url = globalStore.apiUrl + '/admin/club-lounges/' + club.value.id
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.loungeStore.setData(data.data.lounge)
          globalStore.addressStore.setData(data.data.address)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, club
    }
  }
}
</script>
