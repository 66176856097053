<template>
  <button v-if="globalStore.isUserTypeAdministrator && globalStore.isUserTypeAdministrator && eventStore.events.length" @click="resetTournament()" class="nav-link" type="button">
    {{ globalStore.vocabularyStore.getWordByIdAndLanguage('reset tournament', globalStore.languageId) }}
  </button>
</template>

<script>
import { useTournamentStore } from "@/stores/TournamentStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore";
import { useEventStore } from "@/stores/EventStore";

export default {
  name: 'TournamentReset',
  components: {},

  setup() {
    const globalStore = useGlobalStore(),
      tournamentStore = useTournamentStore(),
      notificationStore = useNotificationStore(),
      eventStore = useEventStore()

    function resetTournament() {
      if (!confirm(globalStore.vocabularyStore.getWordByIdAndLanguage('tournament reset question', globalStore.languageId))) {
        return
      }
      fetch(globalStore.apiUrl + '/admin/tournament-management/reset', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tournamentId: tournamentStore.tournament.id })
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('tournament successfully reset', globalStore.languageId) })
            alert(globalStore.vocabularyStore.getWordByIdAndLanguage('click to reload', globalStore.languageId))
            window.location.reload()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => console.error(err))
    }

    return {
      resetTournament, eventStore, globalStore
    }
  }
}
</script>
