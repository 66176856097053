<template>
  <div class="table-responsive" v-if="loungeId">
    <template v-if="globalStore.userAdminStore.propertiesLoaded">
      <table class="table table-striped">
        <tbody>
          <ClubLoungeEquipmentTableItem :loungeIdSet="loungeId" />
          <template v-if="globalStore.loungeEquipmentTableStore.getByLoungeId(loungeId).length">
            <template v-for="row in globalStore.loungeEquipmentTableStore.getByLoungeId(loungeId)" :key="row.id">
              <ClubLoungeEquipmentTableItem
                :loungeEquipmentTableObject="row" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef } from "vue"
import ClubLoungeEquipmentTableItem from "@/components/ClubPresentation/ClubLoungeEquipmentTableItem"

export default {
  name: 'ClubLoungeEquipmentTableList',
  components: { ClubLoungeEquipmentTableItem },
  props: {
    loungeIdSet: {
      required: true,
      type: Number
    },
  },

  setup(props) {
    const loungeId = toRef(props, 'loungeIdSet'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadData()
    })

    function loadData() {
      if (loungeId.value) {
        const url = globalStore.apiUrl + '/admin/club-lounge-equipment-tables/' + loungeId.value
        fetch(url, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
          .then(res => {
            if (res.status !== 200) {
              throw new Error(res.statusText)
            }
            return res.json()
          })
          .then(data => {
            globalStore.vocabularyStore.addRows(data.data.vocabulary)
            globalStore.loungeEquipmentTableStore.setData(data.data.loungeEquipmentTable)
            globalStore.tableSizeStore.setData(data.data.tableSize)
            globalStore.tableSignStore.setData(data.data.tableSign)
            globalStore.clothSignStore.setData(data.data.clothSign)
          })
          .catch(err => notificationStore.add({ type: 'error', message: err }))
      }
    }

    return {
      globalStore, loungeId
    }
  }
}
</script>
