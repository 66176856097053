import { defineStore } from 'pinia'

let nextId = 1

export const useNotificationStore = defineStore('NotificationStore', {
  state: () => {
    return {
      notifications: []
    }
  },
  actions: {
    add(data) {
      data.id = nextId++
      if (data.type == 'error') {
        console.error(data.message)
      }
      this.notifications.push(data)
      setTimeout(() => {
        this.delete(data)
      }, 3e3)
    },
    delete(notification) {
      this.notifications = this.notifications.filter(
        existingNotification => existingNotification.id !== notification.id
      )
    }
  }
})
