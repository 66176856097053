<template>
  <template v-if="globalStore.isUserTypeAdministrator">
    <ButtonAdd v-if="!tournamentParticipantSettingTypeStore.count && addFormActive === false"
      :click-function="initAddForm" />
    <template v-else-if="addFormActive === true">
      <ButtonBack :click-function="deInitAddForm" />
      <FormKit type="form" v-model="itemData" @submit="processAddForm()">
        <FormKitSchema :schema="schema" :data="itemData" />
      </FormKit>
    </template>
    <template v-else-if="updateFormActive === true">
      <ButtonBack :click-function="deInitUpdateForm" />
      <FormKit type="form" v-model="itemData" @submit="processUpdateForm()">
        <FormKitSchema :schema="schema" :data="itemData" />
      </FormKit>
    </template>
  </template>
  <table v-if="tournamentParticipantSettingTypeStore.count"
    class="table table-hover table-striped align-middle table-light">
    <tbody>
      <tr>
        <td>
          {{ getParticipantSettingTypeNameByParticipantSettingTypeId() }}
        </td>
        <td>
          <template v-if="globalStore.isUserTypeAdministrator">
            <ButtonUpdate :click-function="initUpdateForm" />
            <span>&#160;</span>
            <ButtonDelete :click-function="deleteItem" />
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ref } from 'vue'
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import { useTournamentParticipantSettingTypeStore } from "@/stores/TournamentParticipantSettingTypeStore";
import { useStaticValueListStore } from "@/stores/StaticValueListStore";
import { useTournamentStore } from "@/stores/TournamentStore";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";

export default {
  name: 'TournamentParticipantSettingType',
  components: { ButtonDelete, ButtonUpdate, ButtonBack, ButtonAdd },
  setup() {
    const itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref(false),
      tournamentStore = useTournamentStore(),
      tournamentParticipantSettingTypeStore = useTournamentParticipantSettingTypeStore(),
      staticValueListStore = useStaticValueListStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore(),
      schema = ref([])

    function setSchema() {
      schema.value = []

      const options = {}
      for (let i in staticValueListStore.staticValueList.participantSettingType) {
        const row = staticValueListStore.staticValueList.participantSettingType[i]
        options[row.id] = globalStore.getWordById(row.name)
      }

      schema.value.push({
        $formkit: 'select',
        name: 'participantSettingTypeId',
        placeholder: globalStore.vocabularyStore.getWordByIdAndLanguage('choose', globalStore.languageId),
        options: options,
        validation: 'required'
      })
    }
    function getItem() {
      return tournamentParticipantSettingTypeStore.tournamentParticipantSettingType
    }

    function initAddForm() {
      setSchema()
      itemData.value = {}
      addFormActive.value = true
    }

    function initUpdateForm() {
      const item = getItem()
      if (!item) {
        return
      }
      itemData.value = {
        participantSettingTypeId: item.participantSettingTypeId
      }
      setSchema()
      updateFormActive.value = true
    }

    function processAddForm() {
      itemData.value.tournamentId = tournamentStore.tournament.id
      fetch(globalStore.apiUrl + '/admin/tournament-participant-setting-type', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            tournamentParticipantSettingTypeStore.addRow(response.data)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm(id) {
      const item = getItem(id)
      if (item) {
        itemData.value.tournamentId = tournamentStore.tournament.id
        itemData.value.id = item.id
        fetch(globalStore.apiUrl + '/admin/tournament-participant-setting-type', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              tournamentParticipantSettingTypeStore.updateRow(response.data)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm(id)
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(id)
        if (item) {
          fetch(globalStore.apiUrl + '/admin/tournament-participant-setting-type', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                tournamentParticipantSettingTypeStore.deleteRow()
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      itemData.value = {}
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value = {}
    }

    function getParticipantSettingTypeNameByParticipantSettingTypeId() {
      const participantSettingType = staticValueListStore.getItemByTypeAndId('participantSettingType', getItem().participantSettingTypeId)
      return globalStore.getWordById(participantSettingType.name)
    }

    return {
      addFormActive, updateFormActive, itemData, schema,
      globalStore, tournamentParticipantSettingTypeStore,
      initUpdateForm, initAddForm, processUpdateForm, deInitUpdateForm, deInitAddForm, processAddForm, deleteItem,
      getParticipantSettingTypeNameByParticipantSettingTypeId
    }
  }
}
</script>
