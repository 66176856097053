<template>
  <li class="nav-item"><router-link class="nav-link" to="/">Home</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/static-value-list">SVL</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/participants-merge">Participant merge</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/teams">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('teams', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/teams-in-season">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('teams in season', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/participants-in-season">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('participants in season', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/competitions">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('competitions', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/tournaments">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournaments', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/registry-office-log">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('registry office log', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/comments">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('comments', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/organizations">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('organizations', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/users">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('users', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/participants">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('participants', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/teams">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('teams', globalStore.languageId) }}</router-link></li>
  <li class="nav-item"><router-link class="nav-link" to="/clubs">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('clubs', globalStore.languageId) }}</router-link></li>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore";

export default {
  name: 'MenuSuper',
  components: {},

  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore
    }
  }
}
</script>
