import {defineStore} from 'pinia'

export const useTournamentResultCriterionStore = defineStore('TournamentResultCriterionStore', {
  state: () => {
    return {
      tournamentResultCriterion: []
    }
  },
  getters: {
    count: state => state.tournamentResultCriterion.length
  },
  actions: {
    setData(data) {
      this.tournamentResultCriterion = data
    }
  }
})