<template>
  <TeamsInSeason />
</template>

<script>
import TeamsInSeason from "@/components/TeamsInSeason";
export default {
  components: { TeamsInSeason }
}
</script>
