<template>
  <div v-if="globalStore.competitionStore.competition.id">
    <router-link to="/competitions">
      {{ globalStore.vocabularyStore.getWordByIdAndLanguage('competitions', globalStore.languageId) }}
    </router-link>
    <h1>{{ globalStore.competitionStore.competition.name }}</h1>
    <section>
      <!-- Pills -->
      <ul class=" nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li v-if="globalStore.userAdminStore.isUserRegion && globalStore.competitionStore.competition.teamTypeId" class="nav-item" role="presentation">
          <button class="nav-link" id="competition-participants-tab" data-bs-toggle="pill" data-bs-target="#competition-participants" type="button" role="tab" aria-controls="competition-participants">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('competition participants', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="competition-schedule-tab" data-bs-toggle="pill" data-bs-target="#competition-schedule" type="button" role="tab" aria-controls="competition-schedule" aria-selected="true">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('schedule', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="globalStore.userAdminStore.isUserRegion" class="nav-item" role="presentation">
          <button class="nav-link" id="competition-result-criterion-tab" data-bs-toggle="pill" data-bs-target="#competition-result-criterion" type="button" role="tab" aria-controls="competition-result-criterion">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('competition result criterion', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="globalStore.userAdminStore.isUserRegion && !globalStore.competitionStore.competition.teamTypeId" class="nav-item" role="presentation">
          <button class="nav-link" id="competition-tournament-result-criterion-tab" data-bs-toggle="pill" data-bs-target="#competition-tournament-result-criterion" type="button" role="tab" aria-controls="competition-tournament-result-criterion">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('competition tournament result criterion', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="globalStore.competitionStore.competition.teamTypeId && globalStore.userAdminStore.isUserRegion" class="nav-item" role="presentation">
          <button class="nav-link" id="competition-round-definition-tab" data-bs-toggle="pill" data-bs-target="#competition-round-definition" type="button" role="tab" aria-controls="competition-round-definition">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('competition round definition', globalStore.languageId) }}
          </button>
        </li>
        <li v-else-if="globalStore.userAdminStore.isUserRegion" class="nav-item" role="presentation">
          <button class="nav-link" id="tournament-stages-tab" data-bs-toggle="pill" data-bs-target="#tournament-stages" type="button" role="tab" aria-controls="tournament-stages" aria-selected="false">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stages', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="globalStore.userAdminStore.isUserRegion && !globalStore.competitionStore.competition.teamTypeId" class="nav-item" role="presentation">
          <button class="nav-link" id="competition-ranking-point-tab" data-bs-toggle="pill" data-bs-target="#competition-ranking-point" type="button" role="tab" aria-controls="competition-ranking-point">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('competition ranking point', globalStore.languageId) }}
          </button>
        </li>
        <li v-if="globalStore.userAdminStore.isUserRegion && globalStore.competitionStore.competition.teamTypeId" class="nav-item" role="presentation">
          <button class="nav-link" id="competition-team-event-participant-setting-type-tab" data-bs-toggle="pill" data-bs-target="#competition-team-event-participant-setting-type" type="button" role="tab" aria-controls="competition-team-event-participant-setting-type">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('competition team event participant setting type', globalStore.languageId) }}
          </button>
        </li>
      </ul>
      <!-- Contents -->
      <div class="tab-content" id="pills-tabContent">
        <div v-if="globalStore.userAdminStore.isUserRegion && globalStore.competitionStore.competition.teamTypeId" class="tab-pane fade" id="competition-participants" role="tabpanel" aria-labelledby="competition-participants-tab">
          <CompetitionParticipantList :competitionId="globalStore.competitionStore.competition.id" />
        </div>
        <div class="tab-pane fade show active" id="competition-schedule" role="tabpanel" aria-labelledby="competition-schedule-tab">
          <CompetitionTeamEventList v-if="globalStore.competitionStore.competition.teamTypeId" :competitionId="globalStore.competitionStore.competition.id" />
          <TournamentList v-else :competitionId="globalStore.competitionStore.competition.id" />
        </div>
        <div v-if="globalStore.userAdminStore.isUserRegion" class="tab-pane fade" id="competition-result-criterion" role="tabpanel" aria-labelledby="competition-result-criterion-tab">
          <CompetitionResultCriterionList :competitionId="globalStore.competitionStore.competition.id" />
        </div>
        <div v-if="globalStore.userAdminStore.isUserRegion && !globalStore.competitionStore.competition.teamTypeId" class="tab-pane fade" id="competition-tournament-result-criterion" role="tabpanel" aria-labelledby="competition-tournament-result-criterion-tab">
          <CompetitionTournamentResultCriterionList />
        </div>
        <div v-if="globalStore.competitionStore.competition.teamTypeId && globalStore.userAdminStore.isUserRegion" class="tab-pane fade" id="competition-round-definition" role="tabpanel" aria-labelledby="competition-round-definition-tab">
          <CompetitionEventRoundList :competitionId="globalStore.competitionStore.competition.id" />
        </div>
        <div v-else-if="globalStore.userAdminStore.isUserRegion" class="tab-pane fade" id="tournament-stages" role="tabpanel" aria-labelledby="tournament-stages-tab">
          <CompetitionStageDefinition />
        </div>
        <div v-if="globalStore.userAdminStore.isUserRegion && !globalStore.competitionStore.competition.teamTypeId" class="tab-pane fade" id="competition-ranking-point" role="tabpanel" aria-labelledby="competition-ranking-point-tab">
          <CompetitionRankingPointList :competitionId="globalStore.competitionStore.competition.id" />
        </div>
        <div v-if="globalStore.userAdminStore.isUserRegion && globalStore.competitionStore.competition.teamTypeId" class="tab-pane fade" id="competition-team-event-participant-setting-type" role="tabpanel" aria-labelledby="competition-team-event-participant-setting-type-tab">
          <CompetitionTeamEventParticipantSettingTypeList :competitionId="globalStore.competitionStore.competition.id" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useResultCriterionStore } from "@/stores/ResultCriterionStore"
import { useCompetitionResultCriterionStore } from "@/stores/CompetitionResultCriterionStore"
import { useTeamEventRoundStore } from "@/stores/TeamEventRoundStore"
import { useTeamEventRoundParameterStore } from "@/stores/TeamEventRoundParameterStore"
import { useEventParameterStore } from "@/stores/EventParameterStore"
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useCompetitionTeamEventParticipantSettingTypeStore } from "@/stores/CompetitionTeamEventParticipantSettingTypeStore"
import { useTeamEventParticipantSettingTypeStore } from "@/stores/TeamEventParticipantSettingTypeStore"
import CompetitionTeamEventParticipantSettingTypeList from '@/components/CompetitionManagement/CompetitionTeamEventParticipantSettingTypeList'
import CompetitionParticipantList from "@/components/CompetitionManagement/CompetitionParticipantList"
import CompetitionTeamEventList from "@/components/CompetitionManagement/CompetitionTeamEventList"
import CompetitionResultCriterionList from "@/components/CompetitionManagement/CompetitionResultCriterionList"
import CompetitionEventRoundList from "@/components/CompetitionManagement/CompetitionEventRoundList"
import TournamentList from '@/components/TournamentList.vue'
import { useCompetitionStageStore } from "@/stores/CompetitionStageStore"
import { useCompetitionTournamentResultCriterionStore } from "@/stores/CompetitionTournamentResultCriterionStore"
import { useCompetitionRankingPointStore } from "@/stores/CompetitionRankingPointStore"
import CompetitionStageDefinition from '@/components/CompetitionManagement/CompetitionStageDefinition'
import CompetitionTournamentResultCriterionList from '@/components/CompetitionManagement/CompetitionTournamentResultCriterionList'
import CompetitionRankingPointList from '@/components/CompetitionManagement/CompetitionRankingPointList'

export default {
  name: 'CompetitionManagement',
  components: {
    CompetitionParticipantList,
    CompetitionTeamEventList,
    CompetitionResultCriterionList,
    CompetitionEventRoundList,
    TournamentList,
    CompetitionStageDefinition,
    CompetitionTournamentResultCriterionList,
    CompetitionRankingPointList,
    CompetitionTeamEventParticipantSettingTypeList
  },

  setup() {
    const route = useRoute(),
      id = +route.params.id,
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      resultCriterionStore = useResultCriterionStore(),
      competitionResultCriterionStore = useCompetitionResultCriterionStore(),
      competitionStageStore = useCompetitionStageStore(),
      eventStageParameterStore = useEventStageParameterStore(),
      teamEventRoundStore = useTeamEventRoundStore(),
      teamEventRoundParameterStore = useTeamEventRoundParameterStore(),
      eventParameterStore = useEventParameterStore(),
      competitionTeamEventParticipantSettingTypeStore = useCompetitionTeamEventParticipantSettingTypeStore(),
      teamEventParticipantSettingTypeStore = useTeamEventParticipantSettingTypeStore(),
      competitionRankingPointStore = useCompetitionRankingPointStore(),
      competitionTournamentResultCriterionStore = useCompetitionTournamentResultCriterionStore()

    onMounted(() => {
      globalStore.competitionStore.competition = {}
      fetch(globalStore.apiUrl + '/admin/competition-management/' + id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.competitionStore.setDataOne(data.data.competition)
          globalStore.competitionStore.addRow(data.data.competition)
          globalStore.competitionDisciplineStore.setData(data.data.competitionDiscipline)
          globalStore.userAdminStore.setUserRole(data.data.competition.organizationId, data.data.competition.sectionId)
          globalStore.disciplineStore.setData(data.data.discipline)
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          resultCriterionStore.setData(data.data.resultCriterion)
          competitionResultCriterionStore.setData(data.data.competitionResultCriterion)
          eventParameterStore.setData(data.data.eventParameter)
          globalStore.competitionParticipantStore.emptyData()
          globalStore.participantStore.addData(data.data.participant)
          globalStore.competitionParticipantStore.setData(data.data.competitionParticipant)
          globalStore.loungeClubAddressTableStore.setData(data.data.loungeClubAddressTable)
          globalStore.clubAddressStore.setData(data.data.clubAddress)
          if (data.data.teamEventStage) {
            teamEventRoundStore.setData(data.data.teamEventStage)
          }
          if (data.data.teamEventStageParameter) {
            teamEventRoundParameterStore.setData(data.data.teamEventStageParameter)
          }
          if (data.data.eventStage) {
            competitionStageStore.setData(data.data.eventStage)
          }
          if (data.data.competitionTournamentResultCriterion) {
            competitionTournamentResultCriterionStore.setData(data.data.competitionTournamentResultCriterion)
          }
          if (data.data.eventStageParameter) {
            eventStageParameterStore.setData(data.data.eventStageParameter)
          }
          if (data.data.competitionTeamEventParticipantSettingType) {
            competitionTeamEventParticipantSettingTypeStore.setData(data.data.competitionTeamEventParticipantSettingType)
          }
          if (data.data.teamEventParticipantSettingType) {
            teamEventParticipantSettingTypeStore.setData(data.data.teamEventParticipantSettingType)
          }
          if (data.data.participantHasParticipant) {
            globalStore.teamParticipantStore.setData(data.data.participantHasParticipant)
          }
          if (data.data.stage) {
            globalStore.stageStore.setData(data.data.stage)
          }
          if (data.data.competitionRankingPoint) {
            competitionRankingPointStore.setData(data.data.competitionRankingPoint)
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    })

    return {
      globalStore
    }
  }
}
</script>
