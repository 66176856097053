import { defineStore } from 'pinia'

export const useCompetitionTournamentResultCriterionStore = defineStore('CompetitionTournamentResultCriterionStore', {
  state: () => {
    return {
      competitionTournamentResultCriterion: []
    }
  },
  getters: {
    count: state => state.competitionTournamentResultCriterion.length
  },
  actions: {
    setData(data) {
      this.competitionTournamentResultCriterion = data
    }
  }
})
