import { defineStore } from 'pinia'

export const useLiveStreamStore = defineStore('LiveStreamStore', {
  state: () => {
    return {
      liveStreams: {}
    }
  },
  getters: {
    count: state => Object.keys(state.liveStreams).length,
    hasEventLiveStream: state => {
      return eventId => {
        return !!state.liveStreams[eventId]
      }
    }
  },
  actions: {
    setData(data) {
      for (let i in data) {
        this.liveStreams[data[i].eventId] = data[i]
      }
    },
    addDataRows(data) {
      for (let i in data) {
        this.addDataRow(data[i])
      }
    },
    addDataRow(data) {
      if (!this.liveStreams[data.eventId]) {
        this.liveStreams[data.eventId] = data
      }
    },
    removeDataRow(id) {
      delete this.liveStreams[id]
    },
    actualizeDataRow(eventId, data) {
      if (this.liveStreams[eventId]) {
        this.liveStreams[eventId] = data
      } else {
        this.addDataRow(data)
      }
    }
  }
})
