<template>
  <TournamentStageDefinition />
  <div v-if="tournamentStageStore.count">
    <!-- Pills -->
    <ul class="nav nav-pills mb-3" id="pills-tabs-stages" role="tablist">
      <li v-for="row in tournamentStageStore.tournamentStages.sort((a, b) => b.priority - a.priority)" :key="row.id" class="nav-item" role="presentation">
        <button v-if="globalStore.applyStageParticipantMinCountLimit(row.stageId)"
          :class="'nav-link' + (row.stageId === globalStore.tournamentStageStore.getActiveStageId ? ' active' : '')"
          :id="'tournament-stage-' + row.stageId + '-tab'" data-bs-toggle="pill" :data-bs-target="'#tournament-stage-' + row.stageId" type="button" role="tab" :aria-controls="'tournament-stage-' + row.stageId"
          :aria-selected="row.stageId === globalStore.tournamentStageStore.activeStageId">
          {{ globalStore.getStageNameById(row.stageId) }}
        </button>
        <button v-else
          :class="'nav-link text-decoration-line-through' + (row.stageId === globalStore.tournamentStageStore.getActiveStageId ? ' active' : '')"
          :id="'tournament-stage-' + row.stageId + '-tab'" data-bs-toggle="pill" :data-bs-target="'#tournament-stage-' + row.stageId" type="button" role="tab" :aria-controls="'tournament-stage-' + row.stageId"
          :aria-selected="row.stageId === globalStore.tournamentStageStore.activeStageId">
          {{ globalStore.getStageNameById(row.stageId) }}
        </button>
      </li>
    </ul>
    <!-- Contents -->
    <div class="tab-content" id="pills-tabStagesContent">
      <div v-for="row in tournamentStageStore.tournamentStages" :key="row.id"
        :class="'tab-pane fade' + (row.stageId === globalStore.tournamentStageStore.getActiveStageId ? ' active show' : '')"
        :id="'tournament-stage-' + row.stageId" role="tabpanel" :aria-labelledby="'tournament-stage-' + row.stageId + '-tab'">
        <TournamentStage v-if="tournamentParticipantStageStore.getTournamentParticipantsByStageId(row.stageId)"
          :stageId="row.stageId" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useTournamentStore } from "@/stores/TournamentStore"
import { useTournamentStageStore } from "@/stores/TournamentStageStore"
import { useTournamentParticipantStageStore } from "@/stores/TournamentParticipantStageStore"
import { useStageStore } from "@/stores/StageStore"
import { useEventStore } from "@/stores/EventStore"
import { useTeamEventStore } from "@/stores/TeamEventStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import { useLiveScoreStore } from "@/stores/LiveScoreStore"
import { useLiveStreamStore } from "@/stores/LiveStreamStore"
import { useEventEquipmentTableStore } from "@/stores/EventEquipmentTableStore"
import TournamentStage from '@/components/TournamentManagement/TournamentStage'
import TournamentStageDefinition from '@/components/TournamentManagement/TournamentStageDefinition'
import { useEventRefereeStore } from "@/stores/EventRefereeStore";
import { useParticipantStore } from "@/stores/ParticipantStore";

export default {
  name: 'TournamentStages',
  components: { TournamentStage, TournamentStageDefinition },

  setup() {
    const tournamentStore = useTournamentStore(),
      eventStore = useEventStore(),
      teamEventStore = useTeamEventStore(),
      tournamentStageStore = useTournamentStageStore(),
      eventRefereeStore = useEventRefereeStore(),
      participantStore = useParticipantStore(),
      tournamentParticipantStageStore = useTournamentParticipantStageStore(),
      stageStore = useStageStore(),
      globalStore = useGlobalStore(),
      liveScoreStore = useLiveScoreStore(),
      liveStreamStore = useLiveStreamStore(),
      eventEquipmentTableStore = useEventEquipmentTableStore()

    onMounted(() => {
      fetch(globalStore.apiUrl + '/admin/tournament-management/stage/view/' + tournamentStore.tournament.id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => res.json())
        .then(data => {
          if (data.data.event) {
            if (!tournamentStore.isTournamentForTeams) {
              eventStore.setData(data.data.event)
            } else {
              const events = data.data.event.filter(row => row.eventId !== null),
                teamEvents = data.data.event.filter(row => row.eventId === null)
              if (events.length) {
                eventStore.setData(events)
              }
              if (teamEvents.length) {
                teamEventStore.setData(teamEvents)
              }
            }
          }
          if (data.data.liveScore) {
            liveScoreStore.setData(data.data.liveScore)
          }
          if (data.data.liveStream) {
            liveStreamStore.setData(data.data.liveStream)
          }
          if (data.data.eventEquipmentTable) {
            eventEquipmentTableStore.setData(data.data.eventEquipmentTable)
          }
          if (data.data.eventReferee) {
            eventRefereeStore.setData(data.data.eventReferee)
          }
          if (data.data.participant) {
            for (let i in data.data.participant) {
              participantStore.participants[data.data.participant[i].id] = data.data.participant[i]
            }
          }
        })
        .catch((err) => console.error(err))
    })

    return {
      tournamentStageStore, tournamentParticipantStageStore, stageStore, globalStore
    }
  }
}
</script>
