<template>
  <p>
    <button v-if="globalStore.hasUserUpdateRule" @click="(alive ? updateSchedule() : toggleAliveDrags())" type="button" :class="'btn ' + (alive ? 'btn-success' : 'btn-primary')">{{
      alive ?
      globalStore.vocabularyStore.getWordByIdAndLanguage('save schedule', globalStore.languageId) :
      globalStore.vocabularyStore.getWordByIdAndLanguage('update schedule', globalStore.languageId)
    }}
    </button>
  </p>
  <p v-if="alive">
    {{ globalStore.vocabularyStore.getWordByIdAndLanguage('schedule help', globalStore.languageId) }}
  </p>
  <div class="drag-events" v-if="globalStore.eventStore.hasStageEvents(stageId)"
    :style="'grid-template-columns: repeat(' + (tables.length + 1) + ', 1fr)'">
    <div>{{ globalStore.vocabularyStore.getWordByIdAndLanguage('round', globalStore.languageId) }}</div>
    <div v-for="table in tables" :key="table.id">
      {{ globalStore.vocabularyStore.getWordByIdAndLanguage('table nr', globalStore.languageId) }}
      {{ table.number }}
    </div>
    <template v-for="event in getStageEventsSection()" :key="event.id">
      <div v-if="+event.table.index === 0">
        <strong>{{ event.round }}.</strong> |
        <template v-if="alive">
          <FormKit type="datetime-local" v-model="roundDateStart[event.round]" validation="required" />
        </template>
        <template v-else>{{ globalStore.formatDateTime(roundDateStart[event.round]) }}</template>
      </div>
      <div v-if="event.id && (liveScoreStore.liveScores[event.id] || event.eventParticipant[0].score !== null)"
        :data-dateStart="event.dateStart" :data-id="event.id" :data-round="event.round"
        :data-table-id="event.table.id" :data-table-index="event.table.index" :data-table-number="event.table.number"
        :class="'no-drag-event group-' + globalStore.getEventStageSign(event)">
        <!-- Event with livescore -->
        <img v-if="liveScoreStore.liveScores[event.id]" :src="require('@/assets/images/abacus.svg')" alt=""
          class="icon-abacus" />
        <template v-if="globalStore.getEventStageSign(event) !== ''">{{ globalStore.getEventStageSign(event) }} |
        </template>
        <span>{{ getParticipantNames(event) }}</span>
      </div>
      <div v-else-if="event.id" :data-dateStart="event.dateStart" :data-id="event.id" :data-round="event.round"
        :data-table-id="event.table.id" :data-table-index="event.table.index" :data-table-number="event.table.number"
        :class="'drag-event drag-event-' + stageId + ' group-' + globalStore.getEventStageSign(event)">
        <!-- Event to drag -->
        <template v-if="globalStore.getEventStageSign(event) !== ''">{{ globalStore.getEventStageSign(event) }} |
        </template>
        <span>{{ getParticipantNames(event) }} </span>
      </div>
      <div v-else :data-id="0" :data-dateStart="event.dateStart" :data-round="event.round"
        :data-table-id="event.table.id" :data-table-index="event.table.index" :data-table-number="event.table.number"
        :class="'drag-event drag-event-' + stageId">
        <span>----</span>
      </div>
    </template>
  </div>
  <br />
</template>

<script>
import { ref, toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore";
import { useLiveScoreStore } from "@/stores/LiveScoreStore";
import { useEventEquipmentTableStore } from "@/stores/EventEquipmentTableStore";

export default {
  name: 'TournamentStageSchedule',
  components: {},
  props: {
    setStageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const stageId = toRef(props, 'setStageId'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      liveScoreStore = useLiveScoreStore(),
      eventEquipmentTableStore = useEventEquipmentTableStore(),
      alive = ref(false),
      roundDateStart = ref({}),
      tournament = globalStore.tournamentStore.tournament,
      tables = globalStore.loungeClubAddressTableStore.getByClubIdTableSizeId(
        tournament.clubId,
        tournament.tableSizeId
      )

    function switchEvents(event_0, event_1) {
      if (+event_0.id > 0 && +event_1.id > 0 && globalStore.eventStore.getEventById(+event_0.id).stageId !== globalStore.eventStore.getEventById(+event_1.id).stageId) {
        alert(globalStore.vocabularyStore.getWordByIdAndLanguage('schedule switch error stage does not equal', globalStore.languageId))
        return
      }
      globalStore.eventStore.switchSchedule([event_0, event_1])
    }

    function updateSchedule() {
      fetch(globalStore.apiUrl + '/admin/tournament-schedule-event', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ event: getStageEvents(), round: roundDateStart.value })
      })
        .then(res => res.json())
        .then(response => {
          for (let i in roundDateStart.value) {
            roundDateStart.value[i] = roundDateStart.value[i].replace('T', ' ')
          }
          if (response.statusCode === 200) {
            const events = getStageEvents()
            for (let i in events) {
              const event = events[i]
              event.dateStart = roundDateStart.value[event.round]
              if (event.dateStart.includes('T')) {
                event.dateStart = event.dateStart.replace('T', ' ') + ':00'
              }
              if (event.table.id) {
                event.loungeEquipmentTableId = event.table.id
              }
            }
            toggleAliveDrags()
            notificationStore.add({
              type: 'success',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)
            })
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({
              type: 'error',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
            })
          }
        })
        .catch((err) => console.error(err))
    }

    function getParticipantNames(event) {
      let names = []
      for (let i in event.eventParticipant) {
        if (event.eventParticipant[i].participantId) {
          names.push(globalStore.getParticipantNameById(event.eventParticipant[i].participantId))
        } else if (event.eventParticipant[i].prediction) {
          let name = event.eventParticipant[i].prediction.name
          if (event.eventParticipant[i].prediction.relatedEventParticipants) {
            name += ' (' + event.eventParticipant[i].prediction.relatedEventParticipants + ')'
          }
          names.push(name)
        }
      }
      return names.join(' v ')
    }

    function getStageEvents() {
      const tablesCount = tables.length

      let events = globalStore.eventStore.getEventsByStageWithParticipantPredictionByStageId(stageId.value, globalStore),
        eventsWithoutByes = [],
        tableIndex = 0

      for (let i in events) {
        if (!eventHasFreelancer(events[i])) {
          eventsWithoutByes.push(events[i])
        }
      }

      for (let i in eventsWithoutByes) {
        const event = eventsWithoutByes[i]
        roundDateStart.value[event.round] = roundDateStart.value[event.round] ? roundDateStart.value[event.round] : event.dateStart
        if (!event.table && eventEquipmentTableStore.hasEventEquipmentTable(event.id)) {
          const eventEquipmentTable = eventEquipmentTableStore.eventEquipmentTables[event.id],
            loungeEquipmentTableId = eventEquipmentTable.loungeEquipmentTableId,
            tableData = globalStore.loungeClubAddressTableStore.getById(loungeEquipmentTableId)

          event.table = {
            id: tableData.id,
            number: tableData.number,
            index: globalStore.loungeClubAddressTableStore.getTableIndex(loungeEquipmentTableId)
          }
        }
      }

      for (let i in eventsWithoutByes) {
        const event = eventsWithoutByes[i]

        if (tableIndex === tablesCount || eventsWithoutByes.length === 1) {
          tableIndex = 0
        }
        if (!tables[tableIndex]) {
          continue
        }

        if (event.table === undefined) {
          event.table = {
            id: tables[tableIndex].id,
            number: tables[tableIndex].number,
            index: tableIndex
          }
        }
        tableIndex++
      }

      eventsWithoutByes = eventsWithoutByes.sort((a, b) => {
        if (a.round === b.round && a.table && b.table) {
          return a.table.index - b.table.index
        }
        return a.round - b.round
      })

      return eventsWithoutByes
    }

    function getStageEventsSection() {
      const maxTableIndex = tables.length - 1,
        events = getStageEvents(),
        section = []
      let tableIndex = 0

      for (let i = 0; i < events.length; i++) {
        if (!events[i].table) {
          continue
        }
        const event = events[i],
          eventTableIndex = +event.table.index,
          round = +event.round

        if (tableIndex < eventTableIndex) {
          let fakeEventsCount = eventTableIndex - tableIndex
          for (let j = fakeEventsCount; j > 0; j--) {
            section.push(getFakeEvent(eventTableIndex - j, round))
            tableIndex++
          }
        }

        section.push(event)

        if ((!events[i + 1] || events[i].round !== events[i + 1].round || events[i + 1].table.index === 0) && eventTableIndex < maxTableIndex) {
          let fakeEventsCount = maxTableIndex - eventTableIndex
          for (let j = 1; j <= fakeEventsCount; j++) {
            section.push(getFakeEvent(j + eventTableIndex, round))
            tableIndex++
          }
        }
        tableIndex++
        if (tableIndex > maxTableIndex) {
          tableIndex = 0
        }
      }

      return section
    }

    function getFakeEvent(index, round) {
      return {
        table: {
          index: index,
          id: tables[index].id,
          number: tables[index].number
        },
        round: round,
        dateStart: roundDateStart[round]
      }
    }

    function toggleAliveDrags() {
      let dragSrcEl = null
      const items = document.querySelectorAll('.drag-event-' + stageId.value)

      function handleDragStart(e) {
        this.style.opacity = '0.4'
        dragSrcEl = this
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('text/html', this.innerHTML)
      }

      function handleDragOver(e) {
        if (e.preventDefault) {
          e.preventDefault()
        }
        e.dataTransfer.dropEffect = 'move'
        return false;
      }

      function handleDragEnter() {
        this.classList.add('over')
      }

      function handleDragLeave() {
        this.classList.remove('over')
      }

      function handleDrop(e) {
        e.stopPropagation()

        if (dragSrcEl && dragSrcEl !== this) {
          const event_0 = {
            id: dragSrcEl.getAttribute('data-id'),
            round: dragSrcEl.getAttribute('data-round'),
            tableId: dragSrcEl.getAttribute('data-table-id'),
            tableNumber: dragSrcEl.getAttribute('data-table-number'),
            tableIndex: dragSrcEl.getAttribute('data-table-index'),
            dateStart: dragSrcEl.getAttribute('data-dateStart'),
          },
            event_1 = {
              id: this.getAttribute('data-id'),
              round: this.getAttribute('data-round'),
              tableId: this.getAttribute('data-table-id'),
              tableNumber: this.getAttribute('data-table-number'),
              tableIndex: this.getAttribute('data-table-index'),
              dateStart: this.getAttribute('data-dateStart'),
            }

          switchEvents(event_0, event_1)
        }
        return false;
      }

      function handleDragEnd() {
        this.style.opacity = '1'
        items.forEach(function (item) {
          item.classList.remove('over')
        });
      }

      alive.value = !alive.value
      if (alive.value === true) {
        items.forEach(item => {
          item.setAttribute('draggable', 'true')
        })
      } else {
        items.forEach(item => {
          item.setAttribute('draggable', 'false')
        })
      }

      items.forEach(item => {
        item.addEventListener('dragstart', handleDragStart, false)
        item.addEventListener('dragenter', handleDragEnter, false)
        item.addEventListener('dragover', handleDragOver, false)
        item.addEventListener('dragleave', handleDragLeave, false)
        item.addEventListener('drop', handleDrop, false)
        item.addEventListener('dragend', handleDragEnd, false)
      })
    }

    function eventHasFreelancer(event) {
      for (let i in event.eventParticipant) {
        if (event.eventParticipant[i].participantId === globalStore.eventStore.freelancerId) {
          return true
        }
      }
      return false
    }

    return {
      stageId, globalStore, alive, eventEquipmentTableStore, tables, roundDateStart, liveScoreStore,
      toggleAliveDrags, getParticipantNames, getStageEventsSection, updateSchedule
    }
  }
}
</script>

<style scoped>
img.icon-abacus {
  width: 1.5em;
  top: -.2em;
  position: relative;
}

.drag-events {
  display: grid;
  gap: 10px;
}

.drag-event {
  border: 3px solid #666;
  background-color: #ddd;
  border-radius: .5em;
  padding: 10px;
}

.no-drag-event {
  border: 3px solid #666;
  border-radius: .5em;
  padding: 10px;
  opacity: .5;
}

div[draggable="true"] {
  cursor: move;
}

.drag-event.over {
  border: 3px dotted #666;
}

.group-A {
  background-color: lightcoral;
}

.group-B {
  background-color: lightsteelblue;
}

.group-C {
  background-color: lightgoldenrodyellow;
}

.group-D {
  background-color: lightgreen;
}

.group-E {
  background-color: lightpink;
}

.group-F {
  background-color: lightsalmon;
}

.group-H {
  background-color: lightgray;
}
</style>
