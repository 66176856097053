<template>
  <ParticipantsInSeason />
</template>

<script>
import ParticipantsInSeason from "@/components/ParticipantsInSeason";
export default {
  components: { ParticipantsInSeason }
}
</script>
