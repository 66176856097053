<template>
  <template v-if="globalStore.isUserTypeAdministrator">
    <template v-if="addFormActive === true">
      <table class="table table-hover table-striped align-middle table-light">
        <tbody>
          <tr>
            <td>
              <ButtonBack :click-function="deInitAddForm" />
            </td>
            <td>
              <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
                <FormKitSchema :schema="schema" />
              </FormKit>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else-if="!tournamentStage.competitionId && getItems().length < disciplineStore.disciplines.length">
      <ButtonAdd :click-function="initAddForm"
        :word="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage add params', globalStore.languageId)" />
    </template>
    <hr />
  </template>
  <template v-if="getItems().length">
    <table class="table table-hover table-striped align-middle table-light">
      <tbody>
        <template v-for="(roundParameters, round) in getItems()" :key="round">
          <template
            v-if="globalStore.isUserTypeAdministrator && updateFormActive[tournamentStage.id] && updateFormActive[tournamentStage.id][roundParameters[0].round] === true">
            <tr>
              <td>
                <button @click="deInitUpdateForm(tournamentStage.id, roundParameters[0].round)"
                  class="btn btn-outline-primary btn-sm">
                  {{ globalStore.vocabularyStore.getWordByIdAndLanguage('back', globalStore.languageId) }}
                </button>
              </td>
              <td>
                <FormKit type="form" v-model="itemData[tournamentStage.id][roundParameters[0].round]"
                  @submit="processUpdateForm(roundParameters[0].round)">
                  <FormKitSchema :schema="schema" />
                </FormKit>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <th colspan="2">
                {{ roundParameters[0].round }}.
                <template v-for="(value, key) in roundParameters" :key="key">
                  <template v-if="value.eventParameterId === 10">
                    {{ globalStore.getDisciplineNameById(value.value) }}
                  </template>
                </template>
              </th>
            </tr>
            <tr>
              <td>
                <ul>
                  <template v-for="(value, key) in roundParameters" :key="key">
                    <li v-if="value.eventParameterId !== 11">
                      <template v-if="value.eventParameterId === 10">
                        <!-- Discipline -->
                        {{ globalStore.getEventParameterNameById(value.eventParameterId) }}: {{
                          globalStore.getDisciplineNameById(value.value) }}
                      </template>
                      <template v-else>
                        {{ globalStore.getEventParameterNameById(value.eventParameterId) }}: {{ value.value }}
                      </template>
                    </li>
                  </template>
                </ul>
              </td>
              <td>
                <template v-if="globalStore.isUserTypeAdministrator">
                  <template v-if="updateFormActive[tournamentStage.id] === true">
                    <ButtonSave :click-function="processUpdateForm"
                      :click-function-parameter="roundParameters[0].round" />
                  </template>
                  <template
                    v-else-if="!tournamentStage.competitionId && !hasAnyEventLiveScore(stageId) && (!eventStore.hasStageEvents(stageId) || eventStore.hasStageOnlyBlankEvents(stageId))">
                    <button v-if="globalStore.hasUserUpdateRule"
                      @click="initUpdateForm(tournamentStage.id, roundParameters[0].round)"
                      class="btn btn-outline-primary btn-sm">
                      {{ globalStore.vocabularyStore.getWordByIdAndLanguage('update', globalStore.languageId) }}
                    </button>
                    <span>&#160;</span>
                    <ButtonDelete :click-function="deleteItem" :click-function-parameter="roundParameters[0].round" />
                  </template>
                </template>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </template>
</template>

<script>
import { ref, toRef } from 'vue'
import { useEventStore } from "@/stores/EventStore"
import { useTournamentStageStore } from "@/stores/TournamentStageStore"
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useEventParameterStore } from "@/stores/EventParameterStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import { useDisciplineStore } from "@/stores/DisciplineStore"
import { useLiveScoreStore } from "@/stores/LiveScoreStore"
import { FormKitSchema } from '@formkit/vue'
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import ButtonSave from "@/components/Button/ButtonSave.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";

export default {
  name: 'TournamentStageDefinitionEvent',
  components: { ButtonDelete, ButtonSave, ButtonAdd, ButtonBack, FormKitSchema },
  props: {
    stageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const stageId = toRef(props, 'stageId'),
      itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref(false),
      tournamentStageStore = useTournamentStageStore(),
      eventStageParameterStore = useEventStageParameterStore(),
      eventParameterStore = useEventParameterStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore(),
      eventStore = useEventStore(),
      liveScoreStore = useLiveScoreStore(),
      disciplineStore = useDisciplineStore(),
      tournamentStage = tournamentStageStore.getTournamentStageByStageId(stageId.value),
      onlyTrue = {
        '1': ''
      },
      schema = ref([])

    function getItems() {
      let items = []
      if (eventStageParameterStore.eventStageParameters[tournamentStage.id]) {
        for (let round in eventStageParameterStore.eventStageParameters[tournamentStage.id]) {
          items.push(eventStageParameterStore.eventStageParameters[tournamentStage.id][round])
        }
      }
      return items
    }

    function setSchema() {
      schema.value = []
      schema.value.push({
        $el: 'h3',
        children: 'Parametry zápasu'
      });

      for (let i in eventParameterStore.eventParameters) {
        const item = eventParameterStore.eventParameters[i]
        if (
          item.id === 9 ||
          item.id === 10 ||
          item.id === 12 ||
          item.id === 1 ||
          item.id === 2 ||
          item.id === 3 ||
          item.id === 4 ||
          item.id === 5
        ) {
          const parameter = {
            $formkit: 'number',
            name: item.id.toString(),
            label: globalStore.getEventParameterNameById(item.id),
          }

          if (item.id === 1 || item.id === 2 || item.id === 10 || item.id === 11 || item.id === 12) {
            parameter.validation = 'required'
            parameter.min = 1
          } else if (item.id === 9) {
            parameter.min = 2
            parameter.max = 3
            parameter.validation = 'required|min:2|max:3'
          } else if (item.id === 4 || item.id === 5) {
            parameter.$formkit = 'checkbox'
            parameter.options = onlyTrue
          }
          if (item.id === 10) {
            const options = {}
            for (let j in disciplineStore.disciplines) {
              options[disciplineStore.disciplines[j].id] = globalStore.getDisciplineNameById(disciplineStore.disciplines[j].name)
            }
            parameter.$formkit = 'select'
            parameter.options = options
          }
          schema.value.push(parameter)
        }
      }

      schema.value.push({
        $el: 'h3',
        children: 'Parametry livescore'
      });

      for (let i in eventParameterStore.eventParameters) {
        const item = eventParameterStore.eventParameters[i]
        if (
          item.id === 6 ||
          item.id === 7 ||
          item.id === 8 ||
          item.id === 13 ||
          item.id === 14
        ) {
          const parameter = {
            $formkit: 'number',
            name: item.id.toString(),
            label: globalStore.getEventParameterNameById(item.id),
            id: 'id' + item.id.toString(),
            min: 1
          }
          if (item.id === 13) {
            parameter.help = globalStore.vocabularyStore.getWordByIdAndLanguage('event timeout alert help', globalStore.languageId)
          } else if (item.id === 14) {
            parameter.help = globalStore.vocabularyStore.getWordByIdAndLanguage('event autotimeout help', globalStore.languageId)
          }
          /*if (item.id === 6 || item.id === 7 || item.id === 8 || item.id === 13) {
            parameter.if = '$get(id14).value === undefined'
          } else if (item.id === 14) {
            parameter.if = '$get(id6).value === undefined'
          }*/
          schema.value.push(parameter)
        }
      }
    }

    function initAddForm() {
      itemData.value[''] = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm(id, round) {
      const items = getItems()
      if (!items.length || !items[round - 1]) {
        return
      }
      itemData.value[id] = {}
      itemData.value[id][round] = {}
      for (let i in eventStageParameterStore.eventStageParameters[id][round]) {
        const eventStageParameter = eventStageParameterStore.eventStageParameters[id][round][i]
        if (eventStageParameter.eventStageId === tournamentStage.id) {
          if (eventStageParameter.eventParameterId === 4 || eventStageParameter.eventParameterId === 5) {
            itemData.value[id][round][eventStageParameter.eventParameterId] = [eventStageParameter.value]
          } else {
            itemData.value[id][round][eventStageParameter.eventParameterId] = eventStageParameter.value
          }
        }
      }

      setSchema()

      updateFormActive.value[id] = {}
      updateFormActive.value[id][round] = true
    }

    function getCreatingRound() {
      let items = eventStageParameterStore.eventStageParameters[tournamentStage.id] || null
      if (!items) {
        return 1
      }
      for (let i = 1; i < 100; i++) {
        if (items[i] === undefined) {
          return i;
        }
      }
      return 100
    }

    function processAddForm() {
      itemData.value[''].eventStageId = tournamentStage.id
      itemData.value[''].round = getCreatingRound()
      fetch(globalStore.apiUrl + '/admin/tournament-event-stage-parameter', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            eventStageParameterStore.addDataFromArray(response.data)
            deInitAddForm()
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => console.error(err))
    }

    function processUpdateForm(round) {
      const item = itemData.value[tournamentStage.id][round]
      if (item) {
        itemData.value[tournamentStage.id][round].eventStageId = tournamentStage.id
        itemData.value[tournamentStage.id][round].round = round
        fetch(globalStore.apiUrl + '/admin/tournament-event-stage-parameter', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value[tournamentStage.id][round])
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              eventStageParameterStore.replaceStageParameters(response.data, tournamentStage.id)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              const newParams = {}
              for (let i in response.data) {
                if (response.data[i].eventParameterId === 1) {
                  newParams.winningPartsCount = response.data[i].value
                } else if (response.data[i].eventParameterId === 2) {
                  newParams.targetPoints = response.data[i].value
                } else if (response.data[i].eventParameterId === 3) {
                  newParams.maxInnings = response.data[i].value
                } else if (response.data[i].eventParameterId === 4) {
                  newParams.equalizingInning = response.data[i].value
                } else if (response.data[i].eventParameterId === 5) {
                  newParams.extension = response.data[i].value
                }
              }
              eventStore.updateStageEventParameters(tournamentStage.stageId, newParams)
              deInitUpdateForm(tournamentStage.id, round)
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem(round) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const items = getItems()[round - 1]
        if (items) {
          fetch(globalStore.apiUrl + '/admin/tournament-event-stage-parameter', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ items: items })
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                eventStageParameterStore.deleteStageParametersByEventStageId(items[0].eventStageId, round)
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deInitUpdateForm(id, round) {
      updateFormActive.value[id][round] = false
      if (itemData.value[id][round]) {
        delete (itemData.value[id][round])
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    function hasAnyEventLiveScore(stageId) {
      const events = eventStore.getEventsByStage(stageId)
      for (let i in events) {
        if (liveScoreStore.hasEventLiveScore(events[i].id)) {
          return true
        }
      }
      return false
    }

    return {
      globalStore, eventStageParameterStore, eventParameterStore, itemData, updateFormActive, addFormActive, onlyTrue,
      tournamentStage, schema, eventStore, disciplineStore,
      getItems, initAddForm, initUpdateForm, processAddForm, processUpdateForm, deleteItem, deInitUpdateForm, deInitAddForm,
      hasAnyEventLiveScore
    }
  }
}
</script>
