<template>
  <div v-if="organizationSectionId">
    <section>
      <!-- Pills -->
      <ul class=" nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link" :id="'organization-section-seasons-tab-' + organizationSectionId" data-bs-toggle="pill" :data-bs-target="'#organization-section-seasons-' + organizationSectionId" type="button" role="tab" aria-controls="organization-section-seasons">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('organization section seasons', globalStore.languageId) }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" :id="'organization-section-regions-tab-' + organizationSectionId" data-bs-toggle="pill" :data-bs-target="'#organization-section-regions-' + organizationSectionId" type="button" role="tab" aria-controls="organization-section-regions">
            {{ globalStore.vocabularyStore.getWordByIdAndLanguage('organization section regions', globalStore.languageId) }}
          </button>
        </li>
      </ul>
      <!-- Contents -->
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade" :id="'organization-section-seasons-' + organizationSectionId" role="tabpanel" :aria-labelledby="'organization-section-seasons-tab-' + organizationSectionId">
          <SeasonList :organizationSectionIdSet="organizationSectionId" />
        </div>
        <div class="tab-pane fade" :id="'organization-section-regions-' + organizationSectionId" role="tabpanel" :aria-labelledby="'organization-section-regions-tab-' + organizationSectionId">
          <SectionRegionList :organizationSectionIdSet="organizationSectionId" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import SeasonList from '@/components/OrganizationManagement/SectionManagement/SeasonList'
import SectionRegionList from '@/components/OrganizationManagement/SectionManagement/SectionRegionList'

export default {
  name: 'SectionSettings',
  components: {
    SeasonList,
    SectionRegionList
  },
  props: {
    organizationSectionIdSet: {
      required: true,
      type: Number
    },
  },

  setup(props) {
    const organizationSectionId = toRef(props, 'organizationSectionIdSet'),
      globalStore = useGlobalStore()

    return {
      globalStore, organizationSectionId
    }
  }
}
</script>
