import { defineStore } from 'pinia'

export const useClubAddressStore = defineStore('ClubAddressStore', {
  state: () => {
    return {
      data: []
    }
  },
  getters: {
    count: state => state.data.length,
    getById: state => {
      return id => {
        const data = state.data.filter(row => row.id === id)
        return data ? data[0] : null
      }
    },
    getByClubId: state => {
      return clubId => {
        return state.data.filter(row => row.clubId === clubId)
      }
    },
    getByAddressId: state => {
      return addressId => {
        return state.data.filter(row => row.addressId === addressId)
      }
    }
  },
  actions: {
    setData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          this.addData(rows[i])
        }
      }
    },
    addData(item) {
      if (item && item.loungeId && !this.data.filter(row => row.loungeId === item.loungeId).length) {
        this.data.push(item)
      }
    }
  }
})
