<template>
  <template v-if="liveScore">
    <table class="table table-striped">
      <tbody>
        <tr v-for="player in liveScore.players" :key="'i' + player.id">
          <td>{{ player.name }}</td>
          <td v-for="(set, index) in player.sets" :key="index">
            <template v-if="set.points !== undefined">{{ set.points }}</template>&#160;/&#160;<template v-if="set.innings !== undefined">{{ set.innings }}</template>
            <template v-if="set.extensionPoints !== undefined">
              &#160;/&#160;{{ set.extensionPoints }}
            </template>
          </td>
          <td>
            <template v-if="player.run !== undefined && player.run !== null">{{ player.run }}</template>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table table-striped">
      <thead>
        <tr>
          <th>{{ globalStore.vocabularyStore.getWordByIdAndLanguage('inning', globalStore.languageId) }}</th>
          <th colspan="2" v-for="player in liveScore.players" :key="player.id">{{ player.name }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(setNumber, setIndex) in getCountOfSets()" :key="setNumber">
          <tr v-if="getCountOfSets() > 1">
            <th :colspan="(getCountOfPlayers() * 2) + 1">
              Set {{ setNumber }}
            </th>
          </tr>
          <tr v-for="(inningNumber, inningIndex) in getCountOfSetInnings(setIndex)" :key="inningNumber">
            <td>
              <span style="float: right;">
                {{ inningNumber }}.
              </span>
            </td>
            <template v-for="(player, i) in liveScore.players" :key="player.id">
              <td :class="getRun(i, setIndex, inningIndex) === player.highestRun ? 'table-success' : ''">
                <span style="float: right;">
                  {{ getRun(i, setIndex, inningIndex) }}
                </span>
              </td>
              <td>
                <strong style="float: right;">
                  {{ getParticipantSumPointInSetByInning(i, setIndex, inningIndex) }}
                </strong>
              </td>
            </template>
          </tr>
          <template v-if="hasSetExtension(setIndex)">
            <tr>
              <th :colspan="(getCountOfPlayers() * 2) + 1">
                Extension
              </th>
            </tr>
            <tr v-for="(inningNumber, inningIndex) in getCountOfSetInningsExtension(setIndex)" :key="inningNumber">
              <td>
                <span style="float: right;">
                  {{ inningNumber }}.
                </span>
              </td>
              <template v-for="(player, i) in liveScore.players" :key="player.id">
                <td :class="getRunExtension(i, setIndex, inningIndex) === player.highestRun ? 'table-success' : ''">
                  <span style="float: right;">
                    {{ getRunExtension(i, setIndex, inningIndex) }}
                  </span>
                </td>
                <td>
                  <strong style="float: right;">
                    {{ getParticipantSumPointInSetByInningExtension(i, setIndex, inningIndex) }}
                  </strong>
                </td>
              </template>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </template>
</template>

<script>
import { toRef } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"

export default {
  name: 'EventRecord',
  components: {},
  props: {
    liveScoreObject: {
      required: true,
      type: Object
    }
  },

  setup(props) {
    const liveScore = toRef(props, 'liveScoreObject'),
      globalStore = useGlobalStore()

    function getCountOfSets() {
      let count = 0
      for (let i in liveScore.value.players) {
        if (liveScore.value.players[i].sets && liveScore.value.players[i].sets.length > count) {
          count = liveScore.value.players[i].sets.length
        }
      }
      return count
    }
    function getCountOfSetInnings(setIndex) {
      let count = 0
      if (liveScore.value.runs && liveScore.value.runs[setIndex]) {
        for (let i in liveScore.value.runs[setIndex]) {
          if (liveScore.value.runs[setIndex][i] && liveScore.value.runs[setIndex][i].length > count) {
            count = liveScore.value.runs[setIndex][i].length
          }
        }
      }
      return count
    }
    function getCountOfSetInningsExtension(setIndex) {
      let count = 0
      if (liveScore.value.extensionRuns && liveScore.value.extensionRuns.runs[setIndex]) {
        for (let i in liveScore.value.extensionRuns.runs[setIndex]) {
          if (liveScore.value.extensionRuns.runs[setIndex][i].length > count) {
            count = liveScore.value.extensionRuns.runs[setIndex][i].length
          }
        }
      }
      return count
    }
    function getCountOfPlayers() {
      return liveScore.value.players.length
    }
    function getRun(participantIndex, setIndex, inningIndex) {
      if (liveScore.value.runs) {
        if (liveScore.value.runs[setIndex]) {
          if (liveScore.value.runs[setIndex][participantIndex]) {
            if (liveScore.value.runs[setIndex][participantIndex][inningIndex]) {
              return liveScore.value.runs[setIndex][participantIndex][inningIndex]
            }
          }
        }
      }
      return 0
    }
    function getParticipantSumPointInSetByInning(participantIndex, setIndex, inningIndex) {
      let sum = 0;
      if (liveScore.value.runs) {
        if (liveScore.value.runs[setIndex]) {
          if (liveScore.value.runs[setIndex][participantIndex]) {
            if (liveScore.value.runs[setIndex][participantIndex][inningIndex] !== undefined) {
              for (let i in liveScore.value.runs[setIndex][participantIndex]) {
                if (i <= inningIndex) {
                  sum += liveScore.value.runs[setIndex][participantIndex][i]
                }
              }
            }
          }
        }
      }
      return sum
    }
    function hasSetExtension(setIndex) {
      if (liveScore.value.extensionRuns && liveScore.value.extensionRuns.runs) {
        if (liveScore.value.extensionRuns.runs[setIndex] && liveScore.value.extensionRuns.runs[setIndex].length) {
          return true
        }
      }
      return 0
    }
    function getRunExtension(participantIndex, setIndex, inningIndex) {
      if (liveScore.value.extensionRuns && liveScore.value.extensionRuns.runs) {
        if (liveScore.value.extensionRuns.runs[setIndex]) {
          if (liveScore.value.extensionRuns.runs[setIndex][participantIndex]) {
            if (liveScore.value.extensionRuns.runs[setIndex][participantIndex][inningIndex]) {
              return liveScore.value.extensionRuns.runs[setIndex][participantIndex][inningIndex]
            }
          }
        }
      }
      return 0
    }
    function getParticipantSumPointInSetByInningExtension(participantIndex, setIndex, inningIndex) {
      let sum = 0;
      if (liveScore.value.extensionRuns && liveScore.value.extensionRuns.runs) {
        if (liveScore.value.extensionRuns.runs[setIndex]) {
          if (liveScore.value.extensionRuns.runs[setIndex][participantIndex]) {
            if (liveScore.value.extensionRuns.runs[setIndex][participantIndex][inningIndex] !== undefined) {
              for (let i in liveScore.value.extensionRuns.runs[setIndex][participantIndex]) {
                if (i <= inningIndex) {
                  sum += liveScore.value.extensionRuns.runs[setIndex][participantIndex][i]
                }
              }
            }
          }
        }
      }
      return sum
    }

    return {
      liveScore, globalStore,
      getCountOfSets, getCountOfPlayers, getCountOfSetInnings, getRun, getParticipantSumPointInSetByInning, hasSetExtension,
      getCountOfSetInningsExtension, getRunExtension, getParticipantSumPointInSetByInningExtension
    }
  }
}
</script>
