<template>
  <tr>
    <template v-if="sectionRegion">
      <template v-if="updateFormActive">
        <td>
          <ButtonBack :click-function="deInitUpdateForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processUpdateForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
      <template v-else>
        <td>
          <template v-if="sectionRegion.regionInSectionId">
            {{
      globalStore.getWordById(
        globalStore.organizationRegionStore.getById(globalStore.sectionHasRegionStore.getById(sectionRegion.regionInSectionId).regionInOrganizationId).name
      )
    }}
          </template>
        </td>
        <td>
          {{ globalStore.getWordById(globalStore.organizationRegionStore.getById(sectionRegion.regionInOrganizationId).name) }}
        </td>
        <td>
          <ButtonUpdate
            :click-function="initUpdateForm" />
          <span>&#160;</span>
          <ButtonDelete
            :click-function="deleteItem" />
        </td>
      </template>
    </template>
    <template v-else>
      <td v-if="addFormActive === false" colspan="3">
        <ButtonAdd :viewPermanently="true" :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="3">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKitSchema :schema="schema" />
          </FormKit>
        </td>
      </template>
    </template>
  </tr>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import ButtonUpdate from "@/components/Button/ButtonUpdate";
import ButtonDelete from "@/components/Button/ButtonDelete";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";

export default {
  name: 'SectionRegionItem',
  components: { ButtonUpdate, ButtonDelete, ButtonBack, ButtonAdd },
  props: {
    organizationIdSet: {
      required: false,
      type: Number
    },
    sectionIdSet: {
      required: false,
      type: Number
    },
    sectionRegionObject: {
      required: false,
      type: Object
    },
    organizationSectionObject: {
      required: false,
      type: Object
    }
  },

  setup(props) {
    const organizationId = toRef(props, 'organizationIdSet'),
      sectionId = toRef(props, 'sectionIdSet'),
      sectionRegion = toRef(props, 'sectionRegionObject'),
      organizationSection = toRef(props, 'organizationSectionObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      updateFormActive = ref(false),
      addFormActive = ref(false),
      schema = ref([])

    function setSchema() {
      const oId = organizationSection.value ? organizationSection.value.organizationId : organizationId.value,
        sId = organizationSection.value ? organizationSection.value.sectionId : sectionId.value

      schema.value = []

      schema.value.push({
        $formkit: 'select',
        name: 'regionInSectionId',
        options: { '0': '...', ...globalStore.getSvlSectionRegion(oId, sId) },
        label: globalStore.getWordById('parent region')
      })

      schema.value.push({
        $formkit: 'select',
        name: 'regionInOrganizationId',
        options: globalStore.getSvlOrganizationRegion(oId),
        validation: 'required',
        label: globalStore.getWordById('choose region')
      })
    }

    function initAddForm() {
      itemData.value = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm() {
      if (!sectionRegion.value) {
        return
      }
      const item = Object.assign({}, sectionRegion.value)
      itemData.value = item
      setSchema()
      updateFormActive.value = true
    }

    function deInitAddForm() {
      addFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function deInitUpdateForm() {
      updateFormActive.value = false
      if (itemData.value) {
        itemData.value = {}
      }
    }

    function processAddForm() {
      itemData.value.organizationId = organizationId.value
      itemData.value.sectionId = sectionId.value
      fetch(globalStore.apiUrl + '/admin/organization-section-region', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            globalStore.sectionHasRegionStore.setData(response.data.sectionHasRegion)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm() {
      if (sectionRegion.value) {
        fetch(globalStore.apiUrl + '/admin/organization-section-region', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              globalStore.sectionHasRegionStore.updateData(response.data.sectionHasRegion)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm()
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = sectionRegion.value
        fetch(globalStore.apiUrl + '/admin/organization-section-region', {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              globalStore.sectionHasRegionStore.deleteData(item)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    return {
      globalStore, updateFormActive, itemData, addFormActive, schema, organizationId, sectionRegion,
      deleteItem, initUpdateForm, deInitUpdateForm, processUpdateForm,
      initAddForm, deInitAddForm, processAddForm
    }
  }
}
</script>
