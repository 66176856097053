import {defineStore} from 'pinia'

export const useDisciplineCoefficientStore = defineStore('DisciplineCoefficientStore', {
  state: () => {
    return {
      disciplineCoefficients: {}
    }
  },
  getters: {
    count: state => Object.keys(state.disciplineCoefficients).length,
    getCoefficientByDisciplineId: state => {
      return disciplineId => {
        return state.disciplineCoefficients[disciplineId].value
      }
    },
  },
  actions: {
    setData(data) {
      this.disciplineCoefficients = data
    }
  }
})