import { defineStore } from 'pinia'

export const useStageStore = defineStore('StageStore', {
  state: () => {
    return {
      stages: {}
    }
  },
  getters: {
    count: state => Object.keys(state.stages).length,
    isStageFinal: state => {
      return stageId => {
        for (let i in state.stages) {
          if (state.stages[i].id === stageId) {
            return state.stages[i].isFinal === 1
          }
        }
        return false
      }
    },
    getById: state => {
      return id => {
        for (let i in state.stages) {
          if (+state.stages[i].id === +id) {
            return state.stages[i]
          }
        }
        return null
      }
    }
  },
  actions: {
    setData(data) {
      this.stages = data
    }
  }
})
