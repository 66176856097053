<template>
  <template v-if="globalStore.isUserTypeAdministrator">
    <template v-if="addFormActive === true">
      <table class="table table-hover table-striped align-middle table-light">
        <tbody>
        <tr>
          <td>
            <ButtonBack :click-function="deInitAddForm"/>
          </td>
          <td>
            <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
              <FormKitSchema :schema="schema"/>
            </FormKit>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <ButtonAdd
        :click-function="initAddForm"
        :word="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage add round', globalStore.languageId)"
      />
      <hr />
    </template>
  </template>

  <template v-if="getItems().length">
    <table class="table table-hover table-striped align-middle table-light">
      <tbody>
        <template v-for="(roundDefinition, round) in getItems()" :key="round">
          <template v-if="globalStore.isUserTypeAdministrator && updateFormActive[roundDefinition.id] === true">
            <tr>
              <td>
                <ButtonBack
                  :click-function="deInitUpdateForm"
                  :click-function-parameter="roundDefinition.id"
                />
              </td>
              <td>
                <FormKit type="form" v-model="itemData[roundDefinition.id]" @submit="processUpdateForm(roundDefinition.id)">
                  <FormKitSchema :schema="schema"/>
                </FormKit>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <th colspan="2">{{ globalStore.getDisciplineNameById(roundDefinition.disciplineId) }}</th>
            </tr>
            <tr>
              <td>
                <ul>
                  <template v-for="(value, key) in roundDefinition" :key="key">
                    <li v-if="key === 'eventCount'">
                      {{ globalStore.vocabularyStore.getWordByIdAndLanguage('event count', globalStore.languageId) }}:
                      {{ value }}
                    </li>
                  </template>
                </ul>
              </td>
              <td>
                <template v-if="globalStore.isUserTypeAdministrator">
                  <template v-if="updateFormActive[roundDefinition.id] === true">
                    <ButtonUpdate
                      :click-function="processUpdateForm"
                      :click-function-parameter="roundDefinition.id"
                      :word="globalStore.vocabularyStore.getWordByIdAndLanguage('save', globalStore.languageId)"
                    />
                  </template>
                  <template v-else-if="!roundDefinition.competitionId && (!eventStore.hasStageEvents(stageId))">
                    <ButtonUpdate
                      :click-function="initUpdateForm"
                      :click-function-parameter="roundDefinition.id"
                    />
                    <span>&#160;</span>
                    <ButtonDelete
                      :click-function="deleteItem"
                      :click-function-parameter="roundDefinition.id"
                    />
                  </template>
                </template>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <TeamEventRoundParameter :teamEventStage="roundDefinition.id" :stageId="roundDefinition.stageId"/>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </template>
</template>

<script>
import {ref, toRef} from 'vue'
import TeamEventRoundParameter from '@/components/TeamEventRoundParameter'
import { useEventStore } from "@/stores/EventStore"
import { useTeamEventRoundStore } from "@/stores/TeamEventRoundStore"
import { useTournamentStageStore } from "@/stores/TournamentStageStore"
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useEventParameterStore } from "@/stores/EventParameterStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import { useDisciplineStore } from "@/stores/DisciplineStore"
import { FormKitSchema } from '@formkit/vue'
import {useTournamentStore} from "@/stores/TournamentStore";
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";

export default {
  name: 'TournamentStageDefinitionTeamEvent',
  components: {ButtonDelete, ButtonUpdate, ButtonAdd, ButtonBack, FormKitSchema, TeamEventRoundParameter},
  props: {
    stageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const stageId = toRef(props, 'stageId'),
      itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref(false),
      teamEventRoundStore = useTeamEventRoundStore(),
      tournamentStageStore = useTournamentStageStore(),
      eventStageParameterStore = useEventStageParameterStore(),
      eventParameterStore = useEventParameterStore(),
      notificationStore = useNotificationStore(),
      disciplineStore = useDisciplineStore(),
      globalStore = useGlobalStore(),
      eventStore = useEventStore(),
      tournamentStore = useTournamentStore(),
      tournamentStage = tournamentStageStore.getTournamentStageByStageId(stageId.value),
      onlyTrue = {
        '1': ''
      },
      schema = ref([])

    function getItems() {
      let items = []
      for (let i in teamEventRoundStore.teamEventRounds) {
        if (teamEventRoundStore.teamEventRounds[i].stageId === stageId.value) {
          items.push(teamEventRoundStore.teamEventRounds[i])
        }
      }
      return items
    }

    function setSchema() {
      schema.value = []

      const disciplines = {}
      for (let i in disciplineStore.disciplines) {
        const disciplineId = disciplineStore.disciplines[i].id
        disciplines[disciplineId] = globalStore.getDisciplineNameById(disciplineId)
      }

      const parameter1 = {
        $formkit: 'select',
        name: 'disciplineId',
        label: globalStore.getWordById('discipline'),
        validation: 'required',
        options: disciplines
      }
      schema.value.push(parameter1)

      const parameter2 = {
        $formkit: 'number',
        name: 'eventCount',
        label: globalStore.getWordById('event count'),
        validation: 'required|min:1',
        min: 1
      }
      schema.value.push(parameter2)
    }

    function getItem(id) {
      for (let i in teamEventRoundStore.teamEventRounds) {
        if (teamEventRoundStore.teamEventRounds[i].id === id) {
          return teamEventRoundStore.teamEventRounds[i]
        }
      }
      return null
    }

    function initAddForm() {
      itemData.value[''] = {}
      setSchema()
      addFormActive.value = true
    }

    function initUpdateForm(id) {
      const item = getItem(id)
      if (!item) {
        return
      }
      itemData.value[id] = {}
      itemData.value[id].eventCount = item.eventCount
      itemData.value[id].id = item.id
      setSchema()
      updateFormActive.value[id] = true
    }

    function processAddForm() {
      itemData.value[''].tournamentId = tournamentStore.tournament.id
      itemData.value[''].stageId = stageId.value
      fetch(globalStore.apiUrl + '/admin/team-event-round', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            teamEventRoundStore.addData([response.data])
            deInitAddForm()
            notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId)})
          } else if (response.error) {
            notificationStore.add({type: 'error', message: response.error.description})
          } else {
            notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
          }
        })
        .catch((err) => console.error(err))
    }

    function processUpdateForm(id) {
      const item = itemData.value[id]
      if (item) {
        fetch(globalStore.apiUrl + '/admin/team-event-round', {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(itemData.value[id])
        })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            teamEventRoundStore.actualizeRow(response.data)
            notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)})
            deInitUpdateForm(id)
          } else if (response.error) {
            notificationStore.add({type: 'error', message: response.error.description})
          } else {
            notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
          }
        })
        .catch(err =>notificationStore.add({type: 'error', message: err.message}))
      }
    }

    function deleteItem(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(id)
        if (item) {
          fetch(globalStore.apiUrl + '/admin/team-event-round', {
            method: 'DELETE',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                teamEventRoundStore.deleteDataById(id)
                notificationStore.add({type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId)})
              } else if (response.error) {
                notificationStore.add({type: 'error', message: response.error.description})
              } else {
                notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
              }
            })
            .catch(err => notificationStore.add({type: 'error', message: err.message}))
        }
      }
    }

    function deInitUpdateForm(id) {
      updateFormActive.value[id] = false
      if (itemData.value[id]) {
        delete(itemData.value[id])
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    return {
      globalStore, eventStageParameterStore, eventParameterStore, itemData, updateFormActive, addFormActive, onlyTrue,
      tournamentStage, schema, eventStore, teamEventRoundStore,
      getItems, initAddForm, initUpdateForm, processAddForm, processUpdateForm, deleteItem, deInitUpdateForm, deInitAddForm
    }
  }
}
</script>
