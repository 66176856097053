import {defineStore} from 'pinia'

export const useResultCriterionStore = defineStore('ResultCriterionStore', {
  state: () => {
    return {
      resultCriterion: []
    }
  },
  getters: {
    count: state => state.resultCriterion.length
  },
  actions: {
    setData(data) {
      this.resultCriterion = data
    }
  }
})