<template>
  <br />
  <FormKit
    type="select"
    :options="{ '': 'Discipline', ...disciplines }"
    v-model="showFilterDiscipline" />
  <FormKit
    type="select"
    :options="{ '': 'Team type', ...types }"
    v-model="showFilterType" />
  <FormKit
    type="select"
    :options="{ '': 'Table size', ...tables }"
    v-model="showFilterTable" />
  <table class="table table-hover table-striped align-middle table-light">
    <tbody>
      <tr>
        <td>
          <FormKit type="form" v-model="itemData['']" @submit="processUpdateForm()">
            <p>Source participant(s)</p>
            <FormKit
              type="text"
              name="participantNameToMerge"
              @keyup="loadParticipantsToMerge(itemData[''], 'participantNameToMerge', 'participantToMergeId')"
              autocomplete="off" />
            <FormKit
              type="checkbox"
              :options="getFiltered(autoloadedParticipantsToMerge)"
              validation="required"
              name="participantToMergeId" />
            <hr />
            <p>Target participant</p>
            <FormKit
              type="text"
              name="participantName"
              @keyup="loadParticipants(itemData[''], 'participantName', 'participantId')"
              autocomplete="off" />
            <FormKit
              type="select"
              :options="getFiltered(autoloadedParticipants)"
              validation="required"
              name="participantId" />
          </FormKit>
        </td>
      </tr>
    </tbody>
  </table>

  <table class="table table-hover table-striped align-middle table-light">
    <tbody>
      <tr v-for="(row, i) in oneTimeParticipants" :key="i">
        <td>{{ row.participantId }}</td>
        <td>{{ row.name }}</td>
        <td>{{ row.participantToMergeId }}</td>
        <td>
          <button @click="doOnceMerge(row)" class="button">Merge</button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"

export default {
  name: 'ParticipantMerge',
  components: {},
  setup() {
    const itemData = ref({}),
      filterClub = ref(''),
      showFilterDiscipline = ref(''),
      showFilterTable = ref(''),
      showFilterType = ref(''),
      disciplines = ref({}),
      types = ref({}),
      tables = ref({}),
      oneTimeParticipants = ref({}),
      autoloadedParticipants = ref({}),
      autoloadedParticipantsToMerge = ref({}),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore()

    onMounted(() => {
      const url = globalStore.apiUrl + '/admin/participants-merge-load'
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            oneTimeParticipants.value = response.data
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    })

    function processUpdateForm() {
      fetch(globalStore.apiUrl + '/admin/participants-merge', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            for (let i in itemData.value[''].participantToMergeId) {
              const idParticipantToMerge = +itemData.value[''].participantToMergeId[i],
                participantId = +itemData.value[''].participantId
              if (idParticipantToMerge !== participantId) {
                for (let j in autoloadedParticipants.value) {
                  if (+j === idParticipantToMerge) {
                    delete autoloadedParticipants.value[j]
                  }
                }
                for (let k in autoloadedParticipantsToMerge.value) {
                  if (+k === idParticipantToMerge) {
                    delete autoloadedParticipantsToMerge.value[k]
                  }
                }
              }
            }
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function loadParticipants(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: 'team-and-participant',
          of: 1,
          o: 4,
          se: 1,
          s: 2022,
          c: '',
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const teams = {}
              for (let i = 0; i < response.data.length; i++) {
                if (!globalStore.teamInSeasonStore.getByTeamId(response.data[i].id)) {
                  teams[response.data[i].id] = response.data[i].name
                  if (setSelected === false) {
                    vModel[targetValueName] = response.data[i].id
                    setSelected = true
                  }
                }
              }
              autoloadedParticipants.value = teams
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function loadParticipantsToMerge(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: 'team-and-participant',
          of: 1,
          o: 4,
          se: 1,
          s: 2022,
          c: '',
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const teams = {}
              for (let i = 0; i < response.data.length; i++) {
                if (!globalStore.teamInSeasonStore.getByTeamId(response.data[i].id)) {
                  teams[response.data[i].id] = response.data[i].name
                  const nameParts = response.data[i].name.split(' | ')
                  disciplines.value[nameParts[4]] = nameParts[4]
                  tables.value[nameParts[3]] = nameParts[3]
                  types.value[nameParts[2]] = nameParts[2]
                  if (setSelected === false) {
                    vModel[targetValueName] = response.data[i].id
                    setSelected = true
                  }
                }
              }
              autoloadedParticipantsToMerge.value = teams
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function getFiltered(rows) {
      const data = {};

      for (let i in rows) {
        const row = rows[i]
        if (
          (!showFilterDiscipline.value || row.includes(showFilterDiscipline.value))
          && (!showFilterTable.value || row.includes(showFilterTable.value))
          && (!showFilterType.value || row.includes(showFilterType.value))
        ) {
          data[i] = row
        }
      }

      return data
    }

    function doOnceMerge(data) {
      fetch(globalStore.apiUrl + '/admin/participants-merge', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            const mergedId = data.participantId
            for (let i in oneTimeParticipants.value) {
              const oneTimeParticipant = oneTimeParticipants.value[i]
              if (oneTimeParticipant.participantId == mergedId) {
                delete oneTimeParticipants.value[i]
              }
            }
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    return {
      itemData, globalStore, filterClub, autoloadedParticipants, autoloadedParticipantsToMerge,
      disciplines, tables, types, showFilterDiscipline, showFilterTable, showFilterType,
      oneTimeParticipants,
      processUpdateForm, loadParticipants, loadParticipantsToMerge, getFiltered,
      doOnceMerge
    }
  }
}
</script>
