<template>
  <template v-if="globalStore.userAdminStore.propertiesLoaded">
    <FormKit type="select"
      :options="getRoundNumbers()"
      v-model="filterRound" />
    <FormKit type="select"
      :options="getTeamEventParticipantNames()"
      v-model="filterParticipant" />
    <FormKit type="radio"
      :options="getFinishedOptions()"
      v-model="filterFinished" />
    <table class="table table-striped">
      <tbody>
        <TeamEventItem :cId="id" v-if="globalStore.userAdminStore.isUserRegion && globalStore.competitionParticipantStore.competitionParticipants.length > 1" />
        <template v-if="globalStore.teamEventStore.getTeamEventsByCompetition(competitionId).length">
          <template v-for="(row, i) in globalStore.teamEventStore.getTeamEventsByCompetition(competitionId).sort((a, b) => {
            return a.round - b.round
          })" :key="i">
            <TeamEventItem v-if="(
              filterRound == 0
              || +filterRound === +row.round
            )
              && (
                filterParticipant == 0
                || +filterParticipant === +row.eventParticipant[0].participantId
                || +filterParticipant === +row.eventParticipant[1].participantId
              )
              && (
                filterFinished == 0
                || (filterFinished == 1 && row.eventParticipant[0].score != null)
                || (filterFinished == 2 && row.eventParticipant[0].score == null)
              )"
              :teamEventObject="row"
              :is-updatable="!isTeamEventLocked(row.stageId)"
              :cId="id" />
          </template>
        </template>
      </tbody>
    </table>
  </template>
</template>

<script>
import { ref, toRef, onMounted } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import TeamEventItem from "@/components/TeamEventItem"

export default {
  name: 'CompetitionTeamEventList',
  components: { TeamEventItem },
  props: {
    competitionId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const id = toRef(props, 'competitionId'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      filterParticipant = ref(0),
      filterFinished = ref(0),
      filterRound = ref(0)

    onMounted(() => {
      globalStore.teamEventStore.emptyData()

      fetch(globalStore.apiUrl + '/admin/competition-team-event/' + id.value, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          if (data.data.eventReferee) {
            globalStore.eventRefereeStore.addData(data.data.eventReferee)
          }
          if (data.data.participant) {
            globalStore.participantStore.addData(data.data.participant)
          }
          if (data.data.event) {
            globalStore.teamEventStore.addData(data.data.event)
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    })

    function isTeamEventLocked() {
      return false
    }

    function getRoundNumbers() {
      const teamEvents = globalStore.teamEventStore.getTeamEventsByCompetition(id.value),
        data = {}
      for (let i in teamEvents) {
        if (teamEvents[i].round) {
          data[teamEvents[i].round] = teamEvents[i].round
        }
      }
      return { '0': globalStore.getWordById('filter rounds'), ...data }
    }

    function getTeamEventParticipantNames() {
      const teamEvents = globalStore.teamEventStore.getTeamEventsByCompetition(id.value),
        data = {}
      for (let i in teamEvents) {
        for (let j in teamEvents[i].eventParticipant) {
          const participantId = teamEvents[i].eventParticipant[j].participantId
          if (participantId) {
            data[participantId] = globalStore.getParticipantNameById(participantId)
          }
        }
      }
      return {
        '0': globalStore.getWordById('filter team participants'), ...data
      }
    }

    function getFinishedOptions() {
      return {
        '0': globalStore.getWordById('filter all'),
        1: globalStore.getWordById('filter finished'),
        2: globalStore.getWordById('filter not finished'),
      }
    }

    return {
      globalStore, id, filterParticipant, filterRound, filterFinished,
      isTeamEventLocked, getRoundNumbers, getTeamEventParticipantNames, getFinishedOptions
    }
  }
}
</script>
