import {defineStore} from 'pinia'

export const useTeamSubstituteStore = defineStore('TeamSubstituteStore', {
  state: () => {
    return {
      teamSubstitutes: {}
    }
  },
  getters: {
    count: state => Object.keys(state.teamSubstitutes).length,
  },
  actions: {
    setData(data) {
      for (let i in data) {
        this.teamSubstitutes[i] = {}
        for (let j in data[i]) {
          this.teamSubstitutes[i][data[i][j].id] = data[i][j].nameYearMonthBirth
        }
      }
    }
  }
})