import { defineStore } from 'pinia'

export const useTeamTypeStore = defineStore('TeamTypeStore', {
  state: () => {
    return {
      teamTypes: []
    }
  },
  getters: {
    count: state => state.teamTypes.length,
    getById: state => {
      return id => {
        for (let i in state.teamTypes) {
          if (+state.teamTypes[i].id === +id) {
            return state.teamTypes[i]
          }
        }
      }
    }
  },
  actions: {
    setData(data) {
      this.teamTypes = data
    }
  }
})
