import { defineStore } from 'pinia'

export const useCountryStore = defineStore('CountryStore', {
  state: () => {
    return {
      data: []
    }
  },
  getters: {
    count: state => state.data.length,
    getById: state => {
      return id => {
        const data = state.data.filter(row => row.id === id)
        return data ? data[0] : null
      }
    },
    getFlagImageNameById: state => {
      return id => {
        const country = state.getById(id)
        return country ? country.flag : null
      }
    }
  },
  actions: {
    setData(rows) {
      if (rows && rows.length) {
        for (let i in rows) {
          this.addData(rows[i])
        }
      }
    },
    addData(item) {
      if (!this.data.filter(row => row.id === item.id).length) {
        this.data.push(item)
      }
    }
  }
})
