import { defineStore } from 'pinia'
import { useEventPredictionStore } from "@/stores/EventPredictionStore";
import { useGlobalStore } from "@/stores/GlobalStore";

export const useEventStore = defineStore('EventStore', {
  state: () => {
    return {
      freelancerId: 2876,
      freelancerTeamId: 2876,
      events: [],
      eventPredictionStore: useEventPredictionStore(),
      globalStore: useGlobalStore()
    }
  },
  getters: {
    count: state => state.events.length,
    getEventsByStage: state => {
      return stageId => {
        return state.events.filter(
          event => +event.stageId === +stageId
        )
      }
    },
    getEventsByStageIdStageSign: state => {
      return (stageId, stageSign) => {
        // Can not be event.stageSign === stageSign
        return state.events.filter(
          event => +event.stageId === +stageId && (!stageSign ? event.stageSign === '' || event.stageSign === null : event.stageSign == stageSign)
        )
      }
    },
    getParticipantNamesFromEvent: state => {
      return event => {
        let participantNames = ''
        for (let i = 0; i < event.eventParticipant.length; i++) {
          const eventParticipant = event.eventParticipant[i]
          if (eventParticipant.prediction) {
            participantNames += eventParticipant.prediction.name
          } else {
            participantNames += state.globalStore.getParticipantNameById(eventParticipant.participantId)
          }
          if (i < event.eventParticipant.length - 1) {
            participantNames += ' v '
          }
        }
        return participantNames
      }
    },
    getParticipantNamesFromEventByStageIdStageSignIndex: state => {
      return (stageId, stageSign, index) => {
        if (stageSign === '') {
          stageSign = null
        }
        const event = state.getEventsByStageIdStageSign(stageId, stageSign)[index]
        return state.getParticipantNamesFromEvent(event)
      }
    },
    getEventsByStageWithParticipantPredictionByStageId: state => {
      return stageId => {
        const events = state.events.filter(event => +event.stageId === +stageId),
          stageSignsEvents = {},
          stageSignParticipants = state.globalStore.tournamentParticipantStageStore.getStageParticipantsWithPrediction(stageId, state.globalStore),
          tournamentEventStage = state.globalStore.tournamentStageStore.getTournamentStageByStageId(stageId),
          tournamentParticipantsCount = state.globalStore.tournamentParticipantStore.count

        for (let i in events) {
          const event = events[i],
            stageId = event.stageId,
            stageSign = event.stageSign || ''

          stageSignsEvents[stageSign] = stageSignsEvents[stageSign] || 0
          for (let j in event.eventParticipant) {
            const eventParticipant = event.eventParticipant[j]
            if (!eventParticipant.participantId) {
              if (!eventParticipant.prediction) {
                let prediction = state.eventPredictionStore.getEventParticipantPrediction(stageId, stageSign, stageSignsEvents[stageSign], j)
                if (prediction) {
                  let name = '',
                    relatedEventParticipants = ''
                  if (prediction.status) {
                    name = state.globalStore.getWordById(prediction.status)
                    let previousTournamentStage = null,
                      previousTournamentStageId = stageId
                    if (tournamentEventStage.stageSystemTypeId !== 1) {
                      previousTournamentStage = state.globalStore.tournamentStageStore.getPreviousValidNonFinalTournamentStage(stageId, tournamentParticipantsCount, state.globalStore)
                      if (previousTournamentStage) {
                        previousTournamentStageId = previousTournamentStage.stageId
                      }
                    }
                    relatedEventParticipants = state.getParticipantNamesFromEventByStageIdStageSignIndex(previousTournamentStageId, stageSign, prediction.sourceEventIndex)
                    const parts = relatedEventParticipants.split(' v ')
                    if (parts[0] === parts[1] && prediction.setting) {
                      name = stageSignParticipants[stageSign][prediction.setting - 1].prediction.name
                      relatedEventParticipants = ''
                    }
                  } else if (prediction.setting) {
                    if (stageSignParticipants[stageSign][prediction.setting - 1]
                      && stageSignParticipants[stageSign][prediction.setting - 1].prediction) {
                      name = stageSignParticipants[stageSign][prediction.setting - 1].prediction.name
                    } else {
                      name = prediction.setting
                    }
                  } else {
                    name = 'undefined'
                  }
                  eventParticipant.prediction = {
                    name: name,
                    relatedEventParticipants: relatedEventParticipants
                  }
                }
              }
            }
          }
          stageSignsEvents[stageSign]++
        }

        return events
      }
    },
    hasStageEvents: state => {
      return stageId => {
        const events = state.events.filter(event => +event.stageId === +stageId)
        return events.length > 0
      }
    },
    hasTeamEventEvent: state => {
      return teamEventId => {
        const teamEvents = state.events.filter(event => +event.eventId === +teamEventId)
        return teamEvents.length > 0
      }
    },
    hasStageOnlyBlankEvents: state => {
      return stageId => {
        const stageEvents = state.events.filter(event => event.stageId === stageId)
        for (let i in stageEvents) {
          let hasFreelancer = false;
          for (let j in stageEvents[i].eventParticipant) {
            if (stageEvents[i].eventParticipant[j].participantId === state.freelancerId) {
              hasFreelancer = true
            }
          }
          for (let j in stageEvents[i].eventParticipant) {
            const eventParticipant = stageEvents[i].eventParticipant[j]
            if (hasFreelancer === false && eventParticipant.score !== '' && eventParticipant.score !== null) {
              return false
            }
          }
        }
        return true
      }
    },
    hasStageOnlyWrittenEvents: state => {
      return stageId => {
        const stageEvents = state.events.filter(event => event.stageId === stageId)
        if (!stageEvents.length) {
          return false
        }
        for (let i in stageEvents) {
          for (let j in stageEvents[i].eventParticipant) {
            const eventParticipant = stageEvents[i].eventParticipant[j]
            if (eventParticipant.score === '' || eventParticipant.score === null) {
              return false
            }
          }
        }
        return true
      }
    },
    hasParticipantOnlyBlankEvents: state => {
      return (stageId, participantId) => {
        const stageEvents = state.events.filter(event => event.stageId === stageId)
        if (!stageEvents.length) {
          return true
        }
        for (let i in stageEvents) {
          for (let j in stageEvents[i].eventParticipant) {
            const eventParticipant = stageEvents[i].eventParticipant[j]
            if (eventParticipant.participantId !== participantId && eventParticipant.score !== '' && eventParticipant.score === null) {
              return false
            }
          }
        }
        return true
      }
    },
    getEventById: state => {
      return id => {
        return state.events.filter(event => +event.id === +id)[0]
      }
    },
    getEventIdByHash: state => {
      return hash => {
        for (let i in state.events) {
          if (state.events[i].hash === hash) {
            return state.events[i].id
          }
        }
        return null
      }
    },
    getTeamEventEvents: state => {
      return teamEventId => {
        return state.events.filter(event => +event.eventId === +teamEventId)
      }
    }
  },
  actions: {
    setData(data) {
      this.events = data
    },
    addData(data) {
      for (let i in data) {
        let event = false
        for (let j in this.events) {
          if (this.events[j].id === data[i].id) {
            event = true
            break
          }
        }
        if (!event) {
          this.events.push(data[i])
        }
      }
    },
    updateParticipants(data) {
      for (let i in data) {
        const eventParticipant = data[i],
          eventId = eventParticipant.eventId,
          event = this.getEventById(eventId)
        if (event) {
          for (let j in event.eventParticipant) {
            if (event.eventParticipant[j].id === eventParticipant.id) {
              event.eventParticipant[j].participantId = eventParticipant.participantId
              break
            }
          }
        }
      }
    },
    actualizeRow(event) {
      for (let i in this.events) {
        if (this.events[i].id === event.id) {
          this.events[i] = event
        }
      }
    },
    updateStageEventParameters(stageId, newParams) {
      const events = this.events.filter(event => event.stageId === stageId)
      for (let i in events) {
        const event = events[i]
        if (event.winningPartsCount !== newParams.winningPartsCount) {
          event.winningPartsCount = newParams.winningPartsCount
        }
        if (event.extension && !newParams.extension) {
          event.extension = false
        } else if (!event.extension && newParams.extension) {
          event.extension = true
        }
        if (event.equalizingInning !== newParams.equalizingInning) {
          event.equalizingInning = newParams.equalizingInning
        }
        for (let j in event.eventParticipant) {
          const eventParticipant = event.eventParticipant[j]
          if (eventParticipant.targetPoints !== newParams.targetPoints) {
            eventParticipant.targetPoints = newParams.targetPoints
          }
          if (eventParticipant.maxInnings !== newParams.maxInnings) {
            eventParticipant.maxInnings = newParams.maxInnings
          }
          if (eventParticipant.eventParticipantResult && eventParticipant.eventParticipantResult.length !== newParams.winningPartsCount) {
            eventParticipant.eventParticipantResult = []
            for (let k = 0; k < newParams.winningPartsCount; k++) {
              eventParticipant.eventParticipantResult.push({
                eventParticipantId: eventParticipant.id,
                extension: null,
                innings: null,
                points: null,
              })
            }
          }
        }
      }
    },
    deleteDataByStage(stageId) {
      this.events = this.events.filter(event => event.stageId !== stageId)
    },
    switchSchedule(events) {
      const id_0 = +events[0].id,
        id_1 = +events[1].id
      let event_0 = null,
        event_1 = null
      for (let i in this.events) {
        if (this.events[i].id === id_0) {
          event_0 = this.events[i]
        } else if (this.events[i].id === id_1) {
          event_1 = this.events[i]
        }
      }

      if (event_0 && event_1) {
        const round_0 = +event_0.round,
          dateStart_0 = event_0.dateStart,
          table_0 = event_0.table

        event_0.round = +event_1.round
        event_0.dateStart = event_1.dateStart
        event_0.table = event_1.table
        event_1.round = round_0
        event_1.dateStart = dateStart_0
        event_1.table = table_0
      } else if (event_0) {
        // Switch by blank
        event_0.round = +events[1].round
        event_0.dateStart = events[1].dateStart
        event_0.table = {
          id: events[1].tableId,
          number: events[1].tableNumber,
          index: events[1].tableIndex,
        }
      } else if (event_1) {
        // Switch by blank
        event_1.round = +events[0].round
        event_1.dateStart = events[0].dateStart
        event_1.table = {
          id: events[0].tableId,
          number: events[0].tableNumber,
          index: events[0].tableIndex,
        }
      }
    },
    actualizeEventGameData(eventId, liveData, actualizeScore) {
      for (let i in this.events) {
        if (this.events[i].id === eventId) {
          let event = this.events[i],
            currentData = { ...liveData }
          for (let j in event.eventParticipant) {
            const eventParticipant = event.eventParticipant[j]
            let liveParticipant = {},
              participantIndex = 0
            for (let k in currentData.players) {
              if (currentData.players[k].id === eventParticipant.participantId) {
                liveParticipant = currentData.players[k]
                participantIndex = +k
              }
            }

            if (liveParticipant.highestRun !== undefined) {
              eventParticipant.highRun1 = liveParticipant.highestRun
            }
            if (liveParticipant.highestRun2 !== undefined) {
              eventParticipant.highRun2 = liveParticipant.highestRun2
            }
            if (liveParticipant.disqualification !== undefined) {
              eventParticipant.disqualificationId = +liveParticipant.disqualification
            }
            let points, innings
            for (let k in liveParticipant.sets) {
              const set = liveParticipant.sets[k]
              if (!eventParticipant.eventParticipantResult) {
                eventParticipant.eventParticipantResult = []
              }
              if (!eventParticipant.eventParticipantResult[k]) {
                eventParticipant.eventParticipantResult[k] = {
                  eventParticipantId: eventParticipant.id,
                  points: null,
                  innings: null,
                  extension: null,
                  score: null
                }
              }
              if (set.points !== undefined && set.points !== null) {
                eventParticipant.eventParticipantResult[k].points = set.points
                if (!points) {
                  points = 0
                }
                points += set.points
              }
              if (set.innings !== undefined && set.innings !== null) {
                eventParticipant.eventParticipantResult[k].innings = set.innings
                if (!innings) {
                  innings = 0
                }
                innings += set.innings
              }
              if (set.extensionPoints !== undefined && set.completed !== undefined && set.completed !== false && set.completed !== null && +set.completed === participantIndex) {
                eventParticipant.eventParticipantResult[k].extension = 1
              }
              if (set.score !== undefined) {
                eventParticipant.eventParticipantResult[k].score = set.score
              }
            }
            eventParticipant.points = points
            eventParticipant.innings = innings
            if (actualizeScore && liveParticipant.id === eventParticipant.participantId) {
              if (currentData.completed !== undefined && currentData.completed !== false && currentData.completed !== null) {
                if (currentData.completed !== true && +currentData.completed === participantIndex) {
                  eventParticipant.score = 2
                } else if (currentData.completed === true) {
                  eventParticipant.score = 1
                } else {
                  eventParticipant.score = 0
                }
              } else {
                eventParticipant.score = null
              }
            }
          }
          break
        }
      }
    }
  }
})
