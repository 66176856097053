<template>
  <MenuBase :onChangeFn="loadComments" />
  <div class="table-responsive">
    <template v-if="globalStore.userAdminStore.propertiesLoaded">
      <select v-model="commentTypeId" class="form-select form-select-sm w-auto m-2 me-0 mb-0 btn btn-sm btn-outline-secondary pe-5">
        <option value="">...</option>
        <template v-for="(name, i) in getCommentTypes()" :key="i">
          <option :value="i">{{ globalStore.getCommentTypeNameById(name) }}</option>
        </template>
      </select>
      <table class="table table-striped">
        <tbody>
          <CommentItem :tournaments="tournamentSvl" :leagues="leagueSvl" />
          <template v-if="globalStore.commentStore.getAll.length">
            <template v-for="(row, i) in globalStore.sortBy(getComments(), [{ date: 'desc' }])" :key="i">
              <CommentItem
                :commentObject="row"
                :tournaments="tournamentSvl"
                :leagues="leagueSvl" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, ref } from "vue"
import MenuBase from "@/components/Menu/MenuBase"
import CommentItem from "@/components/CommentItem"

export default {
  name: 'CommentList',
  components: { MenuBase, CommentItem },

  setup() {
    const globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      tournamentSvl = ref([]),
      leagueSvl = ref([]),
      commentTypeId = ref('')

    onMounted(() => {
      loadComments()
    })

    function loadComments() {
      if (!globalStore.userAdminStore.activeSeason) {
        return
      }
      const url = globalStore.apiUrl + '/admin/comments/' + (globalStore.userAdminStore.activeOrganization || 'null') + '/' + (globalStore.userAdminStore.activeSection || 'null') + '/' + (globalStore.userAdminStore.activeSeason || 'null')
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.vocabularyPublicStore.setData(data.data.vocabularyPublic)
          globalStore.commentStore.setData(data.data.comment)
          globalStore.commentTypeStore.setData(data.data.commentType)
          globalStore.leagueCommentStore.setData(data.data.leagueComment)
          globalStore.tournamentCommentStore.setData(data.data.tournamentComment)
          tournamentSvl.value = data.data.tournamentSvl
          leagueSvl.value = data.data.leagueSvl
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    function getComments() {
      if (commentTypeId.value) {
        return globalStore.commentStore.getAll.filter(row => +row.commentTypeId === +commentTypeId.value)
      }
      return globalStore.commentStore.getAll
    }

    function getCommentTypes() {
      const data = {},
        comments = globalStore.commentStore.getAll
      for (let i in comments) {
        if (!data[comments[i].commentTypeId]) {
          data[comments[i].commentTypeId] = comments[i].commentTypeId
        }
      }
      return data
    }

    return {
      globalStore, tournamentSvl, leagueSvl, commentTypeId,
      loadComments, getComments, getCommentTypes
    }
  }
}
</script>
