<template>
  <template v-if="globalStore.userAdminStore.loggedUser && globalStore.userAdminStore.loggedUser.id">
    <button @click="processLogout" class="btn">
      <img :src="require('@/assets/images/logout.svg')" alt="" class="icon-logout"/>
    </button>
  </template>
</template>

<script>
import {useGlobalStore} from "@/stores/GlobalStore"

export default {
  name: 'LogoutAdmin',
  components: {},

  setup() {
    const globalStore = useGlobalStore()

    function processLogout() {
      fetch(globalStore.apiUrl + '/logout', {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => {
        if (response.status === 200) {
          globalStore.userAdminStore.unsetUser()
        }
      })
    }

    return {
      globalStore,
      processLogout
    }
  }
}
</script>

<style scoped>
img.icon-logout {
    width: 1.5em;
    height: auto;
    cursor: pointer;
    margin: 0;
}
button {
    margin-right: 1em;
}
</style>
