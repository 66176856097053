<template>
  <table v-if="globalStore.isUserTypeAdministrator && globalStore.hasUserAddRule" class="table table-hover table-striped align-middle table-light">
    <tbody>
    <tr>
      <td>
        <FormKit type="form" v-model="itemData['']" @submit="createTemplate()">
          <FormKit
              type="text"
              name="tournamentName"
              :help="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament autoload', globalStore.languageId)"
              @keyup="loadTournaments(itemData[''], 'tournamentName', 'sourceTournamentId')"
              autocomplete="off"
          />
          <FormKit
              type="select"
              :options="autoloadedTournaments"
              validation="required"
              name="sourceTournamentId"
              :label="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament autoloaded', globalStore.languageId)"
          />
        </FormKit>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { ref } from 'vue'
import { useTournamentStore } from "@/stores/TournamentStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"

export default {
  name: 'TournamentTemplate',
  components: {},
  setup() {
    const itemData = ref({}),
      autoloadedTournaments = ref({}),
      tournamentStore = useTournamentStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore()

    function createTemplate() {
      const payload = {
        tournamentId: tournamentStore.tournament.id,
        sourceTournamentId: itemData.value[''].sourceTournamentId
      }
      fetch(globalStore.apiUrl + '/admin/tournament-template', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(payload)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            alert(globalStore.vocabularyStore.getWordByIdAndLanguage('click to reload', globalStore.languageId))
            window.location.reload()
          } else if (response.error) {
            notificationStore.add({type: 'error', message: response.error.description})
          } else {
            notificationStore.add({type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)})
          }
        })
        .catch(err => notificationStore.add({type: 'error', message: err.message}))
    }

    function loadTournaments(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: 'tournament',
          key: key,
          teamTypeId: tournamentStore.tournament.teamTypeId
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const tournaments = {}
              for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].id !== tournamentStore.tournament.id) {
                  tournaments[response.data[i].id] = response.data[i].season + ': ' + response.data[i].name
                  if (setSelected === false) {
                    vModel[targetValueName] = response.data[i].id
                    setSelected = true
                  }
                }
              }
              autoloadedTournaments.value = tournaments
            }
          })
          .catch(err => notificationStore.add({type: 'error', message: err.message}))
      }
    }

    return {
      itemData, globalStore, autoloadedTournaments, tournamentStore,
      createTemplate, loadTournaments
    }
  }
}
</script>
