<template>
  <ParticipantItem />
</template>

<script>
import ParticipantItem from "@/components/ParticipantItem";
export default {
  components: { ParticipantItem }
}
</script>
