<template>
  <UserList />
</template>

<script>
import UserList from "@/components/UserList";
export default {
  components: { UserList }
}
</script>
