<template>
  <MenuBase :onChangeFn="loadParticipants" />
  <div class="table-responsive">
    <template v-if="globalStore.userAdminStore.propertiesLoaded && globalStore.userAdminStore.activeOrganization && globalStore.userAdminStore.activeSection">
      <table class="table table-striped">
        <tbody>
          <ParticipantInSeasonItem />
          <template v-if="getParticipants()">
            <tr>
              <td>{{ getParticipants().length }}</td>
              <td v-for="paramName in globalStore.seasonSettingTypeStore.data" :key="paramName.id" class="text-nowrap">
                {{ globalStore.getWordById(paramName.name) }}
              </td>
              <td></td>
            </tr>
            <template v-for="(row, i) in getParticipants()" :key="i">
              <ParticipantInSeasonItem :participantInSeasonObject="row" :participantObject="globalStore.participantStore.getById(row.participantId)" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef, ref } from "vue"
import MenuBase from "@/components/Menu/MenuBase"
import ParticipantInSeasonItem from "@/components/ParticipantInSeasonItem"

export default {
  name: 'ParticipantsInSeason',
  components: { MenuBase, ParticipantInSeasonItem },
  props: {
    multi: {
      required: false,
      type: Boolean
    }
  },

  setup(props) {
    const isMulti = toRef(props, 'multi'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      showFilterDiscipline = ref(''),
      showFilterTable = ref(''),
      showFilterType = ref(''),
      disciplines = ref({}),
      types = ref({}),
      tables = ref({})

    onMounted(() => {
      loadParticipants()
    })

    function loadParticipants() {
      const url = globalStore.apiUrl + '/admin/participants-in-season/' + (globalStore.userAdminStore.activeOrganization || 'null') + '/' + (globalStore.userAdminStore.activeSection || 'null') + '/' + (globalStore.userAdminStore.activeSeason || 'null') + '/' + (globalStore.userAdminStore.activeClub || 'null')
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.participantStore.setData(data.data.participant)
          globalStore.seasonParticipantStore.setData(data.data.seasonParticipant)
          globalStore.seasonSettingTypeStore.setData(data.data.seasonSettingType)
          globalStore.seasonParticipantParameterStore.setData(data.data.seasonSettingParameter)
          globalStore.clubStore.setData(data.data.club)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    function getParticipants() {
      return globalStore.seasonParticipantStore.getByOrganizationIdSectionIdSeason(
        globalStore.userAdminStore.activeOrganization,
        globalStore.userAdminStore.activeSection,
        globalStore.userAdminStore.activeSeason
      )
    }

    function isMultiParticipant() {
      return isMulti.value
    }

    return {
      globalStore, showFilterDiscipline, showFilterTable, showFilterType,
      disciplines, tables, types,
      loadParticipants, getParticipants, isMultiParticipant
    }
  }
}
</script>
