<template>
  <div class="table-responsive" v-if="club">
    <template v-if="globalStore.userAdminStore.propertiesLoaded">
      <table class="table table-striped">
        <tbody>
          <ClubPresentationParameterItem :clubIdSet="club.id" />
          <template v-if="globalStore.clubPresentationParameterStore.getByClubId(club.id).length">
            <template v-for="row in globalStore.clubPresentationParameterStore.getByClubId(club.id)" :key="row.id">
              <ClubPresentationParameterItem
                :clubPresentationParameterObject="row" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted, toRef } from "vue"
import ClubPresentationParameterItem from "@/components/ClubPresentation/ClubPresentationParameterItem"

export default {
  name: 'ClubPresentationParameterList',
  components: { ClubPresentationParameterItem },
  props: {
    clubObject: {
      required: true,
      type: Object
    },
  },

  setup(props) {
    const club = toRef(props, 'clubObject'),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadData()
    })

    function loadData() {
      const url = globalStore.apiUrl + '/admin/club-presentation-parameters/' + club.value.id
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.clubParameterStore.setData(data.data.clubParameter)
          globalStore.clubPresentationParameterStore.setData(data.data.clubPresentationParameter)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore, club
    }
  }
}
</script>
