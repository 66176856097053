<template>
  <div class="toast show">
    <div :class="'toast-body alert-' + (notification.type === 'error' ? 'danger' : notification.type)">
      {{ notification.message }} {{ (notification.type === 'error' ? ':(' : ':)') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.toast {
  animation: fadeOut 5s;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 0 .25em .25em
}
@keyframes fadeOut {
  50% { opacity: 1 }
  100% { opacity: 0 }
}
</style>