<template>
  <div class="notifications">
    <NotificationBar v-for="notification in notificationStore.notifications" :key="notification.id" :notification="notification"/>
  </div>
</template>

<script>
import NotificationBar from "@/components/NotificationBar";
import { useNotificationStore } from "@/stores/NotificationStore";

export default {
  components: {
    NotificationBar
  },
  setup() {
    const notificationStore = useNotificationStore()

    return {
      notificationStore
    }
  }
}
</script>

<style scoped>
div.notifications {
  position: absolute;
  right: 0;
}
</style>
