<template>
  <template v-if="!stageId && globalStore.isUserTypeAdministrator">
    <template v-if="addFormActive === true">
      <ButtonBack :click-function="deInitAddForm" />
      <br />
      <br />
      <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
        <FormKitSchema :schema="schema" :data="itemData['']" />
      </FormKit>
    </template>
    <template v-else>
      <ButtonAdd :click-function="initAddForm"
        :word="globalStore.vocabularyStore.getWordByIdAndLanguage('add stage definition', globalStore.languageId)" />
      <br />
      <br />
    </template>
  </template>
  <template v-if="getItem().stageId">
    <table class="table table-hover table-striped align-middle table-light">
      <tbody>
        <template
          v-if="globalStore.isUserTypeAdministrator && updateFormActive[getItem().id] === true && itemData[getItem().id].id === getItem().id">
          <tr>
            <td>
              <ButtonBack :click-function="deInitUpdateForm" :click-function-parameter="getItem().id" />
            </td>
            <td>
              <FormKit type="form" v-model="itemData[getItem().id]" @submit="processUpdateForm()">
                <FormKitSchema :schema="schema" :data="itemData[getItem().id]" />
              </FormKit>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td>
              <h6>
                <template v-if="getItem().competitionId">{{ globalStore.vocabularyStore
                  .getWordByIdAndLanguage('defined by competition', globalStore.languageId) }}</template>
                <template v-if="getItem().tournamentId">{{ globalStore.vocabularyStore
                  .getWordByIdAndLanguage('defined by tournament', globalStore.languageId) }}</template>
              </h6>
              <ul>
                <template v-if="getItem().stageParts">
                  <li>
                    <template v-for="stagePart in getItem().stageParts" :key="stagePart.id">
                      <ul>
                        <template v-for="(value, key) in stagePart" :key="key">
                          <template v-if="key === 'disciplineId' || key === 'eventCount'">
                            <li>
                              {{ globalStore.vocabularyStore.getWordByIdAndLanguage(key, 1) }}:
                              {{ value }}
                            </li>
                          </template>
                        </template>
                      </ul>
                    </template>
                  </li>
                </template>
                <template v-else>
                  <template v-for="(value, key) in getItem()" :key="key">
                    <template v-if="key !== 'priority'">
                      <li v-if="key !== 'eventCount'
                        && key !== 'disciplineId'
                        && key !== 'id'
                        && key !== 'competitionId'
                        && key !== 'tournamentId'
                        && key !== 'stageId'
                        ">
                        {{ globalStore.vocabularyStore.getWordByIdAndLanguage(key, 1) }}:
                        <template v-if="key === 'stageSystemTypeId'">{{ stageSystemTypes[value] }}</template>
                        <template v-else-if="key === 'stageSettingTypeId'">{{ stageSettingTypes[value] }}</template>
                        <template v-else-if="key === 'eventOrderTypeId'">{{ eventOrderTypes[value] || eventOrderTypes4[value] || 'Default' }}</template>
                        <template v-else>{{ value }}</template>
                      </li>
                    </template>
                  </template>
                </template>
              </ul>
            </td>
            <td>
              <template v-if="globalStore.isUserTypeAdministrator">
                <template v-if="updateFormActive[getItem().id] === true && itemData[getItem().id].id === getItem().id">
                  <button @click="processUpdateForm(getItem().id)" class="btn btn-outline-primary btn-sm">Send</button>
                </template>
                <template v-else-if="!eventStore.hasStageEvents(getItem().stageId)">
                  <ButtonUpdate :click-function="initUpdateForm" :click-function-parameter="getItem().id"
                    :word="!getItem().tournamentId ? globalStore.vocabularyStore.getWordByIdAndLanguage('redefine for tournament', globalStore.languageId) : globalStore.vocabularyStore.getWordByIdAndLanguage('update', globalStore.languageId)" />
                  <span>&#160;</span>
                  <ButtonDelete v-if="!getItem().competitionId" :click-function="deleteItem"
                    :click-function-parameter="getItem().id" />
                </template>
              </template>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </template>
</template>

<script>
import { ref, toRef } from 'vue'
import { useEventStore } from "@/stores/EventStore"
import { useTournamentStageStore } from "@/stores/TournamentStageStore"
import { useEventStageParameterStore } from "@/stores/EventStageParameterStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useStageStore } from "@/stores/StageStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";

export default {
  name: 'TournamentStageDefinition',
  components: { ButtonDelete, ButtonUpdate, ButtonAdd, ButtonBack },
  props: {
    stageId: {
      required: false,
      type: Number
    }
  },

  setup(props) {
    const stageId = toRef(props, 'stageId'),
      itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref(false),
      tournamentStageStore = useTournamentStageStore(),
      eventStageParameterStore = useEventStageParameterStore(),
      stageStore = useStageStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore(),
      eventStore = useEventStore(),
      stageSystemTypes = {
        '1': 'Každý s každým',
        '2': 'KO',
        '4': 'KO *'
      },
      stageSettingTypes = {
        '1': 'Náhodně',
        '2': 'Dle výsledku předchozí fáze'
      },
      eventOrderTypes = {
        '0': '[2-3][1-3][1-2]',
        '1': '[2-3][1-L][W-1]',
        '2': '[2-3][1-W][L-1]',
        '5': '[1-3][2-3][1-2]'
      },
      eventOrderTypes4 = {
        '0': '[2-3][1-4][2-4][1-3][3-4][1-2]',
        '3': '[2-3][1-4][L-L][W-W][?-?][1-?]',
        '4': '[2-3][1-4][W-L][L-W][?-?][1-?]',
      },
      schema = ref([])

    function setSchema() {
      schema.value = []
      if (addFormActive.value === true) {
        const stageOptions = {},
          tournamentHasStage = {}

        for (let i in tournamentStageStore.tournamentStages) {
          tournamentHasStage[tournamentStageStore.tournamentStages[i].stageId] = tournamentStageStore.tournamentStages[i].stageId
        }

        for (let i in stageStore.stages) {
          const stage = stageStore.stages[i]
          if (!tournamentHasStage[stage.id]) {
            stageOptions[stage.id] = globalStore.vocabularyStore.getWordByIdAndLanguage(stage.name, 1)
          }
        }

        schema.value.push({
          $formkit: 'select',
          name: 'stageId',
          label: globalStore.vocabularyStore.getWordByIdAndLanguage('tournament stage', globalStore.languageId),
          options: stageOptions,
          validation: 'required'
        })
      }

      schema.value.push({
        $formkit: 'select',
        name: 'stageSystemTypeId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('stageSystemTypeId', globalStore.languageId),
        options: stageSystemTypes,
        validation: 'required'
      })

      schema.value.push({
        $formkit: 'select',
        name: 'stageSettingTypeId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('stageSettingTypeId', globalStore.languageId),
        options: stageSettingTypes,
        validation: 'required'
      })

      schema.value.push({
        $formkit: 'number',
        name: 'participantMaxCount',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('participantMaxCount', globalStore.languageId),
        validation: 'required',
        min: 1
      })

      schema.value.push({
        $formkit: 'number',
        name: 'participantGroupPreferredCount',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('participantGroupPreferredCount', globalStore.languageId),
        validation: 'required',
        min: 1
      })

      schema.value.push({
        if: "$participantGroupPreferredCount == '3'",
        $formkit: 'select',
        name: 'eventOrderTypeId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('eventOrderTypeId', globalStore.languageId),
        options: eventOrderTypes
      })
      schema.value.push({
        if: "$participantGroupPreferredCount == '4'",
        $formkit: 'select',
        name: 'eventOrderTypeId',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('eventOrderTypeId', globalStore.languageId),
        options: eventOrderTypes4
      })

      schema.value.push({
        $formkit: 'number',
        name: 'tournamentParticipantMinCount',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('tournamentParticipantMinCount', globalStore.languageId)
      })

      schema.value.push({
        $formkit: 'number',
        name: 'playgroundCount',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('playgroundCount', globalStore.languageId),
        validation: 'required',
        min: 1
      })

      schema.value.push({
        $formkit: 'number',
        name: 'eventDuration',
        label: globalStore.vocabularyStore.getWordByIdAndLanguage('eventDuration', globalStore.languageId),
        validation: 'required',
        min: 1
      })
    }

    function getItem() {
      for (let i in tournamentStageStore.tournamentStages) {
        if (tournamentStageStore.tournamentStages[i].stageId === stageId.value) {
          return tournamentStageStore.tournamentStages[i]
        }
      }
      return {}
    }

    function initAddForm() {
      itemData.value[''] = {}
      addFormActive.value = true
      setSchema()
    }

    function initUpdateForm(id) {
      const item = getItem()
      if (!item) {
        return
      }
      setSchema()
      itemData.value[id] = {
        id: item.id,
        stageSystemTypeId: item.stageSystemTypeId,
        stageSettingTypeId: item.stageSettingTypeId,
        eventOrderTypeId: item.eventOrderTypeId,
        participantMaxCount: item.participantMaxCount,
        participantGroupPreferredCount: item.participantGroupPreferredCount,
        tournamentParticipantMinCount: item.tournamentParticipantMinCount,
        playgroundCount: item.playgroundCount,
        eventDuration: item.eventDuration
      }
      updateFormActive.value[id] = true
    }

    function processAddForm() {
      tournamentStageStore.setActiveStageId(stageId.value)
      itemData.value[''].tournamentId = globalStore.tournamentStore.tournament.id
      fetch(globalStore.apiUrl + '/admin/tournament-event-stage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            const eventStageId = getItem().id,
              set = tournamentStageStore.replaceDataRow(response.data)
            if (!set) {
              tournamentStageStore.addDataRow(response.data)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            } else {
              eventStageParameterStore.deleteStageParametersByEventStageId(eventStageId)
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            }
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch((err) => console.error(err))
    }

    function processUpdateForm() {
      tournamentStageStore.setActiveStageId(stageId.value)
      const item = getItem()
      if (item) {
        if (item.competitionId) {
          itemData.value[''] = itemData.value[item.id]
          itemData.value[''].stageId = stageId.value
          delete itemData.value[item.id]
          processAddForm()
          deInitUpdateForm(item.id)
        } else {
          itemData.value[item.id].tournamentId = globalStore.tournamentStore.tournament.id
          itemData.value[item.id].stageId = stageId.value
          fetch(globalStore.apiUrl + '/admin/tournament-event-stage', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(itemData.value[item.id])
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200 && response.data) {
                tournamentStageStore.replaceDataRow(response.data)
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
                deInitUpdateForm(response.data.id)
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deleteItem() {
      tournamentStageStore.setActiveStageId(stageId.value)
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem()
        item.tournamentId = globalStore.tournamentStore.tournament.id
        item.stageId = stageId.value
        if (item) {
          fetch(globalStore.apiUrl + '/admin/tournament-event-stage', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                if (response.data && response.data.item) {
                  tournamentStageStore.replaceDataRow(response.data.item)
                  notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
                  if (response.data.parameters) {
                    eventStageParameterStore.replaceStageParameters(response.data.parameters, getItem().id)
                  }
                } else {
                  tournamentStageStore.deleteDataRow(item)
                  notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
                }
              } else if (response.error) {
                notificationStore.add({ type: 'error', message: response.error.description })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deInitUpdateForm(id) {
      updateFormActive.value[id] = false
      if (itemData.value[id]) {
        delete (itemData.value[id])
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
    }

    return {
      itemData, updateFormActive, addFormActive, stageSystemTypes, stageSettingTypes, tournamentStageStore, schema,
      globalStore, eventStore, eventOrderTypes, eventOrderTypes4,
      getItem, initAddForm, initUpdateForm, processAddForm, processUpdateForm, deleteItem, deInitUpdateForm, deInitAddForm
    }
  }
}
</script>
