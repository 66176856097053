<template>
  <div class="teamEvents" v-if="teamEventStore.hasStageTeamEvents(stageId)">
    <FormKit v-if="Object.keys(getStageSigns()).length > 1" type="select"
             :options="{'' : globalStore.vocabularyStore.getWordByIdAndLanguage('filter groups', globalStore.languageId), ...getStageSigns()}"
             v-model="groupSign"/>
    <table class="table table-hover align-middle table-light">
      <tbody>
      <template v-for="row in teamEventStore.getTeamEventsByStage(stageId)" :key="row.id">
        <TeamEventItem v-if="groupSign === '' || groupSign === getStageSign(row.id)"
            :teamEventObject="row"
            :is-updatable="!isStageLocked(row.stageId)"
        />
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {ref, toRef} from 'vue'
import {useGlobalStore} from "@/stores/GlobalStore"
import {useNotificationStore} from "@/stores/NotificationStore"
import {useTournamentParticipantStore} from "@/stores/TournamentParticipantStore";
import {useTournamentParticipantStageStore} from "@/stores/TournamentParticipantStageStore";
import {useTeamEventStore} from "@/stores/TeamEventStore";
import {useEventStore} from "@/stores/EventStore";
import TeamEventItem from "@/components/TeamEventItem";

export default {
  name: 'TournamentStageTeamEvent',
  components: {TeamEventItem},
  props: {
    setStageId: {
      required: true,
      type: Number
    }
  },

  setup(props) {
    const stageId = toRef(props, 'setStageId'),
        teamEventStore = useTeamEventStore(),
        eventStore = useEventStore(),
        notificationStore = useNotificationStore(),
        tournamentParticipantStageStore = useTournamentParticipantStageStore(),
        tournamentParticipantStore = useTournamentParticipantStore(),
        globalStore = useGlobalStore(),
        itemData = ref({}),
        formActive = ref({}),
        disqualificationOptions = {
          '0': '',
          '1': globalStore.vocabularyStore.getWordByIdAndLanguage('event disqualification error', globalStore.languageId),
          '2': globalStore.vocabularyStore.getWordByIdAndLanguage('event extension sorry', globalStore.languageId),
          '3': globalStore.vocabularyStore.getWordByIdAndLanguage('event extension time limit', globalStore.languageId)
        },
        groupSign = ref('')

    function getItem(id) {
      const teamEvents = teamEventStore.getTeamEventsByStage(stageId.value)
      for (let i in teamEvents) {
        if (teamEvents[i].id === id) {
          return teamEvents[i]
        }
      }
      return {}
    }

    function getStageSigns() {
      const teamEvents = teamEventStore.getTeamEventsByStage(stageId.value),
          data = {}
      for (let i in teamEvents) {
        data[teamEvents[i].stageSign] = globalStore.vocabularyStore.getWordByIdAndLanguage('filter group', globalStore.languageId) + ' ' + teamEvents[i].stageSign
      }

      return Object.keys(data).sort().reduce((obj, key) => {
        obj[key] = data[key]
        return obj
      }, {})
    }

    function getStageSign(id) {
      const teamEvent = teamEventStore.getTeamEventById(id)

      if (teamEvent.stageSign) {
        return teamEvent.stageSign
      }

      return ''
    }

    function initForm(id) {
      const item = getItem(id)
      if (!item) {
        return
      }
      itemData.value[id] = item
      formActive.value[id] = true
    }

    function processForm(id) {
      const item = getItem(id)
      if (item) {
        item.round = itemData.value[id].round

        fetch(globalStore.apiUrl + '/admin/team-event/' + item.id, {
          method: 'PUT',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(item)
        })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200 && response.data && response.data.teamEvent) {
                teamEventStore.actualizeRow(response.data.teamEvent)
                if (response.data.tournamentParticipantStage !== undefined) {
                  tournamentParticipantStageStore.mergeRows(response.data.tournamentParticipantStage)
                }
                if (response.data.tournamentParticipant !== undefined) {
                  tournamentParticipantStore.setData(response.data.tournamentParticipant)
                }
                if (response.data.teamEvents !== undefined) {
                  for (let i in response.data.teamEvents) {
                    teamEventStore.actualizeRow(response.data.teamEvents[i])
                  }
                }
                notificationStore.add({
                  type: 'success',
                  message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId)
                })
              } else if (response.error) {
                notificationStore.add({type: 'error', message: response.error.description})
              } else {
                notificationStore.add({
                  type: 'error',
                  message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
                })
              }
              deInitForm(id)
            })
            .catch(err => notificationStore.add({type: 'error', message: err.message}))
      }
    }

    function deInitForm(id) {
      formActive.value[id] = false
      if (itemData.value[id]) {
        delete (itemData.value[id])
      }
    }

    function isStageLocked(stageId) {
      if (globalStore.stageStore.isStageFinal(stageId)) {
        return false
      }
      const nextStageId = globalStore.tournamentStageStore.getNextStageIdByStageId(stageId)
      return teamEventStore.hasStageTeamEvents(nextStageId) && !teamEventStore.hasStageOnlyBlankTeamEvents(nextStageId)
    }

    return {
      stageId, formActive, itemData, globalStore, disqualificationOptions, teamEventStore, eventStore, groupSign,
      initForm, processForm, deInitForm, isStageLocked, getStageSigns, getStageSign
    }
  }
}
</script>
