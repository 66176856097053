<template>
  <div class="form-check form-switch">
    <input class="form-check-input" type="checkbox" v-model="viewStageSetting" id="flexSwitchCheckDefault" />
    <label class="form-check-label" for="flexSwitchCheckDefault">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('view stage setting', globalStore.languageId) }}</label>
  </div>
  <template v-if="globalStore.isUserTypeAdministrator">
    <ButtonAdd v-if="addFormActive === false" :clickFunction="initAddForm" />
    <table v-if="addFormActive === true" class="table table-hover table-striped align-middle table-light">
      <tbody>
        <tr>
          <td>
            <ButtonBack :clickFunction="deInitAddForm" />
          </td>
          <td>
            <FormKit type="form" v-model="itemData['']" @submit="processAddForm()">
              <FormKit
                type="number"
                name="setting"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant setting', globalStore.languageId)" />
              <FormKit
                type="text"
                name="participantName"
                :help="globalStore.vocabularyStore.getWordByIdAndLanguage('participant autoload', globalStore.languageId)"
                @keyup="loadParticipants(itemData[''], 'participantName', 'participantId')"
                autocomplete="off" />
              <FormKit
                type="select"
                :options="autoloadedParticipants"
                validation="required"
                name="participantId"
                :label="globalStore.vocabularyStore.getWordByIdAndLanguage('participant autoloaded', globalStore.languageId)" />
            </FormKit>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
  <div v-if="tournamentParticipantStore.count">
    <div class="float-end">
      <FormKit type="select"
        :options="sortLisOptions"
        :placeholder="globalStore.vocabularyStore.getWordByIdAndLanguage('sort by', globalStore.languageId)"
        v-model="sortListBy" />
    </div>
    <table class="table table-hover table-striped align-middle table-light">
      <thead>
        <tr>
          <td>{{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant setting', globalStore.languageId) }}</td>
          <td>{{ globalStore.vocabularyStore.getWordByIdAndLanguage('count of', globalStore.languageId) }}: {{ tournamentParticipantStore.count }}</td>
          <td v-if="tournamentStore.tournament.competitionId">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant ranking', globalStore.languageId) }}</td>
          <td v-else colspan="3">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant ranking', globalStore.languageId) }}</td>
          <td colspan="2" v-if="tournamentStore.tournament.competitionId">{{ globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant points', globalStore.languageId) }}</td>
        </tr>
      </thead>
      <tbody>
        <template v-for="row in getSortedList()" :key="row.id">
          <tr>
            <template v-if="globalStore.isUserTypeAdministrator && updateFormActive[row.id] === true && itemData[row.id].id === row.id">
              <td>
                <ButtonBack :clickFunction="deInitUpdateForm" :clickFunctionParameter="row.id" />
                <FormKit type="number" v-model="itemData[row.id].setting" :label="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant setting', globalStore.languageId)" />
              </td>
              <td>
                <strong>&#160;{{ globalStore.getParticipantName(row) }}</strong>
              </td>
              <td>
                <FormKit type="number" v-model="itemData[row.id].position" :label="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant ranking', globalStore.languageId)" />
              </td>
              <td v-if="tournamentStore.tournament.competitionId">
                <FormKit type="number" v-model="itemData[row.id].points" :label="globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant points', globalStore.languageId)" />
              </td>
            </template>
            <template v-else>
              <td>
                <strong>{{ row.setting }}</strong>
                <img v-if="row.fixed" :src="require('@/assets/images/lock.svg')" @click="viewLiveScore = !viewLiveScore" alt="" class="icon-lock" />
                <small v-else-if="row.settingNote">
                  ({{ row.settingNote }})
                </small>
              </td>
              <td>
                <strong>{{ globalStore.getParticipantName(row) }}</strong><br />
                <small>{{ row.inserted ? globalStore.formatDateTime(row.inserted) : '' }}</small>
              </td>
              <td>{{ row.position }}</td>
              <td>{{ row.points }}</td>
            </template>
            <td>
              <template v-if="globalStore.isUserTypeAdministrator">
                <template v-if="updateFormActive[row.id] === true && itemData[row.id].id === row.id">
                  <ButtonSave :clickFunction="processUpdateForm" :clickFunctionParameter="row.id" />
                </template>
                <template v-else>
                  <ButtonUpdate :clickFunction="initUpdateForm" :clickFunctionParameter="row.id" />
                  <span>&#160;</span>
                  <ButtonDelete v-if="!tournamentParticipantStageStore.hasTournamentParticipantsAnyStage(row.id)"
                    :clickFunction="deleteItem" :clickFunctionParameter="row.id" />
                </template>
              </template>
            </td>
          </tr>
          <TournamentStageParticipant v-if="viewStageSetting" :tournamentParticipantIdx="parseInt(row.id)" />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useTournamentStore } from "@/stores/TournamentStore"
import { useTournamentParticipantStore } from "@/stores/TournamentParticipantStore"
import { useTournamentParticipantStageStore } from "@/stores/TournamentParticipantStageStore"
import { useParticipantStore } from "@/stores/ParticipantStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useGlobalStore } from "@/stores/GlobalStore"
import TournamentStageParticipant from "@/components/TournamentManagement/TournamentStageParticipant";
import ButtonAdd from "@/components/Button/ButtonAdd";
import ButtonBack from "@/components/Button/ButtonBack";
import ButtonSave from "@/components/Button/ButtonSave.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";

export default {
  name: 'TournamentParticipant',
  components: { ButtonDelete, ButtonUpdate, ButtonSave, ButtonBack, TournamentStageParticipant, ButtonAdd },
  setup() {
    const itemData = ref({}),
      updateFormActive = ref({}),
      addFormActive = ref(false),
      autoloadedParticipants = ref({}),
      viewStageSetting = ref(false),
      sortListBy = ref(''),
      tournamentStore = useTournamentStore(),
      tournamentParticipantStore = useTournamentParticipantStore(),
      tournamentParticipantStageStore = useTournamentParticipantStageStore(),
      participantStore = useParticipantStore(),
      notificationStore = useNotificationStore(),
      globalStore = useGlobalStore(),
      sortLisOptions = {
        'position': globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant ranking', globalStore.languageId),
        'setting': globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant setting', globalStore.languageId),
        'inserted': globalStore.vocabularyStore.getWordByIdAndLanguage('tournament participant inserted', globalStore.languageId)
      }

    function getItem(id) {
      for (let i in tournamentParticipantStore.tournamentParticipants) {
        if (tournamentParticipantStore.tournamentParticipants[i].id === id) {
          return tournamentParticipantStore.tournamentParticipants[i]
        }
      }
      return {}
    }

    function initAddForm() {
      itemData.value[''] = {}
      addFormActive.value = true
    }

    function initUpdateForm(id) {
      const item = getItem(id)
      if (!item) {
        return
      }
      itemData.value[id] = {
        id: item.id,
        participantId: item.participantId || item.teamId,
        position: item.position,
        points: item.points,
        setting: item.fixed ? item.setting : null
      }
      updateFormActive.value[id] = true
    }

    function processAddForm() {
      itemData.value[''].tournamentId = tournamentStore.tournament.id
      fetch(globalStore.apiUrl + '/admin/tournament-participant', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value[''])
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data) {
            participantStore.participants[response.data.participant.id] = response.data.participant
            if (response.data.tournamentParticipants) {
              tournamentParticipantStore.updateRows(response.data.tournamentParticipants)
            }
            tournamentParticipantStore.tournamentParticipants.push(response.data.item)
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully added', globalStore.languageId) })
            deInitAddForm()
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processUpdateForm(id) {
      const item = getItem(id)
      if (item) {
        itemData.value[id].tournamentId = tournamentStore.tournament.id
        fetch(globalStore.apiUrl + '/admin/tournament-participant', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(itemData.value[id])
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data) {
              if (response.data.tournamentParticipants) {
                tournamentParticipantStore.updateRows(response.data.tournamentParticipants)
              }
              if (response.data.item) {
                for (let i in tournamentParticipantStore.tournamentParticipants) {
                  if (tournamentParticipantStore.tournamentParticipants[i].id === response.data.item.id) {
                    tournamentParticipantStore.tournamentParticipants[i] = response.data.item
                  }
                }
              }
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
              deInitUpdateForm(id)
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
          })
          .catch((err) => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem(id) {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = getItem(id)
        if (item) {
          fetch(globalStore.apiUrl + '/admin/tournament-participant', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                for (let i in tournamentParticipantStore.tournamentParticipants) {
                  if (tournamentParticipantStore.tournamentParticipants[i].id === id) {
                    const participantId = tournamentParticipantStore.tournamentParticipants[i].participantId
                    tournamentParticipantStore.tournamentParticipants.splice(i, 1)
                    delete participantStore.participants[participantId]
                    break
                  }
                }
                if (response.data.tournamentParticipants) {
                  tournamentParticipantStore.updateRows(response.data.tournamentParticipants)
                }
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deInitUpdateForm(id) {
      updateFormActive.value[id] = false
      if (itemData.value[id]) {
        delete (itemData.value[id])
        autoloadedParticipants.value = {}
      }
    }

    function deInitAddForm() {
      addFormActive.value = false
      itemData.value[''] = {}
      autoloadedParticipants.value = {}
    }

    function loadParticipants(vModel, sourceKeyName, targetValueName) {
      const key = vModel[sourceKeyName]
      if (key.length > 2) {
        const payload = {
          type: (tournamentStore.isTournamentForTeams ? 'team-in-season' : 'participant'),
          of: (tournamentStore.tournament.organizationId === 4) ? 1 : 0,
          o: tournamentStore.tournament.organizationId,
          se: tournamentStore.tournament.sectionId,
          s: tournamentStore.tournament.season,
          t: tournamentStore.tournament.tableSizeId,
          d: globalStore.disciplineStore.disciplines.length === 1 && globalStore.disciplineStore.disciplines[0].id === 6 ? 6 : null,
          teamTypeId: tournamentStore.tournament.teamTypeId,
          key: key
        }
        const url = globalStore.apiUrl + '/admin/autocomplete?' + new URLSearchParams(payload)
        fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200) {
              let setSelected = false;
              const participants = {}
              for (let i = 0; i < response.data.length; i++) {
                if (tournamentParticipantStore.getTournamentParticipantsByParticipantId(response.data[i].id) === false) {
                  participants[response.data[i].id] = response.data[i].name
                  if (setSelected === false) {
                    vModel[targetValueName] = response.data[i].id
                    setSelected = true
                  }
                }
              }
              autoloadedParticipants.value = participants
            }
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function getSortedList() {
      if (sortListBy.value === 'inserted') {
        return tournamentParticipantStore.tournamentParticipants.sort((a, b) => {
          return a[sortListBy.value] && b[sortListBy.value] ? a[sortListBy.value].localeCompare(b[sortListBy.value]) : 1000000000
        })
      } else if (sortListBy.value === 'position') {
        return tournamentParticipantStore.tournamentParticipants.sort((a, b) => (a[sortListBy.value] ? a[sortListBy.value] : 1000) - (b[sortListBy.value] ? b[sortListBy.value] : 1000))
      }
      return tournamentParticipantStore.tournamentParticipants.sort((a, b) => a[sortListBy.value] - b[sortListBy.value])
    }

    return {
      addFormActive, updateFormActive, itemData, tournamentParticipantStore, tournamentParticipantStageStore,
      participantStore, globalStore, autoloadedParticipants, tournamentStore, viewStageSetting,
      sortListBy, sortLisOptions,
      initUpdateForm, initAddForm, processUpdateForm, deInitUpdateForm, deInitAddForm, processAddForm,
      deleteItem, loadParticipants, getSortedList
    }
  }
}
</script>

<style>
.icon-lock {
  width: 0.75em;
}
</style>
