import { defineStore } from 'pinia'

export const useCompetitionResultCriterionStore = defineStore('CompetitionResultCriterionStore', {
  state: () => {
    return {
      competitionResultCriterion: []
    }
  },
  getters: {
    count: state => state.competitionResultCriterion.length
  },
  actions: {
    setData(data) {
      this.competitionResultCriterion = data
    }
  }
})
