<template>
  <table class="table table-hover table-striped align-middle table-light">
    <tbody>
      <tr>
        <td>
          <FormKit type="form" v-model="itemData" @submit="processLoginForm()">
            <FormKit type="email" name="name" />
            <FormKit type="password" name="password" />
          </FormKit>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { ref } from "vue"
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'LoginAdmin',
  components: {},

  setup() {
    const globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      itemData = ref({}),
      router = useRouter(),
      route = useRoute()

    itemData.value = {}

    function processLoginForm() {
      fetch(globalStore.apiUrl + '/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemData.value)
      })
        .then(res => res.json())
        .then(async response => {
          if (response.statusCode === 200 && response.data && response.data.user) {
            await globalStore.userAdminStore.setUser(response.data.user)
            await globalStore.checkUserAdminParams()
            if (route.name === 'Home') {
              if (!globalStore.userAdminStore.activeClub) {
                router.push('/competitions')
              } else {
                window.location.reload()
              }
            } else if (route.name === 'LoginAdmin' && !route.redirectedFrom) {
              if (!globalStore.userAdminStore.activeClub) {
                router.push('/competitions')
              } else {
                router.push('/')
              }
            } else if (route.redirectedFrom) {
              router.push(route.redirectedFrom)
            } else {
              window.location.reload()
            }
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else if (response.statusCode !== 200) {
            notificationStore.add({
              type: 'error',
              message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId)
            })
          }
          return route
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    return {
      globalStore, itemData,
      processLoginForm
    }
  }
}
</script>
