import {defineStore} from 'pinia'

export const useTeamStore = defineStore('TeamStore', {
  state: () => {
    return {
      teams: {}
    }
  },
  getters: {
    count: state => Object.keys(state.teams).length,
    getTeamById: state => {
      return id => {
        for (let i in state.teams) {
          if (+state.teams[i].id === +id) {
            return state.teams[i]
          }
        }
      }
    }
  },
  actions: {
    setData(data) {
      this.teams = data
    }
  }
})