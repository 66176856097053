<template>
  <template v-if="!teamEvent">
    <tr>
      <td colspan="6" v-if="addFormActive === false">
        <ButtonAdd :click-function="initAddForm" />
      </td>
      <template v-else>
        <td>
          <ButtonBack :click-function="deInitAddForm" />
        </td>
        <td colspan="5">
          <FormKit type="form" v-model="itemData" @submit="processAddForm">
            <FormKit type="number" v-model="itemData.round" validation="required" :label="globalStore.getWordById('event round')" />
            <FormKit type="datetime-local" v-model="itemData.dateStart" validation="required" :label="globalStore.getWordById('date start')" />
            <FormKit type="select" @change="setLounge" :options="{ '0': '', ...participantOptions }" v-model="itemData.participantId0" validation="required" :label="globalStore.getWordById('home')" />
            <FormKit type="select" :options="{ '0': '', ...participantOptions }" v-model="itemData.participantId1" validation="required" :label="globalStore.getWordById('away')" />
            <FormKit type="select" :options="{ '0': '', ...loungeOptions }" v-model="itemData.loungeId" validation="required" :label="globalStore.getWordById('lounge')" />
          </FormKit>
        </td>
      </template>
    </tr>
  </template>
  <template v-if="teamEvent && teamEvent.id">
    <template v-if="formActive === true">
      <tr class="bg-light">
        <td>
          <ButtonBack :click-function="deInitForm" />
        </td>
        <td colspan="3">
          <FormKit type="datetime-local" v-model="itemData.dateStart" validation="required" :label="globalStore.getWordById('date start')" />
          <FormKit v-if="Object.keys(loungeOptions).length" type="select" :options="loungeOptions" v-model="itemData.loungeId" :label="globalStore.getWordById('lounge')" />
        </td>
        <td>
          <FormKit type="number" v-model="itemData.round" validation="required" :label="globalStore.vocabularyStore.getWordByIdAndLanguage('event round', globalStore.languageId)" />
          <FormKit type="select" :options="{ '0': '', '1': 'Odloženo', '2': 'Zrušeno' }" v-model="itemData.statusId" validation="required" :label="globalStore.getWordById('status')" />
        </td>
        <td>
          <ButtonSave :click-function="processForm" />
        </td>
      </tr>
      <tr>
        <td colspan="6">
          <table class="table table-sm">
            <tbody>
              <tr v-for="(eventParticipant, index) in teamEvent.eventParticipant" :key="eventParticipant.id">
                <td>
                  {{ globalStore.getParticipantNameById(eventParticipant.participantId) }}
                </td>
                <td>
                  <FormKit type="select" :options="disqualificationOptions" v-model="itemData.eventParticipant[index].disqualificationId" />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </template>
    <template v-else>
      <tr>
        <td>
          <template v-if="!hasByeParticipant()">
            {{ teamEvent.round }}. | {{ globalStore.formatDateTime(teamEvent.dateStart) }} |
          </template>
          {{ teamEvent.stageSign }}
          <br />
          <button @click="loadEvents" class="btn btn-sm btn-primary">
            {{ globalStore.getWordById('events') }}
          </button>
          <img :src="require('@/assets/images/referee.svg')" @click="viewReferee = !viewReferee" alt="" class="icon-abacus" />
          <EventReferee v-if="viewReferee" :eventObject="teamEvent" />
        </td>
        <template v-for="(eventParticipant, i) in teamEvent.eventParticipant" :key="eventParticipant.id">
          <td v-if="i % 2 === 1">
            <strong>{{ teamEventStore.freelancerTeamId === eventParticipant.teamId ? 0 : eventParticipant.score }}</strong>
            <br />
            <span class="badge bg-secondary">
              {{ teamEventStore.freelancerTeamId === eventParticipant.teamId ? 0 : ((+eventParticipant.wonParts * 2) + +eventParticipant.drawParts) }}
            </span>
          </td>
          <td :class="i % 2 === 0 ? 'text-end' : ''">
            {{ globalStore.getParticipantNameById(eventParticipant.participantId) }}
            <template v-if="eventParticipant.disqualificationId">
              <br />
              <span v-if="eventParticipant.disqualificationId" class="badge bg-secondary">
                {{ disqualificationOptions[eventParticipant.disqualificationId] }}
              </span>
            </template>
            <template v-else>
              <br />
              <small>{{ globalStore.formatAverage(eventParticipant.average, 3) }}</small>
            </template>
          </td>
          <td v-if="i % 2 === 0" class="text-end">
            <strong>{{ teamEventStore.freelancerTeamId === eventParticipant.teamId ? 0 : eventParticipant.score }}</strong>
            <br />
            <span class="badge bg-secondary">
              {{ teamEventStore.freelancerTeamId === eventParticipant.teamId ? 0 : ((+eventParticipant.wonParts * 2) + +eventParticipant.drawParts) }}
            </span>
          </td>
        </template>
        <td>
          <ButtonUpdate
            v-if="isUpdatable"
            :click-function="initForm" />
          <span>&#160;</span>
          <ButtonDelete v-if="globalStore.userAdminStore.isUserRegion"
            :click-function="deleteItem" />
        </td>
      </tr>
      <template v-if="teamEvent && teamEvent.id && !hasByeParticipant()">
        <tr v-if="viewEvents
    && hasEvent()
    && (hasAllTeamEventParticipants() || hasAnyEventParticipant())
    && teamEvent.eventParticipant[0].score === null
    ">
          <td colspan="6">
            <TeamEventParticipantSettingModal v-if="hasEvent() && hasAllTeamEventParticipants()" :data="getDataForParticipantSetting()" />
            <EventLiveScoreModal v-if="hasAnyEventParticipant()" :data="getDataForLiveScore()" :event="teamEvent" />
          </td>
        </tr>
        <tr v-if="viewEvents && globalStore.eventStore.hasTeamEventEvent(teamEvent.id)">
          <td colspan="6">
            <table class="table table-sm table-hover align-middle table-light">
              <tbody>
                <template v-for="event in eventStore.getTeamEventEvents(teamEvent.id)" :key="event.id">
                  <EventItem
                    :eventObject="event"
                    :isUpdatable="isUpdatable" />
                </template>
              </tbody>
            </table>
          </td>
        </tr>
      </template>
    </template>
  </template>
</template>

<script>
import { toRef, ref } from 'vue'
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { useTeamEventStore } from "@/stores/TeamEventStore";
import { useEventStore } from "@/stores/EventStore";
import { useEventEquipmentTableStore } from "@/stores/EventEquipmentTableStore";
import EventItem from "@/components/EventItem";
import TeamEventParticipantSettingModal from "@/components/Modal/TeamEventParticipantSettingModal";
import EventLiveScoreModal from "@/components/Modal/EventLiveScoreModal";
import { useTeamParticipantStore } from "@/stores/TeamParticipantStore";
import ButtonAdd from "@/components/Button/ButtonAdd.vue";
import ButtonBack from "@/components/Button/ButtonBack.vue";
import ButtonSave from "@/components/Button/ButtonSave.vue";
import ButtonUpdate from "@/components/Button/ButtonUpdate.vue";
import ButtonDelete from "@/components/Button/ButtonDelete.vue";
import EventReferee from "@/components/EventReferee";

export default {
  name: 'TeamEventItem',
  components: { EventReferee, ButtonAdd, ButtonUpdate, ButtonDelete, ButtonSave, ButtonBack, EventItem, TeamEventParticipantSettingModal, EventLiveScoreModal },
  props: {
    teamEventObject: {
      required: false,
      type: Object
    },
    isUpdatable: {
      required: false,
      type: Boolean,
      default: false
    },
    cId: {
      required: false,
      type: Number
    }
  },

  setup(props) {
    const teamEvent = toRef(props, 'teamEventObject'),
      competitionId = toRef(props, 'cId'),
      teamEventStore = useTeamEventStore(),
      viewReferee = ref(false),
      eventStore = useEventStore(),
      globalStore = useGlobalStore(),
      notificationStore = useNotificationStore(),
      teamParticipantStore = useTeamParticipantStore(),
      eventEquipmentTableStore = useEventEquipmentTableStore(),
      viewEvents = ref(false),
      itemData = ref({}),
      addFormActive = ref(false),
      formActive = ref(false),
      disqualificationOptions = {
        '0': '',
        '1': globalStore.vocabularyStore.getWordByIdAndLanguage('event disqualification error', globalStore.languageId),
        '2': globalStore.vocabularyStore.getWordByIdAndLanguage('event extension sorry', globalStore.languageId),
        '3': globalStore.vocabularyStore.getWordByIdAndLanguage('event extension time limit', globalStore.languageId)
      },
      loungeOptions = getLounges(),
      participantOptions = getParticipants()

    function getLounges() {
      const addresses = {},
        addressList = globalStore.clubAddressStore.data,
        tournamentAddressList = globalStore.loungeClubAddressTableStore.data

      for (let i in addressList) {
        const address = addressList[i]
        addresses[address.loungeId] = (address.city ? address.city + ', ' : '') + address.street + ' ' + address.streetNumber
      }
      for (let i in tournamentAddressList) {
        const address = tournamentAddressList[i]
        addresses[address.loungeId] = (address.city ? address.city + ', ' : '') + address.street + ' ' + address.streetNumber
      }
      return addresses
    }

    function getParticipants() {
      const participants = {}
      if (competitionId.value) {
        const sources = globalStore.competitionParticipantStore.getByCompetitionId(competitionId.value)
        for (let i in sources) {
          participants[sources[i].participantId] = globalStore.getParticipantNameById(sources[i].participantId)
        }
      }
      return participants
    }

    function initForm() {
      const item = Object.assign({}, teamEvent.value)
      if (!item) {
        return
      }
      itemData.value = item
      formActive.value = true
    }

    function initAddForm() {
      itemData.value = itemData.value || {}
      itemData.value.participantId0 = ''
      itemData.value.participantId1 = ''
      itemData.value.loungeId = ''
      const lastItem = decodeURIComponent(globalStore.getCookieValueByName('lte'))
      if (lastItem) {
        const e = JSON.parse(lastItem)
        if (e) {
          itemData.value.round = e.round
          itemData.value.dateStart = e.dateStart
        }
      }
      addFormActive.value = true
    }

    function deInitAddForm() {
      itemData.value.participantId0 = ''
      itemData.value.participantId1 = ''
      itemData.value.loungeId = ''
      addFormActive.value = false
    }

    function getDataForLiveScore() {
      const events = eventStore.getTeamEventEvents(teamEvent.value.id);
      return {
        events: events.filter(event => event.eventParticipant[0].score === null),
        identifier: teamEvent.value.id
      }
    }

    function getDataForParticipantSetting() {
      const data = {
        competitionId: teamEvent.value.competitionId,
        tournamentId: teamEvent.value.tournamentId,
        dateStart: teamEvent.value.dateStart,
        events: {},
        teams: {},
        teamParticipants: {},
        disciplines: [],
        teamEventId: teamEvent.value.id
      }

      if (globalStore.eventStore.getTeamEventEvents(teamEvent.value.id).length) {
        const events = globalStore.eventStore.getTeamEventEvents(teamEvent.value.id)
        for (let i in events) {
          const event = events[i]
          if (data.events[' ' + event.disciplineId] === undefined) {
            data.events[' ' + event.disciplineId] = 0
            data.disciplines[event.disciplineId] = globalStore.getDisciplineNameById(event.disciplineId)
          }
          data.events[' ' + event.disciplineId] = data.events[' ' + event.disciplineId] + 1
        }
      }

      for (let i in teamEvent.value.eventParticipant) {
        data.teams[i] = {}
        data.teams[i][teamEvent.value.eventParticipant[i].teamId] = globalStore.getParticipantNameById(teamEvent.value.eventParticipant[i].participantId)
      }

      for (let i in data.teams) {
        for (let j in data.teams[i]) {
          const teamParticipant = teamParticipantStore.getTeamParticipantsByTeamId(j)
          data.teamParticipants[j] = {}
          for (let k in teamParticipant) {
            data.teamParticipants[j][teamParticipant[k].participantId] = globalStore.getParticipantNameYearMonthBirthById(teamParticipant[k].participantId)
          }
        }
      }

      if (
        data.events
        && Object.keys(data.events).length === 1
        && data.events[' 6']
        && data.events[' 6'] == 9
      ) {
        // 3C 9 events => 3C 3 events
        data.events[' 6'] = 3
      }

      return data
    }

    function processAddForm() {
      const item = {},
        competition = globalStore.competitionStore.competition
      item.round = itemData.value.round
      item.loungeId = itemData.value.loungeId
      item.dateStart = itemData.value.dateStart
      item.participantId = [itemData.value.participantId0, itemData.value.participantId1]
      item.competitionId = competitionId.value
      item.organizationId = competition.organizationId
      item.sectionId = competition.sectionId
      item.season = competition.season
      item.tableSizeId = competition.tableSizeId

      fetch(globalStore.apiUrl + '/admin/team-event', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item)
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200 && response.data && response.data.teamEvent) {
            teamEventStore.addData([response.data.teamEvent])
            if (response.data.event && response.data.event.length) {
              eventStore.addData(response.data.event)
            }
            notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            deInitAddForm()
            globalStore.cookiesStore.setCookieValue('lte', JSON.stringify(item))
          } else if (response.error) {
            notificationStore.add({ type: 'error', message: response.error.description })
          } else {
            notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    function processForm() {
      const item = teamEvent.value
      if (item) {
        item.round = itemData.value.round
        item.loungeId = itemData.value.loungeId
        item.statusId = itemData.value.statusId || null
        item.dateStart = itemData.value.dateStart

        fetch(globalStore.apiUrl + '/admin/team-event', {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(item)
        })
          .then(res => res.json())
          .then(response => {
            if (response.statusCode === 200 && response.data && response.data.teamEvent) {
              teamEventStore.actualizeRow(response.data.teamEvent)
              if (response.data.event && response.data.event.length) {
                for (let i in response.data.event) {
                  const event = response.data.event[i],
                    storedEvent = eventStore.getEventById(event.id)
                  if (storedEvent) {
                    storedEvent.dateStart = event.dateStart
                  }
                }
              }
              notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully updated', globalStore.languageId) })
            } else if (response.error) {
              notificationStore.add({ type: 'error', message: response.error.description })
            } else {
              notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
            }
            deInitForm()
          })
          .catch(err => notificationStore.add({ type: 'error', message: err.message }))
      }
    }

    function deleteItem() {
      const text = globalStore.getWordById('really delete item')
      if (confirm(text) === true) {
        const item = { id: teamEvent.value.id }
        if (item) {
          fetch(globalStore.apiUrl + '/admin/team-event', {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(item)
          })
            .then(res => res.json())
            .then(response => {
              if (response.statusCode === 200) {
                teamEventStore.removeDataRow(item.id)
                notificationStore.add({ type: 'success', message: globalStore.vocabularyStore.getWordByIdAndLanguage('successfully deleted', globalStore.languageId) })
              } else {
                notificationStore.add({ type: 'error', message: globalStore.vocabularyStore.getWordByIdAndLanguage('error occurred', globalStore.languageId) })
              }
            })
            .catch(err => notificationStore.add({ type: 'error', message: err.message }))
        }
      }
    }

    function deInitForm() {
      formActive.value = false
      itemData.value = {}
    }

    function hasByeParticipant() {
      for (let i in teamEvent.value.eventParticipant) {
        if (teamEvent.value.eventParticipant[i].teamId && teamEvent.value.eventParticipant[i].teamId === teamEventStore.freelancerTeamId) {
          return true
        }
      }
      return false
    }

    function hasAnyEventParticipant() {
      const events = eventStore.getTeamEventEvents(teamEvent.value.id)
      for (let i in events) {
        for (let j in events[i].eventParticipant) {
          if (events[i].eventParticipant[j].participantId) {
            return true
          }
        }
      }
      return false
    }

    function hasEvent() {
      return eventStore.getTeamEventEvents(teamEvent.value.id).length > 0
    }

    function hasAllTeamEventParticipants() {
      for (let i in teamEvent.value.eventParticipant) {
        if (teamEvent.value.eventParticipant[i].participantId === null) {
          return false
        }
      }
      return true
    }

    function setLounge() {
      const participant = globalStore.competitionParticipantStore.getByCompetitionAndParticipantId(competitionId.value, itemData.value.participantId0)
      if (participant && participant[0]) {
        const lounges = globalStore.clubAddressStore.getByClubId(participant[0].clubId)
        if (lounges && lounges[0]) {
          itemData.value.loungeId = lounges[0].loungeId
        }
      }
    }

    function loadEvents() {
      viewEvents.value = !viewEvents.value
      if (globalStore.eventStore.hasTeamEventEvent(teamEvent.value.id)) {
        return
      }
      const url = globalStore.apiUrl + '/admin/team-event-events/' + teamEvent.value.id
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(response => {
          if (response.statusCode === 200) {
            if (response.data) {
              if (response.data.event) {
                globalStore.eventStore.addData(response.data.event)
              }
              if (response.data.liveScore) {
                globalStore.liveScoreStore.addDataRows(response.data.liveScore)
              }
              if (response.data.liveStream) {
                globalStore.liveStreamStore.addDataRows(response.data.liveStream)
              }
              if (response.data.eventEquipmentTable) {
                eventEquipmentTableStore.addDataRows(response.data.eventEquipmentTable)
              }
              if (response.data.participant) {
                globalStore.participantStore.addData(response.data.participant)
              }
              if (response.data.eventReferee) {
                globalStore.eventRefereeStore.addData(response.data.eventReferee)
              }
            }
          }
        })
        .catch(err => notificationStore.add({ type: 'error', message: err.message }))
    }

    return {
      teamEvent, formActive, teamEventStore, eventStore, globalStore, viewEvents, viewReferee,
      disqualificationOptions, loungeOptions, itemData, addFormActive, participantOptions,
      initForm, processForm, deInitForm, getDataForLiveScore, getDataForParticipantSetting, hasAnyEventParticipant,
      hasEvent, hasAllTeamEventParticipants, hasByeParticipant, processAddForm, initAddForm, deInitAddForm,
      setLounge, loadEvents, deleteItem
    }
  }
}
</script>
