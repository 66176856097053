import {defineStore} from 'pinia'

export const useTournamentStageRankingStore = defineStore('TournamentStageRankingStore', {
  state: () => {
    return {
      tournamentStageRanking: []
    }
  },
  getters: {
    count: state => state.tournamentStageRanking.length
  },
  actions: {
    setData(data) {
      for (let i in data) {
        this.tournamentStageRanking[i] = data[i]
      }
    }
  }
})