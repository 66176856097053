<template>
  <MenuBase :onChangeFn="loadOrganizations" />
  <div class="table-responsive">
    <template v-if="globalStore.userAdminStore.propertiesLoaded">
      <table class="table table-striped">
        <tbody>
          <OrganizationItem />
          <template v-if="globalStore.organizationStore.data.length">
            <template v-for="row in globalStore.organizationStore.data" :key="row.id">
              <OrganizationItem :organizationObject="row" />
            </template>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import { useGlobalStore } from "@/stores/GlobalStore"
import { useNotificationStore } from "@/stores/NotificationStore"
import { onMounted } from "vue"
import MenuBase from "@/components/Menu/MenuBase"
import OrganizationItem from "@/components/OrganizationItem"

export default {
  name: 'OrganizationList',
  components: { MenuBase, OrganizationItem },

  setup() {
    const globalStore = useGlobalStore(),
      notificationStore = useNotificationStore()

    onMounted(() => {
      loadOrganizations()
    })

    function loadOrganizations() {
      if (!globalStore.userAdminStore.activeSeason) {
        return
      }
      const url = globalStore.apiUrl + '/admin/organizations/' + globalStore.userAdminStore.activeOrganization
      fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => {
          if (res.status !== 200) {
            throw new Error(res.statusText)
          }
          return res.json()
        })
        .then(data => {
          globalStore.vocabularyStore.addRows(data.data.vocabulary)
          globalStore.organizationStore.setData(data.data.organization)
          globalStore.countryStore.setData(data.data.country)
          globalStore.clubStore.setData(data.data.club)
        })
        .catch(err => notificationStore.add({ type: 'error', message: err }))
    }

    return {
      globalStore,
      loadOrganizations
    }
  }
}
</script>
