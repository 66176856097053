import {defineStore} from 'pinia'

export const useTournamentParticipantStore = defineStore('TournamentParticipantStore', {
  state: () => {
    return {
      tournamentParticipants: []
    }
  },
  getters: {
    count: state => state.tournamentParticipants.length,
    getTournamentParticipantsByParticipantId: state => {
      return id => {
        for (let i = 0; i < state.tournamentParticipants.length; i++) {
          if (state.tournamentParticipants[i].participantId === id) {
            return state.tournamentParticipants[i]
          }
        }
        return false
      }
    },
    getParticipantIdByTournamentParticipantId: state => {
      return (id, isTournamentForTeams) => {
        for (let i = 0; i < state.tournamentParticipants.length; i++) {
          if (state.tournamentParticipants[i].id === id) {
            return isTournamentForTeams ? state.tournamentParticipants[i].teamId : state.tournamentParticipants[i].participantId
          }
        }
        return false
      }
    }
  },
  actions: {
    setData(data) {
      this.tournamentParticipants = data
    },
    updateRows(data) {
      for (let i in data) {
        const id = data[i].id
        for (let j in this.tournamentParticipants) {
          if (this.tournamentParticipants[j].id === id) {
            this.tournamentParticipants[j] = data[i]
          }
        }
      }
    }
  }
})