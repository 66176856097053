<template>
  <TournamentList />
</template>

<script>
import TournamentList from "@/components/TournamentList";
export default {
  components: { TournamentList }
}
</script>
