<template>
  <OrganizationList />
</template>

<script>
import OrganizationList from "@/components/OrganizationList";
export default {
  components: { OrganizationList }
}
</script>
