import { defineStore } from 'pinia'

export const useTournamentStore = defineStore('TournamentStore', {
  state: () => {
    return {
      tournaments: [],
      tournament: {}
    }
  },
  getters: {
    count: state => state.tournaments.length,
    isTournamentForTeams: state => state.tournament.teamTypeId && state.tournament.teamTypeId !== 5,
    getById: state => {
      return id => {
        if (!id) {
          return null
        }
        const tournament = state.tournaments.filter(row => +row.id === +id)
        if (tournament && tournament[0]) {
          return tournament[0]
        }
        return null
      }
    }
  },
  actions: {
    setData(data) {
      this.tournaments = data
    },
    setDataOne(data) {
      this.tournament = data
    },
    deleteRow(id) {
      for (let i in this.tournaments) {
        if (+id === +this.tournaments[i].id) {
          delete this.tournaments[i]
        }
      }
    },
    updateRow(data) {
      for (let i in this.tournaments) {
        if (+data.id === +this.tournaments[i].id) {
          this.tournaments[i] = data
        }
      }
    },
    addRow(data) {
      this.tournaments.push(data)
    }
  }
})
